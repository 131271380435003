import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    error: {},
    success: false,
    count: {}
}

type AuthActionType = {
    type:
    | AuthActionTypes.FETCH_COUNT
    | AuthActionTypes.FETCH_COUNT_SUCCESS
    | AuthActionTypes.FETCH_COUNT_FAIL
    
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    }
}

const WebsiteViewReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.FETCH_COUNT:
            return { ...state, loading: true }
        case AuthActionTypes.FETCH_COUNT_SUCCESS:
            return { ...state, loading: false, count: action.payload.data }
        case AuthActionTypes.FETCH_COUNT_FAIL:
            return { ...state, loading: false, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default WebsiteViewReducer;