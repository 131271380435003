import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { WarehouseActionTypes } from './constant';
import {
  createWarehouse,
  fetchWarehouses,
  fetchWarehouseById,
  editWarehouse,
  fetchWarehouseItemsByWarehouseId,
} from 'helpers/api/warehouse';
import {
  createWarehouseSuccess,
  createWarehouseFailure,
  fetchWarehousesSuccess,
  fetchWarehousesFailure,
  fetchWarehouseByIdSuccess,
  fetchWarehouseByIdFailure,
  editWarehouseSuccess,
  editWarehouseFailure,
  fetchWarehouseItemsSuccess,
  fetchWarehouseItemsFailure,
} from './actions';

function* fetchWarehousesSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchWarehouses, page, limit, sortBy, sort, searchBy);
    const warehouseData = response.data; 
    yield put(fetchWarehousesSuccess(warehouseData));
  } catch (e: any) {
    yield put(fetchWarehousesFailure(e));
  }
}

function* createWarehouseSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;

    const response = yield call(createWarehouse, data);
    const warehouseData = response.data;
    yield put(createWarehouseSuccess(warehouseData));
    yield put({ type: WarehouseActionTypes.FETCH_WAREHOUSES_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createWarehouseFailure(e));
  }
}

function* fetchWarehouseByIdSaga(action: any): SagaIterator {
  try {
    console.log(action.payload, "shubhi")
    console.log("Saga: Fetching warehouse by ID:", action.payload);  // Debug log
    const response = yield call(fetchWarehouseById, action.payload);
    const warehouseData = response.data;
    console.log("Saga: API response for warehouse by ID:", warehouseData);  // Debug log

    yield put(fetchWarehouseByIdSuccess(warehouseData));
  } catch (e: any) {
    yield put(fetchWarehouseByIdFailure(e));
  }
}

function* editWarehouseSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;

    const response = yield call(editWarehouse, id, data);
    const warehouseData = response.data;
    yield put(editWarehouseSuccess(warehouseData));
    yield put({ type: WarehouseActionTypes.FETCH_WAREHOUSE_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editWarehouseFailure(e));
  }
}

function* fetchWarehouseItemsSaga(action: any): SagaIterator {
  try {
    const { warehouseId } = action.payload;
    const response = yield call(fetchWarehouseItemsByWarehouseId, warehouseId);
    yield put(fetchWarehouseItemsSuccess(response.data));
  } catch (e: any) {
    yield put(fetchWarehouseItemsFailure(e));
  }
}

function* watchWarehouse() {

  yield takeEvery(WarehouseActionTypes.FETCH_WAREHOUSE_ITEMS_REQUEST, fetchWarehouseItemsSaga);
  yield takeEvery(WarehouseActionTypes.FETCH_WAREHOUSES_REQUEST, fetchWarehousesSaga);
  yield takeEvery(WarehouseActionTypes.CREATE_WAREHOUSE_REQUEST, createWarehouseSaga);
  yield takeEvery(WarehouseActionTypes.FETCH_WAREHOUSE_BY_ID_REQUEST, fetchWarehouseByIdSaga);
  yield takeEvery(WarehouseActionTypes.EDIT_WAREHOUSE_REQUEST, editWarehouseSaga);
}

export default watchWarehouse;
