import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from './i18n';
import { configureStore } from "redux/store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// for admin charts 
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);




ReactDOM.render(

<Provider store={configureStore({})} >
  <I18nextProvider i18n={i18n}>
      <App />
  <ToastContainer
    pauseOnFocusLoss={false}
  />
  </I18nextProvider>
</Provider>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
