import { FabDrawingActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  fabDrawing: [],
  singleFabDrawing: {},
  error: {},
  success: false,
};

const FabDrawingReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case FabDrawingActionTypes.CREATE_FabDrawing_REQUEST:
    case FabDrawingActionTypes.EDIT_FabDrawing_REQUEST:
    case FabDrawingActionTypes.FETCH_FabDrawingS_REQUEST:
    case FabDrawingActionTypes.FETCH_FabDrawing_BY_ID_REQUEST:
    case FabDrawingActionTypes.RESET_FabDrawing_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case FabDrawingActionTypes.SET_SINGLE_FabDrawing:
    return { ...state , singleFabDrawing: action.payload.data, loading:false }  
    case FabDrawingActionTypes.CREATE_FabDrawing_SUCCESS:
      return {
        ...state,
        fabDrawing: [...state.fabDrawing, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case FabDrawingActionTypes.EDIT_FabDrawing_SUCCESS:
      const updatedFabDrawings = state.fabDrawing.map((fabDrawing: any) =>
        fabDrawing.id === action.payload.data.id ? action.payload.data : fabDrawing
      );
      return {
        ...state,
        fabDrawing: updatedFabDrawings,
        loading: false,
        error: {},
        success: true,
      };

    case FabDrawingActionTypes.FETCH_FabDrawingS_SUCCESS:
      return {
        ...state,
        fabDrawing: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case FabDrawingActionTypes.FETCH_FabDrawing_BY_ID_SUCCESS:
      return {
        ...state,
        singleFabDrawing: action.payload.data,
        fabDrawing: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case FabDrawingActionTypes.RESET_SINGLE_FabDrawing:
      return {
          ...state,
          singleFabDrawing: null,
      };

    case FabDrawingActionTypes.CREATE_FabDrawing_FAILURE:
    case FabDrawingActionTypes.EDIT_FabDrawing_FAILURE:
    case FabDrawingActionTypes.FETCH_FabDrawingS_FAILURE:
    case FabDrawingActionTypes.FETCH_FabDrawing_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default FabDrawingReducer;
