import { UserSession, UserType } from "redux/types/DataTypes";

const USER_TYPE_WITH_EXPLICIT_ACCESS = [
  UserType.CUSTOMER, UserType.VENDOR, UserType.TRANSPORTER, UserType.SUPPLIER
]
/**
 * @description
 * This class implements the methods to check the user permission
 * for a given UI elements (routes/components)
 */
class PermissionService {

  session: UserSession;
  constructor(userSession: UserSession) {
    this.session = userSession;
  }
  /**
   * @method Public
   * @description Fetch the user access from store
   */
  getCurrentUserAccess() {
    // Fetch from the storage where user state saved
    return this.session.privileges;
  }

  /**
   * @method Public
   * @param {string[]} currentUserRoles
   * @param {string} requiredRole
   *
   */
  verifyRole(currentUserAccess: string[], requiredAccess: string[]) {
    //check if user roles have the required access
    for (let i = 0; i < requiredAccess.length; i++) {
      const access = requiredAccess[i];
      if (!currentUserAccess.includes(access)) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param {string[]} requiredAccess
   * @returns {boolean}
   * @description Check if user can access the given element or not based on required access and user role
   */
  can(requiredAccess: string[]): boolean {
    const userPrivileges = this.getCurrentUserAccess();
    const hasAccess = this.verifyRole(userPrivileges, requiredAccess);
    return hasAccess;
  }

  validateUserType(userType?: UserType | UserType[] | '*', strictCheck?: boolean) {
    if (strictCheck) {
      if (Array.isArray(userType)) {
        return userType.includes(this.session.userType);
      } else {
        return userType === this.session.userType;
      }
    }
    if (userType === '*') {
      return true;
    }
    if (USER_TYPE_WITH_EXPLICIT_ACCESS.includes(this.session.userType)) {
      
      if (!userType) {
        return false;
      }
      if (Array.isArray(userType)) {
        return userType.includes(this.session.userType);
      }

      return userType === this.session.userType;
    }

    return true;
  }
}

export default PermissionService;
