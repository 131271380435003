import { ActionTypes } from './constants';


const INIT_STATE = {
    rawMaterial: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    createRawMaterial: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    updateRawMaterial: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    deleteRawMaterial: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    rawMaterialDetails: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    unit: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    chart: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
    commonError: {
        loading: false,
        error: null,
        success: false,
        data: null
    }
}

type RawMaterialType = {
    type:
    | ActionTypes.FETCH_RAW_MATERIAL_LIST
    | ActionTypes.FETCH_RAW_MATERIAL_LIST_SUCCESS
    | ActionTypes.FETCH_RAW_MATERIAL_LIST_FAILURE
    | ActionTypes.RESET_RAW_MATERIAL_LIST
    | ActionTypes.FETCH_SINGLE_RAW_MATERIAL
    | ActionTypes.FETCH_SINGLE_RAW_MATERIAL_SUCCESS
    | ActionTypes.FETCH_SINGLE_RAW_MATERIAL_FAILURE
    | ActionTypes.RESET_FETCH_SINGLE_RAW_MATERIAL
    | ActionTypes.CREATE_RAW_MATERIAL
    | ActionTypes.CREATE_RAW_MATERIAL_SUCCESS
    | ActionTypes.CREATE_RAW_MATERIAL_FAILURE
    | ActionTypes.RESET_CREATE_RAW_MATERIAL
    | ActionTypes.UPDATE_RAW_MATERIAL
    | ActionTypes.UPDATE_RAW_MATERIAL_SUCCESS
    | ActionTypes.UPDATE_RAW_MATERIAL_FAILURE
    | ActionTypes.RESET_UPDATE_RAW_MATERIAL
    | ActionTypes.DELETE_RAW_MATERIAL
    | ActionTypes.DELETE_RAW_MATERIAL_SUCCESS
    | ActionTypes.DELETE_RAW_MATERIAL_FAILURE
    | ActionTypes.RESET_DELETE_RAW_MATERIAL
    | ActionTypes.FETCH_RAW_MATERIAL_UNITS
    | ActionTypes.FETCH_RAW_MATERIAL_UNITS_SUCCESS
    | ActionTypes.FETCH_RAW_MATERIAL_UNITS_FAILURE
    | ActionTypes.RESET_RAW_MATERIAL_UNITS
    | ActionTypes.FETCH_RAW_MATERIAL_CHART
    | ActionTypes.FETCH_RAW_MATERIAL_CHART_SUCCESS
    | ActionTypes.FETCH_RAW_MATERIAL_CHART_FAILURE
    | ActionTypes.RESET_RAW_MATERIAL_CHART
    | ActionTypes.SET_RAW_MATERIAL_ERROR

    payload: {
        data?: {} | any;
        error?: {} | string
    }
}

export default function RawMaterialReducer(state = INIT_STATE, action: RawMaterialType) {
    switch (action.type) {
        case ActionTypes.FETCH_RAW_MATERIAL_LIST:
            return { ...state, rawMaterial: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.FETCH_RAW_MATERIAL_LIST_SUCCESS:
            return { ...state, rawMaterial: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.FETCH_RAW_MATERIAL_LIST_FAILURE:
            return { ...state, rawMaterial: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.FETCH_SINGLE_RAW_MATERIAL:
            return { ...state, rawMaterialDetails: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.FETCH_SINGLE_RAW_MATERIAL_SUCCESS:
            return { ...state, rawMaterialDetails: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.FETCH_SINGLE_RAW_MATERIAL_FAILURE:
            return { ...state, rawMaterialDetails: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_FETCH_SINGLE_RAW_MATERIAL:
            return { ...state, rawMaterialDetails: { loading: false, data: null, success: false, error: null } }
        case ActionTypes.CREATE_RAW_MATERIAL:
            return { ...state, createRawMaterial: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.CREATE_RAW_MATERIAL_SUCCESS:
            return { ...state, createRawMaterial: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.CREATE_RAW_MATERIAL_FAILURE:
            return { ...state, createRawMaterial: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_CREATE_RAW_MATERIAL:
            return { ...state, createRawMaterial: { loading: false, data: null, success: false, error: null } }
        case ActionTypes.UPDATE_RAW_MATERIAL:
            return { ...state, updateRawMaterial: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.UPDATE_RAW_MATERIAL_SUCCESS:
            return { ...state, updateRawMaterial: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.UPDATE_RAW_MATERIAL_FAILURE:
            return { ...state, updateRawMaterial: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_UPDATE_RAW_MATERIAL:
            return { ...state, updateRawMaterial: { loading: false, data: null, success: false, error: null } }
        case ActionTypes.DELETE_RAW_MATERIAL:
            return { ...state, deleteRawMaterial: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.DELETE_RAW_MATERIAL_SUCCESS:
            return { ...state, deleteRawMaterial: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.DELETE_RAW_MATERIAL_FAILURE:
            return { ...state, deleteRawMaterial: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_DELETE_RAW_MATERIAL:
            return { ...state, deleteRawMaterial: { loading: false, data: null, success: false, error: null } }
        case ActionTypes.FETCH_RAW_MATERIAL_UNITS:
            return { ...state, unit: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.FETCH_RAW_MATERIAL_UNITS_SUCCESS:
            return { ...state, unit: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.FETCH_RAW_MATERIAL_UNITS_FAILURE:
            return { ...state, unit: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.FETCH_RAW_MATERIAL_CHART:
            return { ...state, chart: { loading: true, data: null, success: false, error: null } }
        case ActionTypes.FETCH_RAW_MATERIAL_CHART_SUCCESS:
            return { ...state, chart: { loading: false, data: action.payload.data, success: true, error: null } }
        case ActionTypes.FETCH_RAW_MATERIAL_CHART_FAILURE:
            return { ...state, chart: { loading: false, data: null, success: false, error: action.payload.error } }
        case ActionTypes.RESET_RAW_MATERIAL_CHART:
            return { ...state, chart: { loading: false, data: null, success: false, error: null } }
        default:
            return { ...state }
    }
}