export enum ActionTypes {
    FETCH_ALL_JOBS = "FETCH_ALL_JOBS",
    SET_ALL_JOBS = "SET_ALL_JOBS",
    FETCH_PRODUCT_JOBS = "GET_PRODUCT_JOBS",
    SET_PRODUCT_JOBS = "SET_PRODUCT_JOBS",
    ADD_PRODUCT_JOB = "ADD_PRODUCT_JOBS",
    ADD_PRODUCT_JOB_SUCCESS = "ADD_PRODUCT_JOBS_SUCCESS",
    ADD_PRODUCT_JOB_FAIL = "ADD_PRODUCT_JOBS_FAIL",
    UPDATE_PRODUCT_JOB = "UPDATE_PRODUCT_JOBS",
    UPDATE_PRODUCT_JOB_SUCCESS = "UPDATE_PRODUCT_JOBS_SUCCESS",
    UPDATE_PRODUCT_JOB_FAIL = "UPDATE_PRODUCT_JOBS_FAIL",
    SET_JOB_DEFINATION_ERROR = "SET_JOB_DEFINATION_ERROR",
    FETCH_SINGLE_JOB_DEFINATION = "FETCH_SINGLE_JOB_DEFINATION",
    SET_SINGLE_JOB_DEFINATION = "SET_SINGLE_JOB_DEFINATION",
    CLEAR_SINGLE_JOB_STATE = "CLEAR_SINGLE_JOB_STATE"
}