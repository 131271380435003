import { APICore } from "./apiCore";
const api = new APICore();

function fetchAllJobs(params: { page: string, limit: string, sortBy: string, sort: string }) {
    const baseUrl = `/job-definition?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}`
    return api.get(`${baseUrl}`, "");
}


function getProductJobs(params: { id: string, page: string, limit: string, sortBy: string, sort: string }) {
    const baseUrl = `/job-definition/product/${params.id}?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}`;
    return api.get(`${baseUrl}`, "");
}

function getProductSingleJob(data: any) {
    const baseUrl = `/job-definition/${data.jobID}`;
    return api.get(`${baseUrl}`, "")
}

function createProductJob(data: any) {
    const baseUrl = `/job-definition/${data.productID}`;
    return api.create(`${baseUrl}`, { product: data.product })
}

function updateProductJob(data: any) {
    const baseUrl = `/job-definition/${data.jobID}`;
    return api.update(`${baseUrl}`, { product: data.product })
}

export { fetchAllJobs, getProductJobs, updateProductJob, createProductJob, getProductSingleJob }
