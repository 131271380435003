export enum GinActionTypes {
    CREATE_GIN_REQUEST = 'CREATE_GIN_REQUEST',
    CREATE_GIN_SUCCESS = 'CREATE_GIN_SUCCESS',
    CREATE_GIN_FAILURE = 'CREATE_GIN_FAILURE',
    FETCH_GINS_REQUEST = 'FETCH_GINS_REQUEST',
    FETCH_GINS_SUCCESS = 'FETCH_GINS_SUCCESS',
    FETCH_GINS_FAILURE = 'FETCH_GINS_FAILURE',
    FETCH_GIN_BY_ID_REQUEST = 'FETCH_GIN_BY_ID_REQUEST',
    FETCH_GIN_BY_ID_SUCCESS = 'FETCH_GIN_BY_ID_SUCCESS',
    FETCH_GIN_BY_ID_FAILURE = 'FETCH_GIN_BY_ID_FAILURE',
    EDIT_GIN_REQUEST = 'EDIT_GIN_REQUEST',
    EDIT_GIN_SUCCESS = 'EDIT_GIN_SUCCESS',
    EDIT_GIN_FAILURE = 'EDIT_GIN_FAILURE',
    RESET_GIN_SUCCESS_STATE = 'RESET_GIN_SUCCESS_STATE',
    FETCH_SINGLE_GIN = 'FETCH_SINGLE_GIN',
    SET_SINGLE_GIN = 'SET_SINGLE_GIN',
    RESET_SINGLE_GIN = 'RESET_SINGLE_GIN'
}
