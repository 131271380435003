export enum editDealsTypes{
    FETCH_DEALS_REQUEST = 'FETCH_DEALS_REQUEST',
    FETCH_DEALS_SUCCESS = 'FETCH_DEALS_SUCCESS',
    FETCH_DEALS_FAILURE = 'FETCH_DEALS_FAILURE',
    FETCH_DEAL_BY_ID_REQUEST = 'FETCH_DEAL_BY_ID_REQUEST',
    FETCH_DEAL_BY_ID_SUCCESS = 'FETCH_DEAL_BY_ID_SUCCESS',
    FETCH_DEAL_BY_ID_FAILURE = 'FETCH_DEAL_BY_ID_FAILURE',
    EDIT_DEAL_REQUEST = 'EDIT_DEAL_REQUEST',
    EDIT_DEAL_SUCCESS = 'EDIT_DEAL_SUCCESS',
    EDIT_DEAL_FAILURE = 'EDIT_DEAL_FAILURE',

}
