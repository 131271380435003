export enum HandoverActionTypes {
    CREATE_HANDOVER_REQUEST = 'CREATE_HANDOVER_REQUEST',
    CREATE_HANDOVER_SUCCESS = 'CREATE_HANDOVER_SUCCESS',
    CREATE_HANDOVER_FAILURE = 'CREATE_HANDOVER_FAILURE',
    FETCH_HANDOVERS_REQUEST = 'FETCH_HANDOVERS_REQUEST',
    FETCH_HANDOVERS_SUCCESS = 'FETCH_HANDOVERS_SUCCESS',
    FETCH_HANDOVERS_FAILURE = 'FETCH_HANDOVERS_FAILURE',
    FETCH_HANDOVER_BY_ID_REQUEST = 'FETCH_HANDOVER_BY_ID_REQUEST',
    FETCH_HANDOVER_BY_ID_SUCCESS = 'FETCH_HANDOVER_BY_ID_SUCCESS',
    FETCH_HANDOVER_BY_ID_FAILURE = 'FETCH_HANDOVER_BY_ID_FAILURE',
    EDIT_HANDOVER_REQUEST = 'EDIT_HANDOVER_REQUEST',
    EDIT_HANDOVER_SUCCESS = 'EDIT_HANDOVER_SUCCESS',
    EDIT_HANDOVER_FAILURE = 'EDIT_HANDOVER_FAILURE',
    RESET_HANDOVER_SUCCESS_STATE = 'RESET_HANDOVER_SUCCESS_STATE',
    FETCH_SINGLE_HANDOVER = 'FETCH_SINGLE_HANDOVER',
    SET_SINGLE_HANDOVER = 'SET_SINGLE_HANDOVER',
    RESET_SINGLE_HANDOVER = 'RESET_SINGLE_HANDOVER'
}