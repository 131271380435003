export enum GADrawingActionTypes {
    CREATE_GADrawing_REQUEST = 'CREATE_GADrawing_REQUEST',
    CREATE_GADrawing_SUCCESS = 'CREATE_GADrawing_SUCCESS',
    CREATE_GADrawing_FAILURE = 'CREATE_GADrawing_FAILURE',
    FETCH_GADrawingS_REQUEST = 'FETCH_GADrawingS_REQUEST',
    FETCH_GADrawingS_SUCCESS = 'FETCH_GADrawingS_SUCCESS',
    FETCH_GADrawingS_FAILURE = 'FETCH_GADrawingS_FAILURE',
    FETCH_GADrawing_BY_ID_REQUEST = 'FETCH_GADrawing_BY_ID_REQUEST',
    FETCH_GADrawing_BY_ID_SUCCESS = 'FETCH_GADrawing_BY_ID_SUCCESS',
    FETCH_GADrawing_BY_ID_FAILURE = 'FETCH_GADrawing_BY_ID_FAILURE',
    EDIT_GADrawing_REQUEST = 'EDIT_GADrawing_REQUEST',
    EDIT_GADrawing_SUCCESS = 'EDIT_GADrawing_SUCCESS',
    EDIT_GADrawing_FAILURE = 'EDIT_GADrawing_FAILURE',
    RESET_GADrawing_SUCCESS_STATE = 'RESET_GADrawing_SUCCESS_STATE',
    FETCH_SINGLE_GADrawing = 'FETCH_SINGLE_GADrawing',
    SET_SINGLE_GADrawing = 'SET_SINGLE_GADrawing',
    RESET_SINGLE_GADrawing = 'RESET_SINGLE_GADrawing'
}
