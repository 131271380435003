import { GRNActionTypes as ActionTypes } from './constants';

export const createGRNRequest = (data: any) => ({
  type: ActionTypes.CREATE_GRN_REQUEST,
  payload: {data},
});

export const resetGRNSuccessState = () => ({
  type: ActionTypes.RESET_GRN_SUCCESS_STATE,
});

export const createGRNSuccess = (response: any) => ({
  type: ActionTypes.CREATE_GRN_SUCCESS,
  payload: response,
});

export const createGRNFailure = (error: any) => ({
  type: ActionTypes.CREATE_GRN_FAILURE,
  payload: error,
});

export const resetSingleGRN = () => ({
  type: ActionTypes.RESET_SINGLE_GRN,
});

export const fetchGRNsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_GRNS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchGRNsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GRNS_SUCCESS,
  payload: response,
});

export const fetchGRNsFailure = (error: any) => ({
  type: ActionTypes.FETCH_GRNS_FAILURE,
  payload: error,
});

export const fetchGRNByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_GRN_BY_ID_REQUEST,
  payload: id,
});

export const fetchGRNByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GRN_BY_ID_SUCCESS,
  payload: response,
});

export const fetchGRNByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_GRN_BY_ID_FAILURE,
  payload: error,
});

export const editGRNRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_GRN_REQUEST,
  payload: { id, data },
});

export const editGRNSuccess = (response: any) => ({
  type: ActionTypes.EDIT_GRN_SUCCESS,
  payload: response,
});

export const editGRNFailure = (error: any) => ({
  type: ActionTypes.EDIT_GRN_FAILURE,
  payload: error,
});
export const resetSingleProjectGRN = () => ({
  type: ActionTypes.RESET_SINGLE_PROJECT_GRN,
});
export const fetchGRNByProjectIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_GRN_BY_PROJECT_ID_REQUEST,
  payload: id,
});

export const fetchGRNByProjectIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GRN_BY_PROJECT_ID_SUCCESS,
  payload: response,
});

export const fetchGRNByProjectIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_GRN_BY_PROJECT_ID_FAILURE,
  payload: error,
});
