import { APICore } from "./apiCore";
const api = new APICore();

function createQuotation(data: any) {
    const baseUrl = `/quotation/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchQuotations(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/quotation/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchQuotationById(id: number) {
    const url = `/quotation/${id}`;
    return api.get(url, "");
}

function fetchQuotationByProjectId(id: number) {
    const url = `/quotation/project/${id}`;
    return api.get(url, "");
}

function editQuotation(id: number, data: any) {
    const url = `/quotation/${id}`;
    return api.update(url, data);
}


async function createQuotationWithFiles(params: { quotationData: any, imageData?: any }) {
    try {
        const baseUrl = '/quotation';

        const createdQuotation = await api.create(baseUrl, { ...params.quotationData });

        const quotationResponse = createdQuotation.data;

        params.quotationData?.item_details?.forEach(async (item: any) => {
            await updateQuotationItemWithFile({id:quotationResponse?.data?.newQuotation?.id, quotationData:item, imageData:item.files})
        });

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'Quotation');
            params.imageData.append('typeId', String(quotationResponse?.data?.newQuotation?.id));
            params.imageData.append('tagId', '39');

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(Array.isArray(params.quotationData.image_id) ? params.quotationData.image_id : []),
            ...newImageDataResponse
        ];

        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: quotationResponse?.data?.newQuotation?.id,
                body: {
                    ...quotationResponse?.data?.newQuotation,
                    image_id: combinedImageIds.filter((img: any) => img !== null && img !== undefined)
                }
            };

            const baseQuotationUrl = `/quotation/updateImage/${quotationResponse?.data?.newQuotation?.id}`;
            const updateQuotation = await api.update(baseQuotationUrl, { ...updatedBody.body });

            return updateQuotation;
        }

        return quotationResponse;

    } catch (error) {
        console.error('Error creating quotation with files:', error);
        throw error;
    }
}

async function updateQuotationWithFiles(params: { id: number, quotationData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'Quotation');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', '39');

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(Array.isArray(params.quotationData.image_id) ? params.quotationData.image_id : []),
            ...newImageDataResponse
        ];

        const baseUrl = `/quotation/${params.id}`;
        return api.update(baseUrl, {
            ...params.quotationData,
            image_id: combinedImageIds.filter((img: any) => img !== null && img !== undefined)
        });
    } catch (error) {
        console.error('Error updating quotation with files:', error);
        throw error;
    }
}

async function updateQuotationItemWithFile(params: { id: number, quotationData: any, imageData?:any}) {
    try {

        const baseUrl = `/quotation/quotationItem/${params.id}`;
        const quotationItem = await api.update(baseUrl, {
            ...params.quotationData,
        });

        console.log("params.quotationData", params.quotationData)
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        let uploadResponse = null;

        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'Quotation Item');
            params.imageData.append('typeId', String(quotationItem?.data?.data?.id));
            params.imageData.append('tagId', "56");


            const newImageFile = await api.create(fileUrl, params.imageData);
            uploadResponse = newImageFile?.data?.data?.response;

            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));

            const combinedImageIds = [
                ...(params.quotationData.image_id || []),
                ...newImageDataResponse
            ];

            const updatedQuotationItem = await api.update(baseUrl, {
                ...quotationItem?.data?.data,
                image_id: combinedImageIds.filter((img: any) => img !== null && img !== undefined)
            });

            console.log(updatedQuotationItem, "quotationItem");
            return {
                quotationItem: updatedQuotationItem,
                uploadResponse
            };
        }


        console.log(quotationItem, "quotationItem");
        return {
            quotationItem,
            uploadResponse: null
        };
    } catch (error) {
        console.error('Error updating Quotation with files:', error);
        throw error;
    }
}




export { createQuotation, fetchQuotationById, fetchQuotations, editQuotation, createQuotationWithFiles, updateQuotationWithFiles, updateQuotationItemWithFile , fetchQuotationByProjectId};
