import { ActionTypes } from './constants';

export type NotificationActionType = {
    type:
    | ActionTypes.FETCH_NOTIFICATIONS
    | ActionTypes.SET_NOTIFICATIONS
    | ActionTypes.SET_NOTIF_ERROR
    | ActionTypes.UPDATE_NOTIFICATION_STATUS
    | ActionTypes.READ_ALL_NOTIFICATIONS
    payload: {} | string;
}

//disptach action to get all teams
export const getNotification = (data: any): NotificationActionType => ({
    type: ActionTypes.FETCH_NOTIFICATIONS,
    payload: { data }
})

export const updateNotificationStatus = (data: any): NotificationActionType => ({
    type: ActionTypes.UPDATE_NOTIFICATION_STATUS,
    payload: { data }
})



//this action will be executed to handle api response
export const notificationApiResponse = (
    actionType: string,
    data: any
) => {



    // FETCH_NOTIFICATION = "FETCH_NOTIFICATION",
    // SET_NOTIFICATION = "SET_NOTIFICATION",
    // SET_ERROR = "SET_ERROR",

    switch (actionType) {
        case ActionTypes.FETCH_NOTIFICATIONS:
            return {
                type: ActionTypes.SET_NOTIFICATIONS,
                payload: { data }
            }
        case ActionTypes.FETCH_ALL_NOTIFICATIONS:
            return {
                type: ActionTypes.SET_ALL_NOTIFICATIONS,
                payload: { data }
            }
        case ActionTypes.UPDATE_NOTIFICATION_SUCCESS:
            return {
                type: ActionTypes.UPDATE_NOTIFICATION_SUCCESS,
                payload: { data }
            }
        case ActionTypes.UPDATE_NOTIFICATION_FAIL:
            return {
                type: ActionTypes.UPDATE_NOTIFICATION_FAIL,
                payload: { error: data }
            }
        default:
            return {
                type: ActionTypes.SET_NOTIF_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

// this action will be executed when there is error while adding team
export const notificationApiError = (actionType: string, error: any): NotificationActionType => ({
    type: ActionTypes.SET_NOTIF_ERROR,
    payload: { actionType, error }
})

export const readAllNotification = (data: any) => ({
    type: ActionTypes.READ_ALL_NOTIFICATIONS,
    payload: { data }
})
export const readAllNotificationResponse = (actionType: string, data: any) => {

    switch (actionType) {
        case ActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS:
            return {
                type: ActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS,
                payload: { data }
            }
        case ActionTypes.READ_ALL_NOTIFICATIONS_FAIL:
            return {
                type: ActionTypes.READ_ALL_NOTIFICATIONS_FAIL,
                payload: { error: data }
            }
        default:
            return {
                type: ActionTypes.SET_NOTIF_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }

}
