import { APICore } from "./apiCore";
const api = new APICore();

function fetchCustomer(params: { page: string, limit: string, sortBy: string, sort: string, filterBy?: string, searchBy?: string, orgId?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    console.log(params, "params1")
    const baseUrl = `/customer?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&filterBy=${params.filterBy}&searchBy=${params?.searchBy ? params?.searchBy : ''}&orgId=${params?.orgId ? params?.orgId : ''} `;
    return api.get(`${baseUrl}`, "");
}

function fetchCustomerForDasbord(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/customer/dashboard/data?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "");
}


function fetchSingleCustomer(params: any) {
    const baseUrl = `/customer/${params}`;
    return api.get(`${baseUrl}`, {});
}

function createCustomer(body: any) {
    const baseUrl = "/customer";
    return api.create(`${baseUrl}`, body);
}

function updateCustomer(data: any) {
    const baseUrl = `/customer/${data.id}`;
    return api.update(`${baseUrl}`, { customer: data.updateBody });
}
function deleteCustomer(data: any) {
    const baseUrl = `/customer/${data.id}`;
    return api.delete(`${baseUrl}`);
}
function deleteCustomerByPhone(data: any) {
    const baseUrl = `/customer/phone/${data.phone}`;
    return api.delete(`${baseUrl}`);
}

function fetchCustomerLocation(){
    const baseUrl = "/customer/location";
    return api.create(baseUrl,"");
}





export { fetchCustomer,fetchCustomerLocation, fetchSingleCustomer, createCustomer, updateCustomer, fetchCustomerForDasbord, deleteCustomer , deleteCustomerByPhone};