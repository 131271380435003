import { QuotationActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  quotations: [],
  singleQuotation: {},
  projectQuotation:[],
  error: {},
  success: false,
};

const QuotationReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case QuotationActionTypes.CREATE_QUOTATION_REQUEST:
    case QuotationActionTypes.EDIT_QUOTATION_REQUEST:
    case QuotationActionTypes.FETCH_QUOTATIONS_REQUEST:
    case QuotationActionTypes.FETCH_QUOTATION_BY_ID_REQUEST:
    case QuotationActionTypes.FETCH_QUOTATION_BY_PROJECT_ID_REQUEST:
    case QuotationActionTypes.RESET_QUOTATION_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case QuotationActionTypes.SET_SINGLE_QUOTATION:
    return { ...state , singleQuotation: action.payload.data, loading:false }  
    case QuotationActionTypes.CREATE_QUOTATION_SUCCESS:
      return {
        ...state,
        quotations: [...state.quotations, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case QuotationActionTypes.EDIT_QUOTATION_SUCCESS:
      const updatedQuotations = state.quotations.map((quotation: any) =>
        quotation.id === action.payload.data.id ? action.payload.data : quotation
      );
      return {
        ...state,
        quotations: updatedQuotations,
        loading: false,
        error: {},
        success: true,
      };

    case QuotationActionTypes.FETCH_QUOTATIONS_SUCCESS:
      return {
        ...state,
        quotations: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };
    case QuotationActionTypes.FETCH_QUOTATION_BY_PROJECT_ID_SUCCESS:
      return {
        ...state,
        indents: [action.payload.data],
        projectQuotation: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case QuotationActionTypes.FETCH_QUOTATION_BY_ID_SUCCESS:
      return {
        ...state,
        singleQuotation: action.payload.data,
        quotations: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case QuotationActionTypes.RESET_SINGLE_QUOTATION:
      return {
          ...state,
          singleQuotation: null,
      };

    case QuotationActionTypes.CREATE_QUOTATION_FAILURE:
    case QuotationActionTypes.EDIT_QUOTATION_FAILURE:
    case QuotationActionTypes.FETCH_QUOTATIONS_FAILURE:
    case QuotationActionTypes.FETCH_QUOTATION_BY_ID_FAILURE:
    case QuotationActionTypes.FETCH_QUOTATION_BY_PROJECT_ID_FAILURE:

      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default QuotationReducer;
