import { InventoryActionTypes } from './constant';

const INIT_STATE = {
  count: 0,
  countHistory: 0,
  loading: true,
  inventory: [],
  inventoryHistory: [],
  error: {},
  success: false,
  singleInventory: {},
};

const InventoryReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case InventoryActionTypes.CREATE_INVENTORY_REQUEST:
    case InventoryActionTypes.EDIT_INVENTORY_REQUEST:
    case InventoryActionTypes.FETCH_INVENTORYS_REQUEST:
    case InventoryActionTypes.FETCH_INVENTORYSHISTORY_REQUEST:
    case InventoryActionTypes.FETCH_INVENTORY_BY_ID_REQUEST:
    case InventoryActionTypes.RESET_INVENTORY_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case InventoryActionTypes.SET_SINGLE_INVENTORY:
      console.log(action.payload,"shubhi")
      return { ...state , singleInventory: action.payload.data, loading:false }
    
    case InventoryActionTypes.CREATE_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: [...state.inventory, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case InventoryActionTypes.EDIT_INVENTORY_SUCCESS:
      const updatedInventorys = state.inventory.map((inventory: any) =>
        inventory.id === action.payload.data.id ? action.payload.data : inventory
      );
      return {
        ...state,
        inventory: updatedInventorys,
        loading: false,
        error: {},
        success: true,
      };

    case InventoryActionTypes.FETCH_INVENTORYS_SUCCESS:
      console.log(action.payload,"yoooo")
      return {
        ...state,
        inventory: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };
    
    case InventoryActionTypes.FETCH_INVENTORYSHISTORY_SUCCESS:
      console.log(action.payload,"yoooo")
      return {
        ...state,
        inventoryHistory: action.payload.data,
        loading: false,
        error: {},
        success: true,
        countHistory: action.payload.count,
      };

    case InventoryActionTypes.FETCH_INVENTORY_BY_ID_SUCCESS:
      return {
        ...state,
        singleInventory: action.payload.data,
        inventory: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case InventoryActionTypes.RESET_SINGLE_INVENTORY:
        return {
            ...state,
            singleInventory: null,
        };

    case InventoryActionTypes.CREATE_INVENTORY_FAILURE:
    case InventoryActionTypes.EDIT_INVENTORY_FAILURE:
    case InventoryActionTypes.FETCH_INVENTORYS_FAILURE:
    case InventoryActionTypes.FETCH_INVENTORYSHISTORY_FAILURE:
    case InventoryActionTypes.FETCH_INVENTORY_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default InventoryReducer;
