import { ActionTypes } from './constant';

export type LeadActionType = {
    type:
    | ActionTypes.CREATE_LEAD
    | ActionTypes.FETCH_LEAD
    | ActionTypes.SET_ERROR
    | ActionTypes.SET_LEAD
    | ActionTypes.UPDATE_LEAD
    | ActionTypes.FETCH_SINGLE_LEAD
    | ActionTypes.SET_SINGLE_LEAD
    | ActionTypes.UPDATE_LEAD_SUCCESS
    | ActionTypes.UPDATE_LEAD_FAIL
    | ActionTypes.CREATE_LEAD_SUCCESS
    | ActionTypes.CREATE_LEAD_FAIL
    | ActionTypes.FETCH_LEAD_TAG
    | ActionTypes.SET_LEAD_STATUS
    payload: {} | string;
}



export const getLead = (data: any): LeadActionType => ({
    type: ActionTypes.FETCH_LEAD,
    payload: { data }
})


export const getSingleLead = (id: any): LeadActionType => ({
    type: ActionTypes.FETCH_SINGLE_LEAD,
    payload: { id }
})


export const updateLead = (data: any): LeadActionType => ({
    type: ActionTypes.UPDATE_LEAD,
    payload: { data }
})



export const createLead = (data: any): LeadActionType => ({
    type: ActionTypes.CREATE_LEAD,
    payload: { data }
})

export const fetchLeadStatus = () => ({
    type: ActionTypes.FETCH_LEAD_STATUS,
    payload: {}
})

export const leadApiResponse = (
    actionType: string,
    data: any
): LeadActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_LEAD:
            return {
                type: ActionTypes.SET_LEAD,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_LEAD:
            return {
                type: ActionTypes.SET_SINGLE_LEAD,
                payload: { data }
            }
        case ActionTypes.FETCH_LEAD_STATUS:
            return {
                type: ActionTypes.SET_LEAD_STATUS,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}


export const updateLeadResponse = (actionType: string, data: any): LeadActionType => {
    switch (actionType) {
        case ActionTypes.UPDATE_LEAD_SUCCESS:
            return {
                type: ActionTypes.UPDATE_LEAD_SUCCESS,
                payload: { actionType, data }
            }
        case ActionTypes.UPDATE_LEAD_FAIL:
            return {
                type: ActionTypes.UPDATE_LEAD_FAIL,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}


export const addLeadResponse = (actionType: string, data: any): LeadActionType => {
    switch (actionType) {
        case ActionTypes.CREATE_LEAD_SUCCESS:
            return {
                type: ActionTypes.CREATE_LEAD_SUCCESS,
                payload: { actionType, data }
            }
        case ActionTypes.CREATE_LEAD_FAIL:
            return {
                type: ActionTypes.CREATE_LEAD_FAIL,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While adding data to store ! " },
            };
    }
}


export const leadApiError = (actionType: string, error: any): LeadActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})
