import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    organization: {},
    singleOrganization: {},
    error: {},
    success: false,
    newOrganization: {},
    organizationCount: {},
    location: {},
    organizationDeleteSuccess: false,
    organizationCreatedWithCustomer: {},
    organizationWithCustomerStatus: false,
    organizationUpdatedWithCustomer: true

}

type OrganizationActionType = {
    type:
    | AuthActionTypes.SET_ORGANIZATION
    | AuthActionTypes.CREATE_ORGANIZATION
    | AuthActionTypes.FETCH_ORGANIZATION
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_ORGANIZATION
    | AuthActionTypes.SET_SINGLE_ORGANIZATION
    | AuthActionTypes.ADD_ORGANIZATION_SUCCESS
    | AuthActionTypes.ADD_ORGANIZATION_FAIL
    | AuthActionTypes.UPDATE_ORGANIZATION_SUCCESS
    | AuthActionTypes.UPDATE_ORGANIZATION_FAIL
    | AuthActionTypes.SET_ORGANIZATION_DASHBOARD_DATA
    | AuthActionTypes.DELETE_ORGANIZATION_SUCCESS
    | AuthActionTypes.DELETE_ORGANIZATION_FAIL
    | AuthActionTypes.SET_ORGANIZATION_LOCATION
    | AuthActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER
    | AuthActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS
    | AuthActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_FAIL
    | AuthActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER
    | AuthActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS
    | AuthActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_FAIL
    | AuthActionTypes.RESET_ORG_STATE
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}


const OrganizationReducer = (state: any = INIT_STATE, action: OrganizationActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_ORGANIZATION:
            console.log(action.payload.data);
            return { ...state, organization: action.payload.data, singleOrganization: {}, loading: false, organizationWithCustomerStatus: false, organizationUpdatedWithCustomer: false, success: false, error: {}, organizationDeleteSuccess: false }
        case AuthActionTypes.SET_SINGLE_ORGANIZATION:
            console.log(action.payload.data);
            return { ...state, singleOrganization: action.payload.data, loading: false, organizationWithCustomerStatus: false, organizationUpdatedWithCustomer: false, success: false, error: {} }
        case AuthActionTypes.SET_ORGANIZATION_DASHBOARD_DATA:
            return { ...state, organizationCount: action.payload.data, loading: false, success: false, error: {} }
        case AuthActionTypes.ADD_ORGANIZATION_SUCCESS:
            console.log(action.payload.data, "orgaddeddd")
            return { ...state, success: true, error: {}, newOrganization: action.payload.data,organizationWithCustomerStatus: true }
        case AuthActionTypes.ADD_ORGANIZATION_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_ORGANIZATION_SUCCESS:
            return { ...state, success: true, error: {},organizationUpdatedWithCustomer:true }
        case AuthActionTypes.UPDATE_ORGANIZATION_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.SET_ERROR:
            return { ...state, organization: {}, singleOrganization: {}, error: action.payload.error }
        case AuthActionTypes.DELETE_ORGANIZATION_SUCCESS:
            return { ...state, organizationDeleteSuccess: true, error: {} }
        case AuthActionTypes.DELETE_ORGANIZATION_FAIL:
            return { ...state, organizationDeleteSuccess: false, error: action.payload.error, }
        case AuthActionTypes.SET_ORGANIZATION_LOCATION:
            return { ...state, loading: false, success: false, location: action.payload.data };
        case AuthActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS:
            console.log(action.payload.data, "action.payload.datareducer")
            return { ...state, organizationCreatedWithCustomer: action.payload.data, organizationWithCustomerStatus: true, loading: false, success: true, error: {} }
        case AuthActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_FAIL:
            return { ...state, organizationCreatedWithCustomer: {}, organizationWithCustomerStatus: false, loading: false, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS:
            return { ...state, organizationUpdatedWithCustomer: true, loading: false, success: true, error: {} }
        case AuthActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_FAIL:
            console.log(action.payload.error, "action.payload.error")
            return { ...state, organizationUpdatedWithCustomer: false, loading: false, success: false, error: action.payload.error }
        case AuthActionTypes.RESET_ORG_STATE:
            return { ...state, organization: {}, success: false }
        default:
            return { ...state }
    }
}

export default OrganizationReducer;