import { GADrawingActionTypes as ActionTypes } from './constants';

export const createGADrawingRequest = (data: any) => ({
  type: ActionTypes.CREATE_GADrawing_REQUEST,
  payload: {data},
});

export const resetGADrawingSuccessState = () => ({
  type: ActionTypes.RESET_GADrawing_SUCCESS_STATE,
});

export const createGADrawingSuccess = (response: any) => ({
  type: ActionTypes.CREATE_GADrawing_SUCCESS,
  payload: response,
});

export const createGADrawingFailure = (error: any) => ({
  type: ActionTypes.CREATE_GADrawing_FAILURE,
  payload: error,
});

export const resetSingleGADrawing = () => ({
  type: ActionTypes.RESET_SINGLE_GADrawing,
});

export const fetchGADrawingsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_GADrawingS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchGADrawingsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GADrawingS_SUCCESS,
  payload: response,
});

export const fetchGADrawingsFailure = (error: any) => ({
  type: ActionTypes.FETCH_GADrawingS_FAILURE,
  payload: error,
});

export const fetchGADrawingByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_GADrawing_BY_ID_REQUEST,
  payload: id,
});

export const fetchGADrawingByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GADrawing_BY_ID_SUCCESS,
  payload: response,
});

export const fetchGADrawingByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_GADrawing_BY_ID_FAILURE,
  payload: error,
});

export const editGADrawingRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_GADrawing_REQUEST,
  payload: { id, data },
});

export const editGADrawingSuccess = (response: any) => ({
  type: ActionTypes.EDIT_GADrawing_SUCCESS,
  payload: response,
});

export const editGADrawingFailure = (error: any) => ({
  type: ActionTypes.EDIT_GADrawing_FAILURE,
  payload: error,
});
