import { APICore } from "./apiCore";
const api = new APICore();

function createHandover(data: any) {
    const baseUrl = `/handover/`;
    return api.create(`${baseUrl}`, { ...data });
}

function fetchHandovers(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/handover/`;
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchHandoverById(id: number) {
    const url = `/handover/${id}`;
    return api.get(url, "");
}

function editHandover(id: number, data: any) {
    const url = `/handover/${id}`;
    return api.update(url, data);
}

async function createHandoverWithFiles(params: { handoverData: any, imageData?: any }) {
    try {
        const baseUrl = '/handover';

        const createdHandover = await api.create(baseUrl, { ...params.handoverData });
        const handoverResponse = createdHandover.data;

        const fileUrl = `file/upload`;
        let newimageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105)); // Replace with actual user ID
            params.imageData.append('type', 'Handover');
            params.imageData.append('typeId', String(handoverResponse?.data?.newHandover?.id));
            // params.imageData.append('tagId', "3");

            const newDocumentFile = await api.create(fileUrl, params.imageData);
            const newDocumentFileResponse = newDocumentFile.data;
            newimageDataResponse = (newDocumentFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if (newimageDataResponse.length > 0) {
            const updatedBody = {
                id: handoverResponse?.data?.newHandover?.id,
                body: {
                    ...handoverResponse?.data?.newHandover,
                    image_id: newimageDataResponse,
                }
            };

            const baseHandoverUrl = `/handover/updateImage/${handoverResponse?.data?.newHandover?.id}`;
            const updateHandover = await api.update(baseHandoverUrl, { ...updatedBody.body });

            return updateHandover;
        }

        return handoverResponse;

    } catch (error) {
        console.error('Error creating handover with files:', error);
        throw error;
    }
}

async function updateHandoverWithFiles(params: { id: number, handoverData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newimageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id)); // Replace with actual user ID
            params.imageData.append('type', 'Handover');
            params.imageData.append('typeId', String(params.id));
            // params.imageData.append('tagId', "3");

            const newDocumentFile = await api.create(fileUrl, params.imageData);
            const newDocumentFileResponse = newDocumentFile.data;
            newimageDataResponse = (newDocumentFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedDocumentIds = [
            ...(params.handoverData.image_id || []),
            ...newimageDataResponse
        ];

        const baseUrl = `/handover/${params.id}`;
        return api.update(baseUrl, {
            ...params.handoverData,
            image_id: combinedDocumentIds
        });
    } catch (error) {
        console.error('Error updating handover with files:', error);
        throw error;
    }
}

export { createHandover, fetchHandoverById, fetchHandovers, editHandover, createHandoverWithFiles, updateHandoverWithFiles };