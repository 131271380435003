import { APICore } from "./apiCore";

const api = new APICore();

function fetchAllContacts(page: any, limit: any) {
    const baseUrl = `/contact/list/`
    const params = new URLSearchParams({
        page: page,
        limit: limit
    }).toString();
    const url = `${baseUrl}?${params}`;

    return api.get(url, "");
}

function syncContacts() {
    const baseURL = `/contact/list/sync`
    return api.create(`${baseURL}`, "");
}


export { fetchAllContacts, syncContacts };
