/* Layout types and other constants */

export enum LayoutTypes {
  LAYOUT_VERTICAL = "vertical",
  LAYOUT_HORIZONTAL = "topnav",
  LAYOUT_DETACHED = "detached",
  LAYOUT_FULL = "full",
}

export enum LayoutColor {
  LAYOUT_COLOR_LIGHT = "light",
  LAYOUT_COLOR_DARK = "dark",
}

export enum LayoutWidth {
  LAYOUT_WIDTH_FLUID = "fluid",
  LAYOUT_WIDTH_BOXED = "boxed",
}

export enum SideBarTheme {
  LEFT_SIDEBAR_THEME_DEFAULT = "default",
  LEFT_SIDEBAR_THEME_LIGHT = "light",
  LEFT_SIDEBAR_THEME_DARK = "dark",
}

export enum SideBarWidth {
  LEFT_SIDEBAR_TYPE_FIXED = "fixed",
  LEFT_SIDEBAR_TYPE_CONDENSED = "condensed",
  LEFT_SIDEBAR_TYPE_SCROLLABLE = "scrollable",
}
