import { APICore } from "./apiCore";
import { deleteFileById } from "./files";

const api = new APICore();

function fetchVendor(params: { page: string, limit: string, sortBy: string, sort: string, filterBy?: any, searchBy?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    const baseUrl = `/vendor?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&filterBy=${params.filterBy}&searchBy=${params?.searchBy ? params?.searchBy : ''}`;
    return api.get(`${baseUrl}`, '');
}

function searchVendor(params: { jobId: string }) {
    const baseUrl = `/vendor/search/${params.jobId}`;
    return api.get(`${baseUrl}`, '');
}

function fetchVendorForDasbord(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/vendor/dashboard/data?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.create(`${baseUrl}`, "");
}


function fetchSingleVendor(params: any) {
    const baseUrl = `/vendor/${params}`;
    return api.get(`${baseUrl}`, {});
}

function createVendor(body: any) {
    const baseUrl = "/vendor";
    return api.create(`${baseUrl}`, body);
}

function updateVendor(data: any) {
    const baseUrl = `/vendor/${data.id}`;
    return api.update(`${baseUrl}`, { vendor: data.updateBody });
}

function deleteVendor(data: any) {
    const baseUrl = `/vendor/${data.id}`;
    return api.delete(`${baseUrl}`);
}

async function handleDeleteFileById(field:any , fileId:any) {
    const deleteResponsejbjhb =  await deleteFileById({id: fileId})
    if(deleteResponsejbjhb){
        field.value = "";
    }


}

async function uploadFiles(filesArray: { name: string, file: File }[], customForm1: any,  newVendorId:any, newVendorUserId:any, customForm2?: any,) {
    const fileUrl = `file/upload`;
    console.log("custom forms", customForm1, customForm2)
    const updateCustomForm = (form: any, label: string, filePath: string, fileId:any) => {
        Object.keys(form.sections).forEach(sectionKey => {
            const section = form.sections[sectionKey];
            Object.keys(section.fields).forEach(fieldKey => {
                const field = section.fields[fieldKey];
                if (field.label === label) {
                    field.value = filePath;
                    field.onRemove =()=>{
                         return handleDeleteFileById(field, fileId)
                    }
                }
            });
        })
        console.log("shubhi",form)
        return form;
    };

    const uploadPromises = filesArray.map(async (fileObj) => {
        const formData = new FormData();
        formData.append('file', fileObj.file);
        formData.append('uploadedBy', newVendorUserId)
        formData.append('type', 'Vendor')
        formData.append('typeId', newVendorId)
        formData.append('tagId', "8")
        try {
            const response = await api.create(fileUrl, formData);
            const responseData = response.data;
            const fileId = responseData?.data?.response?.id;
            const filePath = responseData?.data?.response?.filePaths;
            console.log(`Uploaded ${fileObj.name}:`, responseData);

            let updatedCustomForm1 = updateCustomForm(customForm1, fileObj.name, filePath, fileId);
            let updatedCustomForm2 = customForm2 
                ? updateCustomForm(customForm2, fileObj.name, filePath, fileId) 
                : null; // Only update customForm2 if it exists

            return { fileId, updatedCustomForm1, updatedCustomForm2 };
        } catch (error) {
            console.error(`Error uploading ${fileObj.name}:`, error);
            throw error;
        }
    });

    try {
        const results = await Promise.all(uploadPromises);
        console.log('All files uploaded and custom forms updated successfully:', results);
        return results; 
    } catch (error) {
        console.error('Error uploading files:', error);
        throw error;
    }
}


async function createVendorWithFiles(params: { vendorData: any, logoData?: any, files?:any }) {
    const baseUrl = '/vendor';
    const fileUrl = `file/upload`
    console.log("paramsT", params)

    const createdVendor = await api.create(`${baseUrl}`, { vendor: { ...params?.vendorData } })
    const vendorResponse = createdVendor.data
    const vendor_id = vendorResponse?.response?.vendor?.dataValues.id

    const oldMachine = vendorResponse?.response?.vendor?.dataValues?.machine_details
    if (oldMachine) {
        oldMachine.forEach((item: any) => {
            item.vendor_id = vendor_id
        })
    }

    console.log("files here ", params?.files)



    const newvendorType = vendorResponse?.response?.jobWorkVendorResponse? {...vendorResponse?.response?.jobWorkVendorResponse?.response?.vendor?.dataValues} : {...vendorResponse?.response?.rawMaterialSupplierResponse?.response?.vendor?.dataValues}
    const newVendor = { ...vendorResponse?.response?.vendor?.dataValues, name: vendorResponse?.response?.vendor?.name, email: vendorResponse?.response?.vendor?.email, mobile: vendorResponse?.response?.vendor?.mobile, machine_details: oldMachine }

    let logo_data_response = null
   

    if (params?.logoData) {
        params?.logoData?.append('uploadedBy', newVendor?.user_id)
        params?.logoData?.append('type', 'Vendor')
        params?.logoData?.append('typeId', newVendor?.id)
        params?.logoData?.append('tagId', "8")

        const newFile = await api.create(`${fileUrl}`, params?.logoData)
        const newFileResponse = newFile.data
        console.log(newFileResponse, "newFileResponse2")
        logo_data_response = newFileResponse?.data?.response?.id

    }

    console.log("BeforeUpdate", logo_data_response)
    const updateBody = {
        id: newVendor?.user_id,
        body: {
            ...newVendor,
            logo_id: logo_data_response
        }
    }
    console.log("afterUpdate", updateBody)

    const baseVendorUrl = `/vendor/${newVendor?.user_id}`;
    const updatedVendor = await api.update(`${baseVendorUrl}`, { vendor: { ...updateBody.body } })

    const results = await  uploadFiles(params.files,newvendorType?.custom_form, updatedVendor.data.response.vendor.custom_form, newVendor?.id, newVendor?.user_id )

    const customFileUpdatedVendorBody = {
        id: newVendor?.user_id,
        body: {
            ...updatedVendor.data.response.vendor,
            custom_form : results[results.length-1]?.updatedCustomForm2,
            vendorTypeForm: results[results.length -1]?.updatedCustomForm1
        }
    }
    const fileUpdatedVendor = await api.update(`${baseVendorUrl}`, {vendor: {...customFileUpdatedVendorBody.body}})


    return fileUpdatedVendor;

}


async function updateVendorWithFiles(params: any) {
    try {
        console.log("paramsss", params)
        const fileUrl = `file/upload`
        const newLogoData = params.logoData
        let newLogoDataResponse = null

        console.log("files here ", params?.files)

        
        if (newLogoData) {
            if (params.updateBody.logo_id) {
                const baseUrl = `file/${params.updateBody.logo_id}`
                await api.delete(`${baseUrl}`)
            }

            newLogoData?.append('uploadedBy', params?.id)
            newLogoData?.append('type', 'Vendor')
            newLogoData?.append('typeId', params?.id)
            newLogoData?.append('tagId', "8")

            const newFile = await api.create(`${fileUrl}`, newLogoData)
            const newFileResponse = newFile.data
            console.log(newFileResponse, "newFileResponse1")
            newLogoDataResponse = newFileResponse?.data?.response?.id
        }
        console.log("dcbsjhbjbjhb")

        const baseUrl = `/vendor/${params.id}`;
        const updatedVendor = await api.update(`${baseUrl}`, { vendor: { ...params.updateBody,  logo_id: newLogoDataResponse ? newLogoDataResponse : params?.updateBody?.logo_id } });
        console.log("kjdncsnkjnkjn")
        const results = await  uploadFiles(params.files,updatedVendor?.data?.response?.vendor?.vendorTypeForm, updatedVendor?.data?.response?.vendor?.custom_form, updatedVendor?.data?.response?.existingVendor?.id, updatedVendor?.data?.response?.existingVendor?.user_id )
        console.log("shubhi updated", results[results.length-1]?.updatedCustomForm2)
        const customFileUpdatedVendorBody = {
            id: params.id,
            body: {
                ...updatedVendor.data.response.vendor,
                custom_form : results[results.length-1]?.updatedCustomForm2,
                vendorTypeForm: results[results.length -1]?.updatedCustomForm1
            }
        }
        console.log("shubhi first", customFileUpdatedVendorBody)
        const fileUpdatedVendor = await api.update(`${baseUrl}`, {vendor: {...customFileUpdatedVendorBody.body}})

        console.log("gdvhgsv", fileUpdatedVendor)
        return fileUpdatedVendor
    } catch (err) {
        console.log(err, "errorwithfileupload")
    }
}


export { fetchVendor, createVendor, updateVendor, fetchSingleVendor, fetchVendorForDasbord, deleteVendor, createVendorWithFiles, updateVendorWithFiles, searchVendor };