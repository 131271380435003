// import { APICore } from 'helpers/api/apiCore';
import { ActionTypes as AuthActionTypes } from "./constants";
import { FormState } from "../types/DataTypes";

// const api = new APICore();

const INIT_STATE = {
  loading: true,
  form: {},
  error: {},
  success: false,
  updateSuccess: false,
  singleForm: {},
  enquiryForm: {},
  allForm: {},
  formAnswer: {},
  formStatus: {},
  deleteFormSuccess: false,
  formByUUID: {},
};

type AuthActionType = {
  type: AuthActionTypes.FETCH_FORM
  | AuthActionTypes.SET_ERROR
  | AuthActionTypes.SET_FORM_SUCCESS
  | AuthActionTypes.SET_FORM
  | AuthActionTypes.SET_ENQUIRY_FORM
  | AuthActionTypes.SET_SINGLE_FORM
  | AuthActionTypes.SET_ALL_FORM
  | AuthActionTypes.UPDATE_FORM_SUCCESS
  | AuthActionTypes.SET_FORM_ANSWER
  | AuthActionTypes.DELETE_FORM
  | AuthActionTypes.DELETE_FORM_SUCCESS
  | AuthActionTypes.DELETE_FORM_FAIL
  | AuthActionTypes.SET_FORM_STATUS
  | AuthActionTypes.SET_FORM_BY_UUID_SUCCESS
  | AuthActionTypes.SET_FORM_BY_UUID_FAIL
  | AuthActionTypes.RESET_FORM_BY_UUID;

  payload: {
    actionType?: string;
    data?: {};
    error?: string;
  };
};

const FormReducer = (state: FormState = INIT_STATE, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionTypes.SET_FORM:
      return { ...state, form: action.payload, singleForm: {}, loading: false, deleteFormSuccess: false, enquiryForm: null, error: {} };
    case AuthActionTypes.SET_ENQUIRY_FORM:
      return { ...state, enquiryForm: action.payload, loading: false, updateSuccess: false, success: false };
    case AuthActionTypes.SET_FORM_SUCCESS:
      return { ...state, success: true, updateSuccess: false, loading: false, error: {} };
    case AuthActionTypes.SET_ALL_FORM:
      return { ...state, allForm: action.payload, loading: false };
    case AuthActionTypes.SET_SINGLE_FORM:
      return { ...state, singleForm: action.payload, loading: false, updateSuccess: false, success: false };
    case AuthActionTypes.UPDATE_FORM_SUCCESS:
      return { ...state, loading: false, updateSuccess: true, success: false, error: {} };
    case AuthActionTypes.SET_ERROR:
      return { ...state, singleForm: {}, error: action.payload, loading: false };
    case AuthActionTypes.SET_FORM_ANSWER:
      return { ...state, formAnswer: action.payload?.data, loading: false };
    case AuthActionTypes.DELETE_FORM_SUCCESS:
      return { ...state, deleteFormSuccess: true, error: {} }
    case AuthActionTypes.DELETE_FORM_FAIL:
      return { ...state, deleteFormSuccess: false, error: action.payload.error }
    case AuthActionTypes.SET_FORM_STATUS:
      return { ...state, formStatus: action.payload.data }
    case AuthActionTypes.SET_FORM_BY_UUID_SUCCESS:
      return { ...state, formByUUID: action.payload.data }
    case AuthActionTypes.SET_FORM_BY_UUID_FAIL:
      return { ...state, formByUUID: {} , error: action.payload.error}
    case AuthActionTypes.RESET_FORM_BY_UUID:
      return { ...state, formByUUID: {} };
    default:
      return { ...state };
  }
};

export default FormReducer;
