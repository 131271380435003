import { WarehouseActionTypes } from './constant';

const INIT_STATE = {
  count: 0,
  loading: true,
  warehouse: [],
  warehouseItems: [],
  error: {},
  success: false,
  singleWarehouse: {},
};

const WarehouseReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case WarehouseActionTypes.CREATE_WAREHOUSE_REQUEST:
    case WarehouseActionTypes.EDIT_WAREHOUSE_REQUEST:
    case WarehouseActionTypes.FETCH_WAREHOUSES_REQUEST:
    case WarehouseActionTypes.FETCH_WAREHOUSE_BY_ID_REQUEST:
    case WarehouseActionTypes.RESET_WAREHOUSE_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };
    case WarehouseActionTypes.FETCH_WAREHOUSE_ITEMS_SUCCESS:
      return {
        ...state,
        warehouseItems: action.payload.data,
        loading: false,
        error: {},
        success: true,
      };
  
    case WarehouseActionTypes.FETCH_WAREHOUSE_ITEMS_REQUEST:
      return { ...state, loading: true, error: {}, success: false };
    case WarehouseActionTypes.SET_SINGLE_WAREHOUSE:
      console.log(action.payload,"shubhi")
      return { ...state , singleWarehouse: action.payload.data, loading:false }
    
    case WarehouseActionTypes.CREATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouse: [...state.warehouse, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case WarehouseActionTypes.EDIT_WAREHOUSE_SUCCESS:
      const updatedWarehouses = state.warehouse.map((warehouse: any) =>
        warehouse.id === action.payload.data.id ? action.payload.data : warehouse
      );
      return {
        ...state,
        warehouse: updatedWarehouses,
        loading: false,
        error: {},
        success: true,
      };

    case WarehouseActionTypes.FETCH_WAREHOUSES_SUCCESS:
      console.log(action.payload,"yoooo")
      return {
        ...state,
        warehouse: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case WarehouseActionTypes.FETCH_WAREHOUSE_BY_ID_SUCCESS:
      return {
        ...state,
        singleWarehouse: action.payload.data,
        warehouse: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case WarehouseActionTypes.RESET_SINGLE_WAREHOUSE:
        return {
            ...state,
            singleWarehouse: null,
        };

    case WarehouseActionTypes.CREATE_WAREHOUSE_FAILURE:
    case WarehouseActionTypes.EDIT_WAREHOUSE_FAILURE:
    case WarehouseActionTypes.FETCH_WAREHOUSES_FAILURE:
    case WarehouseActionTypes.FETCH_WAREHOUSE_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };
    case WarehouseActionTypes.FETCH_WAREHOUSE_ITEMS_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };
    default:
      return state;
  }
};

export default WarehouseReducer;
