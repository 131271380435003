export enum ActionTypes {
    FETCH_ALL_TRIGGER = "FETCH_ALL_TRIGGER",
    SET_ALL_TRIGGER = "SET_ALL_TRIGGER",
    FETCH_TRIGGER_TYPE_FOR = "FETCH_TRIGGER_TYPE_FOR",
    SET_TRIGGER_TYPE_FOR = "SET_TRIGGER_TYPE_FOR",
    FETCH_TRIGGER_TYPE_BY = "FETCH_TRIGGER_TYPE_BY",
    SET_TRIGGER_TYPE_BY = "SET_TRIGGER_TYPE_BY",
    ADD_TRIGGER = "ADD_TRIGGER",
    ADD_TRIGGER_SUCCESS = "ADD_TRIGGER_SUCCESS",
    ADD_TRIGGER_FAIL = "ADD_TRIGGER_FAIL",
    UPDATE_TRIGGER = "UPDATE_TRIGGER",
    UPDATE_TRIGGER_SUCCESS = "UPDATE_TRIGGER_SUCCESS",
    UPDATE_TRIGGER_FAIL = "UPDATE_TRIGGER_FAIL",
    SET_TRIGGER_ERROR = "SET_TRIGGER_ERROR",
    FETCH_SINGLE_TRIGGER = "FETCH_SINGLE_TRIGGER",
    SET_SINGLE_TRIGGER = "SET_SINGLE_TRIGGER",
    FETCH_TRIGGER_ENTITY = "FETCH_TRIGGER_ENTITY",
    SET_TRIGGER_ENTITY = "SET_TRIGGER_ENTITY",
    FETCH_TRIGGER_ENTITY_TYPE = "FETCH_TRIGGER_ENTITY_TYPE",
    SET_TRIGGER_ENTITY_TYPE = "SET_TRIGGER_ENTITY_TYPE",
    FETCH_TRIGGER_ENTITY_STATE = "FETCH_TRIGGER_ENTITY_STATE",
    SET_TRIGGER_ENTITY_STATE = "SET_TRIGGER_ENTITY_STATE",
    RESET_ENTITY_DEPENDENT = "RESET_ENTITY_DEPENDENT",
    RESET_ENTITY_TYPE_DEPENDENT = "RESET_ENTITY_TYPE_DEPENDENY",
    RESET_ENTITY_STATE_DEPENDENT = "RESET_ENTITY_STATE_DEPENDENT"

}