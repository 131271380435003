// import { APICore } from 'helpers/api/apiCore';
import { LoginState, Response, UserSession } from "redux/types/DataTypes";
import { ActionTypes as AuthActionTypes } from "./constants";

// const api = new APICore();

const INIT_STATE = {
  loading: false,
  isVerifyCustomerLoaded: false,
  email: localStorage.getItem('email') || "",
  enquiryId: "",
  key: "",
  otp: "",
  isValidSession: false,
  error: {},
  session: {
    sessionId: localStorage.getItem('token'),
    privileges: JSON.parse(localStorage.getItem('privileges') ?? "{}"),
    userRole: localStorage.getItem('userRole'),
    userType: localStorage.getItem('userType'),
    userId: localStorage.getItem('userId'),
    userName: localStorage.getItem('userName'),
  } as Partial<UserSession>,
  sessionDeleted: false,
  resendOtpStatus: false
};

type AuthActionType = {
  type:
  | AuthActionTypes.SEND_OTP_REQUEST
  | AuthActionTypes.OTP_REQUEST_SUCCESS
  | AuthActionTypes.OTP_REQUEST_FAIL
  | AuthActionTypes.VERIFY_OTP_REQUEST
  | AuthActionTypes.VERIFY_OTP_SUCCESS
  | AuthActionTypes.SET_CUSTOMER_SESSION
  | AuthActionTypes.VERIFY_OTP_FAIL
  | AuthActionTypes.EDIT_MAIL
  | AuthActionTypes.LOGOUT
  | AuthActionTypes.ERROR_CUSTOMER_SESSION
  | AuthActionTypes.DELETE_USER_SESSION
  | AuthActionTypes.DELETE_USER_SESSION_SUCCESS
  | AuthActionTypes.DELETE_USER_SESSION_FAIL
  | AuthActionTypes.RESEND_OTP_SUCCESS
  | AuthActionTypes.RESEND_OTP_FAIL

  payload: {
    actionType?: string;
    data?: Response;
    error?: string;
    email?: string;
  };
};

const AuthReducer = (
  state: LoginState = INIT_STATE,
  action: AuthActionType
) => {
  switch (action.type) {
    case AuthActionTypes.SEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        email: action.payload.email,
        error: {},
        resendOtpStatus: false
      };
    case AuthActionTypes.OTP_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        key: action.payload?.data?.response?.key,
        error: {},
        resendOtpStatus: false

      };
    case AuthActionTypes.OTP_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case AuthActionTypes.VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: {}
      };
    case AuthActionTypes.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isValidSession: action.payload?.data?.response?.isValidSession,
        session: {
          sessionId: action.payload?.data?.response?.session?.sessionId,
          userType: action.payload?.data?.response?.userType,
          userId: action.payload?.data?.response?.userId,
          userName: action.payload?.data?.response?.userName,
          userRole: action.payload?.data?.response?.session?.userRole,
          privileges: action.payload?.data?.response?.session?.privileges,
        }
      };

    case AuthActionTypes.RESEND_OTP_SUCCESS:
      return {
        ...state,
        resendOtpStatus: true
      }

    case AuthActionTypes.RESEND_OTP_FAIL:
      return {
        ...state,
        resendOtpStatus: false
      }

    case AuthActionTypes.SET_CUSTOMER_SESSION:
      return {
        ...state,
        loading: false,
        isVerifyCustomerLoaded: true,
        isValidSession: action.payload?.data?.response?.isValidSession,
        email: action.payload?.data?.response?.email,
        enquiryId: action.payload?.data?.response.enquiryId,
        session: {
          sessionId: action.payload?.data?.response?.session,
          userType: action.payload?.data?.response?.userType,
          userId: action.payload?.data?.response?.userId,
          userName: action.payload?.data?.response?.userName,
          privileges: action.payload?.data?.response?.privileges,

        }
      };
    case AuthActionTypes.ERROR_CUSTOMER_SESSION:
      return {
        ...state,
        isVerifyCustomerLoaded: true,
      };

    case AuthActionTypes.VERIFY_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case AuthActionTypes.EDIT_MAIL:
      return {
        ...state,
        key: "",
        email: "",
        isExist: false
      };
    case AuthActionTypes.LOGOUT:
      return {
        key: "",
        email: "",
        otp: "",
        error: {},
        isValidSession: false
      }
    case AuthActionTypes.DELETE_USER_SESSION_SUCCESS:
      return {
        ...state,
        sessionDeleted: true,
      };
    case AuthActionTypes.DELETE_USER_SESSION_FAIL:
      return {
        ...state,
        sessionDeleted: false,
      };
    default:
      return { ...state };
  }
};

export default AuthReducer;
