export enum ClaimActionTypes {
    CREATE_CLAIM_REQUEST = 'CREATE_CLAIM_REQUEST',
    CREATE_CLAIM_SUCCESS = 'CREATE_CLAIM_SUCCESS',
    CREATE_CLAIM_FAILURE = 'CREATE_CLAIM_FAILURE',
    FETCH_CLAIMS_REQUEST = 'FETCH_CLAIMS_REQUEST',
    FETCH_CLAIMS_SUCCESS = 'FETCH_CLAIMS_SUCCESS',
    FETCH_CLAIMS_FAILURE = 'FETCH_CLAIMS_FAILURE',
    FETCH_CLAIM_BY_ID_REQUEST = 'FETCH_CLAIM_BY_ID_REQUEST',
    FETCH_CLAIM_BY_ID_SUCCESS = 'FETCH_CLAIM_BY_ID_SUCCESS',
    FETCH_CLAIM_BY_ID_FAILURE = 'FETCH_CLAIM_BY_ID_FAILURE',
    EDIT_CLAIM_REQUEST = 'EDIT_CLAIM_REQUEST',
    EDIT_CLAIM_SUCCESS = 'EDIT_CLAIM_SUCCESS',
    EDIT_CLAIM_FAILURE = 'EDIT_CLAIM_FAILURE',
    RESET_CLAIM_SUCCESS_STATE = 'RESET_CLAIM_SUCCESS_STATE',
    FETCH_SINGLE_CLAIM = 'FETCH_SINGLE_CLAIM',
    SET_SINGLE_CLAIM = 'SET_SINGLE_CLAIM',
    RESET_SINGLE_CLAIM = 'RESET_SINGLE_CLAIM'
}
