/* eslint-disable */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { editDealsTypes } from './constants';
import { fetchDealByIdApi, editDeals, fetchdeals } from 'helpers/api/deals';
import { 
  fetchDealsSuccess,
  fetchDealsFailure,
  fetchDealByIdSuccess, 
  fetchDealByIdFailure, 
  editDealSuccess, 
  editDealFailure 
} from './actions';


function* fetchDealsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchdeals, page, limit , sortBy, sort, searchBy);
    const rfqsData = response.data; 
    yield put(fetchDealsSuccess(rfqsData));
  } catch (e: any) {
    yield put(fetchDealsFailure(e));
  }
}

// Worker saga: fetch deal by ID
function* fetchDealByIdSaga(action: any): SagaIterator  {
  try {
    // Call the API to fetch the deal by ID
    const response = yield call(fetchDealByIdApi, action.payload);
    
    // Dispatch success action with the deal data
    yield put(fetchDealByIdSuccess(response.data));
  } catch (error) {
    // Dispatch failure action with the error
    yield put(fetchDealByIdFailure(error));
  }
}

// Worker saga: edit deal
function* editDealSaga(action: any): SagaIterator  {
  try {
    // Call the API to edit the deal
    const response = yield call(editDeals, action.payload.id, action.payload.data);
    
    // Dispatch success action with the updated deal data
    yield put(editDealSuccess(response.data));
  } catch (error) {
    // Dispatch failure action with the error
    yield put(editDealFailure(error));
  }
}

// Watcher saga: watches for actions dispatched to the store and starts the appropriate worker saga
// function* dealSaga() {
//   // Watch for the fetch deal by ID request action
//   yield takeLatest(editDealsTypes.FETCH_DEAL_BY_ID_REQUEST, fetchDealByIdSaga);
  
//   // Watch for the edit deal request action
//   yield takeLatest(editDealsTypes.EDIT_DEAL_REQUEST, editDealSaga);
// }

function* watchDeals() {
  yield takeEvery(editDealsTypes.FETCH_DEAL_BY_ID_REQUEST, fetchDealByIdSaga);
  yield takeEvery(editDealsTypes.EDIT_DEAL_REQUEST, editDealSaga);
  yield takeEvery(editDealsTypes.FETCH_DEALS_REQUEST,  fetchDealsSaga);

}
export default watchDeals;
