import { APICore } from "./apiCore";
const api = new APICore();

function createGADrawing(data: any) {
    const baseUrl = `/gaDrawing/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchGADrawings(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/gaDrawing/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchGADrawingById(id: number) {
    const url = `/gaDrawing/${id}`;
    return api.get(url, "");
}

function editGADrawing(id: number, data: any) {
    const url = `/gaDrawing/${id}`;
    return api.update(url, data);
}


async function createGADrawingWithFiles(params: { gaDrawingData: any, imageData?: any }) {
    try {
        const baseUrl = '/gaDrawing';

        const createdGADrawing = await api.create(baseUrl, { ...params.gaDrawingData });

        const gaDrawingResponse = createdGADrawing.data;

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'GA Drawing');
            params.imageData.append('typeId', String(gaDrawingResponse?.data?.newGA?.id));
            params.imageData.append('tagId', "3");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: gaDrawingResponse?.data?.newGA?.id,
                body: {
                    ...gaDrawingResponse?.data?.newGA,
                    image_id: newImageDataResponse,
                }
            };

            const baseRfqUrl = `/gaDrawing/updateImage/${gaDrawingResponse?.data?.newGA?.id}`;
            const updateRfq = await api.update(baseRfqUrl, { ...updatedBody.body });

            return updateRfq;
        }

        return gaDrawingResponse;

    } catch (error) {
        console.error('Error creating gaDrawing with files:', error);
        throw error;
    }
}

async function updateGADrawingWithFiles(params: { id: number, gaDrawingData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'GA Drawing');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "3");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.gaDrawingData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/gaDrawing/${params.id}`;
        return api.update(baseUrl, {
            ...params.gaDrawingData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating gaDrawing with files:', error);
        throw error;
    }
}




export { createGADrawing, fetchGADrawingById, fetchGADrawings, editGADrawing, createGADrawingWithFiles, updateGADrawingWithFiles };
