import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchTeam, createTeam, fetchSingleTeam, updateTeam, deleteTeam } from "helpers";
import { teamApiResponse, teamApiError, teamUpdateResponse, teamUpdateError, teamAddResponse, teamAddError, deleteTeamResponse, deleteTeamError } from "./actions";
import { ActionTypes } from './constants';

function* team(params: any): SagaIterator {
    try {
        const response = yield call(fetchTeam, { page: params.payload.data.page, limit: params.payload.data.limit,sortBy: params.payload.data.sortBy, sort: params.payload.data.sort });
        const teamData = yield response.data;
        yield put(teamApiResponse(ActionTypes.FETCH_TEAM, teamData));
    } catch (error: any) {
        yield put(teamApiError(ActionTypes.FETCH_TEAM, error));
    }
}

function* singleTeam(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleTeam, params.payload.id);
        const singleTeam = response.data;
        yield put(teamApiResponse(ActionTypes.FETCH_SINGLE_TEAM, singleTeam))
    } catch (error: any) {
        yield put(teamApiError(ActionTypes.FETCH_SINGLE_TEAM, error));
    }
}


function* _updateTeam({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateTeam, { id: payload.data.id, updateBody: payload.data.body })
        const teamData = response.data;
        console.log("updated successfully", teamData);
        yield put(teamUpdateResponse(ActionTypes.UPDATE_TEAM, teamData));
    } catch (e: any) {
        yield put(teamUpdateError(ActionTypes.UPDATE_TEAM, e))
    }
}



function* _createTeam({ payload }: any): SagaIterator {
    try {
        const response = yield call(createTeam, { team: payload.data });
        const teamData = response.data;
        console.log("Response in the _createTeam", teamData);
        yield put(teamAddResponse(ActionTypes.CREATE_TEAM, teamData));
    } catch (error: any) {
        yield put(teamAddError(ActionTypes.CREATE_TEAM, error));
    }
}

function* _deleteTeam(params: any): SagaIterator {
    console.log(params, "params")
    try {

        const response = yield call(deleteTeam, { id: params.payload.data.id });
        const TeamData = yield response.data;
        yield put(deleteTeamResponse(ActionTypes.DELETE_TEAM_SUCCESS, TeamData));
    } catch (e: any) {
        yield put(deleteTeamError(ActionTypes.DELETE_TEAM_FAIL, e))
    }
}

export function* watchTeam() {
    yield takeEvery(ActionTypes.FETCH_TEAM, team);
    yield takeEvery(ActionTypes.CREATE_TEAM, _createTeam);
    yield takeEvery(ActionTypes.UPDATE_TEAM, _updateTeam);
    yield takeEvery(ActionTypes.FETCH_SINGLE_TEAM, singleTeam)
    yield takeEvery(ActionTypes.DELETE_TEAM, _deleteTeam)
}

export default watchTeam;
