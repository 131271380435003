export enum QuotationActionTypes {
    CREATE_QUOTATION_REQUEST = 'CREATE_QUOTATION_REQUEST',
    CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS',
    CREATE_QUOTATION_FAILURE = 'CREATE_QUOTATION_FAILURE',
    FETCH_QUOTATIONS_REQUEST = 'FETCH_QUOTATIONS_REQUEST',
    FETCH_QUOTATIONS_SUCCESS = 'FETCH_QUOTATIONS_SUCCESS',
    FETCH_QUOTATIONS_FAILURE = 'FETCH_QUOTATIONS_FAILURE',
    FETCH_QUOTATION_BY_ID_REQUEST = 'FETCH_QUOTATION_BY_ID_REQUEST',
    FETCH_QUOTATION_BY_ID_SUCCESS = 'FETCH_QUOTATION_BY_ID_SUCCESS',
    FETCH_QUOTATION_BY_ID_FAILURE = 'FETCH_QUOTATION_BY_ID_FAILURE',
    FETCH_QUOTATION_BY_PROJECT_ID_REQUEST = 'FETCH_QUOTATION_BY_PROJECT_ID_REQUEST',
    FETCH_QUOTATION_BY_PROJECT_ID_SUCCESS = 'FETCH_QUOTATION_BY_PROJECT_ID_SUCCESS',
    FETCH_QUOTATION_BY_PROJECT_ID_FAILURE = 'FETCH_QUOTATION_BY_PROJECT_ID_FAILURE',
    EDIT_QUOTATION_REQUEST = 'EDIT_QUOTATION_REQUEST',
    EDIT_QUOTATION_SUCCESS = 'EDIT_QUOTATION_SUCCESS',
    EDIT_QUOTATION_FAILURE = 'EDIT_QUOTATION_FAILURE',
    RESET_QUOTATION_SUCCESS_STATE = 'RESET_QUOTATION_SUCCESS_STATE',
    FETCH_SINGLE_QUOTATION = 'FETCH_SINGLE_QUOTATION',
    SET_SINGLE_QUOTATION = 'SET_SINGLE_QUOTATION',
    RESET_SINGLE_QUOTATION = 'RESET_SINGLE_QUOTATION',
    FETCH_SINGLE_PROJECT_QUOTATION = 'FETCH_SINGLE_PROJECT_QUOTATION',
    SET_SINGLE_PROJECT_QUOTATION = 'SET_SINGLE_PROJECT_QUOTATION',
    RESET_SINGLE_PROJECT_QUOTATION = 'RESET_SINGLE_PROJECT_QUOTATION'
}
