import { GinActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  gins: [],
  singleGin: {},
  error: {},
  success: false,
};

const GinReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GinActionTypes.CREATE_GIN_REQUEST:
    case GinActionTypes.EDIT_GIN_REQUEST:
    case GinActionTypes.FETCH_GINS_REQUEST:
    case GinActionTypes.FETCH_GIN_BY_ID_REQUEST:
    case GinActionTypes.RESET_GIN_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case GinActionTypes.SET_SINGLE_GIN:
    return { ...state , singleGin: action.payload.data, loading:false }  
    case GinActionTypes.CREATE_GIN_SUCCESS:
      return {
        ...state,
        gins: [...state.gins, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case GinActionTypes.EDIT_GIN_SUCCESS:
      const updatedGins = state.gins.map((gin: any) =>
        gin.id === action.payload.data.id ? action.payload.data : gin
      );
      return {
        ...state,
        gins: updatedGins,
        loading: false,
        error: {},
        success: true,
      };

    case GinActionTypes.FETCH_GINS_SUCCESS:
      return {
        ...state,
        gins: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case GinActionTypes.FETCH_GIN_BY_ID_SUCCESS:
      return {
        ...state,
        singleGin: action.payload.data,
        gins: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case GinActionTypes.RESET_SINGLE_GIN:
      return {
          ...state,
          singleGin: null,
      };

    case GinActionTypes.CREATE_GIN_FAILURE:
    case GinActionTypes.EDIT_GIN_FAILURE:
    case GinActionTypes.FETCH_GINS_FAILURE:
    case GinActionTypes.FETCH_GIN_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default GinReducer;
