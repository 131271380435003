export enum CustomerQuotationActionTypes {
    CREATE_CUSTOMER_QUOTATION_REQUEST = 'CREATE_CUSTOMER_QUOTATION_REQUEST',
    CREATE_CUSTOMER_QUOTATION_SUCCESS = 'CREATE_CUSTOMER_QUOTATION_SUCCESS',
    CREATE_CUSTOMER_QUOTATION_FAILURE = 'CREATE_CUSTOMER_QUOTATION_FAILURE',
    FETCH_CUSTOMER_QUOTATIONS_REQUEST = 'FETCH_CUSTOMER_QUOTATIONS_REQUEST',
    FETCH_CUSTOMER_QUOTATIONS_SUCCESS = 'FETCH_CUSTOMER_QUOTATIONS_SUCCESS',
    FETCH_CUSTOMER_QUOTATIONS_FAILURE = 'FETCH_CUSTOMER_QUOTATIONS_FAILURE',
    FETCH_CUSTOMER_QUOTATION_BY_ID_REQUEST = 'FETCH_CUSTOMER_QUOTATION_BY_ID_REQUEST',
    FETCH_CUSTOMER_QUOTATION_BY_ID_SUCCESS = 'FETCH_CUSTOMER_QUOTATION_BY_ID_SUCCESS',
    FETCH_CUSTOMER_QUOTATION_BY_ID_FAILURE = 'FETCH_CUSTOMER_QUOTATION_BY_ID_FAILURE',
    EDIT_CUSTOMER_QUOTATION_REQUEST = 'EDIT_CUSTOMER_QUOTATION_REQUEST',
    EDIT_CUSTOMER_QUOTATION_SUCCESS = 'EDIT_CUSTOMER_QUOTATION_SUCCESS',
    EDIT_CUSTOMER_QUOTATION_FAILURE = 'EDIT_CUSTOMER_QUOTATION_FAILURE',
    RESET_CUSTOMER_QUOTATION_SUCCESS_STATE = 'RESET_CUSTOMER_QUOTATION_SUCCESS_STATE',
    FETCH_SINGLE_CUSTOMER_QUOTATION = 'FETCH_SINGLE_CUSTOMER_QUOTATION',
    SET_SINGLE_CUSTOMER_QUOTATION = 'SET_SINGLE_CUSTOMER_QUOTATION',
    RESET_SINGLE_CUSTOMER_QUOTATION = 'RESET_SINGLE_CUSTOMER_QUOTATION'
}
