import { Enquiry } from "redux/types/DataTypes";
import { ActionTypes } from "./constants";

export type EnquiryActionType = {
  type:
  | ActionTypes.FETCH_ENQUIRY
  | ActionTypes.SET_ENQUIRY
  | ActionTypes.SET_ERROR
  | ActionTypes.SET_SINGLE_ENQUIRY
  | ActionTypes.FETCH_SINGLE_ENQUIRY
  | ActionTypes.CREATE_ENQUIRY
  | ActionTypes.CREATE_ENQUIRY_FORM
  | ActionTypes.UPDATE_ENQUIRY
  | ActionTypes.CREATE_ENQUIRY_SUCCESS
  | ActionTypes.CREATE_ENQUIRY_SUCCESS_FORM
  | ActionTypes.UPDATE_ENQUIRY_SUCCESS
  | ActionTypes.FETCH_ENQUIRY_STATUSES
  | ActionTypes.SET_ENQUIRY_STATUSES
  | ActionTypes.GET_ENQUIRY_FORM
  | ActionTypes.SET_ENQUIRY_FORM
  | ActionTypes.GET_REVENUE_CHART_DATA
  | ActionTypes.SET_REVENUE_CHART_DATA
  | ActionTypes.DASHBOARD_ENQUIRY_DATA
  | ActionTypes.SET_ENQUIRY_DASHBOARD_DATA
  | ActionTypes.DELETE_ENQUIRY
  | ActionTypes.DELETE_ENQUIRY_SUCCESS
  | ActionTypes.DELETE_ENQUIRY_FAIL
  | ActionTypes.FETCH_ENQUIRY_LOCATION
  | ActionTypes.SET_ENQUIRY_LOCATION
  | ActionTypes.FETCH_SKU
  | ActionTypes.SET_SKU
  | ActionTypes.UPDATE_ENQUIRY_FORM
  | ActionTypes.UPDATE_ENQUIRY_FORM_SUCCESS
  | ActionTypes.UPDATE_ENQUIRY_FORM_FAIL
  | ActionTypes.RESET_ENQUIRY_FORM_UPDATE
  | ActionTypes.UPDATE_ENQUIRY_FAIL
  | ActionTypes.UPDATE_ENQUIRY_RESET
  payload: {} | string;
};

//dispatch action to get enquiry
export const getEnquiry = (data: any): EnquiryActionType => ({
  type: ActionTypes.FETCH_ENQUIRY,
  payload: { data },
});

//dispatch action to get enquiry
export const getEnquiryDetail = (id: any): EnquiryActionType => ({
  type: ActionTypes.FETCH_SINGLE_ENQUIRY,
  payload: { id },
});
//create enquiry
export const saveEnquiry = (data: any): EnquiryActionType => ({

  type: ActionTypes.CREATE_ENQUIRY,
  payload: { data },
});


export const getSkuVariant = (data: any) => ({
  type: ActionTypes.FETCH_SKU,
  payload: { data }
})

//dispatch action to get productDetails
export const getEnquiryStatuses = (): EnquiryActionType => ({
  type: ActionTypes.FETCH_ENQUIRY_STATUSES,
  payload: {},
});

export const getRevenueChartData = (data: any): EnquiryActionType => ({
  type: ActionTypes.GET_REVENUE_CHART_DATA,
  payload: { data },
});

export const saveEnquiryForm = (data: any): EnquiryActionType => ({
  type: ActionTypes.CREATE_ENQUIRY_FORM,
  payload: { data },
});

export const getEnquiryForm = (data: any): EnquiryActionType => ({
  type: ActionTypes.GET_ENQUIRY_FORM,
  payload: { data },
});

//update enquiry
export const updateEnquiry = (data: any): EnquiryActionType => ({
  type: ActionTypes.UPDATE_ENQUIRY,
  payload: { data },
});

export const resetEnquiry = () => ({
  type: ActionTypes.RESET,
  payload: {}
})

export const hardReset = () => ({
  type: ActionTypes.HARD_RESET,
  payload: {}
})

export const resetEnquiryForm = () => ({
  type: ActionTypes.RESET_ENQUIRY_FORM,
  payload: {}
})

export const resetEnquiryFormSuccess = () =>({
  type: ActionTypes.RESET_ENQUIRY_CUSTOM_FORM
})

export const getDashboardEnquiryData = (data: any): EnquiryActionType => ({
  type: ActionTypes.DASHBOARD_ENQUIRY_DATA,
  payload: { data }
})


export const updateEnquiryForm = (id: any): EnquiryActionType => ({
  type: ActionTypes.UPDATE_ENQUIRY_FORM,
  payload: { id }
})

export const resetEnquiryFormUpdateSuccess = (): EnquiryActionType => ({
  type: ActionTypes.RESET_ENQUIRY_FORM_UPDATE,
  payload: {}
})


export const enquiryFormUpdateResponse = (actionType: string, data: any): EnquiryActionType => {
  switch (actionType) {
    case ActionTypes.UPDATE_ENQUIRY_FORM_SUCCESS:
      return {
        type: ActionTypes.UPDATE_ENQUIRY_FORM_SUCCESS,
        payload: { data }
      }
    case ActionTypes.UPDATE_ENQUIRY_FORM_FAIL:
      return {
        type: ActionTypes.UPDATE_ENQUIRY_FORM_FAIL,
        payload: { error: data }
      }
    default:
      return {
        type: ActionTypes.SET_ERROR,
        payload: { error: "Problem while updating..." }
      }
  }
}

export const resetEnquiryUpdate = () =>({
  type:ActionTypes.UPDATE_ENQUIRY_RESET,
  payload:{}
})



export const enquiryUpdateResponse = (actionType:string,data:any)=>{
  switch(actionType){
    case ActionTypes.UPDATE_ENQUIRY_SUCCESS:
      return {
        type:ActionTypes.UPDATE_ENQUIRY_SUCCESS,
        payload:{data}
      }
    case ActionTypes.UPDATE_ENQUIRY_FAIL:
      return {
        type: ActionTypes.UPDATE_ENQUIRY_FAIL,
        payload:{error:data}
      }
    default: 
      return {
        type: ActionTypes.SET_ERROR,
        payload: { error: "Problem while updating..." }
      }
  }
}

//enquiry action to  dispatch
export const enquiryApiResponse = (
  actionType: string,
  data: Enquiry
): EnquiryActionType => {
  switch (actionType) {
    case ActionTypes.FETCH_ENQUIRY:
      return {
        type: ActionTypes.SET_ENQUIRY,
        payload: { actionType, data },
      };
    case ActionTypes.DASHBOARD_ENQUIRY_DATA:
      return {
        type: ActionTypes.SET_ENQUIRY_DASHBOARD_DATA,
        payload: { data }
      }
    case ActionTypes.FETCH_SINGLE_ENQUIRY:
      return {
        type: ActionTypes.SET_SINGLE_ENQUIRY,
        payload: { actionType, data },
      };
    case ActionTypes.CREATE_ENQUIRY_SUCCESS:
      return {
        type: ActionTypes.CREATE_ENQUIRY_SUCCESS,
        payload: { actionType, data },
      };
    case ActionTypes.SET_ENQUIRY_STATUSES:
      return {
        type: ActionTypes.SET_ENQUIRY_STATUSES,
        payload: { actionType, data },
      };
    case ActionTypes.SET_ENQUIRY_FORM:
      return {
        type: ActionTypes.SET_ENQUIRY_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.CREATE_ENQUIRY_SUCCESS_FORM:
      return {
        type: ActionTypes.CREATE_ENQUIRY_SUCCESS_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.UPDATE_ENQUIRY_SUCCESS:
      return {
        type: ActionTypes.UPDATE_ENQUIRY_SUCCESS,
        payload: { actionType, data },
      };
    case ActionTypes.GET_REVENUE_CHART_DATA:
      return {
        type: ActionTypes.SET_REVENUE_CHART_DATA,
        payload: { data }
      }
    case ActionTypes.FETCH_ENQUIRY_LOCATION:
      return {
        type: ActionTypes.SET_ENQUIRY_LOCATION,
        payload: { actionType, data }
      }
    case ActionTypes.FETCH_SKU:
      return {
        type: ActionTypes.SET_SKU,
        payload: { actionType, data }
      }
    default:
      return {
        type: ActionTypes.SET_ERROR,
        payload: { actionType, error: "Error While setting data to store ! " },
      };
  }
};

export const getEnquiryMap = () => ({
  type: ActionTypes.FETCH_ENQUIRY_LOCATION,
  payload: {}
})



//api response error handling
export const apiResponseError = (
  actionType: string,
  error: any
): EnquiryActionType => {
  switch (actionType) {
    case ActionTypes.SET_ENQUIRY_FORM:
      return {
        type: ActionTypes.SET_ENQUIRY_FORM,
        payload: {}
      }
    default:
      return {
        type: ActionTypes.SET_ERROR,
        payload: { actionType, error }
      }


  }

};



//! DELETING Enquiry
export const deleteEnquiry = (data: any): EnquiryActionType => ({
  type: ActionTypes.DELETE_ENQUIRY,
  payload: { data }
})

export const deleteEnquiryResponse = (actionType: any, data: any): EnquiryActionType => ({
  type: ActionTypes.DELETE_ENQUIRY_SUCCESS,
  payload: { actionType, data }
})
export const deleteEnquiryError = (actionType: any, error: any): EnquiryActionType => ({
  type: ActionTypes.DELETE_ENQUIRY_FAIL,
  payload: { actionType, error }
})