import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { HandoverActionTypes } from './constants';
import {
  createHandover,
  editHandover,
  fetchHandoverById,
  fetchHandovers,
} from 'helpers/api/handover';
import {
  createHandoverFailure,
  createHandoverSuccess,
  editHandoverFailure,
  editHandoverSuccess,
  fetchHandoverByIdFailure,
  fetchHandoverByIdSuccess,
  fetchHandoversFailure,
  fetchHandoversSuccess,
} from './actions';

function* fetchHandoversSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchHandovers, page, limit, sortBy, sort, searchBy);
    const handoversData = response.data;
    yield put(fetchHandoversSuccess(handoversData));
  } catch (e: any) {
    yield put(fetchHandoversFailure(e));
  }
}

function* createHandoverSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createHandover, data);
    const handoverData = response.data;
    yield put(createHandoverSuccess(handoverData));
    yield put({ type: HandoverActionTypes.FETCH_HANDOVERS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createHandoverFailure(e));
  }
}

function* fetchHandoverByIdSaga(action: any): SagaIterator {
  try {
    const response = yield call(fetchHandoverById, action.payload);
    const handoverData = response.data;
    yield put(fetchHandoverByIdSuccess(handoverData));
  } catch (e: any) {
    yield put(fetchHandoverByIdFailure(e));
  }
}

function* editHandoverSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editHandover, id, data);
    const handoverData = response.data;
    yield put(editHandoverSuccess(handoverData));
    yield put({ type: HandoverActionTypes.FETCH_HANDOVER_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editHandoverFailure(e));
  }
}

function* watchHandovers() {
  yield takeEvery(HandoverActionTypes.FETCH_HANDOVERS_REQUEST, fetchHandoversSaga);
  yield takeEvery(HandoverActionTypes.CREATE_HANDOVER_REQUEST, createHandoverSaga);
  yield takeEvery(HandoverActionTypes.FETCH_HANDOVER_BY_ID_REQUEST, fetchHandoverByIdSaga);
  yield takeEvery(HandoverActionTypes.EDIT_HANDOVER_REQUEST, editHandoverSaga);
}

export default watchHandovers;
