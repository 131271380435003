export enum InventoryActionTypes {
    CREATE_INVENTORY_REQUEST = 'CREATE_INVENTORY_REQUEST',
    CREATE_INVENTORY_SUCCESS = 'CREATE_INVENTORY_SUCCESS',
    CREATE_INVENTORY_FAILURE = 'CREATE_INVENTORY_FAILURE',
    FETCH_INVENTORYS_REQUEST = 'FETCH_INVENTORYS_REQUEST',
    FETCH_INVENTORYS_SUCCESS = 'FETCH_INVENTORYS_SUCCESS',
    FETCH_INVENTORYS_FAILURE = 'FETCH_INVENTORYS_FAILURE',
    FETCH_INVENTORYSHISTORY_REQUEST = 'FETCH_INVENTORYSHISTORY_REQUEST',
    FETCH_INVENTORYSHISTORY_SUCCESS = 'FETCH_INVENTORYSHISTORY_SUCCESS',
    FETCH_INVENTORYSHISTORY_FAILURE = 'FETCH_INVENTORYSHISTORY_FAILURE',
    FETCH_INVENTORY_BY_ID_REQUEST = 'FETCH_INVENTORY_BY_ID_REQUEST',
    FETCH_INVENTORY_BY_ID_SUCCESS = 'FETCH_INVENTORY_BY_ID_SUCCESS',
    FETCH_INVENTORY_BY_ID_FAILURE = 'FETCH_INVENTORY_BY_ID_FAILURE',
    EDIT_INVENTORY_REQUEST = 'EDIT_INVENTORY_REQUEST',
    EDIT_INVENTORY_SUCCESS = 'EDIT_INVENTORY_SUCCESS',
    EDIT_INVENTORY_FAILURE = 'EDIT_INVENTORY_FAILURE',
    RESET_INVENTORY_SUCCESS_STATE = 'RESET_INVENTORY_SUCCESS_STATE',
    FETCH_SINGLE_INVENTORY = 'FETCH_SINGLE_INVENTORY',
    SET_SINGLE_INVENTORY = 'SET_SINGLE_INVENTORY',
    RESET_SINGLE_INVENTORY = 'RESET_SINGLE_INVENTORY'
  }
  