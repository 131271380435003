import { APICore } from "./apiCore";
const api = new APICore();

function fetchOrganization(params: { page: string, limit: string, sortBy: string, sort: string, filterBy?: string, searchBy?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    const baseUrl = `/organization?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&filterBy=${params.filterBy}&searchBy=${params?.searchBy ? params?.searchBy : ''}`;
    return api.get(`${baseUrl}`, "");
}

function fetchOrganizationForDasbord(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/organization/dashboard/data?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "");
}


function fetchSingleOrganization(params: any) {
    const baseUrl = `/organization/${params}`;
    return api.get(`${baseUrl}`, {});
}

function createOrganization(body: any) {
    const baseUrl = "/organization";
    return api.create(`${baseUrl}`, body);
}

function updateOrganization(data: any) {
    const baseUrl = `/organization/${data.id}`;
    return api.update(`${baseUrl}`, { organization: data.updateBody });
}
function deleteOrganization(data: any) {
    const baseUrl = `/organization/${data.id}`;
    return api.delete(`${baseUrl}`);
}

function fetchOrganizationLocation() {
    const baseUrl = "/organization/location";
    return api.create(baseUrl, "");
}


async function createOrganizationWithCust(body: { organization: any, customer_details: any }) {
    console.log("apibodyorg", body)
    const response: any = {}

    try {
        const baseUrl = "/organization";
        const organization = { organization: body.organization }
        const newOrganization: any = await api.create(`${baseUrl}`, organization);
        if (!body.customer_details) return newOrganization;
        const customerBaseUrl = "/customer";
        response["organization"] = newOrganization?.data?.response?.organization;

        if (body.customer_details) {
            console.log("newOrganizationinapi", newOrganization)
            let custCount = 0;
            // body.customer_details.forEach((customerData: any, index: any) => {
            for (const customerData of body.customer_details) {
                const customer = {
                    "name": customerData?.name,
                    "email": customerData?.email,
                    "mobile": customerData?.mobile.toString(),
                    "designation": customerData?.designation,
                    "status": customerData?.status,
                    "organization_id": newOrganization?.data?.response?.organization?.id,
                    "organization_name": newOrganization?.data?.response?.organization?.name,
                }
                const createdCustomer = await api.create(`${customerBaseUrl}`, { customer: customer });
                response[`customer${custCount}`] = createdCustomer?.data?.response?.customer;
                custCount++;
                console.log("createdCustomer", createdCustomer)
            }

        }

        return response
    } catch (err) {
        if (response?.organization) {
            await api.delete(`/organization/${response?.organization?.id}`)
        }
        console.log("errorcreate", err)
        response["error"] = err;
        return response

    }
}

async function updateOrganizationWithCust(body: { id: any, updateBody: any, customer_details: any }) {
    console.log("apibodyorg", body)
    const response: any = {}
    try {
        const baseUrl = "/organization";
        const newOrganization: any = await api.update(`${baseUrl}/${body.id}`, { organization: body.updateBody });
        if (!body.customer_details) return newOrganization;
        const customerBaseUrl = "/customer";

        if (body.customer_details) {
            console.log("newOrganizationinapi", newOrganization)
            let custCount = 0;
            for (const customerData of body.customer_details) {
                if (customerData?.identifier) {
                    console.log("customerData", customerData)
                    const customer = {
                        "name": customerData?.name,
                        "email": customerData?.email,
                        "mobile": customerData?.mobile.toString(),
                        "designation": customerData?.designation,
                        "status": customerData?.status,
                        "organization_id": body.id,
                        "organization_name": body?.updateBody?.name,
                    }

                    const createdCustomer = await api.create(`${customerBaseUrl}`, { customer: customer });
                    response[`customer${custCount}`] = createdCustomer?.data?.response?.customer;
                    custCount++;
                    console.log("createdCustomer", createdCustomer)
                }
            }

        }
        return response
    } catch (err) {
        console.log("errorupdate", err)
        response["error"] = err;
        return response
    }
}






export { fetchOrganization, fetchOrganizationLocation, fetchSingleOrganization, createOrganization, updateOrganization, fetchOrganizationForDasbord, deleteOrganization, createOrganizationWithCust, updateOrganizationWithCust };