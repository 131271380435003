import { ApprovalActionTypes as ActionTypes } from './constants';

export const createApprovalV2Request = (data: any) => ({
  type: ActionTypes.CREATE_APPROVALV2_REQUEST,
  payload: {data},
});

export const resetApprovalV2SuccessState = () => ({
  type: ActionTypes.RESET_APPROVALV2_SUCCESS_STATE,
});

export const createApprovalV2Success = (response: any) => ({
  type: ActionTypes.CREATE_APPROVALV2_SUCCESS,
  payload: response,
});

export const createApprovalV2Failure = (error: any) => ({
  type: ActionTypes.CREATE_APPROVALV2_FAILURE,
  payload: error,
});

export const resetSingleApprovalV2 = () => ({
  type: ActionTypes.RESET_SINGLE_APPROVALV2,
});

export const fetchApprovalsV2Request = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string, status:string) => ({
  type: ActionTypes.FETCH_APPROVALSV2_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy, status },
});

export const fetchApprovalsV2Success = (response: any) => ({
  type: ActionTypes.FETCH_APPROVALSV2_SUCCESS,
  payload: response,
});

export const fetchApprovalsV2Failure = (error: any) => ({
  type: ActionTypes.FETCH_APPROVALSV2_FAILURE,
  payload: error,
});

export const fetchApprovalsV2TypesRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_APPROVALSV2_TYPES_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchApprovalsV2TypesSuccess = (response: any) => ({
  type: ActionTypes.FETCH_APPROVALSV2_TYPES_SUCCESS,
  payload: response,
});

export const fetchApprovalsV2TypesFailure = (error: any) => ({
  type: ActionTypes.FETCH_APPROVALSV2_TYPES_FAILURE,
  payload: error,
});

export const fetchApprovalV2ByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_APPROVALV2_BY_ID_REQUEST,
  payload: id,
});

export const fetchApprovalV2ByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_APPROVALV2_BY_ID_SUCCESS,
  payload: response,
});

export const fetchApprovalV2ByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_APPROVALV2_BY_ID_FAILURE,
  payload: error,
});

export const editApprovalV2Request = (id: number, data: any) => ({
  type: ActionTypes.EDIT_APPROVALV2_REQUEST,
  payload: { id, data },
});

export const editApprovalV2Success = (response: any) => ({
  type: ActionTypes.EDIT_APPROVALV2_SUCCESS,
  payload: response,
});

export const editApprovalV2Failure = (error: any) => ({
  type: ActionTypes.EDIT_APPROVALV2_FAILURE,
  payload: error,
});
