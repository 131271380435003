import { APICore } from "./apiCore";
const api = new APICore();

function fetchMachines(params: {
  page: string;
  limit: string;
  sortBy: string;
  sort: string;
  searchBy?: string;
}) {
  const baseUrl = `/machine?page=${params.page}&limit=${params.limit}&sortBy=${
    params.sortBy
  }&sort=${params.sort}&searchBy=${params.searchBy ? params.searchBy : ""}`;
  return api.get(`${baseUrl}`, "");
}

function fetchMachineDetail(params: { id: string }) {
  const baseUrl = `/machine/${params.id}`;
  return api.get(`${baseUrl}`, "");
}

function createMachine(data: any) {
  const baseUrl = `/machine`;  
  return api.create(`${baseUrl}`, { machine: data.machine, fields: data.fields });
}

function updateMachine(data: any) {
  const baseUrl = `/machine/${data.id}`;
  return api.update(`${baseUrl}`, { machine: data.machine, fields: data.fields });
}

function deleteMachine(data: any) {
  const baseUrl = `/machine/${data.id}`;
  return api.get(`${baseUrl}`, "");
}

export {
  fetchMachines,
  fetchMachineDetail,
  createMachine,
  updateMachine,
  deleteMachine,
};
