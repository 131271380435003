import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { ActionTypes } from "./constants";
import { updateTrigger, createTrigger, fetchAllTriggerTypeFor, fetchAllTriggerTypeBy, fetchSingleTrigger, fetchAllTriggers, fetchAllEntity, fetchAllEntityType, fetchAllEntityState } from 'helpers'
import { triggerApiError, triggerApiResponse } from './actions';


function* alltriggers(params: any): SagaIterator {
    try {
        const response = yield call(fetchAllTriggers, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort });
        const triggerData = yield response.data;
        yield put(triggerApiResponse(ActionTypes.FETCH_ALL_TRIGGER, triggerData));
    } catch (e: any) {
        yield put(triggerApiError(ActionTypes.FETCH_ALL_TRIGGER, e))
    }
}


function* allTriggerTypeBy(): SagaIterator {
    try {
        const response = yield call(fetchAllTriggerTypeBy);
        const triggerTypeByData = yield response.data;
        yield put(triggerApiResponse(ActionTypes.FETCH_TRIGGER_TYPE_BY, triggerTypeByData));
    } catch (e: any) {
        yield put(triggerApiError(ActionTypes.FETCH_TRIGGER_TYPE_BY, e))
    }
}


function* allTriggerEntity(): SagaIterator {
    try {
        const response = yield call(fetchAllEntity);
        const triggerEntity = yield response.data;
        yield put(triggerApiResponse(ActionTypes.FETCH_TRIGGER_ENTITY, triggerEntity));
    } catch (e: any) {
        yield put(triggerApiError(ActionTypes.FETCH_TRIGGER_ENTITY, e))
    }
}


function* allTriggerTypeFor(params: any): SagaIterator {
    try {
        const response = yield call(fetchAllTriggerTypeFor, params.payload.data.id);
        const triggerTypeForData = yield response.data;
        yield put(triggerApiResponse(ActionTypes.FETCH_TRIGGER_TYPE_FOR, triggerTypeForData));
    } catch (e: any) {
        yield put(triggerApiError(ActionTypes.FETCH_TRIGGER_TYPE_FOR, e))
    }
}

function* triggerEntityType(params: any): SagaIterator {
    try {
        const response = yield call(fetchAllEntityType, params.payload.data.id);
        const triggerEntityType = yield response.data;
        yield put(triggerApiResponse(ActionTypes.FETCH_TRIGGER_ENTITY_TYPE, triggerEntityType));
    } catch (e: any) {
        yield put(triggerApiError(ActionTypes.FETCH_TRIGGER_ENTITY_TYPE, e))
    }
}


function* triggerEntityState(params: any): SagaIterator {
    try {
        const response = yield call(fetchAllEntityState, params.payload.data.id);
        const triggerEntityTypeState = yield response.data;
        yield put(triggerApiResponse(ActionTypes.FETCH_TRIGGER_ENTITY_STATE, triggerEntityTypeState));
    } catch (e: any) {
        yield put(triggerApiError(ActionTypes.FETCH_TRIGGER_ENTITY_STATE, e))
    }
}







function* singleTrigger(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleTrigger, params.payload.id);
        const singleTrigger = response.data;
        yield put(triggerApiResponse(ActionTypes.FETCH_SINGLE_TRIGGER, singleTrigger))
    } catch (e: any) {
        yield put(triggerApiError(ActionTypes.FETCH_SINGLE_TRIGGER, e));
    }
}


function* _createTrigger({ payload }: any): SagaIterator {
    try {
        const response = yield call(createTrigger, { trigger: payload.data })
        const newTrigger = response.data;
        yield put(triggerApiResponse(ActionTypes.ADD_TRIGGER_SUCCESS, newTrigger))
    } catch (e: any) {
        yield put(triggerApiResponse(ActionTypes.ADD_TRIGGER_FAIL, e));
    }
}


function* _updateTrigger({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateTrigger, { id: payload.data.id, updateBody: payload.data.body })
        const updated = response.data;
        yield put(triggerApiResponse(ActionTypes.UPDATE_TRIGGER_SUCCESS, updated));
    } catch (e: any) {
        yield put(triggerApiResponse(ActionTypes.UPDATE_TRIGGER_FAIL, e));
    }
}


function* watchTrigger() {
    yield takeEvery(ActionTypes.FETCH_ALL_TRIGGER, alltriggers);
    yield takeEvery(ActionTypes.ADD_TRIGGER, _createTrigger);
    yield takeEvery(ActionTypes.UPDATE_TRIGGER, _updateTrigger);
    yield takeEvery(ActionTypes.FETCH_SINGLE_TRIGGER, singleTrigger);
    yield takeEvery(ActionTypes.FETCH_TRIGGER_TYPE_BY, allTriggerTypeBy)
    yield takeEvery(ActionTypes.FETCH_TRIGGER_TYPE_FOR, allTriggerTypeFor)
    yield takeEvery(ActionTypes.FETCH_TRIGGER_ENTITY, allTriggerEntity)
    yield takeEvery(ActionTypes.FETCH_TRIGGER_ENTITY_TYPE, triggerEntityType)
    yield takeEvery(ActionTypes.FETCH_TRIGGER_ENTITY_STATE, triggerEntityState)
}


export default watchTrigger;