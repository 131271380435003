import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { POActionTypes } from './constants';
import {
  createPO,
  fetchPOs,
  fetchPOById,
  editPO,
  fetchPOByProjectId,
} from 'helpers/api/po';
import {
  createPOSuccess,
  createPOFailure,
  fetchPOsSuccess,
  fetchPOsFailure,
  fetchPOByIdSuccess,
  fetchPOByIdFailure,
  editPOSuccess,
  editPOFailure,
  fetchPOByProjectIdSuccess,
  fetchPOByProjectIdFailure,
} from './actions';

function* fetchPOsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy, origin  } = action.payload || {};
    const response = yield call(fetchPOs, page, limit , sortBy, sort, searchBy, origin);
    const posData = response.data; 
    yield put(fetchPOsSuccess(posData));
  } catch (e: any) {
    yield put(fetchPOsFailure(e));
  }
}

function* createPOSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createPO, data);
    const poData = response.data;
    yield put(createPOSuccess(poData));
    yield put({ type: POActionTypes.FETCH_POS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createPOFailure(e));
  }
}

function* fetchPOByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchPOById, action.payload);
    const poData = response.data;
    yield put(fetchPOByIdSuccess(poData));
  } catch (e: any) {
    yield put(fetchPOByIdFailure(e));
  }
}

function* editPOSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editPO, id, data);
    const poData = response.data;
    yield put(editPOSuccess(poData));
    yield put({ type: POActionTypes.FETCH_PO_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editPOFailure(e));
  }
}

function* fetchPOByProjectIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchPOByProjectId, action.payload);
    const indentData = response.data;
    yield put(fetchPOByProjectIdSuccess(indentData));
  } catch (e: any) {
    yield put(fetchPOByProjectIdFailure(e));
  }
}

function* watchPOs() {
  yield takeEvery(POActionTypes.FETCH_POS_REQUEST, fetchPOsSaga);
  yield takeEvery(POActionTypes.CREATE_PO_REQUEST, createPOSaga);
  yield takeEvery(POActionTypes.FETCH_PO_BY_ID_REQUEST, fetchPOByIdSaga);
  yield takeEvery(POActionTypes.FETCH_PO_BY_PROJECT_ID_REQUEST, fetchPOByProjectIdSaga);
  yield takeEvery(POActionTypes.EDIT_PO_REQUEST, editPOSaga);
}

export default watchPOs;
