import { PaymentActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  payments: [],
  outgoingPayments: [],
  poPayments: [],
  milestones: [],
  singlePayment: {},
  error: {},
  success: false,
};

const PaymentReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case PaymentActionTypes.CREATE_PAYMENT_REQUEST:
    case PaymentActionTypes.CREATE_PAYMENT_INVOICE_REQUEST:
    case PaymentActionTypes.EDIT_PAYMENT_REQUEST:
    case PaymentActionTypes.FETCH_PAYMENTS_REQUEST:
    case PaymentActionTypes.FETCH_OUTGOING_PAYMENTS_REQUEST:
    case PaymentActionTypes.FETCH_PO_PAYMENTS_REQUEST:
    case PaymentActionTypes.FETCH_MILESTONES_REQUEST:
    case PaymentActionTypes.FETCH_PAYMENT_BY_ID_REQUEST:
    case PaymentActionTypes.RESET_PAYMENT_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case PaymentActionTypes.SET_SINGLE_PAYMENT:
    return { ...state , singlePayment: action.payload.data, loading:false }  
    case PaymentActionTypes.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: [...state.payments, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case PaymentActionTypes.CREATE_PAYMENT_INVOICE_SUCCESS:
      return {
        ...state,
        payments: [...state.payments, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case PaymentActionTypes.EDIT_PAYMENT_SUCCESS:
      const updatedPayments = state.payments.map((payment: any) =>
        payment.id === action.payload.data.id ? action.payload.data : payment
      );
      return {
        ...state,
        payments: updatedPayments,
        loading: false,
        error: {},
        success: true,
      };

    case PaymentActionTypes.FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload.data,
        loading: false,
        error: {},
        success: true
      };

    case PaymentActionTypes.FETCH_OUTGOING_PAYMENTS_SUCCESS:
      return {
        ...state,
        outgoingPayments: action.payload.data,
        loading: false,
        error: {},
        success: true
      };

    case PaymentActionTypes.FETCH_PO_PAYMENTS_SUCCESS:
      return {
        ...state,
        poPayments: action.payload.data,
        loading: false,
        error: {},
        success: true
      };

    case PaymentActionTypes.FETCH_MILESTONES_SUCCESS:
      return {
        ...state,
        milestones: action.payload.data,
        loading: false,
        error: {},
        success: true
      };

    case PaymentActionTypes.FETCH_PAYMENT_BY_ID_SUCCESS:
      return {
        ...state,
        singlePayment: action.payload.data,
        payments: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case PaymentActionTypes.RESET_SINGLE_PAYMENT:
      return {
          ...state,
          singlePayment: null,
      };

    case PaymentActionTypes.CREATE_PAYMENT_FAILURE:
    case PaymentActionTypes.CREATE_PAYMENT_INVOICE_FAILURE:
    case PaymentActionTypes.EDIT_PAYMENT_FAILURE:
    case PaymentActionTypes.FETCH_PAYMENTS_FAILURE:
    case PaymentActionTypes.FETCH_OUTGOING_PAYMENTS_FAILURE:
    case PaymentActionTypes.FETCH_PO_PAYMENTS_FAILURE:
    case PaymentActionTypes.FETCH_MILESTONES_FAILURE:
    case PaymentActionTypes.FETCH_PAYMENT_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default PaymentReducer;
