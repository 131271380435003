import { CommissioningActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  commissioning: [],
  singleCommissioning: {},
  error: {},
  success: false,
};

const CommissioningReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CommissioningActionTypes.CREATE_COMMISSIONING_REQUEST:
    case CommissioningActionTypes.EDIT_COMMISSIONING_REQUEST:
    case CommissioningActionTypes.FETCH_COMMISSIONINGS_REQUEST:
    case CommissioningActionTypes.FETCH_COMMISSIONINGS_USING_PROJECTID_REQUEST:
    case CommissioningActionTypes.FETCH_COMMISSIONING_BY_ID_REQUEST:
    case CommissioningActionTypes.RESET_COMMISSIONING_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case CommissioningActionTypes.SET_SINGLE_COMMISSIONING:
      return { ...state, singleCommissioning: action.payload.data, loading: false };

    case CommissioningActionTypes.CREATE_COMMISSIONING_SUCCESS:
      return {
        ...state,
        commissioning: [...state.commissioning, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case CommissioningActionTypes.EDIT_COMMISSIONING_SUCCESS:
      const updatedCommissionings = state.commissioning.map((commissioning: any) =>
        commissioning.id === action.payload.data.id ? action.payload.data : commissioning
      );
      return {
        ...state,
        commissioning: updatedCommissionings,
        loading: false,
        error: {},
        success: true,
      };

    case CommissioningActionTypes.FETCH_COMMISSIONINGS_SUCCESS:
    case CommissioningActionTypes.FETCH_COMMISSIONINGS_USING_PROJECTID_SUCCESS:
      return {
        ...state,
        commissioning: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case CommissioningActionTypes.FETCH_COMMISSIONING_BY_ID_SUCCESS:
      return {
        ...state,
        singleCommissioning: action.payload.data,
        commissioning: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case CommissioningActionTypes.RESET_SINGLE_COMMISSIONING:
      return {
        ...state,
        comissioning:[],
        singleCommissioning: {},
      };

    case CommissioningActionTypes.CREATE_COMMISSIONING_FAILURE:
    case CommissioningActionTypes.EDIT_COMMISSIONING_FAILURE:
    case CommissioningActionTypes.FETCH_COMMISSIONINGS_FAILURE:
    case CommissioningActionTypes.FETCH_COMMISSIONING_BY_ID_FAILURE:
    case CommissioningActionTypes.FETCH_COMMISSIONINGS_USING_PROJECTID_FAILURE:

      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default CommissioningReducer;
