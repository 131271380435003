import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { GinActionTypes } from './constants';
import {
  createGin,
  fetchGins,
  fetchGinById,
  editGin,
} from 'helpers/api/gin';
import {
  createGinSuccess,
  createGinFailure,
  fetchGinsSuccess,
  fetchGinsFailure,
  fetchGinByIdSuccess,
  fetchGinByIdFailure,
  editGinSuccess,
  editGinFailure,
} from './actions';

function* fetchGinsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchGins, page, limit , sortBy, sort, searchBy);
    const ginsData = response.data; 
    yield put(fetchGinsSuccess(ginsData));
  } catch (e: any) {
    yield put(fetchGinsFailure(e));
  }
}

function* createGinSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createGin, data);
    const ginData = response.data;
    yield put(createGinSuccess(ginData));
    yield put({ type: GinActionTypes.FETCH_GINS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createGinFailure(e));
  }
}

function* fetchGinByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchGinById, action.payload);
    const ginData = response.data;
    yield put(fetchGinByIdSuccess(ginData));
  } catch (e: any) {
    yield put(fetchGinByIdFailure(e));
  }
}

function* editGinSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editGin, id, data);
    const ginData = response.data;
    yield put(editGinSuccess(ginData));
    yield put({ type: GinActionTypes.FETCH_GIN_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editGinFailure(e));
  }
}

function* watchGins() {
  yield takeEvery(GinActionTypes.FETCH_GINS_REQUEST, fetchGinsSaga);
  yield takeEvery(GinActionTypes.CREATE_GIN_REQUEST, createGinSaga);
  yield takeEvery(GinActionTypes.FETCH_GIN_BY_ID_REQUEST, fetchGinByIdSaga);
  yield takeEvery(GinActionTypes.EDIT_GIN_REQUEST, editGinSaga);
}

export default watchGins;
