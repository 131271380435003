import { ActionTypes } from './constants';

export type MachineManagementType = {
    type:
    | ActionTypes.CREATE_MACHINE
    | ActionTypes.FETCH_MACHINE_LIST
    | ActionTypes.FETCH_MACHINE_LIST_SUCCESS
    | ActionTypes.FETCH_MACHINE_LIST_FAIL
    | ActionTypes.FETCH_MACHINE_DETAIL
    | ActionTypes.FETCH_MACHINE_DETAIL_SUCCESS
    | ActionTypes.FETCH_MACHINE_DETAIL_FAIL
    | ActionTypes.UPDATE_MACHINE
    | ActionTypes.UPDATE_MACHINE_SUCCESS
    | ActionTypes.UPDATE_MACHINE_FAIL
    | ActionTypes.CREATE_MACHINE_SUCCESS
    | ActionTypes.CREATE_MACHINE_FAIL
    | ActionTypes.UPDATE_MACHINE_RESET_STATE
    | ActionTypes.DELETE_MACHINE
    | ActionTypes.DELETE_MACHINE_SUCCESS
    | ActionTypes.DELETE_MACHINE_FAIL
    | ActionTypes.SET_ERROR
    payload: {} | string;
}

//dispatch to get all MACHINEs
export const fetchMachines = (data: any): MachineManagementType => ({
    type: ActionTypes.FETCH_MACHINE_LIST,
    payload: { data }
})

export const resetUpdateMachineState = (): MachineManagementType => ({
    type: ActionTypes.UPDATE_MACHINE_RESET_STATE,
    payload: {}
})

export const resetCreateMachineState = () => ({
    type: ActionTypes.CREATE_MACHINE_RESET_STATE,
    payload: {}
})

export const resetDeleteMachineState = () => ({
    type: ActionTypes.CREATE_MACHINE_RESET_STATE,
    payload: {}
})

export const resetFetchMachineDetailState = () => ({
    type: ActionTypes.FETCH_MACHINE_DETAIL_RESET_STATE,
    payload: {}
})

export const createMachine = (data: any): MachineManagementType => ({
    type: ActionTypes.CREATE_MACHINE,
    payload: { data }
})

export const updateMachine = (data: any): MachineManagementType => ({
    type: ActionTypes.UPDATE_MACHINE,
    payload: { data }
})

export const deleteMACHINE = (data: any): MachineManagementType => ({
    type: ActionTypes.DELETE_MACHINE,
    payload: { data }
  })

export const getMachineByID = (data: any): MachineManagementType => ({
    type: ActionTypes.FETCH_MACHINE_DETAIL,
    payload: { data }
})

export const fetchMachineAPIResponse = (actionType: string, data: any): MachineManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_MACHINE_LIST_SUCCESS:
            return {
                type: ActionTypes.FETCH_MACHINE_LIST_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.FETCH_MACHINE_LIST_FAIL:
            return {
                type: ActionTypes.FETCH_MACHINE_LIST_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const createMachineAPIResponse = (actionType: string, data: any): MachineManagementType => {
    switch (actionType) {
        case ActionTypes.CREATE_MACHINE_SUCCESS:
            return {
                type: ActionTypes.CREATE_MACHINE_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.CREATE_MACHINE_FAIL:
            return {
                type: ActionTypes.CREATE_MACHINE_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const fetchMachineDetailAPIResponse = (actionType: string, data: any): MachineManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_MACHINE_DETAIL_SUCCESS:
            return {
                type: ActionTypes.FETCH_MACHINE_DETAIL_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.FETCH_MACHINE_DETAIL_FAIL:
            return {
                type: ActionTypes.FETCH_MACHINE_DETAIL_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const updateMachineAPIResponse = (actionType: string, data: any): MachineManagementType => {
    switch (actionType) {
        case ActionTypes.UPDATE_MACHINE_SUCCESS:
            return {
                type: ActionTypes.UPDATE_MACHINE_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.UPDATE_MACHINE_FAIL:
            return {
                type: ActionTypes.UPDATE_MACHINE_FAIL,
                payload: { actionType, data: null, error: data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const deleteMachineAPIResponse = (actionType: string, data: any): MachineManagementType => {
    switch (actionType) {
        case ActionTypes.DELETE_MACHINE_SUCCESS:
            return {
                type: ActionTypes.DELETE_MACHINE_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.DELETE_MACHINE_FAIL:
            return {
                type: ActionTypes.DELETE_MACHINE_FAIL,
                payload: { actionType, data: null, error: data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

  