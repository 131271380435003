import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { FabDrawingActionTypes } from './constants';
import {
  createFabDrawing,
  editFabDrawing,
fetchFabDrawings,
fetchFabDrawingById
} from 'helpers/api/fabDrawing';
import {
  createFabDrawingSuccess,
  createFabDrawingFailure,
  fetchFabDrawingByIdFailure,
  fetchFabDrawingByIdSuccess,
  fetchFabDrawingsFailure,
  fetchFabDrawingsSuccess,
  editFabDrawingFailure,
  editFabDrawingSuccess,
} from './actions';

function* fetchFabDrawingsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchFabDrawings, page, limit , sortBy, sort, searchBy);
    const grnsData = response.data; 
    yield put(fetchFabDrawingsSuccess(grnsData));
  } catch (e: any) {
    yield put(fetchFabDrawingsFailure(e));
  }
}

function* createFabDrawingSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createFabDrawing, data);
    const grnData = response.data;
    yield put(createFabDrawingSuccess(grnData));
    yield put({ type: FabDrawingActionTypes.FETCH_FabDrawingS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createFabDrawingFailure(e));
  }
}

function* fetchFabDrawingByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchFabDrawingById, action.payload);
    const grnData = response.data;
    yield put(fetchFabDrawingByIdSuccess(grnData));
  } catch (e: any) {
    yield put(fetchFabDrawingByIdFailure(e));
  }
}

function* editFabDrawingSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editFabDrawing, id, data);
    const grnData = response.data;
    yield put(editFabDrawingSuccess(grnData));
    yield put({ type: FabDrawingActionTypes.FETCH_FabDrawing_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editFabDrawingFailure(e));
  }
}

function* watchFabDrawings() {
  yield takeEvery(FabDrawingActionTypes.FETCH_FabDrawingS_REQUEST, fetchFabDrawingsSaga);
  yield takeEvery(FabDrawingActionTypes.CREATE_FabDrawing_REQUEST, createFabDrawingSaga);
  yield takeEvery(FabDrawingActionTypes.FETCH_FabDrawing_BY_ID_REQUEST, fetchFabDrawingByIdSaga);
  yield takeEvery(FabDrawingActionTypes.EDIT_FabDrawing_REQUEST, editFabDrawingSaga);
}

export default watchFabDrawings;
