import { ItemCategoryActionTypes } from './constant';

const INIT_STATE = {
  count: 0,
  loading: true,
  itemCategories: [],
  error: {},
  success: false,
  singleItemCategory: {},
};

const ItemCategoryReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ItemCategoryActionTypes.CREATE_ITEM_CATEGORY_REQUEST:
    case ItemCategoryActionTypes.EDIT_ITEM_CATEGORY_REQUEST:
    case ItemCategoryActionTypes.FETCH_ITEM_CATEGORIES_REQUEST:
    case ItemCategoryActionTypes.FETCH_ITEM_CATEGORY_BY_ID_REQUEST:
    case ItemCategoryActionTypes.RESET_ITEM_CATEGORY_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case ItemCategoryActionTypes.SET_SINGLE_ITEMCATEGORY:
      console.log(action.payload,"shubhi")
      return { ...state , singleItemCategory: action.payload.data, loading:false }
    
    case ItemCategoryActionTypes.CREATE_ITEM_CATEGORY_SUCCESS:
      return {
        ...state,
        itemCategories: [...state.itemCategories, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case ItemCategoryActionTypes.EDIT_ITEM_CATEGORY_SUCCESS:
      const updatedItemCategories = state.itemCategories.map((itemCategory: any) =>
        itemCategory.id === action.payload.data.id ? action.payload.data : itemCategory
      );
      return {
        ...state,
        itemCategories: updatedItemCategories,
        loading: false,
        error: {},
        success: true,
      };

    case ItemCategoryActionTypes.FETCH_ITEM_CATEGORIES_SUCCESS:
      console.log(action.payload,"yoooo")
      return {
        ...state,
        itemCategories: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case ItemCategoryActionTypes.FETCH_ITEM_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        singleItemCategory: action.payload.data,
        itemCategories: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case ItemCategoryActionTypes.RESET_SINGLE_ITEM_CATEGORY:
        return {
            ...state,
            singleItemCategory: null,
        };

    case ItemCategoryActionTypes.CREATE_ITEM_CATEGORY_FAILURE:
    case ItemCategoryActionTypes.EDIT_ITEM_CATEGORY_FAILURE:
    case ItemCategoryActionTypes.FETCH_ITEM_CATEGORIES_FAILURE:
    case ItemCategoryActionTypes.FETCH_ITEM_CATEGORY_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default ItemCategoryReducer;
