import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchVendor, createVendor, updateVendor, fetchSingleVendor, fetchVendorForDasbord, deleteVendor, createVendorWithFiles, updateVendorWithFiles, searchVendor } from "helpers";
import { vendorAddError, vendorAddResponse, vendorApiError, vendorApiResponse, vendorUpdateResponse, vendorUpdateError, vendorDeleteError, vendorDeleteResponse, vendorUpdateErrorWithFiles, vendorUpdateResponseWithFiles, searchVendorAPIResponse, createVendorWithFilesFail, createVendorWithFilesSuccess } from "./actions";
import { ActionTypes } from "./constants";

function* vendor(params: any): SagaIterator {
    try {
        const response = yield call(fetchVendor, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, filterBy: params.payload.data.filterBy, searchBy: params.payload.data?.searchBy });
        const vendorData = yield response.data;
        yield put(vendorApiResponse(ActionTypes.FETCH_VENDOR, vendorData));
    } catch (e: any) {
        yield put(vendorApiError(ActionTypes.FETCH_VENDOR, e))
    }
}

function* _searchVendor(params: any): SagaIterator {
    try {
        const response = yield call(searchVendor, { jobId: params.payload.jobId });
        const vendorData = yield response.data;
        yield put(searchVendorAPIResponse(ActionTypes.SEARCH_VENDOR_SUCCESS, vendorData));
    } catch (e: any) {
        yield put(searchVendorAPIResponse(ActionTypes.SEARCH_VENDOR_FAIL, e))
    }
}

function* vendorDashboardData(params: any): SagaIterator {
    try {
        const response = yield call(fetchVendorForDasbord, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter });
        const vendorData = yield response.data;
        yield put(vendorApiResponse(ActionTypes.DASHBOARD_VENDOR_DATA, vendorData));
    } catch (e: any) {
        yield put(vendorApiError(ActionTypes.DASHBOARD_VENDOR_DATA, e))
    }
}

function* singleVendor(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleVendor, params.payload.id);
        const singleVendor = response.data;
        yield put(vendorApiResponse(ActionTypes.FETCH_SINGLE_VENDOR, singleVendor))
    } catch (e: any) {
        yield put(vendorApiError(ActionTypes.FETCH_SINGLE_VENDOR, e));
    }
}


function* _createVendor({ payload }: any): SagaIterator {
    try {
        const response = yield call(createVendor, { vendor: payload.data })
        const vendorData = response.data;
        yield put(vendorAddResponse(ActionTypes.CREATE_VENDOR, vendorData))
    } catch (e: any) {
        yield put(vendorAddError(ActionTypes.CREATE_VENDOR, e));
    }
}

function* _updateVendor({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateVendor, { id: payload.data.id, updateBody: payload.data.body })
        const vendorData = response.data;
        console.log("Updated successfully", vendorData);
        yield put(vendorUpdateResponse(ActionTypes.UPDATE_VENDOR, vendorData));
    } catch (e: any) {
        yield put(vendorUpdateError(ActionTypes.UPDATE_VENDOR, e));
    }
}

function* _deleteVendor({ payload }: any): SagaIterator {
    try {
        const response = yield call(deleteVendor, { id: payload.data.id })
        const vendorData = response.data;
        yield put(vendorDeleteResponse(ActionTypes.DELETE_VENDOR_SUCCESS, vendorData));
    } catch (e: any) {
        yield put(vendorDeleteError(ActionTypes.DELETE_VENDOR_FAIL, e));
    }
}


//WITH FILES
function* _createVendorWithFiles({ payload }: any): SagaIterator {
    try {
        const response = yield call(createVendorWithFiles, { vendorData: payload.data.vendor,  logoData: payload.data.logoData, files: payload.data.files })
        const vendorData = response.data;
        console.log("sagatry", vendorData)
        yield put(createVendorWithFilesSuccess(ActionTypes.CREATE_VENDOR_WITH_FILES_SUCCESS, vendorData))
    } catch (e: any) {
        console.log(e, "sagatryE")
        yield put(createVendorWithFilesFail(ActionTypes.CREATE_VENDOR_WITH_FILES_FAIL, e))
    }
}

function* _updateVendorWithFiles({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateVendorWithFiles, { id: payload.data.id, updateBody: payload.data.vendor, logoData: payload.data.logoData, files: payload.data.files })
        const vendorData = response.data;
        console.log("dscbhjbhj", vendorData);
        yield put(vendorUpdateResponseWithFiles(ActionTypes.UPDATE_VENDOR_SUCCESS_WITH_FILES, vendorData))
    } catch (err: any) {
        yield put(vendorUpdateErrorWithFiles(ActionTypes.UPDATE_VENDOR_FAIL_WITH_FILES, err))
    }
}


function* watchVendor() {
    yield takeEvery(ActionTypes.FETCH_VENDOR, vendor);
    yield takeEvery(ActionTypes.SEARCH_VENDOR, _searchVendor);
    yield takeEvery(ActionTypes.CREATE_VENDOR, _createVendor);
    yield takeEvery(ActionTypes.UPDATE_VENDOR, _updateVendor);
    yield takeEvery(ActionTypes.FETCH_SINGLE_VENDOR, singleVendor);
    yield takeEvery(ActionTypes.DASHBOARD_VENDOR_DATA, vendorDashboardData)
    yield takeEvery(ActionTypes.DELETE_VENDOR, _deleteVendor)
    yield takeEvery(ActionTypes.CREATE_VENDOR_WITH_FILES, _createVendorWithFiles)
    yield takeEvery(ActionTypes.UPDATE_VENDOR_WITH_FILES, _updateVendorWithFiles)
}

export default watchVendor;