export enum ActionTypes {
    FETCH_LEAD = "FETCH_LEAD",
    SET_LEAD = "SET_LEAD",
    SET_ERROR = "SET_ERROR",
    CREATE_LEAD = "CREATE_LEAD",
    CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS",
    CREATE_LEAD_FAIL = "CREATE_LEAD_FAIL",
    FETCH_SINGLE_LEAD = "FETCH_SINGLE_LEAD",
    SET_SINGLE_LEAD = "SET_SINGLE_LEAD",
    UPDATE_LEAD = "UPDATE_LEAD",
    UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS",
    UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL",
    FETCH_LEAD_TAG = "FETCH_LEAD_TAG",
    SET_LEAD_TAG = "SET_LEAD_TAG",
    FETCH_LEAD_STATUS= "FETCH_LEAD_STATUS",
    SET_LEAD_STATUS = "SET_LEAD_STATUS",


}