import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchRole, fetchSingleRole, createRole, updateRole, fetchPrivileges, deleteRole } from 'helpers'
import { deleteRoleError, deleteRoleResponse, roleAddError, roleAddResponse, roleApiError, roleApiResponse, roleUpdateError, roleUpdateResponse } from "./actions";
import { ActionTypes } from "./constants";


function* role(): SagaIterator {
    try {
        const response = yield call(fetchRole);
        const roleData = yield response.data;
        yield put(roleApiResponse(ActionTypes.FETCH_ROLE, roleData))
    } catch (error: any) {
        yield put(roleApiError(ActionTypes.FETCH_ROLE, error))
    }
}

function* singleRole(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleRole, params.payload.id);
        const singleRole = response.data;
        yield put(roleApiResponse(ActionTypes.FETCH_SINGLE_ROLE, singleRole))
    } catch (error: any) {
        yield put(roleApiError(ActionTypes.FETCH_SINGLE_ROLE, error))
    }
}

function* _createRole({ payload }: any): SagaIterator {
    try {
        const response = yield call(createRole, payload.data)
        const responseData = response.data;
        yield put(roleAddResponse(ActionTypes.CREATE_ROLE, responseData))
    } catch (error: any) {
        yield put(roleAddError(ActionTypes.CREATE_ROLE, error))
    }
}

function* _getPrivileges(): SagaIterator {
    try {
        const response = yield call(fetchPrivileges);
        const data = response.data;
        yield put(roleApiResponse(ActionTypes.FETCH_PRIVILEGES, data));
    } catch (error: any) {
        yield put(roleApiError(ActionTypes.FETCH_PRIVILEGES, error))
    }
}


function* _updateRole({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateRole, { id: payload.data.id, role: payload.data.body })
        const roleData = response.data;
        console.log("role updated", roleData);
        yield put(roleUpdateResponse(ActionTypes.UPDATE_ROLE, roleData))
    } catch (error: any) {
        yield put(roleUpdateError(ActionTypes.UPDATE_ROLE, error))
    }
}

function* _deleteRole(params: any): SagaIterator {
    console.log(params, "params")
    try {

        const response = yield call(deleteRole, { id: params.payload.data.id });
        const RoleData = yield response.data;
        yield put(deleteRoleResponse(ActionTypes.DELETE_ROLE_SUCCESS, RoleData));
    } catch (e: any) {
        yield put(deleteRoleError(ActionTypes.DELETE_ROLE_FAIL, e))
    }
}


function* watchRole() {
    yield takeEvery(ActionTypes.FETCH_ROLE, role);
    yield takeEvery(ActionTypes.CREATE_ROLE, _createRole);
    yield takeEvery(ActionTypes.UPDATE_ROLE, _updateRole);
    yield takeEvery(ActionTypes.FETCH_SINGLE_ROLE, singleRole)
    yield takeEvery(ActionTypes.FETCH_PRIVILEGES, _getPrivileges);
    yield takeEvery(ActionTypes.DELETE_ROLE, _deleteRole);
}

export default watchRole;