export enum CommissioningActionTypes {
    CREATE_COMMISSIONING_REQUEST = 'CREATE_COMMISSIONING_REQUEST',
    CREATE_COMMISSIONING_SUCCESS = 'CREATE_COMMISSIONING_SUCCESS',
    CREATE_COMMISSIONING_FAILURE = 'CREATE_COMMISSIONING_FAILURE',
    FETCH_COMMISSIONINGS_REQUEST = 'FETCH_COMMISSIONINGS_REQUEST',
    FETCH_COMMISSIONINGS_SUCCESS = 'FETCH_COMMISSIONINGS_SUCCESS',
    FETCH_COMMISSIONINGS_FAILURE = 'FETCH_COMMISSIONINGS_FAILURE',
    FETCH_COMMISSIONINGS_USING_PROJECTID_REQUEST = 'FETCH_COMMISSIONINGS_USING_PROJECTID_REQUEST',
    FETCH_COMMISSIONINGS_USING_PROJECTID_SUCCESS = 'FETCH_COMMISSIONINGS_USING_PROJECTID_SUCCESS',
    FETCH_COMMISSIONINGS_USING_PROJECTID_FAILURE = 'FETCH_COMMISSIONINGS_USING_PROJECTID_FAILURE',
    FETCH_COMMISSIONING_BY_ID_REQUEST = 'FETCH_COMMISSIONING_BY_ID_REQUEST',
    FETCH_COMMISSIONING_BY_ID_SUCCESS = 'FETCH_COMMISSIONING_BY_ID_SUCCESS',
    FETCH_COMMISSIONING_BY_ID_FAILURE = 'FETCH_COMMISSIONING_BY_ID_FAILURE',
    EDIT_COMMISSIONING_REQUEST = 'EDIT_COMMISSIONING_REQUEST',
    EDIT_COMMISSIONING_SUCCESS = 'EDIT_COMMISSIONING_SUCCESS',
    EDIT_COMMISSIONING_FAILURE = 'EDIT_COMMISSIONING_FAILURE',
    RESET_COMMISSIONING_SUCCESS_STATE = 'RESET_COMMISSIONING_SUCCESS_STATE',
    FETCH_SINGLE_COMMISSIONING = 'FETCH_SINGLE_COMMISSIONING',
    SET_SINGLE_COMMISSIONING = 'SET_SINGLE_COMMISSIONING',
    RESET_SINGLE_COMMISSIONING = 'RESET_SINGLE_COMMISSIONING'
}
