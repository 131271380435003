import { APICore } from "./apiCore";
const api = new APICore();

function createDPR(data: any) {
    const baseUrl = `/dpr/`;
    return api.create(`${baseUrl}`, { ...data });
}

function fetchDPRs(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/dpr/`;
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}
function fetchDPRsUsingProjectId(projectId:any,page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/dpr/project`;
    const params = new URLSearchParams();
    if(projectId !== undefined) params.append('projectId', projectId.toString());
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchDPRById(id: number) {
    const url = `/dpr/${id}`;
    return api.get(url, "");
}

function editDPR(id: number, data: any) {
    const url = `/dpr/${id}`;
    return api.update(url, data);
}

async function createDPRWithFiles(params: { dprData: any, imageData?: any }) {
    try {
        const baseUrl = '/dpr';

        const createdDPR = await api.create(baseUrl, { ...params.dprData });
        const dprResponse = createdDPR.data;

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'DPR');
            params.imageData.append('typeId', String(dprResponse?.data?.newDPR?.id));
            params.imageData.append('tagId', "66");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: dprResponse?.data?.newDPR?.id,
                body: {
                    ...dprResponse?.data?.newDPR,
                    image_id: newImageDataResponse,
                }
            };

            const baseRfqUrl = `/dpr/updateImage/${dprResponse?.data?.newDPR?.id}`;
            const updateRfq = await api.update(baseRfqUrl, { ...updatedBody.body });

            return updateRfq;
        }

        return dprResponse;

    } catch (error) {
        console.error('Error creating DPR with files:', error);
        throw error;
    }
}

async function updateDPRWithFiles(params: { id: number, dprData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'DPR');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "66");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.dprData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/dpr/${params.id}`;
        return api.update(baseUrl, {
            ...params.dprData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating DPR with files:', error);
        throw error;
    }
}

export { createDPR, fetchDPRById, fetchDPRs, editDPR, createDPRWithFiles, updateDPRWithFiles, fetchDPRsUsingProjectId };
