import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ApprovalActionTypes } from './constants';
import {
  createApprovalV2,
  fetchApprovalsV2,
  fetchApprovalsV2Types,
  fetchApprovalV2ById,
  editApprovalV2,
} from 'helpers/api/approvalV2';
import {
  createApprovalV2Success,
  createApprovalV2Failure,
  fetchApprovalsV2Success,
  fetchApprovalsV2Failure,
  fetchApprovalsV2TypesSuccess,
  fetchApprovalsV2TypesFailure,
  fetchApprovalV2ByIdSuccess,
  fetchApprovalV2ByIdFailure,
  editApprovalV2Success,
  editApprovalV2Failure,
} from './actions';

function* fetchApprovalsV2Saga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy, status  } = action.payload || {};
    const response = yield call(fetchApprovalsV2, page, limit , sortBy, sort, searchBy, status);
    const approvalsData = response.data; 
    yield put(fetchApprovalsV2Success(approvalsData));
  } catch (e: any) {
    yield put(fetchApprovalsV2Failure(e));
  }
}

function* fetchApprovalsV2TypesSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchApprovalsV2Types, page, limit , sortBy, sort, searchBy);
    const approvalsData = response.data; 
    yield put(fetchApprovalsV2TypesSuccess(approvalsData));
  } catch (e: any) {
    yield put(fetchApprovalsV2TypesFailure(e));
  }
}

function* createApprovalV2Saga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createApprovalV2, data);
    const approvalData = response.data;
    yield put(createApprovalV2Success(approvalData));
    yield put({ type: ApprovalActionTypes.FETCH_APPROVALSV2_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createApprovalV2Failure(e));
  }
}

function* fetchApprovalV2ByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchApprovalV2ById, action.payload);
    const approvalData = response.data;
    yield put(fetchApprovalV2ByIdSuccess(approvalData));
  } catch (e: any) {
    yield put(fetchApprovalV2ByIdFailure(e));
  }
}

function* editApprovalV2Saga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editApprovalV2, id, data);
    const approvalData = response.data;
    yield put(editApprovalV2Success(approvalData));
    yield put({ type: ApprovalActionTypes.FETCH_APPROVALV2_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editApprovalV2Failure(e));
  }
}

function* watchApprovals() {
  yield takeEvery(ApprovalActionTypes.FETCH_APPROVALSV2_REQUEST, fetchApprovalsV2Saga);
  yield takeEvery(ApprovalActionTypes.FETCH_APPROVALSV2_TYPES_REQUEST, fetchApprovalsV2TypesSaga);
  yield takeEvery(ApprovalActionTypes.CREATE_APPROVALV2_REQUEST, createApprovalV2Saga);
  yield takeEvery(ApprovalActionTypes.FETCH_APPROVALV2_BY_ID_REQUEST, fetchApprovalV2ByIdSaga);
  yield takeEvery(ApprovalActionTypes.EDIT_APPROVALV2_REQUEST, editApprovalV2Saga);
}

export default watchApprovals;
