import { DPRActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  dpr: [],
  singleDPR: {},
  error: {},
  success: false,
};

const DPRReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case DPRActionTypes.CREATE_DPR_REQUEST:
    case DPRActionTypes.EDIT_DPR_REQUEST:
    case DPRActionTypes.FETCH_DPRS_REQUEST:
    case DPRActionTypes.FETCH_DPRS_USING_PROJECTID_REQUEST:
    case DPRActionTypes.FETCH_DPR_BY_ID_REQUEST:
    case DPRActionTypes.RESET_DPR_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case DPRActionTypes.SET_SINGLE_DPR:
      return { ...state, singleDPR: action.payload.data, loading: false };

    case DPRActionTypes.CREATE_DPR_SUCCESS:
      return {
        ...state,
        dpr: [...state.dpr, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case DPRActionTypes.EDIT_DPR_SUCCESS:
      const updatedDPRs = state.dpr.map((dpr: any) =>
        dpr.id === action.payload.data.id ? action.payload.data : dpr
      );
      return {
        ...state,
        dpr: updatedDPRs,
        loading: false,
        error: {},
        success: true,
      };

    case DPRActionTypes.FETCH_DPRS_SUCCESS:
    case DPRActionTypes.FETCH_DPRS_USING_PROJECTID_SUCCESS:
      return {
        ...state,
        dpr: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case DPRActionTypes.FETCH_DPR_BY_ID_SUCCESS:
      return {
        ...state,
        singleDPR: action.payload.data,
        dpr: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case DPRActionTypes.RESET_SINGLE_DPR:
      return {
        ...state,
        singleDPR: {},
      };

    case DPRActionTypes.CREATE_DPR_FAILURE:
    case DPRActionTypes.EDIT_DPR_FAILURE:
    case DPRActionTypes.FETCH_DPRS_FAILURE:
    case DPRActionTypes.FETCH_DPR_BY_ID_FAILURE:
    case DPRActionTypes.FETCH_DPRS_USING_PROJECTID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default DPRReducer;
