import { ActionTypes } from "./constants";
import { TransporterResponse } from "redux/types/DataTypes";

export type ActionType = {
    type:
    | ActionTypes.FETCH_TRANSPORTER
    | ActionTypes.SET_TRANSPORTER
    | ActionTypes.SET_ERROR
    | ActionTypes.CREATE_TRANSPORTER
    | ActionTypes.UPDATE_TRANSPORTER
    | ActionTypes.FETCH_SINGLE_TRANSPORTER
    | ActionTypes.SET_SINGLE_TRANSPORTER
    | ActionTypes.UPDATE_TRANSPORTER_SUCCESS
    | ActionTypes.UPDATE_TRANSPORTER_FAIL
    | ActionTypes.ADD_TRANSPORTER_SUCCESS
    | ActionTypes.ADD_TRANSPORTER_FAIL
    | ActionTypes.CREATE_TRANSPORTER_WITH_FILES
    | ActionTypes.UPDATE_TRANSPORTER_WITH_FILES
    | ActionTypes.DELETE_TRANSPORTER
    | ActionTypes.DELETE_TRANSPORTER_SUCCESS
    | ActionTypes.DELETE_TRANSPORTER_FAIL
    payload: {} | string;
};

//dispatch action to get all transporter
export const getTransporter = (data: any): ActionType => ({
    type: ActionTypes.FETCH_TRANSPORTER,
    payload: { data }
})

//dispatch action to get single transporter
export const getSingleTransporter = (id: any): ActionType => ({
    type: ActionTypes.FETCH_SINGLE_TRANSPORTER,
    payload: { id }
})

//dispatch action to create new transporter
export const createTransporter = (data: any): ActionType => ({
    type: ActionTypes.CREATE_TRANSPORTER,
    payload: { data }
})


//dispatch action to update transporter
export const updateTransporter = (data: any): ActionType => ({
    type: ActionTypes.UPDATE_TRANSPORTER,
    payload: { data }
})

//handle fetch responses
export const transporterApiResponse = (actionType: string, data: TransporterResponse): ActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_TRANSPORTER:
            return {
                type: ActionTypes.SET_TRANSPORTER,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_TRANSPORTER:
            return {
                type: ActionTypes.SET_SINGLE_TRANSPORTER,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

//this action will be executed when transporter is updated successfully
export const transporterUpdateResponse = (actionType: string, data: any) => {

    // type: ActionTypes.UPDATE_TRANSPORTER_SUCCESS,
    // payload: { data }

    switch (actionType) {
        case ActionTypes.UPDATE_TRANSPORTER_SUCCESS:
            return {
                type: ActionTypes.UPDATE_TRANSPORTER_SUCCESS,
                payload: { data }
            }
        case ActionTypes.UPDATE_TRANSPORTER_SUCCESS_WITH_FILES:
            return {
                type: ActionTypes.UPDATE_TRANSPORTER_SUCCESS_WITH_FILES,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };

    }
}

// this action will be executed when there is error while updating
export const transporterUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_TRANSPORTER_FAIL,
        payload: { actionType, error }
    }
}


//this action will be executed when transporter is added successfully
export const transporterAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_TRANSPORTER_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while adding
export const transporterAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_TRANSPORTER_FAIL,
        payload: { actionType, error }
    }
}

//handle error while fetching
export const transporterApiError = (actionType: string, error: any): ActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})



//GET TRANSPORTER WITH FILES
export const createTransporterWithFiles = (data: any): ActionType => ({
    type: ActionTypes.CREATE_TRANSPORTER_WITH_FILES,
    payload: { data }
})

export const updateTransporterWithFiles = (data: any): ActionType => ({
    type: ActionTypes.UPDATE_TRANSPORTER_WITH_FILES,
    payload: { data }
})


//! Delete transporter
export const deleteTransporter = (data: any): ActionType => ({
    type: ActionTypes.DELETE_TRANSPORTER,
    payload: { data }
})
export const deleteTransporterResponse = (actionType: string, data: any): ActionType => ({
    type: ActionTypes.DELETE_TRANSPORTER_SUCCESS,
    payload: { actionType, data }
})
export const deleteTransporterError = (actionType: string, error: any): ActionType => ({
    type: ActionTypes.DELETE_TRANSPORTER_FAIL,
    payload: { actionType, error }
})