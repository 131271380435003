import { ActionTypes } from './constants';

export type SupplierActionType = {
    type:
    | ActionTypes.CREATE_SUPPLIER
    | ActionTypes.FETCH_SUPPLIER
    | ActionTypes.SET_SUPPLIER
    | ActionTypes.SET_ERROR
    | ActionTypes.UPDATE_SUPPLIER
    | ActionTypes.FETCH_SINGLE_SUPPLIER
    | ActionTypes.SET_SINGLE_SUPPLIER
    | ActionTypes.UPDATE_SUPPLIER_SUCCESS
    | ActionTypes.UPDATE_SUPPLIER_FAIL
    | ActionTypes.ADD_SUPPLIER_SUCCESS
    | ActionTypes.ADD_SUPPLIER_FAIL
    | ActionTypes.DASHBOARD_SUPPLIER_DATA
    | ActionTypes.SET_SUPPLIER_DASHBOARD_DATA
    | ActionTypes.DELETE_SUPPLIER_FAIL
    | ActionTypes.DELETE_SUPPLIER
    | ActionTypes.SET_SUPPLIER_LOCATION


    payload: {} | string;
}



export const getSupplier = (data: any): SupplierActionType => ({
    type: ActionTypes.FETCH_SUPPLIER,
    payload: { data }
})




//dispatch action to get single supplier data
export const getSingleSupplier = (id: any): SupplierActionType => ({
    type: ActionTypes.FETCH_SINGLE_SUPPLIER,
    payload: { id }
})


//dispatch action to create new supplier
export const createSupplier = (data: any): SupplierActionType => ({
    type: ActionTypes.CREATE_SUPPLIER,
    payload: { data }
})


//dispatch action to update supplier
export const updateSupplier = (data: any): SupplierActionType => ({
    type: ActionTypes.UPDATE_SUPPLIER,
    payload: { data }
})

export const getDashboardSupplierData = (data: any): SupplierActionType => ({
    type: ActionTypes.DASHBOARD_SUPPLIER_DATA,
    payload: { data }
})

export const getSupplierMap = () => ({
    type: ActionTypes.FETCH_SUPPLIER_LOCATION,
    payload: {}
})

export const resetSupplier = () => ({
    type: ActionTypes.RESET_SUPPLIER,
    payload: {}
})



//this will trigger in the saga while receiving response of fetching supplier data
export const supplierApiResponse = (actionType: string, data: any): SupplierActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_SUPPLIER:
            return {
                type: ActionTypes.SET_SUPPLIER,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_SUPPLIER:
            return {
                type: ActionTypes.SET_SINGLE_SUPPLIER,
                payload: { data }
            }
        case ActionTypes.DASHBOARD_SUPPLIER_DATA:
            return {
                type: ActionTypes.SET_SUPPLIER_DASHBOARD_DATA,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

//this action will be executed when supplier is updated successfully
export const supplierUpdateResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_SUPPLIER_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while updating
export const supplierUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_SUPPLIER_FAIL,
        payload: { actionType, error }
    }
}

//this action will be executed when supplier is added successfully
export const supplierAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_SUPPLIER_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while adding
export const supplierAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_SUPPLIER_FAIL,
        payload: { actionType, error }
    }
}

// this action will be executed when there is error while fetching supplier info
export const supplierApiError = (actionType: string, error: any): SupplierActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})

export const deleteSupplier = (data: any): SupplierActionType => ({
    type: ActionTypes.DELETE_SUPPLIER,
    payload: { data }
})

export const supplierDeleteResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.DELETE_SUPPLIER_SUCCESS,
        payload: { data }
    }
}
export const supplierDeleteError = (actionType: string, error: any): SupplierActionType => ({
    type: ActionTypes.DELETE_SUPPLIER_FAIL,
    payload: { actionType, error }
})