import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { IndentActionTypes } from './constants';
import {
  createIndent,
  fetchIndents,
  fetchIndentById,
  editIndent,
  fetchIndentByProjectId,
  fetchIndentItems,
} from 'helpers/api/indents';
import {
  createIndentSuccess,
  createIndentFailure,
  fetchIndentsSuccess,
  fetchIndentsFailure,
  fetchIndentByIdSuccess,
  fetchIndentByIdFailure,
  editIndentSuccess,
  editIndentFailure,
  fetchIndentByProjectIdSuccess,
  fetchIndentByProjectIdFailure,
  fetchIndentsItemsSuccess,
  fetchIndentsItemsFailure,
} from './actions';

function* fetchIndentsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy, status, origin  } = action.payload || {};
    const response = yield call(fetchIndents, page, limit , sortBy, sort, searchBy, status, origin);
    const indentsData = response.data; 
    yield put(fetchIndentsSuccess(indentsData));
  } catch (e: any) {
    yield put(fetchIndentsFailure(e));
  }
}

function* fetchIndentItemsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchIndentItems, page, limit , sortBy, sort, searchBy);
    const indentsData = response.data; 
    yield put(fetchIndentsItemsSuccess(indentsData));
  } catch (e: any) {
    yield put(fetchIndentsItemsFailure(e));
  }
}

function* createIndentSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createIndent, data);
    const indentData = response.data;
    yield put(createIndentSuccess(indentData));
    yield put({ type: IndentActionTypes.FETCH_INDENTS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createIndentFailure(e));
  }
}

function* fetchIndentByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchIndentById, action.payload);
    const indentData = response.data;
    yield put(fetchIndentByIdSuccess(indentData));
  } catch (e: any) {
    yield put(fetchIndentByIdFailure(e));
  }
}

function* fetchIndentByProjectIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchIndentByProjectId, action.payload);
    const indentData = response.data;
    yield put(fetchIndentByProjectIdSuccess(indentData));
  } catch (e: any) {
    yield put(fetchIndentByProjectIdFailure(e));
  }
}

function* editIndentSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editIndent, id, data);
    const indentData = response.data;
    yield put(editIndentSuccess(indentData));
    yield put({ type: IndentActionTypes.FETCH_INDENT_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editIndentFailure(e));
  }
}

function* watchIndents() {
  yield takeEvery(IndentActionTypes.FETCH_INDENTS_REQUEST, fetchIndentsSaga);
  yield takeEvery(IndentActionTypes.FETCH_INDENTS_ITEMS_REQUEST, fetchIndentItemsSaga);
  yield takeEvery(IndentActionTypes.CREATE_INDENT_REQUEST, createIndentSaga);
  yield takeEvery(IndentActionTypes.FETCH_INDENT_BY_ID_REQUEST, fetchIndentByIdSaga);
  yield takeEvery(IndentActionTypes.FETCH_INDENT_BY_PROJECT_ID_REQUEST, fetchIndentByProjectIdSaga);
  yield takeEvery(IndentActionTypes.EDIT_INDENT_REQUEST, editIndentSaga);
}

export default watchIndents;
