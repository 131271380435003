export enum IQCQualityActionTypes {
    CREATE_IQCQUALITY_REQUEST = 'CREATE_IQCQUALITY_REQUEST',
    CREATE_IQCQUALITY_SUCCESS = 'CREATE_IQCQUALITY_SUCCESS',
    CREATE_IQCQUALITY_FAILURE = 'CREATE_IQCQUALITY_FAILURE',
    FETCH_IQCQUALITYS_REQUEST = 'FETCH_IQCQUALITYS_REQUEST',
    FETCH_IQCQUALITYS_SUCCESS = 'FETCH_IQCQUALITYS_SUCCESS',
    FETCH_IQCQUALITYS_FAILURE = 'FETCH_IQCQUALITYS_FAILURE',
    FETCH_IQCQUALITY_BY_ID_REQUEST = 'FETCH_IQCQUALITY_BY_ID_REQUEST',
    FETCH_IQCQUALITY_BY_ID_SUCCESS = 'FETCH_IQCQUALITY_BY_ID_SUCCESS',
    FETCH_IQCQUALITY_BY_ID_FAILURE = 'FETCH_IQCQUALITY_BY_ID_FAILURE',
    EDIT_IQCQUALITY_REQUEST = 'EDIT_IQCQUALITY_REQUEST',
    EDIT_IQCQUALITY_SUCCESS = 'EDIT_IQCQUALITY_SUCCESS',
    EDIT_IQCQUALITY_FAILURE = 'EDIT_IQCQUALITY_FAILURE',
    RESET_IQCQUALITY_SUCCESS_STATE = 'RESET_IQCQUALITY_SUCCESS_STATE',
    FETCH_SINGLE_IQCQUALITY = 'FETCH_SINGLE_IQCQUALITY',
    SET_SINGLE_IQCQUALITY = 'SET_SINGLE_IQCQUALITY',
    RESET_SINGLE_IQCQUALITY = 'RESET_SINGLE_IQCQUALITY'
  }
  