import { APICore } from "./apiCore";

const api = new APICore();

function fetchAllTriggers(params: { page: string, limit: string, sortBy: string, sort: string, searchBy?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    const baseUrl = `/trigger?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}`;
    return api.get(`${baseUrl}`, "");
}

function fetchSingleTrigger(params: any) {
    const baseUrl = `/trigger/${params}`;
    return api.get(`${baseUrl}`, "");
}


function fetchAllTriggerTypeBy() {
    const baseUrl = `/trigger/type/by`;
    return api.get(`${baseUrl}`, "");
}


function fetchAllEntity() {
    const baseUrl = `/trigger/entity`;
    return api.create(`${baseUrl}`, "");
}


function fetchAllTriggerTypeFor(id: any) {
    const baseUrl = `/trigger/type/for/${id}`;
    return api.create(`${baseUrl}`, "");
}



function fetchAllEntityType(id: any) {
    const baseUrl = `/trigger/entity/type/${id}`;
    return api.create(`${baseUrl}`, "");
}


function fetchAllEntityState(id: any) {
    const baseUrl = `/trigger/entity/state/${id}`;
    return api.create(`${baseUrl}`, "");
}



function createTrigger(body: any) {
    const baseUrl = "/trigger";
    return api.create(`${baseUrl}`, body);
}

function updateTrigger(data: any) {
    const baseUrl = `/trigger/${data.id}`;
    return api.update(`${baseUrl}`, data);
}



export { updateTrigger, createTrigger, fetchAllTriggerTypeFor, fetchAllTriggerTypeBy, fetchSingleTrigger, fetchAllTriggers, fetchAllEntity, fetchAllEntityType, fetchAllEntityState }

