import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { ActionTypes } from './constants';
import { contactApiError, contactApiResponse } from "./actions";
import { fetchAllContacts, syncContacts } from "../../helpers/api/contacts";

function* fetchContactsSaga(action: any): SagaIterator {
    try {
        const { page, limit } = action.payload;

        const response = yield call(fetchAllContacts, page, limit);
        const contactData = yield response.data;

        yield put(contactApiResponse(ActionTypes.FETCH_CONTACT, contactData));
    } catch (e: any) {
        yield put(contactApiError(ActionTypes.FETCH_CONTACT, e));
    }
}

function* syncContactsSaga(): SagaIterator {
    try {
        yield call(syncContacts);
        // Fetch contacts again after syncing
        yield put({ type: ActionTypes.FETCH_CONTACT, payload: { page: 1, limit: 15 } });
        yield put(contactApiResponse(ActionTypes.SYNC_CONTACTS, {}));
    } catch (error: any) {
        yield put(contactApiError(ActionTypes.SYNC_CONTACTS, error));
    }
}

function* watchContact() {
    yield takeEvery(ActionTypes.FETCH_CONTACT, fetchContactsSaga);
    yield takeEvery(ActionTypes.SYNC_CONTACTS, syncContactsSaga);
}

export default watchContact;
