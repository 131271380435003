import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { GateEntryActionTypes } from './constants';
import {
  createGateEntry,
  fetchGateEntrys,
  fetchGateEntryById,
  editGateEntry,
} from 'helpers/api/gateEntry';
import {
  createGateEntrySuccess,
  createGateEntryFailure,
  fetchGateEntrysSuccess,
  fetchGateEntrysFailure,
  fetchGateEntryByIdSuccess,
  fetchGateEntryByIdFailure,
  editGateEntrySuccess,
  editGateEntryFailure,
} from './actions';

function* fetchGateEntrysSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchGateEntrys, page, limit , sortBy, sort, searchBy);
    const gateEntrysData = response.data; 
    yield put(fetchGateEntrysSuccess(gateEntrysData));
  } catch (e: any) {
    yield put(fetchGateEntrysFailure(e));
  }
}

function* createGateEntrySaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createGateEntry, data);
    const gateEntryData = response.data;
    yield put(createGateEntrySuccess(gateEntryData));
    yield put({ type: GateEntryActionTypes.FETCH_GATEENTRYS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createGateEntryFailure(e));
  }
}

function* fetchGateEntryByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchGateEntryById, action.payload);
    const gateEntryData = response.data;
    yield put(fetchGateEntryByIdSuccess(gateEntryData));
  } catch (e: any) {
    yield put(fetchGateEntryByIdFailure(e));
  }
}

function* editGateEntrySaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editGateEntry, id, data);
    const gateEntryData = response.data;
    yield put(editGateEntrySuccess(gateEntryData));
    yield put({ type: GateEntryActionTypes.FETCH_GATEENTRY_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editGateEntryFailure(e));
  }
}

function* watchGateEntrys() {
  yield takeEvery(GateEntryActionTypes.FETCH_GATEENTRYS_REQUEST, fetchGateEntrysSaga);
  yield takeEvery(GateEntryActionTypes.CREATE_GATEENTRY_REQUEST, createGateEntrySaga);
  yield takeEvery(GateEntryActionTypes.FETCH_GATEENTRY_BY_ID_REQUEST, fetchGateEntryByIdSaga);
  yield takeEvery(GateEntryActionTypes.EDIT_GATEENTRY_REQUEST, editGateEntrySaga);
}

export default watchGateEntrys;
