import { ActionTypes as TriggerActionTypes } from "./constants";
const INIT_STATE = {
    loading: true,
    triggers: {},
    triggerTypeBy: {},
    triggerTypeFor: {},
    singleTrigger: {},
    entity: {},
    entityType: {},
    entityTypeState: {},
    success: false,
    error: null,
    addSuccess: false,
    addError: null,
    updateSuccess: false,
    updateError: null
}


type ActionTypes = {
    type:
    | TriggerActionTypes.SET_ALL_TRIGGER
    | TriggerActionTypes.SET_TRIGGER_TYPE_BY
    | TriggerActionTypes.SET_TRIGGER_TYPE_FOR
    | TriggerActionTypes.SET_SINGLE_TRIGGER
    | TriggerActionTypes.SET_TRIGGER_ENTITY
    | TriggerActionTypes.SET_TRIGGER_ENTITY_STATE
    | TriggerActionTypes.SET_TRIGGER_ENTITY_TYPE
    | TriggerActionTypes.ADD_TRIGGER_SUCCESS
    | TriggerActionTypes.ADD_TRIGGER_FAIL
    | TriggerActionTypes.UPDATE_TRIGGER_SUCCESS
    | TriggerActionTypes.UPDATE_TRIGGER_FAIL
    | TriggerActionTypes.SET_TRIGGER_ERROR
    | TriggerActionTypes.RESET_ENTITY_DEPENDENT
    | TriggerActionTypes.RESET_ENTITY_TYPE_DEPENDENT
    | TriggerActionTypes.RESET_ENTITY_STATE_DEPENDENT
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}

const TriggerReducer = (state: any = INIT_STATE, action: ActionTypes) => {
    switch (action.type) {
        case TriggerActionTypes.SET_ALL_TRIGGER:
            return {
                ...state, trigger: action.payload.data, success: true, loading: false, addError: null, addSuccess: false, error: null, updateSuccess: false,
                updateError: null, singleTrigger: {}
            }
        case TriggerActionTypes.SET_TRIGGER_TYPE_BY:
            return {
                ...state, triggerTypeBy: action.payload.data
            }
        case TriggerActionTypes.SET_TRIGGER_TYPE_FOR:
            return {
                ...state, triggerTypeFor: action.payload.data
            }
        case TriggerActionTypes.SET_TRIGGER_ENTITY:
            return {
                ...state, entity: action.payload.data
            }
        case TriggerActionTypes.SET_TRIGGER_ENTITY_TYPE:
            return {
                ...state, entityType: action.payload.data
            }
        case TriggerActionTypes.SET_TRIGGER_ENTITY_STATE:
            return {
                ...state, entityTypeState: action.payload.data
            }
        case TriggerActionTypes.RESET_ENTITY_DEPENDENT:
            return {
                ...state,
                entityType: {},
                entityTypeState: {},
                triggerTypeFor: {}
            }
        case TriggerActionTypes.RESET_ENTITY_TYPE_DEPENDENT:
            return {
                ...state,
                entityTypeState: {},
                triggerTypeFor: {}
            }
        case TriggerActionTypes.RESET_ENTITY_STATE_DEPENDENT:
            return {
                ...state,
                triggerTypeFor: {}
            }

        case TriggerActionTypes.SET_SINGLE_TRIGGER:
            return {
                ...state, singleTrigger: action.payload.data, addError: null, addSuccess: false, error: null, updateSuccess: false, updateError: null
            }
        case TriggerActionTypes.ADD_TRIGGER_SUCCESS:
            return {
                ...state, addError: null, addSuccess: true, error: null, updateSuccess: false, updateError: null
            }
        case TriggerActionTypes.ADD_TRIGGER_FAIL:
            return {
                ...state, addError: action.payload.error, addSuccess: false, error: null, updateSuccess: false, updateError: null
            }
        case TriggerActionTypes.UPDATE_TRIGGER_SUCCESS:
            return {
                ...state, addError: null, addSuccess: false, error: null, updateSuccess: true, updateError: null
            }
        case TriggerActionTypes.UPDATE_TRIGGER_FAIL:
            return {
                ...state, addError: null, addSuccess: false, error: null, updateSuccess: false, updateError: action.payload.error
            }
        case TriggerActionTypes.SET_TRIGGER_ERROR:
            return {
                ...state, error: action.payload.error, success: false
            }
        default:
            return { ...state }
    }
}

export default TriggerReducer;