export enum FabDrawingActionTypes {
    CREATE_FabDrawing_REQUEST = 'CREATE_FabDrawing_REQUEST',
    CREATE_FabDrawing_SUCCESS = 'CREATE_FabDrawing_SUCCESS',
    CREATE_FabDrawing_FAILURE = 'CREATE_FabDrawing_FAILURE',
    FETCH_FabDrawingS_REQUEST = 'FETCH_FabDrawingS_REQUEST',
    FETCH_FabDrawingS_SUCCESS = 'FETCH_FabDrawingS_SUCCESS',
    FETCH_FabDrawingS_FAILURE = 'FETCH_FabDrawingS_FAILURE',
    FETCH_FabDrawing_BY_ID_REQUEST = 'FETCH_FabDrawing_BY_ID_REQUEST',
    FETCH_FabDrawing_BY_ID_SUCCESS = 'FETCH_FabDrawing_BY_ID_SUCCESS',
    FETCH_FabDrawing_BY_ID_FAILURE = 'FETCH_FabDrawing_BY_ID_FAILURE',
    EDIT_FabDrawing_REQUEST = 'EDIT_FabDrawing_REQUEST',
    EDIT_FabDrawing_SUCCESS = 'EDIT_FabDrawing_SUCCESS',
    EDIT_FabDrawing_FAILURE = 'EDIT_FabDrawing_FAILURE',
    RESET_FabDrawing_SUCCESS_STATE = 'RESET_FabDrawing_SUCCESS_STATE',
    FETCH_SINGLE_FabDrawing = 'FETCH_SINGLE_FabDrawing',
    SET_SINGLE_FabDrawing = 'SET_SINGLE_FabDrawing',
    RESET_SINGLE_FabDrawing = 'RESET_SINGLE_FabDrawing'
}
