export enum ApprovalActionTypes {
    CREATE_APPROVALV2_REQUEST = 'CREATE_APPROVALV2_REQUEST',
    CREATE_APPROVALV2_SUCCESS = 'CREATE_APPROVALV2_SUCCESS',
    CREATE_APPROVALV2_FAILURE = 'CREATE_APPROVALV2_FAILURE',
    FETCH_APPROVALSV2_REQUEST = 'FETCH_APPROVALSV2_REQUEST',
    FETCH_APPROVALSV2_SUCCESS = 'FETCH_APPROVALSV2_SUCCESS',
    FETCH_APPROVALSV2_FAILURE = 'FETCH_APPROVALSV2_FAILURE',
    FETCH_APPROVALSV2_TYPES_REQUEST = 'FETCH_APPROVALSV2_TYPES_REQUEST',
    FETCH_APPROVALSV2_TYPES_SUCCESS = 'FETCH_APPROVALSV2_TYPES_SUCCESS',
    FETCH_APPROVALSV2_TYPES_FAILURE = 'FETCH_APPROVALSV2_TYPES_FAILURE',
    FETCH_APPROVALV2_BY_ID_REQUEST = 'FETCH_APPROVALV2_BY_ID_REQUEST',
    FETCH_APPROVALV2_BY_ID_SUCCESS = 'FETCH_APPROVALV2_BY_ID_SUCCESS',
    FETCH_APPROVALV2_BY_ID_FAILURE = 'FETCH_APPROVALV2_BY_ID_FAILURE',
    EDIT_APPROVALV2_REQUEST = 'EDIT_APPROVALV2_REQUEST',
    EDIT_APPROVALV2_SUCCESS = 'EDIT_APPROVALV2_SUCCESS',
    EDIT_APPROVALV2_FAILURE = 'EDIT_APPROVALV2_FAILURE',
    RESET_APPROVALV2_SUCCESS_STATE = 'RESET_APPROVALV2_SUCCESS_STATE',
    FETCH_SINGLE_APPROVALV2 = 'FETCH_SINGLE_APPROVALV2',
    SET_SINGLE_APPROVALV2 = 'SET_SINGLE_APPROVALV2',
    RESET_SINGLE_APPROVALV2 = 'RESET_SINGLE_APPROVALV2',
}
