import { put, takeEvery, call, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchApproval, fetchCountApprovals, createApproval, fetchSingleApproval, updateApproval, fetchApprovalTypes, deleteApproval, fetchStatus, fetchApprovalHistory } from "helpers";
import { approvalApiResponse, approvalApiError, approvalUpdateResponse, approvalUpdateError, approvalAddResponse, approvalAddError, deleteApprovalResponse, deleteApprovalError } from "./actions";
import { ActionTypes } from './constants';


function* approval(params: any): SagaIterator {
    try {
        const response = yield call(fetchApproval, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, userId: params.payload.data?.userId, enquiryId: params.payload.data?.id, job_id: params.payload.data?.jobId, searchBy: params.payload.data?.searchBy, filterBy: params.payload.data?.filterBy, assignedToMe: params.payload.data?.assignedToMe, filterByStatus: params.payload.data?.filterByStatus });
        const approvalData = yield response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL, approvalData));
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL, error));
    }
}

function* countApprovals(params: any): SagaIterator {
    try {
        const response = yield call(fetchCountApprovals, { user_id: params.payload.data.user_id, status: params.payload.data.status });
        const countApprovalData = yield response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL_COUNT, countApprovalData));
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL_COUNT, error));
    }
}

function* singleApproval(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleApproval, params.payload.id, params.payload.type);
        const singleApproval = response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_SINGLE_APPROVAL, singleApproval))
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_SINGLE_APPROVAL, error));
    }
}

function* approvalHistory(params: any): SagaIterator {
    try {
        const response = yield call(fetchApprovalHistory, params.payload.id, params.payload.type);
        const history = response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL_STATUS_HISTORY, history))
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL_STATUS_HISTORY, error));
    }
}



function* approvalCommentHistory(params: any): SagaIterator {
    try {
        const response = yield call(fetchApprovalHistory, params.payload.id, params.payload.type);
        const history = response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL_COMMENT_HISTORY, history))
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL_COMMENT_HISTORY, error));
    }
}

function* approvalDueDateHistory(params: any): SagaIterator {
    try {
        const response = yield call(fetchApprovalHistory, params.payload.id, params.payload.type);
        const history = response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL_DUEDATE_HISTORY, history))
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL_DUEDATE_HISTORY, error));
    }
}



function* approvalTypes(params: any): SagaIterator {
    try {
        const response = yield call(fetchApprovalTypes);
        const approvalTypesData = yield response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL_TYPES, approvalTypesData));
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL_TYPES, error));
    }
}

function* approvalStatus(): SagaIterator {
    try {
        const response = yield call(fetchStatus, "APPROVAL_STATUS");
        const approvalStatusData = response.data.data.values;
        // console.log(approvalStatusData, "lklk");

        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL_STATUS, approvalStatusData));
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL_STATUS, error));
    }
}


function* _updateApproval({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateApproval, { id: payload.data.id, updateBody: payload.data.body })
        const approvalData = response.data;
        // console.log("updated successfully", approvalData);
        yield put(approvalUpdateResponse(ActionTypes.UPDATE_APPROVAL, approvalData));
    } catch (e: any) {
        yield put(approvalUpdateError(ActionTypes.UPDATE_APPROVAL, e))
    }
}



function* _createApproval({ payload }: any): SagaIterator {
    try {
        const response = yield call(createApproval, { approval: payload.data });
        const approvalData = response.data;
        // console.log("Response in the _createApproval", approvalData);
        yield put(approvalAddResponse(ActionTypes.CREATE_APPROVAL, approvalData));
    } catch (error: any) {
        yield put(approvalAddError(ActionTypes.CREATE_APPROVAL, error));
    }
}

function* _deleteApproval(params: any): SagaIterator {
    try {

        const response = yield call(deleteApproval, { id: params.payload.data.id });
        const ApprovalData = yield response.data;
        yield put(deleteApprovalResponse(ActionTypes.DELETE_APPROVAL_SUCCESS, ApprovalData));
    } catch (e: any) {
        yield put(deleteApprovalError(ActionTypes.DELETE_APPROVAL_FAIL, e))
    }
}

export function* watchApproval() {
    yield takeLatest(ActionTypes.FETCH_APPROVAL, approval);
    yield takeEvery(ActionTypes.FETCH_APPROVAL_COUNT, countApprovals);
    yield takeEvery(ActionTypes.CREATE_APPROVAL, _createApproval);
    yield takeEvery(ActionTypes.UPDATE_APPROVAL, _updateApproval);
    yield takeEvery(ActionTypes.FETCH_SINGLE_APPROVAL, singleApproval);
    yield takeEvery(ActionTypes.FETCH_APPROVAL_TYPES, approvalTypes)
    yield takeEvery(ActionTypes.DELETE_APPROVAL, _deleteApproval)
    yield takeEvery(ActionTypes.FETCH_APPROVAL_STATUS, approvalStatus)
    yield takeEvery(ActionTypes.FETCH_APPROVAL_STATUS_HISTORY, approvalHistory)
    yield takeEvery(ActionTypes.FETCH_APPROVAL_COMMENT_HISTORY, approvalCommentHistory)
    yield takeEvery(ActionTypes.FETCH_APPROVAL_DUEDATE_HISTORY, approvalDueDateHistory)

}

export default watchApproval;
