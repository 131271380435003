export enum ActionTypes {
    SET_SUPPLIER = "SET_SUPPLIER",
    SET_ERROR = "SET_ERROR",
    CREATE_SUPPLIER = "CREATE_SUPPLIER",
    FETCH_SUPPLIER = "FETCH_SUPPLIER",
    UPDATE_SUPPLIER = "UPDATE_SUPPLIER",
    FETCH_SINGLE_SUPPLIER = "FETCH_SINGLE_SUPPLIER",
    SET_SINGLE_SUPPLIER = "SET_SINGLE_SUPPLIER",
    UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS",
    UPDATE_SUPPLIER_FAIL = "UPDATE_SUPPLIER_FAIL",
    ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS",
    ADD_SUPPLIER_FAIL = "ADD_SUPPLIER_FAIL",
    DASHBOARD_SUPPLIER_DATA = "DASHBOARD_SUPPLIER_DATA",
    SET_SUPPLIER_DASHBOARD_DATA = "SET_SUPPLIER_DASHBOARD_DATA",
    DELETE_SUPPLIER = "DELETE_SUPPLIER",
    DELETE_SUPPLIER_FAIL = "DELETE_SUPPLIER_FAIL",
    DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS",
    FETCH_SUPPLIER_LOCATION="FETCH_SUPPLIER_LOCATION",
    SET_SUPPLIER_LOCATION="SET_SUPPLIER_LOCATION",
    RESET_SUPPLIER = "RESET_SUPPLIER"

}


