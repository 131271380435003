import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { InventoryActionTypes } from './constant';
import {
  createInventory,
  fetchInventorys,
  fetchInventoryById,
  editInventory,
  fetchInventorysHistory,
} from '../../helpers/api/inventory';
import {
  createInventorySuccess,
  createInventoryFailure,
  fetchInventorysSuccess,
  fetchInventorysFailure,
  fetchInventorysHistorySuccess,
  fetchInventorysHistoryFailure,
  fetchInventoryByIdSuccess,
  fetchInventoryByIdFailure,
  editInventorySuccess,
  editInventoryFailure,
} from './actions';

function* fetchInventorysSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchInventorys, page, limit, sortBy, sort, searchBy);
    const inventoryData = response.data; 
    yield put(fetchInventorysSuccess(inventoryData));
  } catch (e: any) {
    yield put(fetchInventorysFailure(e));
  }
}

function* fetchInventorysHistorySaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchInventorysHistory, page, limit, sortBy, sort, searchBy);
    const inventoryData = response.data; 
    yield put(fetchInventorysHistorySuccess(inventoryData));
  } catch (e: any) {
    yield put(fetchInventorysHistoryFailure(e));
  }
}

function* createInventorySaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;

    const response = yield call(createInventory, data);
    const inventoryData = response.data;
    yield put(createInventorySuccess(inventoryData));
    yield put({ type: InventoryActionTypes.FETCH_INVENTORYS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createInventoryFailure(e));
  }
}

function* fetchInventoryByIdSaga(action: any): SagaIterator {
  try {
    console.log(action.payload, "shubhi")
    console.log("Saga: Fetching inventory by ID:", action.payload);  // Debug log
    const response = yield call(fetchInventoryById, action.payload);
    const inventoryData = response.data;
    console.log("Saga: API response for inventory by ID:", inventoryData);  // Debug log

    yield put(fetchInventoryByIdSuccess(inventoryData));
  } catch (e: any) {
    yield put(fetchInventoryByIdFailure(e));
  }
}

function* editInventorySaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;

    const response = yield call(editInventory, id, data);
    const inventoryData = response.data;
    yield put(editInventorySuccess(inventoryData));
    yield put({ type: InventoryActionTypes.FETCH_INVENTORY_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editInventoryFailure(e));
  }
}

function* watchInventory() {
  yield takeEvery(InventoryActionTypes.FETCH_INVENTORYS_REQUEST, fetchInventorysSaga);
  yield takeEvery(InventoryActionTypes.FETCH_INVENTORYSHISTORY_REQUEST, fetchInventorysHistorySaga);
  yield takeEvery(InventoryActionTypes.CREATE_INVENTORY_REQUEST, createInventorySaga);
  yield takeEvery(InventoryActionTypes.FETCH_INVENTORY_BY_ID_REQUEST, fetchInventoryByIdSaga);
  yield takeEvery(InventoryActionTypes.EDIT_INVENTORY_REQUEST, editInventorySaga);
}

export default watchInventory;
