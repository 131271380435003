import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ItemActionTypes } from './constants';
import {
  createItem,
  fetchItems,
  fetchItemBomInputs,
  fetchItemById,
  editItem,
} from 'helpers/api/items';
import {
  createItemSuccess,
  createItemFailure,
  fetchItemsSuccess,
  fetchItemsFailure,
  fetchItemBomInputsSuccess,
  fetchItemBomInputsFailure,
  fetchItemByIdSuccess,
  fetchItemByIdFailure,
  editItemSuccess,
  editItemFailure,
} from './actions';

function* fetchItemsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchItems, page, limit , sortBy, sort, searchBy);
    const itemsData = response.data; 
    yield put(fetchItemsSuccess(itemsData));
  } catch (e: any) {
    yield put(fetchItemsFailure(e));
  }
}

function* fetchItemBomInputsSaga(action: any): SagaIterator {
  try {
    const { id } = action.payload || {};
    const response = yield call(fetchItemBomInputs, id);
    const itemInputsData = response.data; 
    yield put(fetchItemBomInputsSuccess(itemInputsData));
  } catch (e: any) {
    yield put(fetchItemBomInputsFailure(e));
  }
}

function* createItemSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createItem, data);
    const itemData = response.data;
    yield put(createItemSuccess(itemData));
    yield put({ type: ItemActionTypes.FETCH_ITEMS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createItemFailure(e));
  }
}

function* fetchItemByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchItemById, action.payload);
    const itemData = response.data;
    yield put(fetchItemByIdSuccess(itemData));
  } catch (e: any) {
    yield put(fetchItemByIdFailure(e));
  }
}

function* editItemSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editItem, id, data);
    const itemData = response.data;
    yield put(editItemSuccess(itemData));
    yield put({ type: ItemActionTypes.FETCH_ITEM_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editItemFailure(e));
  }
}

function* watchItems() {
  yield takeEvery(ItemActionTypes.FETCH_ITEMS_REQUEST, fetchItemsSaga);
  yield takeEvery(ItemActionTypes.FETCH_ITEM_BOM_INPUTS_REQUEST, fetchItemBomInputsSaga);
  yield takeEvery(ItemActionTypes.CREATE_ITEM_REQUEST, createItemSaga);
  yield takeEvery(ItemActionTypes.FETCH_ITEM_BY_ID_REQUEST, fetchItemByIdSaga);
  yield takeEvery(ItemActionTypes.EDIT_ITEM_REQUEST, editItemSaga);
}

export default watchItems;
