import { Task } from "redux/types/DataTypes";
import { ActionTypes } from "./constants";

export type TaskActionType = {
  type:
  | ActionTypes.CREATE_TASK
  | ActionTypes.FETCH_TASK
  | ActionTypes.FETCH_TASK_COUNT
  | ActionTypes.SET_TASK
  | ActionTypes.SET_TASK_COUNT
  | ActionTypes.SET_ERROR
  | ActionTypes.UPDATE_TASK
  | ActionTypes.FETCH_SINGLE_TASKS
  | ActionTypes.SET_SINGLE_TASK
  | ActionTypes.UPDATE_TASK_SUCCESS
  | ActionTypes.UPDATE_TASK_FAIL
  | ActionTypes.ADD_TASK_SUCCESS
  | ActionTypes.ADD_TASK_FAIL
  | ActionTypes.FETCH_TASK_STATUS
  | ActionTypes.FETCH_TASK_PRIORITY
  | ActionTypes.SET_TASK_STATUS
  | ActionTypes.SET_TASK_PRIORITY
  | ActionTypes.DELETE_TASK
  | ActionTypes.DELETE_TASK_SUCCESS
  | ActionTypes.DELETE_TASK_FAIL


  payload: {} | string;
};

//dispatch action to get task
export const getTask = (data: any): TaskActionType => ({
  type: ActionTypes.FETCH_TASK,
  payload: { data },
});

//disptach action to get all tasks count
export const countTasks = (data: any): TaskActionType => ({
  type: ActionTypes.FETCH_TASK_COUNT,
  payload: { data }
})

//dispatch action to get task detail
export const getTaskDetail = (id: any): TaskActionType => ({
  type: ActionTypes.FETCH_SINGLE_TASKS,
  payload: { id },
});

//create task
export const saveTask = (data: any): TaskActionType => ({
  type: ActionTypes.CREATE_TASK,
  payload: { data },
});


//update task
export const updateTask = (data: any): TaskActionType => ({
  type: ActionTypes.UPDATE_TASK,
  payload: { data }
})


export const resetTask = () => ({
  type: ActionTypes.RESET_TASK_STATE,
  payload: {}
})



//task action to  dispatch to handle api response
export const taskApiResponse = (
  actionType: string,
  data: Task
): TaskActionType => {
  switch (actionType) {
    case ActionTypes.FETCH_TASK:
      return {
        type: ActionTypes.SET_TASK,
        payload: { actionType, data },
      };
    case ActionTypes.FETCH_TASK_COUNT:
      return {
        type: ActionTypes.SET_TASK_COUNT,
        payload: { actionType, data },
      };
    case ActionTypes.FETCH_SINGLE_TASKS:
      return {
        type: ActionTypes.SET_SINGLE_TASK,
        payload: { actionType, data },
      };
    case ActionTypes.FETCH_TASK_STATUS:
      return {
        type: ActionTypes.SET_TASK_STATUS,
        payload: { actionType, data },
      }
    case ActionTypes.FETCH_TASK_PRIORITY:
      return {
        type: ActionTypes.SET_TASK_PRIORITY,
        payload: { actionType, data }
      }
    default:
      return {
        type: ActionTypes.SET_ERROR,
        payload: { actionType, error: "Error While setting data to store ! " },
      };
  }
};

//dispatch action to set error
export const taskUpdateResponse = (actionType: string, data: any): TaskActionType => {
  return {
    type: ActionTypes.UPDATE_TASK_SUCCESS,
    payload: { data }
  }
}

// this action will be executed when there is error while updating the task
export const taskUpdateError = (actionType: string, error: any): TaskActionType => {
  return {
    type: ActionTypes.UPDATE_TASK_FAIL,
    payload: { actionType, error }
  }
}

// this action will be executed when task is added successfully 
export const taskAddResponse = (actionType: string, data: any): TaskActionType => {
  return {
    type: ActionTypes.ADD_TASK_SUCCESS,
    payload: { data }
  }
}

//this action will be executed when there is error while adding new task
export const taskAddError = (actionType: string, error: any): TaskActionType => {
  return {
    type: ActionTypes.ADD_TASK_FAIL,
    payload: { actionType, error }
  }
}

//action to fetch all task status
export const taskStatus = (): TaskActionType => ({
  type: ActionTypes.FETCH_TASK_STATUS,
  payload: {}
})

//action to fetch all task priority
export const taskPriority = (): TaskActionType => ({
  type: ActionTypes.FETCH_TASK_PRIORITY,
  payload: {}
})


//api response error handling
export const apiTaskResponseError = (
  actionType: string,
  error: any
): TaskActionType => ({
  type: ActionTypes.SET_ERROR,
  payload: { actionType, error },
});



//! DELETING Task
export const deleteTask = (data: any): TaskActionType => ({
  type: ActionTypes.DELETE_TASK,
  payload: { data }
})

export const deleteTaskResponse = (actionType: any, data: any): TaskActionType => ({
  type: ActionTypes.DELETE_TASK_SUCCESS,
  payload: { actionType, data }
})
export const deleteTaskError = (actionType: any, error: any): TaskActionType => ({
  type: ActionTypes.DELETE_TASK_FAIL,
  payload: { actionType, error }
})