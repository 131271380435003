import { ActionTypes } from "./constants";

export type MasterJobType = {
    type:
    | ActionTypes.FETCH_ALL_JOBS
    | ActionTypes.FETCH_PRODUCT_JOBS
    | ActionTypes.SET_PRODUCT_JOBS
    | ActionTypes.ADD_PRODUCT_JOB
    | ActionTypes.ADD_PRODUCT_JOB_SUCCESS
    | ActionTypes.ADD_PRODUCT_JOB_FAIL
    | ActionTypes.UPDATE_PRODUCT_JOB
    | ActionTypes.UPDATE_PRODUCT_JOB_SUCCESS
    | ActionTypes.UPDATE_PRODUCT_JOB_FAIL
    | ActionTypes.SET_JOB_DEFINATION_ERROR
    | ActionTypes.SET_ALL_JOBS
    | ActionTypes.FETCH_SINGLE_JOB_DEFINATION
    | ActionTypes.SET_SINGLE_JOB_DEFINATION
    | ActionTypes.CLEAR_SINGLE_JOB_STATE
    payload: {} | string;
}




export const getAllJobs = (
    data: any
): MasterJobType => ({
    type: ActionTypes.FETCH_ALL_JOBS,
    payload: { data },
});


export const clearSingleProductJobState = (): MasterJobType => ({
    type: ActionTypes.CLEAR_SINGLE_JOB_STATE,
    payload: {}
})


export const getProductJobs = (
    data: any
): MasterJobType => ({
    type: ActionTypes.FETCH_PRODUCT_JOBS,
    payload: { data },
});


export const getSingleMasterJob = (data: any): MasterJobType => ({
    type: ActionTypes.FETCH_SINGLE_JOB_DEFINATION,
    payload: { data }
})


export const createProductJob = (data: any): MasterJobType => ({
    type: ActionTypes.ADD_PRODUCT_JOB,
    payload: { data }
})

export const updateProductJob = (data: any): MasterJobType => ({
    type: ActionTypes.UPDATE_PRODUCT_JOB,
    payload: { data }
})



export const masterJobApiResponse = (
    actionType: string,
    data: any
): MasterJobType => {
    switch (actionType) {
        case ActionTypes.FETCH_ALL_JOBS:
            return {
                type: ActionTypes.SET_ALL_JOBS,
                payload: { actionType, data }
            }
        case ActionTypes.FETCH_PRODUCT_JOBS:
            return {
                type: ActionTypes.SET_PRODUCT_JOBS,
                payload: { actionType, data },
            }
        case ActionTypes.FETCH_SINGLE_JOB_DEFINATION:
            return {
                type: ActionTypes.SET_SINGLE_JOB_DEFINATION,
                payload: { actionType, data },
            }
        case ActionTypes.ADD_PRODUCT_JOB_SUCCESS:
            return {
                type: ActionTypes.ADD_PRODUCT_JOB_SUCCESS,
                payload: { actionType, data },
            }
        case ActionTypes.ADD_PRODUCT_JOB_FAIL:
            return {
                type: ActionTypes.ADD_PRODUCT_JOB_FAIL,
                payload: { actionType, error: data },
            }
        case ActionTypes.UPDATE_PRODUCT_JOB_SUCCESS:
            return {
                type: ActionTypes.UPDATE_PRODUCT_JOB_SUCCESS,
                payload: { actionType, data }
            }
        case ActionTypes.UPDATE_PRODUCT_JOB_FAIL:
            return {
                type: ActionTypes.UPDATE_PRODUCT_JOB_FAIL,
                payload: { actionType, error: data }
            }
        default:
            return {
                type: ActionTypes.SET_JOB_DEFINATION_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
};

export const masterJobResponseError = (
    actionType: string,
    error: any
): MasterJobType => ({
    type: ActionTypes.SET_JOB_DEFINATION_ERROR,
    payload: { actionType, error },
});