import { APICore } from "./apiCore";
const api = new APICore();

export function fetchdeals(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
  const baseURL = `/deals/`
  const params = new URLSearchParams();
  if (page !== undefined) params.append('page', page.toString());
  if (limit !== undefined) params.append('limit', limit.toString());
  if (sortBy) params.append('sortBy', sortBy);
  if (sort) params.append('sort', sort);
  if (searchBy) params.append('searchBy', searchBy);
  const url = `${baseURL}?${params.toString()}`;

  return api.get(url, "");
}


export const fetchDealByIdApi = (id: number) => {
    const url = `/deals/${id}`
    return api.get(url, "");
  };

 async function editDeals(id: number, data: any) {
    const url = `/deals/${id}`;
    return api.update(url, data);
  }

  async function createProject(params: { id: number, dealData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'PO Attatchment');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "58");


            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.dealData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/deals/${params.id}`;
        return api.update(baseUrl, {
            ...params.dealData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating RFQ with files:', error);
        throw error;
    }
}

export { createProject, editDeals};
