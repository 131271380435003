import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { ActionTypes } from "./constants";
import { deleteTagError, deleteTagResponse, tagApiResponse } from "./actions";
import { fetchTag,getTagType,createTag,fetchSingleTag,updateTag, deleteTag } from "helpers";


function* saveTag(tag:any): SagaIterator {
    try {
        const response = yield call(createTag,tag.payload);
        const tagData=response.data
        yield put(tagApiResponse(ActionTypes.CREATE_TAG, tagData))
    } catch (error: any) {
        yield put(tagApiResponse(ActionTypes.SET_ERROR, error))
    }
}

function* fetchAllTag({payload}: any): SagaIterator {    
    try { 
        const response = yield call(fetchTag,payload.data);
        const tagData = response.data;
        yield put(tagApiResponse(ActionTypes.FETCH_ALL_TAG, tagData))
    } catch (error: any) {
        yield put(tagApiResponse(ActionTypes.SET_ERROR, error))
    }
}

function* _fetchSingleTag({ payload }: any): SagaIterator {
    try {
        const response = yield call(fetchSingleTag,payload.tagId)
        const responseData = response.data;
        yield put(tagApiResponse(ActionTypes.FETCH_SINGLE_TAG, responseData))
    } catch (error: any) {
        yield put(tagApiResponse(ActionTypes.SET_ERROR, error))
    }
}

function* _updateTag({payload}:any): SagaIterator {
    try {
        const response = yield call(updateTag,payload.tag,payload.tagId);
        const data = response.data;
        yield put(tagApiResponse(ActionTypes.UPDATE_TAG, data));
    } catch (error: any) {
        yield put(tagApiResponse(ActionTypes.SET_ERROR, error))
    }
}

function* _getTagType():SagaIterator {
    try{
        const response=yield call(getTagType)
        const data=response.data
        yield put(tagApiResponse(ActionTypes.GET_TAG_TYPE,data))
    }catch(error:any){
        yield put(tagApiResponse(ActionTypes.SET_ERROR,error))
    }
}

function* _deleteTag(params: any): SagaIterator {
    try {
  
      const response = yield call(deleteTag, { id: params.payload.data.id });
      const TagData = yield response.data;
      yield put(deleteTagResponse(ActionTypes.DELETE_TAG_SUCCESS, TagData));
    } catch (e: any) {
      yield put(deleteTagError(ActionTypes.DELETE_TAG_FAIL, e))
    }
  }

export function* watchTag() {
    yield takeEvery(ActionTypes.CREATE_TAG, saveTag);
    yield takeEvery(ActionTypes.FETCH_ALL_TAG, fetchAllTag);
    yield takeEvery(ActionTypes.FETCH_SINGLE_TAG,_fetchSingleTag);
    yield takeEvery(ActionTypes.UPDATE_TAG, _updateTag)
    yield takeEvery(ActionTypes.GET_TAG_TYPE, _getTagType)
    yield takeEvery(ActionTypes.DELETE_TAG, _deleteTag)
    
}

export default watchTag;