import { useEffect, useState } from "react"


function useDebounce<T>(value: T, delay: number) {

    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(value)
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return debouncedSearchQuery
}


export default useDebounce