import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { IQCQualityActionTypes } from './constant';
import {
  createIQCQuality,
  fetchIQCQualitys,
  fetchIQCQualityById,
  editIQCQuality,
} from 'helpers/api/iqcQuality';
import {
  createIQCQualitySuccess,
  createIQCQualityFailure,
  fetchIQCQualitysSuccess,
  fetchIQCQualitysFailure,
  fetchIQCQualityByIdSuccess,
  fetchIQCQualityByIdFailure,
  editIQCQualitySuccess,
  editIQCQualityFailure,
} from './actions';

function* fetchIQCQualitysSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchIQCQualitys, page, limit, sortBy, sort, searchBy);
    const iqcQualityData = response.data; 
    yield put(fetchIQCQualitysSuccess(iqcQualityData));
  } catch (e: any) {
    yield put(fetchIQCQualitysFailure(e));
  }
}

function* createIQCQualitySaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;

    const response = yield call(createIQCQuality, data);
    const iqcQualityData = response.data;
    yield put(createIQCQualitySuccess(iqcQualityData));
    yield put({ type: IQCQualityActionTypes.FETCH_IQCQUALITYS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createIQCQualityFailure(e));
  }
}

function* fetchIQCQualityByIdSaga(action: any): SagaIterator {
  try {
    console.log(action.payload, "shubhi")
    console.log("Saga: Fetching iqcQuality by ID:", action.payload);  // Debug log
    const response = yield call(fetchIQCQualityById, action.payload);
    const iqcQualityData = response.data;
    console.log("Saga: API response for iqcQuality by ID:", iqcQualityData);  // Debug log

    yield put(fetchIQCQualityByIdSuccess(iqcQualityData));
  } catch (e: any) {
    yield put(fetchIQCQualityByIdFailure(e));
  }
}

function* editIQCQualitySaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;

    const response = yield call(editIQCQuality, id, data);
    const iqcQualityData = response.data;
    yield put(editIQCQualitySuccess(iqcQualityData));
    yield put({ type: IQCQualityActionTypes.FETCH_IQCQUALITY_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editIQCQualityFailure(e));
  }
}

function* watchIQCQuality() {
  yield takeEvery(IQCQualityActionTypes.FETCH_IQCQUALITYS_REQUEST, fetchIQCQualitysSaga);
  yield takeEvery(IQCQualityActionTypes.CREATE_IQCQUALITY_REQUEST, createIQCQualitySaga);
  yield takeEvery(IQCQualityActionTypes.FETCH_IQCQUALITY_BY_ID_REQUEST, fetchIQCQualityByIdSaga);
  yield takeEvery(IQCQualityActionTypes.EDIT_IQCQUALITY_REQUEST, editIQCQualitySaga);
}

export default watchIQCQuality;
