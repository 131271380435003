import { GRNActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  grn: [],
  projectGRN:[],
  singleGRN: {},
  error: {},
  success: false,
};

const GRNReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GRNActionTypes.CREATE_GRN_REQUEST:
    case GRNActionTypes.EDIT_GRN_REQUEST:
    case GRNActionTypes.FETCH_GRNS_REQUEST:
    case GRNActionTypes.FETCH_GRN_BY_ID_REQUEST:
    case GRNActionTypes.FETCH_GRN_BY_PROJECT_ID_REQUEST:
    case GRNActionTypes.RESET_GRN_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case GRNActionTypes.SET_SINGLE_GRN:
    return { ...state , singleGRN: action.payload.data, loading:false }  
    case GRNActionTypes.CREATE_GRN_SUCCESS:
      return {
        ...state,
        grn: [...state.grn, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case GRNActionTypes.EDIT_GRN_SUCCESS:
      const updatedGRNs = state.grn.map((grn: any) =>
        grn.id === action.payload.data.id ? action.payload.data : grn
      );
      return {
        ...state,
        grn: updatedGRNs,
        loading: false,
        error: {},
        success: true,
      };

    case GRNActionTypes.FETCH_GRNS_SUCCESS:
      return {
        ...state,
        grn: action.payload.data,
        singleGRN: {},
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };
  
    case GRNActionTypes.FETCH_GRN_BY_PROJECT_ID_SUCCESS:
      return {
        ...state,
        indents: [action.payload.data],
        projectGRN: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case GRNActionTypes.FETCH_GRN_BY_ID_SUCCESS:
      return {
        ...state,
        singleGRN: action.payload.data,
        grn: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case GRNActionTypes.RESET_SINGLE_GRN:
      return {
          ...state,
          singleGRN: null,
      };

    case GRNActionTypes.RESET_SINGLE_PROJECT_GRN:
      return {
          ...state,
          projectGRN: [],
      };

    case GRNActionTypes.CREATE_GRN_FAILURE:
    case GRNActionTypes.EDIT_GRN_FAILURE:
    case GRNActionTypes.FETCH_GRNS_FAILURE:
    case GRNActionTypes.FETCH_GRN_BY_ID_FAILURE:
    case GRNActionTypes.FETCH_GRN_BY_PROJECT_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default GRNReducer;
