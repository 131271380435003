import { ActionTypes } from "./constants";
import { fetchRawMaterials, fetchRawMaterialDetails, createRawMaterial, updateRawMaterial, deleteRawMaterial,fetchRawMaterialUnit ,fetchRawMaterialChart} from "helpers";
import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
    fetchRawMaterialResponse,
    createRawMaterialResponse,
    updateRawMaterialResponse,
    deleteRawMaterialResponse,
    fetchSingleRawMaterialResponse,
    fetchRawMaterialUnitResponse,
    fetchRawMaterialChartResponse
} from './actions'


function* _fetchRawMaterials(params: any): SagaIterator {
    try {
        const response = yield call(fetchRawMaterials, {
            page: params.payload.data.page,
            limit: params.payload.data.limit,
            sortBy: params.payload.data.sortBy,
            sort: params.payload.data.sort,
            searchBy: params.payload.data?.searchBy,
        });
        const data = response.data;
        yield put(
            fetchRawMaterialResponse(ActionTypes.FETCH_RAW_MATERIAL_LIST_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(
            fetchRawMaterialResponse(ActionTypes.FETCH_RAW_MATERIAL_LIST_FAILURE, error)
        );
    }
}


function* _fetchRawMaterialChart(params: any): SagaIterator {
    try {
        const response = yield call(fetchRawMaterialChart, {
            material: params.payload.data.material,
            type: params.payload.data.type
        });
        const data = response.data;
        yield put(
            fetchRawMaterialChartResponse(ActionTypes.FETCH_RAW_MATERIAL_CHART_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(
            fetchRawMaterialChartResponse(ActionTypes.FETCH_RAW_MATERIAL_CHART_FAILURE, error)
        );
    }
}

function* _fetchRawMaterialUnit(params: any): SagaIterator {
    try {
        const response = yield call(fetchRawMaterialUnit);
        const data = response.data;
        yield put(
            fetchRawMaterialUnitResponse(ActionTypes.FETCH_RAW_MATERIAL_UNITS_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(
            fetchRawMaterialUnitResponse(ActionTypes.FETCH_RAW_MATERIAL_UNITS_FAILURE, error)
        );
    }
}


function* _createRawMaterial({ payload }: any): SagaIterator {
    try {
        const response = yield call(createRawMaterial, {
            rawMaterial: payload.data.rawMaterial,
        });
        const data = response.data;
        yield put(
            createRawMaterialResponse(ActionTypes.CREATE_RAW_MATERIAL_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(createRawMaterialResponse(ActionTypes.CREATE_RAW_MATERIAL_FAILURE, error));
    }
}


function* _getRawMaterialDetails({ payload }: any): SagaIterator {
    try {
        const response = yield call(fetchRawMaterialDetails, { id: payload.data.id });
        const data = response.data;
        yield put(
            fetchSingleRawMaterialResponse(
                ActionTypes.FETCH_SINGLE_RAW_MATERIAL_SUCCESS,
                data
            )
        );
    } catch (error: any) {
        yield put(
            fetchSingleRawMaterialResponse(
                ActionTypes.FETCH_SINGLE_RAW_MATERIAL_FAILURE,
                error
            )
        );
    }
}


function* _updateRawMaterial({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateRawMaterial, {
            id: payload.data.id,
            rawMaterial: payload.data.rawMaterial,
        });
        const data = response.data;
        yield put(
            updateRawMaterialResponse(ActionTypes.UPDATE_RAW_MATERIAL_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(updateRawMaterialResponse(ActionTypes.UPDATE_RAW_MATERIAL_FAILURE, error));
    }
}

function* _deleteRawMaterial({ payload }: any): SagaIterator {
    try {
        const response = yield call(deleteRawMaterial, {
            id: payload.data.id,
        });
        const data = response.data;
        yield put(
            deleteRawMaterialResponse(ActionTypes.DELETE_RAW_MATERIAL_SUCCESS, data)
        );
    } catch (error: any) {
        yield put(deleteRawMaterialResponse(ActionTypes.DELETE_RAW_MATERIAL_FAILURE, error));
    }
}


function* watchRawMaterial() {
    yield takeEvery(ActionTypes.FETCH_RAW_MATERIAL_LIST, _fetchRawMaterials);
    yield takeEvery(ActionTypes.CREATE_RAW_MATERIAL, _createRawMaterial);
    yield takeEvery(ActionTypes.FETCH_SINGLE_RAW_MATERIAL, _getRawMaterialDetails);
    yield takeEvery(ActionTypes.UPDATE_RAW_MATERIAL, _updateRawMaterial);
    yield takeEvery(ActionTypes.DELETE_RAW_MATERIAL, _deleteRawMaterial);
    yield takeEvery(ActionTypes.FETCH_RAW_MATERIAL_UNITS,_fetchRawMaterialUnit)
    yield takeEvery(ActionTypes.FETCH_RAW_MATERIAL_CHART,_fetchRawMaterialChart);
}

export default watchRawMaterial;
