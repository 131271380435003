import { APICore } from "./apiCore";

const api = new APICore();

function fetchApproval(params: { page: string, limit: string, sortBy: string, sort: string, userId?: string, enquiryId?: any, job_id?: any, searchBy?: string, filterBy ?: string, assignedToMe ?: string | boolean , filterByStatus ?: string}) {
  const baseUrl = `/approval?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&userId=${params.userId}&enquiryId=${params.enquiryId}&job_id=${params.job_id || ""}&searchBy=${params.searchBy}&filterBy=${params.filterBy}&assignedToMe=${params.assignedToMe}&filterByStatus=${params.filterByStatus}`;
  return api.get(`${baseUrl}`, "");
}

function fetchCountApprovals(params: { user_id: number, status: number }) {
  const baseUrl = `/approval/count?user_id=${params.user_id}&status=${params.status}`;
  return api.get(`${baseUrl}`, "");
}

function fetchSingleApproval(id: any, type?: string) {
  const baseUrl = `/approval/${id}`;
  return api.get(`${baseUrl}`, { type });
}

function fetchApprovalHistory(id: any, type: string) {
  const baseUrl = `/approval/history?id=${id}&type=${type}`;
  return api.get(`${baseUrl}`, "");
}

function createApproval(body: any) {
  const baseUrl = "/approval";
  return api.create(`${baseUrl}`, body);
}

function updateApproval(body: any) {
  const baseUrl = `/approval/${body.id}`;
  return api.update(`${baseUrl}`, body);
}

function fetchApprovalTypes() {
  const baseUrl = `/approval/approvalTypes`;
  return api.get(`${baseUrl}`, "");
}

function fetchApprovalTypeList(params: { page: string, limit: string, sortBy: string, sort: string }) {
  const baseUrl = `/approval/approvalTypeList`
  return api.get(`${baseUrl}`, params);
}

function deleteApproval(params: any) {
  const baseUrl = `/approval/${params.id}`;
  return api.delete(`${baseUrl}`);
}




export { fetchApproval, fetchCountApprovals, fetchSingleApproval, createApproval, updateApproval, fetchApprovalTypes, fetchApprovalTypeList, deleteApproval, fetchApprovalHistory };
