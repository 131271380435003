import { ActionTypes } from './constants';

const INIT_STATE = {
    loading: true,
    jobDefination: null,
    singleJobDefination: {},
    productJobs: {},
    error: null,
    success: false,
    addSuccess: false,
    updateSuccess: false,
    addError: null,
    updateError: null,
}

type MasterJobActionType = {
    type:
    | ActionTypes.SET_ALL_JOBS
    | ActionTypes.SET_PRODUCT_JOBS
    | ActionTypes.SET_SINGLE_JOB_DEFINATION
    | ActionTypes.SET_JOB_DEFINATION_ERROR
    | ActionTypes.ADD_PRODUCT_JOB_SUCCESS
    | ActionTypes.ADD_PRODUCT_JOB_FAIL
    | ActionTypes.UPDATE_PRODUCT_JOB_SUCCESS
    | ActionTypes.UPDATE_PRODUCT_JOB_FAIL
    | ActionTypes.SET_JOB_DEFINATION_ERROR
    | ActionTypes.CLEAR_SINGLE_JOB_STATE
    payload: {
        actionType?: string;
        data?: {} | any;
        error?: string;
    }
}

const JobdDefinationReducer = (state: any = INIT_STATE, action: MasterJobActionType) => {
    switch (action.type) {
        case ActionTypes.SET_ALL_JOBS:
            return {
                ...state, loading: false, jobDefination: action.payload.data, success: true, error: null, singleJobDefination: {},
                addSuccess: false,
                updateSuccess: false,
                addError: null,
                updateError: null,
            }
        case ActionTypes.SET_PRODUCT_JOBS:
            return {
                ...state, loading: false, productJobs: action.payload.data, success: true, error: null,
                addSuccess: false,
                updateSuccess: false,
                addError: null,
                updateError: null,
            }
        case ActionTypes.SET_SINGLE_JOB_DEFINATION:
            return {
                ...state, loading: false, singleJobDefination: action.payload.data, success: true, error: null,
                addSuccess: false,
                updateSuccess: false,
                addError: null,
                updateError: null,
            }
        case ActionTypes.ADD_PRODUCT_JOB_SUCCESS:
            return {
                ...state, loading: false, success: false, error: null,
                addSuccess: true,
                updateSuccess: false,
                addError: null,
                updateError: null,
            }
        case ActionTypes.ADD_PRODUCT_JOB_FAIL:
            return {
                ...state, loading: false, success: false, error: null,
                addSuccess: false,
                updateSuccess: false,
                addError: action.payload.error,
                updateError: null,
            }
        case ActionTypes.UPDATE_PRODUCT_JOB_SUCCESS:
            return {
                ...state, loading: false, success: false, error: null,
                addSuccess: false,
                updateSuccess: true,
                addError: null,
                updateError: null,
            }
        case ActionTypes.UPDATE_PRODUCT_JOB_FAIL:
            return {
                ...state, loading: false, success: false, error: null,
                addSuccess: false,
                updateSuccess: false,
                addError: null,
                updateError: action.payload.error,
            }
        case ActionTypes.SET_JOB_DEFINATION_ERROR:
            return {
                ...state, loading: false, success: false, error: action.payload.error,
                addSuccess: false,
                updateSuccess: false,
                addError: null,
                updateError: null,
            }
        case ActionTypes.CLEAR_SINGLE_JOB_STATE:
            return {
                ...state,
                singleJobDefination: {}
            }
        default:
            return { ...state }
    }
}

export default JobdDefinationReducer;


