import { APICore } from "./apiCore";
const api = new APICore();

function createIndirectExpense(data: any) {
    const baseUrl = `/expense/indirect/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchIndirectExpenses(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string, status?: string, origin?: string) {
    const baseURL = `/expense/indirect/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    if (status) params.append('status', status);
    if (origin) params.append('origin', origin);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchIndirectExpenseById(id: number) {
    const url = `/expense/indirect/${id}`;
    return api.get(url, "");
}

function editIndirectExpense(id: number, data: any) {
    const url = `/expense/indirect/${id}`;
    return api.update(url, data);
}

export { createIndirectExpense, fetchIndirectExpenseById, fetchIndirectExpenses, editIndirectExpense };
