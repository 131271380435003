import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ClaimActionTypes } from './constants';
import {
  createClaim,
  fetchClaims,
  fetchClaimById,
  editClaim
} from 'helpers/api/claim';
import {
  createClaimSuccess,
  createClaimFailure,
  fetchClaimsSuccess,
  fetchClaimsFailure,
  fetchClaimByIdSuccess,
  fetchClaimByIdFailure,
  editClaimSuccess,
  editClaimFailure,
} from './actions';

function* fetchClaimsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy, status, origin  } = action.payload || {};
    const response = yield call(fetchClaims, page, limit , sortBy, sort, searchBy, status, origin);
    const claimsData = response.data; 
    yield put(fetchClaimsSuccess(claimsData));
  } catch (e: any) {
    yield put(fetchClaimsFailure(e));
  }
}

function* createClaimSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createClaim, data);
    const claimData = response.data;
    yield put(createClaimSuccess(claimData));
    yield put({ type: ClaimActionTypes.FETCH_CLAIMS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createClaimFailure(e));
  }
}

function* fetchClaimByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchClaimById, action.payload);
    const claimData = response.data;
    yield put(fetchClaimByIdSuccess(claimData));
  } catch (e: any) {
    yield put(fetchClaimByIdFailure(e));
  }
}

function* editClaimSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editClaim, id, data);
    const claimData = response.data;
    yield put(editClaimSuccess(claimData));
    yield put({ type: ClaimActionTypes.FETCH_CLAIM_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editClaimFailure(e));
  }
}

function* watchClaims() {
  yield takeEvery(ClaimActionTypes.FETCH_CLAIMS_REQUEST, fetchClaimsSaga);
  yield takeEvery(ClaimActionTypes.CREATE_CLAIM_REQUEST, createClaimSaga);
  yield takeEvery(ClaimActionTypes.FETCH_CLAIM_BY_ID_REQUEST, fetchClaimByIdSaga);
  yield takeEvery(ClaimActionTypes.EDIT_CLAIM_REQUEST, editClaimSaga);
}

export default watchClaims;
