export enum ActionTypes {
    SET_FILES = "SET_FILES",
    SET_ERROR = "SET_ERROR",
    FETCH_FILES = "FETCH_FILES",
    UPLOAD_FILE = "UPLOAD_FILE",
    UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS",
    UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL",
    DOWNLOAD_FILE_BY_ID = "DOWNLOAD_FILE_BY_ID",
    VIEW_FILE_BY_ID = "VIEW_FILE_BY_ID",
    DELETE_FILE_BY_ID = "DELETE_FILE_BY_ID",
    DOWNLOAD_FILE_BY_PATH = "DOWNLOAD_FILE_BY_PATH",
    DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS",
    DOWNLOAD_FILE_FAIL = "DOWNLOAD_FILE_FAIL",
    DELETE_SUCCESS = "DELETE_SUCCESS",
    DELETE_FAIL = "DELETE_FAIL",
    FETCH_TAG = "FETCH_TAG",
    SET_TAG = "SET_TAG",
    RESET = "RESET",
    FILES_BY_ID = "FILES_BY_ID",
    SET_FILES_BY_ID = "SET_FILES_BY_ID",
    RESET_FILE_SUCCESS = "RESET_FILE_SUCCESS",
    FETCH_SINGLE_FILE_BY_ID = "FETCH_SINGLE_FILE_BY_ID",
    SET_SINGLE_FILE_BY_ID = "SET_SINGLE_FILE_BY_ID",
    RESET_SINGLE_FILE_STATE = "RESET_SINGLE_FILE_STATE",
    RESET_DELETE_FILE_SUCCESS = "RESET_DELETE_FILE_SUCCESS",
    RESET_FILE_UPLOAD_SUCCESS = "RESET_FILE_UPLOAD_SUCCESS",
    UPDATE_FILE_BY_ID = "UPDATE_FILE_BY_ID",
    UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS",
    UPDATE_FILE_FAIL = "UPDATE_FILE_FAIL",
    RESET_FILE_UPDATE = "RESET_FILE_UPDATE",
}

