import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchProjectReport } from "helpers";
import { reportApiResponse, projectReportApiError } from "./actions";
import { ActionTypes } from './constants';
import { fetchIndentReport } from "helpers/api/report";

function* projectReport(params: any): SagaIterator {
    try {
        const response = yield call(fetchProjectReport, { startDate: params.payload.data.startDate, endDate: params.payload.data.endDate });
        const reportData = yield response.data;
        yield put(reportApiResponse(ActionTypes.FETCH_PROJECT_REPORT, reportData));
    } catch (error: any) {
        yield put(projectReportApiError(ActionTypes.FETCH_PROJECT_REPORT, error));
    }
}

function* indentReport(params: any): SagaIterator {
    try {
        const response = yield call(fetchIndentReport, { startDate: params.payload.data.startDate, endDate: params.payload.data.endDate });
        const reportData = yield response.data;
        yield put(reportApiResponse(ActionTypes.FETCH_INDENT_REPORT, reportData));
    } catch (error: any) {
        yield put(projectReportApiError(ActionTypes.FETCH_INDENT_REPORT, error));
    }
}

export function* watchReport() {
    yield takeEvery(ActionTypes.FETCH_PROJECT_REPORT, projectReport);
    yield takeEvery(ActionTypes.FETCH_INDENT_REPORT, indentReport);
}

export default watchReport;
