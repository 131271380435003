import { ItemCategoryActionTypes as ActionTypes } from './constant';

export const createItemCategoryRequest = (data: any) => ({
  type: ActionTypes.CREATE_ITEM_CATEGORY_REQUEST,
  payload: {data},
});

export const resetItemCategorySuccessState = () => ({
  type: ActionTypes.RESET_ITEM_CATEGORY_SUCCESS_STATE,
});

export const createItemCategorySuccess = (response: any) => ({
  type: ActionTypes.CREATE_ITEM_CATEGORY_SUCCESS,
  payload: response,
});

export const createItemCategoryFailure = (error: any) => ({
  type: ActionTypes.CREATE_ITEM_CATEGORY_FAILURE,
  payload: error,
});

export const fetchItemCategoriesRequest = (page?: number, limit?: number, sortBy?: string,
  sort?: string,
  searchBy?: string) => ({
  
  type: ActionTypes.FETCH_ITEM_CATEGORIES_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchItemCategoriesSuccess = (response: any) => ({
  type: ActionTypes.FETCH_ITEM_CATEGORIES_SUCCESS,
  payload: response,
});

export const fetchItemCategoriesFailure = (error: any) => ({
  type: ActionTypes.FETCH_ITEM_CATEGORIES_FAILURE,
  payload: error,
});

export const fetchItemCategoryByIdRequest = (id: number) => {
  console.log("Action Creator: fetchItemCategoryByIdRequest called with id:", id); // Debug log
  return{
  type: ActionTypes.FETCH_ITEM_CATEGORY_BY_ID_REQUEST,
  payload: id,
  }};

export const fetchItemCategoryByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_ITEM_CATEGORY_BY_ID_SUCCESS,
  payload: response,
});

export const fetchItemCategoryByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_ITEM_CATEGORY_BY_ID_FAILURE,
  payload: error,
});

export const editItemCategoryRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_ITEM_CATEGORY_REQUEST,
  payload: { id, data },
});

export const editItemCategorySuccess = (response: any) => ({
  type: ActionTypes.EDIT_ITEM_CATEGORY_SUCCESS,
  payload: response,
});

export const editItemCategoryFailure = (error: any) => ({
  type: ActionTypes.EDIT_ITEM_CATEGORY_FAILURE,
  payload: error,
});

export const resetSingleItemCategory = () => ({
  type: ActionTypes.RESET_SINGLE_ITEM_CATEGORY,
});

export const saveItemCategoryBomSuccess = (response: any) => ({
  type: ActionTypes.SAVE_ITEM_CATEGORY_BOM_SUCCESS,
  payload: response,
});

export const saveItemCategoryBomFailure = (error: any) => ({
  type: ActionTypes.SAVE_ITEM_CATEGORY_BOM_FAILURE,
  payload: error,
});

export const itemCategoryApiResponse = (actionType: string, data: any) => {
  console.log(actionType, "herre")

  switch (actionType) {
    case ActionTypes.FETCH_ITEM_CATEGORIES_SUCCESS:
    case ActionTypes.CREATE_ITEM_CATEGORY_SUCCESS:
    case ActionTypes.EDIT_ITEM_CATEGORY_SUCCESS:
    case ActionTypes.SAVE_ITEM_CATEGORY_BOM_SUCCESS:
      return {
        type: actionType,
        payload: data,
      };
    case ActionTypes.FETCH_ITEM_CATEGORY_BY_ID_SUCCESS:
      console.log("shubhi hii")
      return {
        type: ActionTypes.SET_SINGLE_ITEMCATEGORY,
        payload: data
      }

    case ActionTypes.FETCH_ITEM_CATEGORIES_FAILURE:
    case ActionTypes.FETCH_ITEM_CATEGORY_BY_ID_FAILURE:
    case ActionTypes.CREATE_ITEM_CATEGORY_FAILURE:
    case ActionTypes.EDIT_ITEM_CATEGORY_FAILURE:
    case ActionTypes.SAVE_ITEM_CATEGORY_BOM_FAILURE:
      return {
        type: actionType,
        payload: { error: "Error while setting data to store!" },
      };
    default:
      return {
        type: ActionTypes.FETCH_ITEM_CATEGORIES_FAILURE,
        payload: { actionType, error: "Unknown action type" },
      };
  }
};

export const itemCategoryApiError = (actionType: string, error: any) => {
  console.log(actionType, "herre")
  switch (actionType) {
    case ActionTypes.CREATE_ITEM_CATEGORY_REQUEST:
    case ActionTypes.EDIT_ITEM_CATEGORY_REQUEST:
    case ActionTypes.SAVE_ITEM_CATEGORY_BOM_REQUEST:
    case ActionTypes.FETCH_ITEM_CATEGORIES_REQUEST:
    case ActionTypes.FETCH_ITEM_CATEGORY_BY_ID_REQUEST:
      return {
        type: ActionTypes.CREATE_ITEM_CATEGORY_FAILURE,
        payload: { error }
      };
    default:
      return {
        type: ActionTypes.CREATE_ITEM_CATEGORY_FAILURE,
        payload: { error: "Error while setting data to store!" }
      };
  }
};
