import { masterJobApiResponse, masterJobResponseError } from './actions';
import { ActionTypes } from "./constants";
import { fetchAllJobs, getProductJobs, updateProductJob, createProductJob, getProductSingleJob } from 'helpers'
import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";


function* allJobs(params: any): SagaIterator {
    try {
        const response = yield call(fetchAllJobs, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort })
        const productData = response.data;
        yield put(masterJobApiResponse(ActionTypes.FETCH_ALL_JOBS, productData))
    } catch (error: any) {
        yield put(masterJobResponseError(ActionTypes.SET_JOB_DEFINATION_ERROR, error))
    }
}


function* _getProductJobs({ payload }: any): SagaIterator {
    try {
        const response = yield call(getProductJobs, { id: payload.data.id, page: payload.data.page, limit: payload.data.limit, sortBy: payload.data.sortBy, sort: payload.data.sort });
        const singleData = response.data;
        yield put(masterJobApiResponse(ActionTypes.FETCH_PRODUCT_JOBS, singleData));
    } catch (error: any) {
        yield put(masterJobResponseError(ActionTypes.FETCH_PRODUCT_JOBS, error))
    }
}


function* _getSingleMasterJob({ payload }: any): SagaIterator {
    try {
        console.log("jobtabstate", payload)
        const response = yield call(getProductSingleJob, { jobID: payload.data.jobID })
        const singleTaskData = response.data;
        yield put(masterJobApiResponse(ActionTypes.FETCH_SINGLE_JOB_DEFINATION, singleTaskData));
    } catch (error: any) {
        yield put(masterJobResponseError(ActionTypes.FETCH_SINGLE_JOB_DEFINATION, error))
    }
}

function* _createProductjob({ payload }: any): SagaIterator {
    try {
        const response = yield call(createProductJob, { productID: payload.data.productID, product: payload.data.product })
        const projectTaskData = response.data;
        yield put(masterJobApiResponse(ActionTypes.ADD_PRODUCT_JOB_SUCCESS, projectTaskData))
    } catch (error: any) {
        yield put(masterJobApiResponse(ActionTypes.ADD_PRODUCT_JOB_FAIL, error))
    }
}

function* _updateProductJob({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateProductJob, { jobID: payload.data.jobID, product: payload.data.product })
        const projectTaskData = response.data;
        yield put(masterJobApiResponse(ActionTypes.UPDATE_PRODUCT_JOB_SUCCESS, projectTaskData))
    } catch (error: any) {
        yield put(masterJobApiResponse(ActionTypes.UPDATE_PRODUCT_JOB_FAIL, error))
    }
}


function* watchMasterJob() {
    yield takeEvery(ActionTypes.FETCH_ALL_JOBS, allJobs);
    yield takeEvery(ActionTypes.FETCH_PRODUCT_JOBS, _getProductJobs);
    yield takeEvery(ActionTypes.FETCH_SINGLE_JOB_DEFINATION, _getSingleMasterJob);
    yield takeEvery(ActionTypes.ADD_PRODUCT_JOB, _createProductjob)
    yield takeEvery(ActionTypes.UPDATE_PRODUCT_JOB, _updateProductJob)
}

export default watchMasterJob;