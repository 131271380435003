export enum ActionTypes {
    SET_EMPLOYEE = "SET_EMPLOYEE",
    SET_ERROR = "SET_ERROR",
    CREATE_EMPLOYEE = "CREATE_EMPLOYEE",
    FETCH_EMPLOYEE = "FETCH_EMPLOYEE",
    UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE",
    FETCH_SINGLE_EMPLOYE = "FETCH_SINGLE_EMPLOYEE",
    SET_SINGLE_EMPLOYEE = "SET_SINGLE_EMPLOYEE",
    UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS",
    UPDATE_EMPLOYEE_FAIL = "UPDATE_EMPLOYEE_FAIL",
    ADD_EMPLOY_SUCCESS = "ADD_EMPLOY_SUCCESS",
    ADD_EMPLOY_FAIL = "ADD_EMPLOY_FAIL",
    FETCH_GROUPLIST = "FETCH_GROUPLIST",
    SET_GROUPLIST = "SET_GROUPLIST",

    //! delete employee
    DELETE_EMPLOYEE = "DELETE_EMPLOYEE",
    DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS",
    DELETE_EMPLOYEE_FAIL = "DELETE_EMPLOYEE_FAIL",

    //profile image
    FETCH_PROFILE_IMAGE = "FETCH_PROFILE_IMAGE",
    FETCH_PROFILE_IMAGE_SUCCESS = "FETCH_PROFILE_IMAGE_SUCCESS",
    FETCH_PROFILE_IMAGE_FAIL = "FETCH_PROFILE_IMAGE_FAIL",

    //Employee Status
    FETCH_EMPLOYEE_STATUS="FETCH_EMPLOYEE_STATUS",
    SET_EMPLOYEE_STATUS="SET_EMPLOYEE_STATUS"



}


