export enum WarehouseActionTypes {
    CREATE_WAREHOUSE_REQUEST = 'CREATE_WAREHOUSE_REQUEST',
    CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS',
    CREATE_WAREHOUSE_FAILURE = 'CREATE_WAREHOUSE_FAILURE',
    FETCH_WAREHOUSES_REQUEST = 'FETCH_WAREHOUSES_REQUEST',
    FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS',
    FETCH_WAREHOUSES_FAILURE = 'FETCH_WAREHOUSES_FAILURE',
    FETCH_WAREHOUSE_BY_ID_REQUEST = 'FETCH_WAREHOUSE_BY_ID_REQUEST',
    FETCH_WAREHOUSE_BY_ID_SUCCESS = 'FETCH_WAREHOUSE_BY_ID_SUCCESS',
    FETCH_WAREHOUSE_BY_ID_FAILURE = 'FETCH_WAREHOUSE_BY_ID_FAILURE',
    EDIT_WAREHOUSE_REQUEST = 'EDIT_WAREHOUSE_REQUEST',
    EDIT_WAREHOUSE_SUCCESS = 'EDIT_WAREHOUSE_SUCCESS',
    EDIT_WAREHOUSE_FAILURE = 'EDIT_WAREHOUSE_FAILURE',
    RESET_WAREHOUSE_SUCCESS_STATE = 'RESET_WAREHOUSE_SUCCESS_STATE',
    FETCH_SINGLE_WAREHOUSE = 'FETCH_SINGLE_WAREHOUSE',
    SET_SINGLE_WAREHOUSE = 'SET_SINGLE_WAREHOUSE',
    RESET_SINGLE_WAREHOUSE = 'RESET_SINGLE_WAREHOUSE',
    FETCH_WAREHOUSE_ITEMS_REQUEST = 'FETCH_WAREHOUSE_ITEMS_REQUEST',
    FETCH_WAREHOUSE_ITEMS_SUCCESS = 'FETCH_WAREHOUSE_ITEMS_SUCCESS',
    FETCH_WAREHOUSE_ITEMS_FAILURE = 'FETCH_WAREHOUSE_ITEMS_FAILURE',
  }
  