import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { ActionTypes } from './constants';
import { leadApiError, leadApiResponse } from "./actions";
import { fetchAllLeads, uploadLeadLocater } from "helpers/api/leads-locater";

function* fetchLeadsSaga(action: any): SagaIterator {
    try {
        const { page, limit, sortBy, sort, searchBy } = action.payload || {};

        const response = yield call(fetchAllLeads, page, limit, sortBy, sort, searchBy);
        const leadsData = yield response.data;
        yield put(leadApiResponse(ActionTypes.FETCH_ALL_LEADS, leadsData));
    } catch (e: any) {
        yield put(leadApiError(ActionTypes.FETCH_ALL_LEADS, e));
    }
}


function* uploadLeadSaga(action:any): SagaIterator {
    try {
        const { file, source } = action.payload;
        const response = yield call(uploadLeadLocater, file, source);
        const leadData = yield response.data;
        yield put(leadApiResponse(ActionTypes.UPLOAD_LEAD_LOCATOR, leadData));
        yield put({
            type: ActionTypes.FETCH_ALL_LEADS,
            payload: { page: 1, limit: 15, sortBy: 'title', sort: 'asc', searchBy: '' }
        });
          

    } catch (e: any) {
        yield put(leadApiError(ActionTypes.UPLOAD_LEAD_LOCATOR, e));
    }
}

function* watchLeadsLocator() {
    yield takeEvery(ActionTypes.FETCH_ALL_LEADS, fetchLeadsSaga);
    yield takeEvery(ActionTypes.UPLOAD_LEAD_LOCATOR, uploadLeadSaga);
}

export default watchLeadsLocator;
