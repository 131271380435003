import { APICore } from "./apiCore";
const api = new APICore();

function createWarehouse(data: any) {
    const baseUrl = `/warehouse/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchWarehouses(page?: any, limit?: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/warehouse/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchWarehouseById(id: number) {
    const url = `/warehouse/${id}`;
    return api.get(url, "");
}

function fetchWarehouseItemsByWarehouseId(id: number) {
    const url = `/warehouse/items/${id}`;
    return api.get(url, "");
}

function editWarehouse(id: number, data: any) {
    const url = `/warehouse/${id}`;
    return api.update(url, data);
}

function editWarehouseStock(id: number, data: any) {
    const url = `/warehouse/stock/${id}`;
    return api.update(url, data);
}

export { createWarehouse, fetchWarehouseById, fetchWarehouses, editWarehouse, fetchWarehouseItemsByWarehouseId, editWarehouseStock};
