import { IQCEquipmentActionTypes as ActionTypes } from './constant';

export const createIQCEquipmentRequest = (data: any) => ({
  type: ActionTypes.CREATE_IQCEQUIPMENT_REQUEST,
  payload: {data},
});

export const resetIQCEquipmentSuccessState = () => ({
  type: ActionTypes.RESET_IQCEQUIPMENT_SUCCESS_STATE,
});

export const createIQCEquipmentSuccess = (response: any) => ({
  type: ActionTypes.CREATE_IQCEQUIPMENT_SUCCESS,
  payload: response,
});

export const createIQCEquipmentFailure = (error: any) => ({
  type: ActionTypes.CREATE_IQCEQUIPMENT_FAILURE,
  payload: error,
});

export const fetchIQCEquipmentsRequest = (page?: number, limit?: number, sortBy?: string,
  sort?: string,
  searchBy?: string) => ({
  
  type: ActionTypes.FETCH_IQCEQUIPMENTS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchIQCEquipmentsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_IQCEQUIPMENTS_SUCCESS,
  payload: response,
});

export const fetchIQCEquipmentsFailure = (error: any) => ({
  type: ActionTypes.FETCH_IQCEQUIPMENTS_FAILURE,
  payload: error,
});

export const fetchIQCEquipmentByIdRequest = (id: number) => {
  console.log("Action Creator: fetchIQCEquipmentByIdRequest called with id:", id); // Debug log
  return{
  type: ActionTypes.FETCH_IQCEQUIPMENT_BY_ID_REQUEST,
  payload: id,
  }};

export const fetchIQCEquipmentByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_IQCEQUIPMENT_BY_ID_SUCCESS,
  payload: response,
});

export const fetchIQCEquipmentByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_IQCEQUIPMENT_BY_ID_FAILURE,
  payload: error,
});

export const editIQCEquipmentRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_IQCEQUIPMENT_REQUEST,
  payload: { id, data },
});

export const editIQCEquipmentSuccess = (response: any) => ({
  type: ActionTypes.EDIT_IQCEQUIPMENT_SUCCESS,
  payload: response,
});

export const editIQCEquipmentFailure = (error: any) => ({
  type: ActionTypes.EDIT_IQCEQUIPMENT_FAILURE,
  payload: error,
});

export const resetSingleIQCEquipment = () => ({
  type: ActionTypes.RESET_SINGLE_IQCEQUIPMENT,
});

export const iqcEquipmentApiResponse = (actionType: string, data: any) => {
  console.log(actionType, "herre")

  switch (actionType) {
    case ActionTypes.FETCH_IQCEQUIPMENTS_SUCCESS:
    case ActionTypes.CREATE_IQCEQUIPMENT_SUCCESS:
    case ActionTypes.EDIT_IQCEQUIPMENT_SUCCESS:
      return {
        type: actionType,
        payload: data,
      };
    case ActionTypes.FETCH_IQCEQUIPMENT_BY_ID_SUCCESS:
      console.log("shubhi hii")
      return {
        type: ActionTypes.SET_SINGLE_IQCEQUIPMENT,
        payload: data
      }

    case ActionTypes.FETCH_IQCEQUIPMENTS_FAILURE:
    case ActionTypes.FETCH_IQCEQUIPMENT_BY_ID_FAILURE:
    case ActionTypes.CREATE_IQCEQUIPMENT_FAILURE:
    case ActionTypes.EDIT_IQCEQUIPMENT_FAILURE:
      return {
        type: actionType,
        payload: { error: "Error while setting data to store!" },
      };
    default:
      return {
        type: ActionTypes.FETCH_IQCEQUIPMENTS_FAILURE,
        payload: { actionType, error: "Unknown action type" },
      };
  }
};

export const iqcEquipmentApiError = (actionType: string, error: any) => {
  console.log(actionType, "herre")
  switch (actionType) {
    case ActionTypes.CREATE_IQCEQUIPMENT_REQUEST:
    case ActionTypes.EDIT_IQCEQUIPMENT_REQUEST:
    case ActionTypes.FETCH_IQCEQUIPMENTS_REQUEST:
    case ActionTypes.FETCH_IQCEQUIPMENT_BY_ID_REQUEST:
      return {
        type: ActionTypes.CREATE_IQCEQUIPMENT_FAILURE,
        payload: { error }
      };
    default:
      return {
        type: ActionTypes.CREATE_IQCEQUIPMENT_FAILURE,
        payload: { error: "Error while setting data to store!" }
      };
  }
};
