/* eslint-disable */
import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { CommissioningActionTypes } from './constants';
import {
  createCommissioning,
  editCommissioning,
  fetchCommissioningById,
  fetchCommissionings,
  fetchCommissioningsUsingProjectId
} from 'helpers/api/comissioning';
import {
  createCommissioningFailure,
  createCommissioningRequest,
  createCommissioningSuccess,
  editCommissioningFailure,
  editCommissioningRequest,
  editCommissioningSuccess,
  fetchCommissioningByIdFailure,
  fetchCommissioningByIdRequest,
  fetchCommissioningByIdSuccess,
  fetchCommissioningsFailure,
  fetchCommissioningsRequest,
  fetchCommissioningsSuccess,
  fetchCommissioningsUsingProjectIdFailure,
  fetchCommissioningsUsingProjectIdSuccess,
  resetCommissioningSuccessState,
  resetSingleCommissioning
} from './actions';

function* fetchCommissioningsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchCommissionings, page, limit, sortBy, sort, searchBy);
    const commissioningData = response.data;
    yield put(fetchCommissioningsSuccess(commissioningData));
  } catch (e: any) {
    yield put(fetchCommissioningsFailure(e));
  }
}
function* fetchCommissioningsUsingProjectIdSaga(action: any): SagaIterator {
  try {
    const {projectId, page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchCommissioningsUsingProjectId, projectId, page, limit, sortBy, sort, searchBy);
    const commissioningData = response.data;
    yield put(fetchCommissioningsUsingProjectIdSuccess(commissioningData));
  } catch (e: any) {
    yield put(fetchCommissioningsUsingProjectIdFailure(e));
  }
}

function* createCommissioningSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createCommissioning, data);
    const commissioningData = response.data;
    yield put(createCommissioningSuccess(commissioningData));
    // yield put({ type: CommissioningActionTypes.FETCH_COMMISSIONINGS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createCommissioningFailure(e));
  }
}

function* fetchCommissioningByIdSaga(action: any): SagaIterator {
  try {
    const response = yield call(fetchCommissioningById, action.payload);
    const commissioningData = response.data;
    yield put(fetchCommissioningByIdSuccess(commissioningData));
  } catch (e: any) {
    yield put(fetchCommissioningByIdFailure(e));
  }
}

function* editCommissioningSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editCommissioning, id, data);
    const commissioningData = response.data;
    yield put(editCommissioningSuccess(commissioningData));
    // yield put({ type: CommissioningActionTypes.FETCH_COMMISSIONING_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editCommissioningFailure(e));
  }
}

function* watchCommissionings() {
  yield takeEvery(CommissioningActionTypes.FETCH_COMMISSIONINGS_REQUEST, fetchCommissioningsSaga);
  yield takeEvery(CommissioningActionTypes.FETCH_COMMISSIONINGS_USING_PROJECTID_REQUEST, fetchCommissioningsUsingProjectIdSaga);
  yield takeEvery(CommissioningActionTypes.CREATE_COMMISSIONING_REQUEST, createCommissioningSaga);
  yield takeEvery(CommissioningActionTypes.FETCH_COMMISSIONING_BY_ID_REQUEST, fetchCommissioningByIdSaga);
  yield takeEvery(CommissioningActionTypes.EDIT_COMMISSIONING_REQUEST, editCommissioningSaga);
}

export default watchCommissionings;
