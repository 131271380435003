import { APICore } from "./apiCore";
const api = new APICore();

//send otp request
function sendOtp(params: { email: string }) {
  const baseUrl = "/login";
  return api.create(`${baseUrl}`, params);
}

//verify otp
function verifyOtp(params: { email: string; otp: string; key: string,fcmToken:string }) {
  const baseUrl = "/login/otp";
  return api.create(`${baseUrl}`, params);
}

//verify otp
function verifyCustomerSession(params:object) {
  const baseUrl = `/login/validateCustomer`;  
  return api.get(`${baseUrl}`,params);
}

export { sendOtp, verifyOtp,verifyCustomerSession };
