import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { ActionTypes } from './constants';
import { zohoVendorApiError, zohoVendorApiResponse } from "./actions";
import { fetchAllZohoVendors, syncZohoVendors } from "../../helpers/api/zohoVendor";

function* fetchZohoVendorsSaga(action: any): SagaIterator {
    try {
        const { page, limit } = action.payload;

        const response = yield call(fetchAllZohoVendors, page, limit);
        const zohoVendorData = yield response.data;

        yield put(zohoVendorApiResponse(ActionTypes.FETCH_ZOHO_VENDOR, zohoVendorData));
    } catch (e: any) {
        yield put(zohoVendorApiError(ActionTypes.FETCH_ZOHO_VENDOR, e));
    }
}

function* synczohoVendorsSaga(): SagaIterator {
    try {
        yield call(syncZohoVendors);
        // Fetch Vendors again after syncing
        yield put({ type: ActionTypes.FETCH_ZOHO_VENDOR, payload: { page: 1, limit: 15 } });
        yield put(zohoVendorApiResponse(ActionTypes.SYNC_ZOHO_VENDORS, {}));
    } catch (error: any) {
        yield put(zohoVendorApiError(ActionTypes.SYNC_ZOHO_VENDORS, error));
    }
}

function* watchZohoVendor() {
    yield takeEvery(ActionTypes.FETCH_ZOHO_VENDOR, fetchZohoVendorsSaga);
    yield takeEvery(ActionTypes.SYNC_ZOHO_VENDORS, synczohoVendorsSaga);
}

export default watchZohoVendor;
