import { EmployeeResponse } from "redux/types/DataTypes";
import { ActionTypes } from './constants';

export type EmployeeActionType = {
    type:
    | ActionTypes.CREATE_EMPLOYEE
    | ActionTypes.FETCH_EMPLOYEE
    | ActionTypes.SET_EMPLOYEE
    | ActionTypes.SET_ERROR
    | ActionTypes.UPDATE_EMPLOYEE
    | ActionTypes.FETCH_SINGLE_EMPLOYE
    | ActionTypes.SET_SINGLE_EMPLOYEE
    | ActionTypes.UPDATE_EMPLOYEE_SUCCESS
    | ActionTypes.UPDATE_EMPLOYEE_FAIL
    | ActionTypes.ADD_EMPLOY_SUCCESS
    | ActionTypes.ADD_EMPLOY_FAIL
    | ActionTypes.FETCH_GROUPLIST
    | ActionTypes.SET_GROUPLIST
    | ActionTypes.DELETE_EMPLOYEE
    | ActionTypes.DELETE_EMPLOYEE_SUCCESS
    | ActionTypes.DELETE_EMPLOYEE_FAIL
    | ActionTypes.FETCH_PROFILE_IMAGE
    | ActionTypes.FETCH_PROFILE_IMAGE_SUCCESS
    | ActionTypes.FETCH_PROFILE_IMAGE_FAIL
    | ActionTypes.SET_EMPLOYEE_STATUS
    | ActionTypes.FETCH_EMPLOYEE_STATUS
    payload: {} | string;
}


//dispatch action to get all employees
export const getEmployee = (data: any): EmployeeActionType => ({
    type: ActionTypes.FETCH_EMPLOYEE,
    payload: { data }
})


//dispatch action to get single employee data
export const getSingleEmployee = (id: any): EmployeeActionType => ({
    type: ActionTypes.FETCH_SINGLE_EMPLOYE,
    payload: { id }
})


//dispatch action to create new employee
export const createEmployee = (data: any): EmployeeActionType => ({
    type: ActionTypes.CREATE_EMPLOYEE,
    payload: { data }
})

//dispatch action to update employee
export const updateEmployee = (data: any): EmployeeActionType => ({
    type: ActionTypes.UPDATE_EMPLOYEE,
    payload: { data }
})

//this will trigger in the saga while receiving response of fetching employee data
export const employeeApiResponse = (actionType: string, data: EmployeeResponse): EmployeeActionType => {
    // type: ActionTypes.SET_EMPLOYEE,
    // payload: { data }
    switch (actionType) {
        case ActionTypes.FETCH_EMPLOYEE:
            return {
                type: ActionTypes.SET_EMPLOYEE,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_EMPLOYE:
            return {
                type: ActionTypes.SET_SINGLE_EMPLOYEE,
                payload: { data }
            }
        case ActionTypes.FETCH_GROUPLIST:
            return {
                type: ActionTypes.SET_GROUPLIST,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

//this action will be executed when employee is updated successfully
export const employeeUpdateResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_EMPLOYEE_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while updating
export const employeUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_EMPLOYEE_FAIL,
        payload: { actionType, error }
    }
}

//this action will be executed when employee is added successfully
export const employeeAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_EMPLOY_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while adding
export const employeeAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_EMPLOY_FAIL,
        payload: { actionType, error }
    }
}

// this action will be executed when there is error while fetching employee info
export const employeeApiError = (actionType: string, error: any): EmployeeActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})

//action to get employees by team
export const getEmployeesByGroup = (data: any): EmployeeActionType => ({
    type: ActionTypes.FETCH_GROUPLIST,
    payload: { data }
})

export const deleteEmployee = (data: any): EmployeeActionType => ({
    type: ActionTypes.DELETE_EMPLOYEE,
    payload: { data }
})

export const deleteEmployeeResponse = (actionType: string, error: any): EmployeeActionType => ({
    type: ActionTypes.DELETE_EMPLOYEE_SUCCESS,
    payload: { actionType, error }
})
export const deleteEmployeeError = (actionType: string, error: any): EmployeeActionType => ({
    type: ActionTypes.DELETE_EMPLOYEE_FAIL,
    payload: { actionType, error }
})

export const getSingleEmployeeImage = (id: any): EmployeeActionType => ({
    type: ActionTypes.FETCH_PROFILE_IMAGE,
    payload: { id }
})

export const getSingleEmployeeImageResponse = (actionType: string, data: any): EmployeeActionType => {
    return {
        type: ActionTypes.FETCH_PROFILE_IMAGE_SUCCESS,
        payload: { actionType, data }
    }
}

export const setEmployeeStatus = (actionType: string, data: any): EmployeeActionType => {
    return {
        type: ActionTypes.SET_EMPLOYEE_STATUS,
        payload: { actionType, data }
    }
}
export const getSingleEmployeeImageError = (actionType: string, error: any): EmployeeActionType => {
    return {
        type: ActionTypes.FETCH_PROFILE_IMAGE_FAIL,
        payload: { actionType, error }
    }
}

export const getEmployeeStatus= (): EmployeeActionType => {
    return {
        type: ActionTypes.FETCH_EMPLOYEE_STATUS,
        payload: { }
    }
}