import { APICore } from "./apiCore";

const api = new APICore();

function fetchChatMessages(id: any) {
  //const baseUrl = `/chat/getChatMessages/${id}`;
  const baseUrl = `/chat/getChatMessages/2`;
  return api.get(`${baseUrl}`, id);
}

function fetchChats(params: { page: string, limit: string, sortBy: string, sort: string, userId?: string, userType?: string}) {
  const baseUrl = `/chat/chatList?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&userId=${params.userId}&userType=${params.userType}`;
  return api.get(`${baseUrl}`, "");
}

function fetchCountChats(params: { user_id: number }) {
  const baseUrl = `/chat/count?user_id=${params.user_id}`;
  return api.get(`${baseUrl}`, "");
}

export { fetchChatMessages, fetchChats, fetchCountChats};
