import { APICore } from "./apiCore";
 
const api = new APICore();
//
function fetchNotification(params: { userId: any, userType: any, typeId?: string, limit?: any }) {
    const url = `/notification/notif/all?user_id=${params.userId}&user_type=${params.userType}&type_id=${params.typeId ? params.typeId : ''}&limit=${params.limit ? params.limit : ''}`
    return api.get(`${url}`, "");
}
 
function updateNotification(params: { userId: string, id: string }) {
    const url = `/notification/update/status?user_id=${params.userId}&id=${params.id}`
    return api.create(`${url}`, "");
}
function updateAllNotification(params: { userId: string }) {
    const url = `/notification/update/allstatus?user_id=${params.userId}`
    return api.update(`${url}`, "");
}
function sendEmail(params: { subject: string, text: string, to: string, template?: any, cc?: string, attachments?: File[] }) {
    const url = `/notification/send-email`;
    const formData = new FormData();
   
    formData.append("subject", params.subject);
    formData.append("text", params.text);
    formData.append("to", params.to);
    if (params.template) formData.append("template", params.template);
    if (params.cc) formData.append("cc", params.cc);
   
    if (params.attachments && params.attachments.length > 0) {
        params.attachments.forEach((file, index) => {
            formData.append(`attachments`, file);
        });
    }
 
    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
    }
 
    return api.create(url, formData);
}
 
export { fetchNotification, updateNotification, updateAllNotification, sendEmail };