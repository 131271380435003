import { APICore } from "./apiCore";
const api = new APICore();

async function createClaim(data: any) {
    const baseUrl = `/claim/`
    const createdClaim = await api.create(`${baseUrl}`, { ...data })
    const claimResponse = createdClaim.data;
    console.log("claim response", claimResponse)
    console.log("cdjhbhjb", data)
    data.claimData?.expenses?.forEach(async (item: any) => {
        await updateExpenseWithFile({id:claimResponse?.data?.newClaim?.id, expenseData:item, imageData:item.files})
    });
    return claimResponse;
}

function fetchClaims(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string, status?: string, origin?: string) {
    const baseURL = `/claim/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    if (status) params.append('status', status);
    if (origin) params.append('origin', origin);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchClaimById(id: number) {
    const url = `/claim/${id}`;
    return api.get(url, "");
}

function editClaim(id: number, data: any) {
    const url = `/claim/${id}`;
    return api.update(url, data);
}

function fetchExpenseTypes() {
    let baseUrl = `/application/metadata?id=12`;
    return api.get(`${baseUrl}`, "");
}

async function updateExpenseWithFile(params: { id: number, expenseData: any, imageData?:any}) {
    try {

        const baseUrl = `/claim/claimExpense/${params.id}`;

        const expense = await api.update(baseUrl, {
            ...params.expenseData,
        });
        console.log("dchjbb", expense)
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        let uploadResponse = null;

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'Expense');
            params.imageData.append('typeId', String(expense?.data?.data?.id));
            params.imageData.append('tagId', "75");

            const newImageFile = await api.create(fileUrl, params.imageData);
            uploadResponse = newImageFile?.data?.data?.response;

            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
            
            const combinedImageIds = [
                ...(params.expenseData.image_id || []),
                ...newImageDataResponse
            ];
    
            const updatedExpense = await api.update(baseUrl, {
                ...expense?.data?.data,
                image_id: combinedImageIds.filter((img: any) => img !== null && img !== undefined)
            });
            
            console.log(updatedExpense, "expense");
            return {
                expense: updatedExpense,
                uploadResponse
            };
        }


        console.log(expense, "expense");
        return {
            expense,
            uploadResponse: null
        };
    } catch (error) {
        console.error('Error updating indent with files:', error);
        throw error;
    }
}


export { createClaim, fetchClaimById, fetchClaims, editClaim, fetchExpenseTypes, updateExpenseWithFile };
