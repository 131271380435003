import { ClaimActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  claims: [],
  singleClaim: {},
  error: {},
  success: false,
};

const ClaimReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ClaimActionTypes.CREATE_CLAIM_REQUEST:
    case ClaimActionTypes.EDIT_CLAIM_REQUEST:
    case ClaimActionTypes.FETCH_CLAIMS_REQUEST:
    case ClaimActionTypes.FETCH_CLAIM_BY_ID_REQUEST:
    case ClaimActionTypes.RESET_CLAIM_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case ClaimActionTypes.SET_SINGLE_CLAIM:
    return { ...state , singleClaim: action.payload.data, loading:false }  
    case ClaimActionTypes.CREATE_CLAIM_SUCCESS:
      return {
        ...state,
        claims: [...state.claims, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case ClaimActionTypes.EDIT_CLAIM_SUCCESS:
      const updatedClaims = state.claims.map((claim: any) =>
        claim.id === action.payload.data.id ? action.payload.data : claim
      );
      return {
        ...state,
        claims: updatedClaims,
        loading: false,
        error: {},
        success: true,
      };

    case ClaimActionTypes.FETCH_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case ClaimActionTypes.FETCH_CLAIM_BY_ID_SUCCESS:
      return {
        ...state,
        singleClaim: action.payload.data,
        claims: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case ClaimActionTypes.RESET_SINGLE_CLAIM:
      return {
          ...state,
          singleClaim: null,
      };

    case ClaimActionTypes.CREATE_CLAIM_FAILURE:
    case ClaimActionTypes.EDIT_CLAIM_FAILURE:
    case ClaimActionTypes.FETCH_CLAIMS_FAILURE:
    case ClaimActionTypes.FETCH_CLAIM_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default ClaimReducer;
