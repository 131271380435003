import { APICore } from "./apiCore";

const api = new APICore();

function fetchProduct(params: { page: string, limit: string, sortBy: string, sort: string, searchBy?: string }) {
  const baseUrl = `/product?disabled=false&page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&searchBy=${params.searchBy ? params.searchBy : ''}`;
  return api.get(`${baseUrl}`, "");
}

function fetchSingleProduct(id: any) {
  const baseUrl = `/product/${id}`;
  return api.get(`${baseUrl}`, id);
}

function createProduct(body: any) {
  const baseUrl = "/product";
  return api.create(`${baseUrl}`, body);
}

function updateProduct(body: any) {
  const baseUrl = `/product/${body.id}`;
  return api.update(`${baseUrl}`, body);
}

function getProductSkuWise(params: { limit: string, filterBy?: any, filter?: any, type?: any }) {
  const baseUrl = `/product/sku/wise?limit=${params.limit}&filterBy=${params.filterBy}&filter=${params.filter}&type=${params.type}`;
  return api.get(`${baseUrl}`, "");
}

function deleteProduct(params: any) {
  const baseUrl = `/product/${params.id}`;
  return api.delete(`${baseUrl}`);
}

function fetchProductSkuList(params: any) {
  const baseUrl = `/product/sku`;
  return api.get(`${baseUrl}`, params);
}

function getSkuFiledsDetails(params: any) {
  const baseUrl = `/product/sku/skuValue`;
  return api.get(`${baseUrl}`, params);
}

export { fetchProductSkuList, fetchProduct, fetchSingleProduct, createProduct, updateProduct, getProductSkuWise, deleteProduct, getSkuFiledsDetails };
