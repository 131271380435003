/* eslint-disable */
import { editDealsTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  deals: [],            // Loading will be true initially while fetching the deal
  singleDeal: {},           // Holds the data of the deal being edited
  error: null,              // Error to capture any failures
  success: false,           // Tracks if the edit operation was successful
};

const DealReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    // When fetching or editing a deal, set loading state
    case editDealsTypes.FETCH_DEALS_REQUEST:
    case editDealsTypes.EDIT_DEAL_REQUEST:
    case editDealsTypes.FETCH_DEAL_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,       // Set loading to true while waiting for the API call
        error: null,         // Clear previous errors
        success: false       // Reset success flag before starting a new operation
      };

    // When successfully fetching the deal by ID
    case editDealsTypes.FETCH_DEAL_BY_ID_SUCCESS:
      return {
        ...state,
        singleDeal: action.payload.data, // Set the deal data
        loading: false,                  // Stop loading
        error: null,                     // Clear any previous errors
        success: true,                   // Set success to true
      };

    // When successfully editing the deal
    case editDealsTypes.EDIT_DEAL_SUCCESS:
      const updateddeals = state.deals.map((deal: any) =>
        deal.id === action.payload.data.id ? action.payload.data : deal
      );
      return {
        ...state,
        deals: updateddeals,
        singleDeal: action.payload.data, // Update the deal with the new data
        loading: false,                  // Stop loading
        error: null,                     // Clear any previous errors
        success: true,                   // Indicate the edit was successful
      };

    case editDealsTypes.FETCH_DEALS_SUCCESS:
      return {
        ...state,
        deals: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    // Handle failures for fetching or editing the deal
    case editDealsTypes.EDIT_DEAL_FAILURE:
    case editDealsTypes.FETCH_DEAL_BY_ID_FAILURE:
    case editDealsTypes.FETCH_DEALS_SUCCESS:
      return {
        ...state,
        error: action.payload.error,     // Capture the error
        loading: false,                  // Stop loading on failure
        success: false                   // Ensure success is false on failure
      };

    // Default case for unhandled actions
    default:
      return state;
  }
};

export default DealReducer;
