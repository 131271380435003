export enum GRNActionTypes {
    CREATE_GRN_REQUEST = 'CREATE_GRN_REQUEST',
    CREATE_GRN_SUCCESS = 'CREATE_GRN_SUCCESS',
    CREATE_GRN_FAILURE = 'CREATE_GRN_FAILURE',
    FETCH_GRNS_REQUEST = 'FETCH_GRNS_REQUEST',
    FETCH_GRNS_SUCCESS = 'FETCH_GRNS_SUCCESS',
    FETCH_GRNS_FAILURE = 'FETCH_GRNS_FAILURE',
    FETCH_GRN_BY_ID_REQUEST = 'FETCH_GRN_BY_ID_REQUEST',
    FETCH_GRN_BY_ID_SUCCESS = 'FETCH_GRN_BY_ID_SUCCESS',
    FETCH_GRN_BY_ID_FAILURE = 'FETCH_GRN_BY_ID_FAILURE',
    EDIT_GRN_REQUEST = 'EDIT_GRN_REQUEST',
    EDIT_GRN_SUCCESS = 'EDIT_GRN_SUCCESS',
    EDIT_GRN_FAILURE = 'EDIT_GRN_FAILURE',
    RESET_GRN_SUCCESS_STATE = 'RESET_GRN_SUCCESS_STATE',
    FETCH_SINGLE_GRN = 'FETCH_SINGLE_GRN',
    SET_SINGLE_GRN = 'SET_SINGLE_GRN',
    RESET_SINGLE_GRN = 'RESET_SINGLE_GRN',
    FETCH_SINGLE_PROJECT_GRN = 'FETCH_SINGLE_PROJECT_GRN',
    SET_SINGLE_PROJECT_GRN = 'SET_SINGLE_PROJECT_GRN',
    RESET_SINGLE_PROJECT_GRN = 'RESET_SINGLE_PROJECT_GRN',
    FETCH_GRN_BY_PROJECT_ID_REQUEST = 'FETCH_GRN_BY_PROJECT_ID_REQUEST',
    FETCH_GRN_BY_PROJECT_ID_SUCCESS = 'FETCH_GRN_BY_PROJECT_ID_SUCCESS',
    FETCH_GRN_BY_PROJECT_ID_FAILURE = 'FETCH_GRN_BY_PROJECT_ID_FAILURE',
}
