export enum ActionTypes {
  FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS",
  SET_NOTIFICATIONS = "SET_NOTIFICATIONS",
  SET_NOTIF_ERROR = "SET_NOTIF_ERROR",
  FETCH_ALL_NOTIFICATIONS = "FETCH_ALL_NOTIFICATION",
  SET_ALL_NOTIFICATIONS = "SET_ALL_NOTIFICATION",
  UPDATE_NOTIFICATION_STATUS = "UPDATE_NOTIFICATION_STATUS",
  UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL",
  READ_ALL_NOTIFICATIONS = "READ_ALL_NOTIFICATIONS",
  READ_ALL_NOTIFICATIONS_SUCCESS = "READ_ALL_NOTIFICATIONS_SUCCESS",
  READ_ALL_NOTIFICATIONS_FAIL = "READ_ALL_NOTIFICATIONS_FAIL",
}
