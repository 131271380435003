import { ChatResponse } from 'redux/types/DataTypes';
import { ActionTypes } from './constants';

export type ChatActionType = {
    type:
    | ActionTypes.FETCH_CHAT
    | ActionTypes.FETCH_CHAT_COUNT
    | ActionTypes.SET_ERROR
    | ActionTypes.SET_CHAT
    payload: {} | string;
}

//disptach action to get all chats
export const getChat = (data: any): ChatActionType => ({
    type: ActionTypes.FETCH_CHAT,
    payload: { data }
})

//disptach action to get all chats count
export const countChats = (data: any): ChatActionType => ({
    type: ActionTypes.FETCH_CHAT_COUNT,
    payload: { data }
})

//this action will be executed to handle api response
export const chatApiResponse = (
    actionType: string,
    data: ChatResponse
) => {
    switch (actionType) {
        case ActionTypes.FETCH_CHAT:
            return {
                type: ActionTypes.SET_CHAT,
                payload: { data }
            }
        case ActionTypes.FETCH_CHAT_COUNT:
            return {
                type: ActionTypes.SET_CHAT_COUNT,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

// this action will be executed when there is error while adding chat
export const chatApiError = (actionType: string, error: any): ChatActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})