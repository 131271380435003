import { APICore } from "./apiCore";
const api = new APICore();

function createItemCategory(data: any) {
    const baseUrl = `/item-category/`
    return api.create(`${baseUrl}`, { ...data })
}

function fetchItemCategories(page?: any, limit?: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/item-category/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchCategoriesExcludeParentCategories(id: any) {
    const url = `/item-category/exclude-parent-category/${id}`;
    return api.get(url, "");
}

function fetchItemCategoryById(id: number) {
    const url = `/item-category/${id}`;
    return api.get(url, "");
}

function editItemCategory(id: number, data: any) {
    const url = `/item-category/${id}`;
    return api.update(url, data);
}

function fetchItemCategoryBomById(id: number) {
    const url = `/bom/item-category/${id}`;
    return api.get(url, "");
}

function saveItemCategoryBom(data: any) {
    const baseUrl = `/bom/item-category`
    return api.create(`${baseUrl}`, { ...data })
}

function fetchItemCategoryBomProcesses(id: number) {
    const url = `/bom/item-category/bom-processes/${id}`;
    return api.get(url, "");
}

export { createItemCategory, fetchItemCategoryById, fetchItemCategories, editItemCategory, fetchItemCategoryBomById, saveItemCategoryBom, fetchItemCategoryBomProcesses, fetchCategoriesExcludeParentCategories};
