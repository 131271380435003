export enum ActionTypes {
    SET_ORGANIZATION = "SET_ORGANIZATION",
    SET_ERROR = "SET_ERROR",
    CREATE_ORGANIZATION = "CREATE_ORGANIZATION",
    FETCH_ORGANIZATION = "FETCH_ORGANIZATION",
    UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",
    FETCH_SINGLE_ORGANIZATION = "FETCH_SINGLE_ORGANIZATION",
    SET_SINGLE_ORGANIZATION = "SET_SINGLE_ORGANIZATION",
    UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS",
    UPDATE_ORGANIZATION_FAIL = "UPDATE_ORGANIZATION_FAIL",
    ADD_ORGANIZATION_SUCCESS = "ADD_ORGANIZATION_SUCCESS",
    ADD_ORGANIZATION_FAIL = "ADD_ORGANIZATION_FAIL",
    DASHBOARD_ORGANIZATION_DATA = "DASHBOARD_ORGANIZATION_DATA",
    SET_ORGANIZATION_DASHBOARD_DATA = "SET_ORGANIZATION_DASHBOARD_DATA",
    DELETE_ORGANIZATION = "DELETE_ORGANIZATION",
    DELETE_ORGANIZATION_FAIL = "DELETE_ORGANIZATION_FAIL",
    DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS",
    FETCH_ORGANIZATION_LOCATION="FETCH_ORGANIZATION_LOCATION",
    SET_ORGANIZATION_LOCATION="SET_ORGANIZATION_LOCATION",

    CREATE_ORGANIZATION_WITH_CUSTOMER = "CREATE_ORGANIZATION_WITH_CUSTOMER",
    CREATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS = "CREATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS",
    CREATE_ORGANIZATION_WITH_CUSTOMER_FAIL = "CREATE_ORGANIZATION_WITH_CUSTOMER_FAIL",
    UPDATE_ORGANIZATION_WITH_CUSTOMER = "UPDATE_ORGANIZATION_WITH_CUSTOMER",
    UPDATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS = "UPDATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS",
    UPDATE_ORGANIZATION_WITH_CUSTOMER_FAIL = "UPDATE_ORGANIZATION_WITH_CUSTOMER_FAIL",


    RESET_ORG_STATE = "RESET_ORG_STATE",


}


