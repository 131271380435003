import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    customer: {},
    singleCustomer: {},
    error: {},
    success: false,
    newCustomer: {},
    customerCount: {},
    location: {},
    customerDeleteSuccess: false,
    customerDeleteByPhoneSuccess: false,
}

type CustomerActionType = {
    type:
    | AuthActionTypes.SET_CUSTOMER
    | AuthActionTypes.CREATE_CUSTOMER
    | AuthActionTypes.FETCH_CUSTOMER
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_CUSTOMER
    | AuthActionTypes.SET_SINGLE_CUSTOMER
    | AuthActionTypes.ADD_CUSTOMER_SUCCESS
    | AuthActionTypes.ADD_CUSTOMER_FAIL
    | AuthActionTypes.UPDATE_CUSTOMER_SUCCESS
    | AuthActionTypes.UPDATE_CUSTOMER_FAIL
    | AuthActionTypes.SET_CUSTOMER_DASHBOARD_DATA
    | AuthActionTypes.DELETE_CUSTOMER_SUCCESS
    | AuthActionTypes.DELETE_CUSTOMER_FAIL
    | AuthActionTypes.DELETE_CUSTOMER_SUCCESS_BY_PHONE
    | AuthActionTypes.DELETE_CUSTOMER_FAIL_BY_PHONE
    | AuthActionTypes.SET_CUSTOMER_LOCATION
    | AuthActionTypes.RESET_CUSTOMER
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}


const CustomerReducer = (state: any = INIT_STATE, action: CustomerActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_CUSTOMER:
            console.log(action.payload.data);
            return { ...state, customer: action.payload.data, singleCustomer: {}, loading: false, success: false, error: {}, customerDeleteSuccess: false, customerDeleteByPhoneSuccess: false }
        case AuthActionTypes.SET_SINGLE_CUSTOMER:
            console.log(action.payload.data);
            return { ...state, singleCustomer: action.payload.data, loading: false, success: false, error: {} }
        case AuthActionTypes.SET_CUSTOMER_DASHBOARD_DATA:
            return { ...state, customerCount: action.payload.data, loading: false, success: false, error: {} }
        case AuthActionTypes.ADD_CUSTOMER_SUCCESS:
            return { ...state, success: true, error: {}, newCustomer: action.payload.data }
        case AuthActionTypes.ADD_CUSTOMER_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_CUSTOMER_SUCCESS:
            return { ...state, success: true, error: {} }
        case AuthActionTypes.UPDATE_CUSTOMER_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.SET_ERROR:
            return { ...state, customer: {}, singleCustomer: {}, error: action.payload.error }
        case AuthActionTypes.DELETE_CUSTOMER_SUCCESS:
            return { ...state, customerDeleteSuccess: true, error: {} }
        case AuthActionTypes.DELETE_CUSTOMER_FAIL:
            return { ...state, customerDeleteSuccess: false, error: action.payload.error, }
        case AuthActionTypes.DELETE_CUSTOMER_SUCCESS_BY_PHONE:
            return { ...state, customerDeleteByPhoneSuccess: true, error: {} }
        case AuthActionTypes.DELETE_CUSTOMER_FAIL_BY_PHONE:
            return { ...state, customerDeleteByPhoneSuccess: false, error: action.payload.error, }
        case AuthActionTypes.SET_CUSTOMER_LOCATION:
            return { ...state, loading: false, success: false, location: action.payload.data };
        case AuthActionTypes.RESET_CUSTOMER:
            return { ...INIT_STATE }
        default:
            return { ...state }
    }
}

export default CustomerReducer;