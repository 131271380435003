import { HandoverActionTypes as ActionTypes } from './constants';

export const createHandoverRequest = (data: any) => ({
  type: ActionTypes.CREATE_HANDOVER_REQUEST,
  payload: { data },
});

export const resetHandoverSuccessState = () => ({
  type: ActionTypes.RESET_HANDOVER_SUCCESS_STATE,
});

export const createHandoverSuccess = (response: any) => ({
  type: ActionTypes.CREATE_HANDOVER_SUCCESS,
  payload: response,
});

export const createHandoverFailure = (error: any) => ({
  type: ActionTypes.CREATE_HANDOVER_FAILURE,
  payload: error,
});

export const resetSingleHandover = () => ({
  type: ActionTypes.RESET_SINGLE_HANDOVER,
});

export const fetchHandoversRequest = (
  page: number,
  limit: number,
  sortBy: string,
  sort: string,
  searchBy: string
) => ({
  type: ActionTypes.FETCH_HANDOVERS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchHandoversSuccess = (response: any) => ({
  type: ActionTypes.FETCH_HANDOVERS_SUCCESS,
  payload: response,
});

export const fetchHandoversFailure = (error: any) => ({
  type: ActionTypes.FETCH_HANDOVERS_FAILURE,
  payload: error,
});

export const fetchHandoverByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_HANDOVER_BY_ID_REQUEST,
  payload: id,
});

export const fetchHandoverByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_HANDOVER_BY_ID_SUCCESS,
  payload: response,
});

export const fetchHandoverByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_HANDOVER_BY_ID_FAILURE,
  payload: error,
});

export const editHandoverRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_HANDOVER_REQUEST,
  payload: { id, data },
});

export const editHandoverSuccess = (response: any) => ({
  type: ActionTypes.EDIT_HANDOVER_SUCCESS,
  payload: response,
});

export const editHandoverFailure = (error: any) => ({
  type: ActionTypes.EDIT_HANDOVER_FAILURE,
  payload: error,
});
