import { APICore } from "./apiCore";

const api = new APICore();

function fetchEnquiry(params: { filter: any, page: string, limit: string, sortBy: string, sort: string, filterBy?: any, searchBy?: string, organizationId?:number }) {
  const baseUrl = `/enquiry?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&userId=${params.filter.user_id}&userType=${params.filter.type}&filterBy=${params.filterBy}&searchBy=${params.searchBy ? params.searchBy : ''}&organizationId=${params.organizationId ? params.organizationId : 0}`;
  return api.get(`${baseUrl}`, "");
}

function fetchEnquiryForDasbord(params: { filterBy?: any, filter?: any }) {
  const baseUrl = `/enquiry/dashboard/data?filterBy=${params.filterBy}&filter=${params.filter}`;
  return api.get(`${baseUrl}`, "");
}

function fetchEnquiryLoacation(){
  const baseUrl = `/enquiry/location`;
  return api.create(`${baseUrl}`, "");
}

function fetchSku(param:any){
  const baseUrl = `/enquiry/sku`;
  return api.get(`${baseUrl}`,param);
}


function fetchSingleEnquiry(id: any) {
  const baseUrl = `/enquiry/${id}`;
  return api.get(`${baseUrl}`, id);
}

function createEnquiry(body: any) {
  const baseUrl = "/enquiry";
  return api.create(`${baseUrl}`, body);
}

function createEnquiryForm(enquiryForm: any) {
  const baseUrl = "/enquiry/form";
  return api.create(`${baseUrl}`, enquiryForm);
}
function getEnquiryForm(enquiryForm: any) {
  const baseUrl = `/enquiry/form`
  return api.get(`${baseUrl}`, enquiryForm);
}

function updateEnquiry(body: any) {
  const baseUrl = `/enquiry/${body.id}`;
  return api.update(`${baseUrl}`, body);
}

function fetchEnquiryStatus() {
  const baseUrl = `/enquiry/status`;
  return api.get(`${baseUrl}`, {});
}

function fetchRevenueChartData(params: { filterBy?: any, filter?: any }) {
  const baseUrl = `/enquiry/revenue/data?filterBy=${params.filterBy}&filter=${params.filter}`
  return api.get(`${baseUrl}`, "");
}

function deleteEnquiry(params: any) {
  const baseUrl = `/enquiry/${params.id}`;
  return api.delete(`${baseUrl}`);
}



export { fetchEnquiry, fetchSingleEnquiry, createEnquiry, updateEnquiry, createEnquiryForm, fetchEnquiryStatus, getEnquiryForm, fetchRevenueChartData, fetchEnquiryForDasbord, deleteEnquiry,fetchEnquiryLoacation,fetchSku };
