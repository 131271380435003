import { IQCQualityActionTypes as ActionTypes } from './constant';

export const createIQCQualityRequest = (data: any) => ({
  type: ActionTypes.CREATE_IQCQUALITY_REQUEST,
  payload: {data},
});

export const resetIQCQualitySuccessState = () => ({
  type: ActionTypes.RESET_IQCQUALITY_SUCCESS_STATE,
});

export const createIQCQualitySuccess = (response: any) => ({
  type: ActionTypes.CREATE_IQCQUALITY_SUCCESS,
  payload: response,
});

export const createIQCQualityFailure = (error: any) => ({
  type: ActionTypes.CREATE_IQCQUALITY_FAILURE,
  payload: error,
});

export const fetchIQCQualitysRequest = (page?: number, limit?: number, sortBy?: string,
  sort?: string,
  searchBy?: string) => ({
  
  type: ActionTypes.FETCH_IQCQUALITYS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchIQCQualitysSuccess = (response: any) => ({
  type: ActionTypes.FETCH_IQCQUALITYS_SUCCESS,
  payload: response,
});

export const fetchIQCQualitysFailure = (error: any) => ({
  type: ActionTypes.FETCH_IQCQUALITYS_FAILURE,
  payload: error,
});

export const fetchIQCQualityByIdRequest = (id: number) => {
  console.log("Action Creator: fetchIQCQualityByIdRequest called with id:", id); // Debug log
  return{
  type: ActionTypes.FETCH_IQCQUALITY_BY_ID_REQUEST,
  payload: id,
  }};

export const fetchIQCQualityByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_IQCQUALITY_BY_ID_SUCCESS,
  payload: response,
});

export const fetchIQCQualityByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_IQCQUALITY_BY_ID_FAILURE,
  payload: error,
});

export const editIQCQualityRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_IQCQUALITY_REQUEST,
  payload: { id, data },
});

export const editIQCQualitySuccess = (response: any) => ({
  type: ActionTypes.EDIT_IQCQUALITY_SUCCESS,
  payload: response,
});

export const editIQCQualityFailure = (error: any) => ({
  type: ActionTypes.EDIT_IQCQUALITY_FAILURE,
  payload: error,
});

export const resetSingleIQCQuality = () => ({
  type: ActionTypes.RESET_SINGLE_IQCQUALITY,
});

export const iqcQualityApiResponse = (actionType: string, data: any) => {
  console.log(actionType, "herre")

  switch (actionType) {
    case ActionTypes.FETCH_IQCQUALITYS_SUCCESS:
    case ActionTypes.CREATE_IQCQUALITY_SUCCESS:
    case ActionTypes.EDIT_IQCQUALITY_SUCCESS:
      return {
        type: actionType,
        payload: data,
      };
    case ActionTypes.FETCH_IQCQUALITY_BY_ID_SUCCESS:
      console.log("shubhi hii")
      return {
        type: ActionTypes.SET_SINGLE_IQCQUALITY,
        payload: data
      }

    case ActionTypes.FETCH_IQCQUALITYS_FAILURE:
    case ActionTypes.FETCH_IQCQUALITY_BY_ID_FAILURE:
    case ActionTypes.CREATE_IQCQUALITY_FAILURE:
    case ActionTypes.EDIT_IQCQUALITY_FAILURE:
      return {
        type: actionType,
        payload: { error: "Error while setting data to store!" },
      };
    default:
      return {
        type: ActionTypes.FETCH_IQCQUALITYS_FAILURE,
        payload: { actionType, error: "Unknown action type" },
      };
  }
};

export const iqcQualityApiError = (actionType: string, error: any) => {
  console.log(actionType, "herre")
  switch (actionType) {
    case ActionTypes.CREATE_IQCQUALITY_REQUEST:
    case ActionTypes.EDIT_IQCQUALITY_REQUEST:
    case ActionTypes.FETCH_IQCQUALITYS_REQUEST:
    case ActionTypes.FETCH_IQCQUALITY_BY_ID_REQUEST:
      return {
        type: ActionTypes.CREATE_IQCQUALITY_FAILURE,
        payload: { error }
      };
    default:
      return {
        type: ActionTypes.CREATE_IQCQUALITY_FAILURE,
        payload: { error: "Error while setting data to store!" }
      };
  }
};
