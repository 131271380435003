import { HandoverActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  handovers: [],
  singleHandover: {},
  error: {},
  success: false,
};

const HandoverReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case HandoverActionTypes.CREATE_HANDOVER_REQUEST:
    case HandoverActionTypes.EDIT_HANDOVER_REQUEST:
    case HandoverActionTypes.FETCH_HANDOVERS_REQUEST:
    case HandoverActionTypes.FETCH_HANDOVER_BY_ID_REQUEST:
    case HandoverActionTypes.RESET_HANDOVER_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case HandoverActionTypes.SET_SINGLE_HANDOVER:
      return { ...state, singleHandover: action.payload.data, loading: false };

    case HandoverActionTypes.CREATE_HANDOVER_SUCCESS:
      return {
        ...state,
        handovers: [...state.handovers, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case HandoverActionTypes.EDIT_HANDOVER_SUCCESS:
      const updatedHandovers = state.handovers.map((handover: any) =>
        handover.id === action.payload.data.id ? action.payload.data : handover
      );
      return {
        ...state,
        handovers: updatedHandovers,
        loading: false,
        error: {},
        success: true,
      };

    case HandoverActionTypes.FETCH_HANDOVERS_SUCCESS:
      return {
        ...state,
        handovers: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case HandoverActionTypes.FETCH_HANDOVER_BY_ID_SUCCESS:
      return {
        ...state,
        singleHandover: action.payload.data,
        handovers: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case HandoverActionTypes.RESET_SINGLE_HANDOVER:
      return {
        ...state,
        singleHandover: null,
      };

    case HandoverActionTypes.CREATE_HANDOVER_FAILURE:
    case HandoverActionTypes.EDIT_HANDOVER_FAILURE:
    case HandoverActionTypes.FETCH_HANDOVERS_FAILURE:
    case HandoverActionTypes.FETCH_HANDOVER_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default HandoverReducer;
