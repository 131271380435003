import { DPRActionTypes as ActionTypes } from './constants';

export const createDPRRequest = (data: any) => ({
  type: ActionTypes.CREATE_DPR_REQUEST,
  payload: { data },
});

export const resetDPRSuccessState = () => ({
  type: ActionTypes.RESET_DPR_SUCCESS_STATE,
});

export const createDPRSuccess = (response: any) => ({
  type: ActionTypes.CREATE_DPR_SUCCESS,
  payload: response,
});

export const createDPRFailure = (error: any) => ({
  type: ActionTypes.CREATE_DPR_FAILURE,
  payload: error,
});

export const resetSingleDPR = () => ({
  type: ActionTypes.RESET_SINGLE_DPR,
});

export const fetchDPRsRequest = (page: number, limit: number, sortBy: string, sort: string, searchBy: string) => ({
  type: ActionTypes.FETCH_DPRS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchDPRsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_DPRS_SUCCESS,
  payload: response,
});

export const fetchDPRsFailure = (error: any) => ({
  type: ActionTypes.FETCH_DPRS_FAILURE,
  payload: error,
});
export const fetchDPRsUsingProjectIdRequest = (projectId: any,page: number, limit: number, sortBy: string, sort: string, searchBy: string) => ({
  type: ActionTypes.FETCH_DPRS_USING_PROJECTID_REQUEST,
  payload: {projectId, page, limit, sortBy, sort, searchBy },
});

export const fetchDPRsUsingProjectIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_DPRS_USING_PROJECTID_SUCCESS,
  payload: response,
});

export const fetchDPRsUsingProjectIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_DPRS_USING_PROJECTID_FAILURE,
  payload: error,
});

export const fetchDPRByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_DPR_BY_ID_REQUEST,
  payload: id,
});

export const fetchDPRByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_DPR_BY_ID_SUCCESS,
  payload: response,
});

export const fetchDPRByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_DPR_BY_ID_FAILURE,
  payload: error,
});

export const editDPRRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_DPR_REQUEST,
  payload: { id, data },
});

export const editDPRSuccess = (response: any) => ({
  type: ActionTypes.EDIT_DPR_SUCCESS,
  payload: response,
});

export const editDPRFailure = (error: any) => ({
  type: ActionTypes.EDIT_DPR_FAILURE,
  payload: error,
});
