import { ExpenseActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  indirectExpenses: [],
  singleIndirectExpense: {},
  error: {},
  success: false,
};

const ExpenseReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ExpenseActionTypes.CREATE_INDIRECT_EXPENSE_REQUEST:
    case ExpenseActionTypes.EDIT_INDIRECT_EXPENSE_REQUEST:
    case ExpenseActionTypes.FETCH_INDIRECT_EXPENSES_REQUEST:
    case ExpenseActionTypes.FETCH_INDIRECT_EXPENSE_BY_ID_REQUEST:
    case ExpenseActionTypes.RESET_INDIRECT_EXPENSE_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case ExpenseActionTypes.SET_SINGLE_INDIRECT_EXPENSE:
    return { ...state , singleIndirectExpense: action.payload.data, loading:false }  
    case ExpenseActionTypes.CREATE_INDIRECT_EXPENSE_SUCCESS:
      return {
        ...state,
        indirectExpenses: [...state.indirectExpenses, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case ExpenseActionTypes.EDIT_INDIRECT_EXPENSE_SUCCESS:
      const updatedIndirectExpenses = state.indirectExpenses.map((indirectExpense: any) =>
        indirectExpense.id === action.payload.data.id ? action.payload.data : indirectExpense
      );
      return {
        ...state,
        indirectExpenses: updatedIndirectExpenses,
        loading: false,
        error: {},
        success: true,
      };

    case ExpenseActionTypes.FETCH_INDIRECT_EXPENSES_SUCCESS:
      return {
        ...state,
        indirectExpenses: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case ExpenseActionTypes.FETCH_INDIRECT_EXPENSE_BY_ID_SUCCESS:
      return {
        ...state,
        singleIndirectExpense: action.payload.data,
        indirectExpenses: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case ExpenseActionTypes.RESET_SINGLE_INDIRECT_EXPENSE:
      return {
          ...state,
          singleIndirectExpense: null,
      };

    case ExpenseActionTypes.CREATE_INDIRECT_EXPENSE_FAILURE:
    case ExpenseActionTypes.EDIT_INDIRECT_EXPENSE_FAILURE:
    case ExpenseActionTypes.FETCH_INDIRECT_EXPENSES_FAILURE:
    case ExpenseActionTypes.FETCH_INDIRECT_EXPENSE_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default ExpenseReducer;
