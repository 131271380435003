export enum IQCParameterActionTypes {
    CREATE_IQCPARAMETER_REQUEST = 'CREATE_IQCPARAMETER_REQUEST',
    CREATE_IQCPARAMETER_SUCCESS = 'CREATE_IQCPARAMETER_SUCCESS',
    CREATE_IQCPARAMETER_FAILURE = 'CREATE_IQCPARAMETER_FAILURE',
    FETCH_IQCPARAMETERS_REQUEST = 'FETCH_IQCPARAMETERS_REQUEST',
    FETCH_IQCPARAMETERS_SUCCESS = 'FETCH_IQCPARAMETERS_SUCCESS',
    FETCH_IQCPARAMETERS_FAILURE = 'FETCH_IQCPARAMETERS_FAILURE',
    FETCH_IQCPARAMETER_BY_ID_REQUEST = 'FETCH_IQCPARAMETER_BY_ID_REQUEST',
    FETCH_IQCPARAMETER_BY_ID_SUCCESS = 'FETCH_IQCPARAMETER_BY_ID_SUCCESS',
    FETCH_IQCPARAMETER_BY_ID_FAILURE = 'FETCH_IQCPARAMETER_BY_ID_FAILURE',
    EDIT_IQCPARAMETER_REQUEST = 'EDIT_IQCPARAMETER_REQUEST',
    EDIT_IQCPARAMETER_SUCCESS = 'EDIT_IQCPARAMETER_SUCCESS',
    EDIT_IQCPARAMETER_FAILURE = 'EDIT_IQCPARAMETER_FAILURE',
    RESET_IQCPARAMETER_SUCCESS_STATE = 'RESET_IQCPARAMETER_SUCCESS_STATE',
    FETCH_SINGLE_IQCPARAMETER = 'FETCH_SINGLE_IQCPARAMETER',
    SET_SINGLE_IQCPARAMETER = 'SET_SINGLE_IQCPARAMETER',
    RESET_SINGLE_IQCPARAMETER = 'RESET_SINGLE_IQCPARAMETER'
  }
  