import { FabDrawingActionTypes as ActionTypes } from './constants';

export const createFabDrawingRequest = (data: any) => ({
  type: ActionTypes.CREATE_FabDrawing_REQUEST,
  payload: {data},
});

export const resetFabDrawingSuccessState = () => ({
  type: ActionTypes.RESET_FabDrawing_SUCCESS_STATE,
});

export const createFabDrawingSuccess = (response: any) => ({
  type: ActionTypes.CREATE_FabDrawing_SUCCESS,
  payload: response,
});

export const createFabDrawingFailure = (error: any) => ({
  type: ActionTypes.CREATE_FabDrawing_FAILURE,
  payload: error,
});

export const resetSingleFabDrawing = () => ({
  type: ActionTypes.RESET_SINGLE_FabDrawing,
});

export const fetchFabDrawingsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_FabDrawingS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchFabDrawingsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_FabDrawingS_SUCCESS,
  payload: response,
});

export const fetchFabDrawingsFailure = (error: any) => ({
  type: ActionTypes.FETCH_FabDrawingS_FAILURE,
  payload: error,
});

export const fetchFabDrawingByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_FabDrawing_BY_ID_REQUEST,
  payload: id,
});

export const fetchFabDrawingByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_FabDrawing_BY_ID_SUCCESS,
  payload: response,
});

export const fetchFabDrawingByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_FabDrawing_BY_ID_FAILURE,
  payload: error,
});

export const editFabDrawingRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_FabDrawing_REQUEST,
  payload: { id, data },
});

export const editFabDrawingSuccess = (response: any) => ({
  type: ActionTypes.EDIT_FabDrawing_SUCCESS,
  payload: response,
});

export const editFabDrawingFailure = (error: any) => ({
  type: ActionTypes.EDIT_FabDrawing_FAILURE,
  payload: error,
});
