import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { ActionTypes } from './constant';
import { processApiError, processApiResponse } from "./actions";
import { fetchProcesses, createProcess, fetchProcessById, editProcess } from "helpers/api/processes";

function* fetchProcessesSaga(action: any): SagaIterator {
    try {
        const { page, limit, sortBy, sort, searchBy } = action.payload || {};

        const response = yield call(fetchProcesses, page, limit,  sortBy, sort, searchBy);
        const processesData = response.data; // Assuming `response` directly contains `data`
        yield put(processApiResponse(ActionTypes.FETCH_PROCESSES_SUCCESS, processesData));
    } catch (e: any) {
        yield put(processApiError(ActionTypes.FETCH_PROCESSES_FAILURE, e));
    }
}


function* createProcessSaga(action: any): SagaIterator {
    try {
        const { data } = action.payload; 
        console.log("here", action)
        const response = yield call(createProcess, data); 
        const processData = yield response.data;
        yield put(processApiResponse(ActionTypes.CREATE_PROCESS_SUCCESS, processData));
        yield put({ type: ActionTypes.FETCH_PROCESSES_REQUEST, payload: { page: 1, limit: 15 } });
    } catch (e: any) {
        yield put(processApiError(ActionTypes.CREATE_PROCESS_FAILURE, e));
    }
}

function* fetchProcessByIdSaga(action: any): SagaIterator {
    try {
        const { id } = action.payload;

        const response = yield call(fetchProcessById, id);
        const processData = yield response.data;
        yield put(processApiResponse(ActionTypes.FETCH_PROCESS_BY_ID_SUCCESS, processData));
    } catch (e: any) {
        yield put(processApiError(ActionTypes.FETCH_PROCESS_BY_ID_FAILURE, e));
    }
}

function* editProcessSaga(action: any): SagaIterator {
    try {
        const { id, data } = action.payload;

        const response = yield call(editProcess, id, data);
        const processData = yield response.data;
        yield put(processApiResponse(ActionTypes.EDIT_PROCESS_SUCCESS, processData));
        yield put({ type: ActionTypes.FETCH_PROCESS_BY_ID_REQUEST, payload: id });

    } catch (e: any) {
        yield put(processApiError(ActionTypes.EDIT_PROCESS_FAILURE, e));
    }
}

function* watchProcesses() {
    yield takeEvery(ActionTypes.FETCH_PROCESSES_REQUEST, fetchProcessesSaga);
    yield takeEvery(ActionTypes.CREATE_PROCESS_REQUEST, createProcessSaga);
    yield takeEvery(ActionTypes.FETCH_PROCESS_BY_ID_REQUEST, fetchProcessByIdSaga);
    yield takeEvery(ActionTypes.EDIT_PROCESS_REQUEST, editProcessSaga);
}

export default watchProcesses;
