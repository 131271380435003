import { IQCQualityActionTypes } from './constant';

const INIT_STATE = {
  count: 0,
  loading: true,
  iqcQuality: [],
  error: {},
  success: false,
  singleIQCQuality: {},
};

const IQCQualityReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case IQCQualityActionTypes.CREATE_IQCQUALITY_REQUEST:
    case IQCQualityActionTypes.EDIT_IQCQUALITY_REQUEST:
    case IQCQualityActionTypes.FETCH_IQCQUALITYS_REQUEST:
    case IQCQualityActionTypes.FETCH_IQCQUALITY_BY_ID_REQUEST:
    case IQCQualityActionTypes.RESET_IQCQUALITY_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case IQCQualityActionTypes.SET_SINGLE_IQCQUALITY:
      console.log(action.payload,"shubhi")
      return { ...state , singleIQCQuality: action.payload.data, loading:false }
    
    case IQCQualityActionTypes.CREATE_IQCQUALITY_SUCCESS:
      return {
        ...state,
        iqcQuality: [...state.iqcQuality, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case IQCQualityActionTypes.EDIT_IQCQUALITY_SUCCESS:
      const updatedIQCQualitys = state.iqcQuality.map((iqcQuality: any) =>
        iqcQuality.id === action.payload.data.id ? action.payload.data : iqcQuality
      );
      return {
        ...state,
        iqcQuality: updatedIQCQualitys,
        loading: false,
        error: {},
        success: true,
      };

    case IQCQualityActionTypes.FETCH_IQCQUALITYS_SUCCESS:
      console.log(action.payload,"yoooo")
      return {
        ...state,
        iqcQuality: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case IQCQualityActionTypes.FETCH_IQCQUALITY_BY_ID_SUCCESS:
      return {
        ...state,
        singleIQCQuality: action.payload.data,
        iqcQuality: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case IQCQualityActionTypes.RESET_SINGLE_IQCQUALITY:
        return {
            ...state,
            singleIQCQuality: null,
        };

    case IQCQualityActionTypes.CREATE_IQCQUALITY_FAILURE:
    case IQCQualityActionTypes.EDIT_IQCQUALITY_FAILURE:
    case IQCQualityActionTypes.FETCH_IQCQUALITYS_FAILURE:
    case IQCQualityActionTypes.FETCH_IQCQUALITY_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default IQCQualityReducer;
