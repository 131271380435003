import { GateEntryActionTypes as ActionTypes } from './constants';

export const createGateEntryRequest = (data: any) => ({
  type: ActionTypes.CREATE_GATEENTRY_REQUEST,
  payload: {data},
});

export const resetGateEntrySuccessState = () => ({
  type: ActionTypes.RESET_GATEENTRY_SUCCESS_STATE,
});

export const createGateEntrySuccess = (response: any) => ({
  type: ActionTypes.CREATE_GATEENTRY_SUCCESS,
  payload: response,
});

export const createGateEntryFailure = (error: any) => ({
  type: ActionTypes.CREATE_GATEENTRY_FAILURE,
  payload: error,
});

export const resetSingleGateEntry = () => ({
  type: ActionTypes.RESET_SINGLE_GATEENTRY,
});

export const fetchGateEntrysRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_GATEENTRYS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchGateEntrysSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GATEENTRYS_SUCCESS,
  payload: response,
});

export const fetchGateEntrysFailure = (error: any) => ({
  type: ActionTypes.FETCH_GATEENTRYS_FAILURE,
  payload: error,
});

export const fetchGateEntryByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_GATEENTRY_BY_ID_REQUEST,
  payload: id,
});

export const fetchGateEntryByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GATEENTRY_BY_ID_SUCCESS,
  payload: response,
});

export const fetchGateEntryByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_GATEENTRY_BY_ID_FAILURE,
  payload: error,
});

export const editGateEntryRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_GATEENTRY_REQUEST,
  payload: { id, data },
});

export const editGateEntrySuccess = (response: any) => ({
  type: ActionTypes.EDIT_GATEENTRY_SUCCESS,
  payload: response,
});

export const editGateEntryFailure = (error: any) => ({
  type: ActionTypes.EDIT_GATEENTRY_FAILURE,
  payload: error,
});
