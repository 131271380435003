import { CustomerQuotationActionTypes as ActionTypes } from './constants';

export const createCustomerQuotationRequest = (data: any) => ({
  type: ActionTypes.CREATE_CUSTOMER_QUOTATION_REQUEST,
  payload: {data},
});

export const resetCustomerQuotationSuccessState = () => ({
  type: ActionTypes.RESET_CUSTOMER_QUOTATION_SUCCESS_STATE,
});

export const createCustomerQuotationSuccess = (response: any) => ({
  type: ActionTypes.CREATE_CUSTOMER_QUOTATION_SUCCESS,
  payload: response,
});

export const createCustomerQuotationFailure = (error: any) => ({
  type: ActionTypes.CREATE_CUSTOMER_QUOTATION_FAILURE,
  payload: error,
});

export const resetSingleCustomerQuotation = () => ({
  type: ActionTypes.RESET_SINGLE_CUSTOMER_QUOTATION,
});

export const fetchCustomerQuotationsRequest = (
  page: number,
  limit: number,
  sortBy: string,
  sort: string,
  searchBy: string
) => {
  console.log('Fetching Customer Quotations:', {
    page,
    limit,
    sortBy,
    sort,
    searchBy
  });

  return {
    type: ActionTypes.FETCH_CUSTOMER_QUOTATIONS_REQUEST,
    payload: { page, limit, sortBy, sort, searchBy },
  };
};


export const fetchCustomerQuotationsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_CUSTOMER_QUOTATIONS_SUCCESS,
  payload: response,
});

export const fetchCustomerQuotationsFailure = (error: any) => ({
  type: ActionTypes.FETCH_CUSTOMER_QUOTATIONS_FAILURE,
  payload: error,
});

export const fetchCustomerQuotationByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_CUSTOMER_QUOTATION_BY_ID_REQUEST,
  payload: id,
});

export const fetchCustomerQuotationByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_CUSTOMER_QUOTATION_BY_ID_SUCCESS,
  payload: response,
});

export const fetchCustomerQuotationByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_CUSTOMER_QUOTATION_BY_ID_FAILURE,
  payload: error,
});

export const editCustomerQuotationRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_CUSTOMER_QUOTATION_REQUEST,
  payload: { id, data },
});

export const editCustomerQuotationSuccess = (response: any) => ({
  type: ActionTypes.EDIT_CUSTOMER_QUOTATION_SUCCESS,
  payload: response,
});

export const editCustomerQuotationFailure = (error: any) => ({
  type: ActionTypes.EDIT_CUSTOMER_QUOTATION_FAILURE,
  payload: error,
});
