import { ActionTypes as AuthActionTypes } from "./constants";
import { ChatResponse } from "redux/types/DataTypes";

const INIT_STATE = {
    loading: true,
    chat: {},
    count: null,
    singleChat: {},
    chatTypes: {},
    error: {},
    success: false
}

type AuthActionType = {
    type:
    | AuthActionTypes.SET_CHAT
    | AuthActionTypes.SET_CHAT_COUNT
    | AuthActionTypes.FETCH_CHAT
    | AuthActionTypes.SET_ERROR
    payload: {
        actionType?: string;
        data?: ChatResponse | {};
        error?: string;
    }
}

const ChatReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_CHAT:
            return { ...state, chat: action.payload.data, loading: false, success: false };
        case AuthActionTypes.SET_CHAT_COUNT:
            return { ...state, count: action.payload.data, loading: false, success: false }
        case AuthActionTypes.SET_ERROR:
            return { ...state, chat: {}, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default ChatReducer;