export enum DPRActionTypes {
    CREATE_DPR_REQUEST = 'CREATE_DPR_REQUEST',
    CREATE_DPR_SUCCESS = 'CREATE_DPR_SUCCESS',
    CREATE_DPR_FAILURE = 'CREATE_DPR_FAILURE',
    FETCH_DPRS_REQUEST = 'FETCH_DPRS_REQUEST',
    FETCH_DPRS_SUCCESS = 'FETCH_DPRS_SUCCESS',
    FETCH_DPRS_FAILURE = 'FETCH_DPRS_FAILURE',
    FETCH_DPRS_USING_PROJECTID_REQUEST = 'FETCH_DPRS_USING_PROJECTID_REQUEST',
    FETCH_DPRS_USING_PROJECTID_SUCCESS = 'FETCH_DPRS_USING_PROJECTID_SUCCESS',
    FETCH_DPRS_USING_PROJECTID_FAILURE = 'FETCH_DPRS_USING_PROJECTID_FAILURE',
    FETCH_DPR_BY_ID_REQUEST = 'FETCH_DPR_BY_ID_REQUEST',
    FETCH_DPR_BY_ID_SUCCESS = 'FETCH_DPR_BY_ID_SUCCESS',
    FETCH_DPR_BY_ID_FAILURE = 'FETCH_DPR_BY_ID_FAILURE',
    EDIT_DPR_REQUEST = 'EDIT_DPR_REQUEST',
    EDIT_DPR_SUCCESS = 'EDIT_DPR_SUCCESS',
    EDIT_DPR_FAILURE = 'EDIT_DPR_FAILURE',
    RESET_DPR_SUCCESS_STATE = 'RESET_DPR_SUCCESS_STATE',
    FETCH_SINGLE_DPR = 'FETCH_SINGLE_DPR',
    SET_SINGLE_DPR = 'SET_SINGLE_DPR',
    RESET_SINGLE_DPR = 'RESET_SINGLE_DPR'
}
