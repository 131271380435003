import { ClaimActionTypes as ActionTypes } from './constants';

export const createClaimRequest = (data: any) => ({
  type: ActionTypes.CREATE_CLAIM_REQUEST,
  payload: {data},
});

export const resetClaimSuccessState = () => ({
  type: ActionTypes.RESET_CLAIM_SUCCESS_STATE,
});

export const createClaimSuccess = (response: any) => ({
  type: ActionTypes.CREATE_CLAIM_SUCCESS,
  payload: response,
});

export const createClaimFailure = (error: any) => ({
  type: ActionTypes.CREATE_CLAIM_FAILURE,
  payload: error,
});

export const resetSingleClaim = () => ({
  type: ActionTypes.RESET_SINGLE_CLAIM,
});

export const fetchClaimsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string,
  status?: string,
  origin?: string) => ({
  type: ActionTypes.FETCH_CLAIMS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy, status, origin },
});

export const fetchClaimsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_CLAIMS_SUCCESS,
  payload: response,
});

export const fetchClaimsFailure = (error: any) => ({
  type: ActionTypes.FETCH_CLAIMS_FAILURE,
  payload: error,
});

export const fetchClaimByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_CLAIM_BY_ID_REQUEST,
  payload: id,
});

export const fetchClaimByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_CLAIM_BY_ID_SUCCESS,
  payload: response,
});

export const fetchClaimByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_CLAIM_BY_ID_FAILURE,
  payload: error,
});

export const editClaimRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_CLAIM_REQUEST,
  payload: { id, data },
});

export const editClaimSuccess = (response: any) => ({
  type: ActionTypes.EDIT_CLAIM_SUCCESS,
  payload: response,
});

export const editClaimFailure = (error: any) => ({
  type: ActionTypes.EDIT_CLAIM_FAILURE,
  payload: error,
});
