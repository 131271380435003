import { ActionTypes } from './constants';


export type FileActionType = {
    type:
    | ActionTypes.FETCH_FILES
    | ActionTypes.SET_FILES
    | ActionTypes.SET_ERROR
    | ActionTypes.UPLOAD_FILE
    | ActionTypes.UPLOAD_FILE_SUCCESS
    | ActionTypes.UPLOAD_FILE_FAIL
    | ActionTypes.DOWNLOAD_FILE_BY_ID
    | ActionTypes.VIEW_FILE_BY_ID
    | ActionTypes.DOWNLOAD_FILE_BY_PATH
    | ActionTypes.DOWNLOAD_FILE_SUCCESS
    | ActionTypes.DOWNLOAD_FILE_FAIL
    | ActionTypes.DELETE_FILE_BY_ID
    | ActionTypes.DELETE_SUCCESS
    | ActionTypes.DELETE_FAIL
    | ActionTypes.FETCH_TAG
    | ActionTypes.SET_TAG
    | ActionTypes.FETCH_SINGLE_FILE_BY_ID
    | ActionTypes.SET_SINGLE_FILE_BY_ID
    | ActionTypes.RESET
    | ActionTypes.FILES_BY_ID
    | ActionTypes.SET_FILES_BY_ID
    | ActionTypes.RESET_FILE_SUCCESS
    | ActionTypes.RESET_SINGLE_FILE_STATE
    | ActionTypes.UPDATE_FILE_SUCCESS
    | ActionTypes.UPDATE_FILE_FAIL
    payload: {} | string;
}

//action to get all files
export const getFiles = (data: any): FileActionType => ({
    type: ActionTypes.FETCH_FILES,
    payload: { data }
})

export const getFilesById = (data: any): FileActionType => ({
    type: ActionTypes.FILES_BY_ID,
    payload: { data }
})

export const updateFileById = (data: any) => ({
    type: ActionTypes.UPDATE_FILE_BY_ID,
    payload: { data }
})


export const resetFileUpdate = ()=>{
    return{
        type: ActionTypes.RESET_FILE_UPDATE,
        payload:{}
    }
}

export const fetchSingleFileById = (data: any): FileActionType => ({
    type: ActionTypes.FETCH_SINGLE_FILE_BY_ID,
    payload: { data }
})

export const resetFileSuccess = (): FileActionType => ({
    type: ActionTypes.RESET_FILE_SUCCESS,
    payload: {}
})

export const resetSingleFileState = (): FileActionType => ({
    type: ActionTypes.RESET_SINGLE_FILE_STATE,
    payload: {}
})

export const reset = (): FileActionType => ({
    type: ActionTypes.RESET,
    payload: {}
})

export const uploadFile = (data: any): FileActionType => ({
    type: ActionTypes.UPLOAD_FILE,
    payload: { data }
})


export const downloadFileById = (data: any): FileActionType => ({
    type: ActionTypes.DOWNLOAD_FILE_BY_ID,
    payload: { data }
})
export const viewFileById = (data: any): FileActionType => ({
    type: ActionTypes.VIEW_FILE_BY_ID,
    payload: { data }
})




export const deleteFileById = (data: any): FileActionType => ({
    type: ActionTypes.DELETE_FILE_BY_ID,
    payload: { data }
})


export const donwloadFileByPath = (data: any): FileActionType => ({
    type: ActionTypes.DOWNLOAD_FILE_BY_PATH,
    payload: { data }
})


export const fetchAllTags = (): FileActionType => ({
    type: ActionTypes.FETCH_TAG,
    payload: {}
})

export const resetFileUploadSuccess = () =>
({
    type: ActionTypes.RESET_FILE_UPLOAD_SUCCESS,
    payload: {}
})

export const resetDeleteFileSuccess = () => ({
    type: ActionTypes.RESET_DELETE_FILE_SUCCESS,
    payload: {}
})

//action after getting api response
export const fileApiResponse = (actionType: string, data: any): FileActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_FILES:
            return {
                type: ActionTypes.SET_FILES,
                payload: { data }
            }
        case ActionTypes.FETCH_TAG:
            return {
                type: ActionTypes.SET_TAG,
                payload: { data }
            }
        case ActionTypes.FILES_BY_ID:
            return {
                type: ActionTypes.SET_FILES_BY_ID,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_FILE_BY_ID:
            return {
                type: ActionTypes.SET_SINGLE_FILE_BY_ID,
                payload: { data }
            }
        case ActionTypes.UPDATE_FILE_SUCCESS:
            return {
                type: ActionTypes.UPDATE_FILE_SUCCESS,
                payload: { data }
            }
        case ActionTypes.UPDATE_FILE_FAIL:
            return {
                type: ActionTypes.UPDATE_FILE_FAIL,
                payload: { error: data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

//handle api response after upload api
export const fileUploadResponse = (actionType: string, data: any): FileActionType => {
    switch (actionType) {
        case ActionTypes.UPLOAD_FILE_SUCCESS:
            return {
                type: ActionTypes.UPLOAD_FILE_SUCCESS,
                payload: { data }
            }
        case ActionTypes.UPLOAD_FILE_FAIL:
            return {
                type: ActionTypes.UPLOAD_FILE_FAIL,
                payload: { error: data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "something went wrong while uploading file.." },
            };
    }
}



export const fileDeleteResponse = (actionType: string, data: any): FileActionType => {
    switch (actionType) {
        case ActionTypes.DELETE_SUCCESS:
            return {
                type: ActionTypes.DELETE_SUCCESS,
                payload: { data }
            }
        case ActionTypes.DELETE_FAIL:
            return {
                type: ActionTypes.DELETE_FAIL,
                payload: { error: data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "something went wrong while uploading file.." },
            };
    }
}


//handle api response after download api
export const fileDownloadResponse = (actionType: string, data: any): FileActionType => {
    switch (actionType) {
        case ActionTypes.DOWNLOAD_FILE_SUCCESS:
            return {
                type: ActionTypes.DOWNLOAD_FILE_SUCCESS,
                payload: { data }
            }
        case ActionTypes.DOWNLOAD_FILE_FAIL:
            return {
                type: ActionTypes.DOWNLOAD_FILE_FAIL,
                payload: { error: data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "something went wrong while downloading file.." },
            };
    }
}


//action to handle error
export const fileApiError = (actionType: string, error: any): FileActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})