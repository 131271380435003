import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { CustomerQuotationActionTypes } from './constants';
import {
  createCustomerQuotation,
  fetchCustomerQuotationById,
  fetchCustomerQuotations,
  editCustomerQuotation,
} from 'helpers/api/customerQuotation';
import {
  createCustomerQuotationSuccess,
  createCustomerQuotationFailure,
  fetchCustomerQuotationsSuccess,
  fetchCustomerQuotationsFailure,
  fetchCustomerQuotationByIdSuccess,
  fetchCustomerQuotationByIdFailure,
  editCustomerQuotationSuccess,
  editCustomerQuotationFailure,
} from './actions';

function* fetchCustomerQuotationsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    console.log("cdvhgv")
    const response = yield call(fetchCustomerQuotations, page, limit , sortBy, sort, searchBy);
    const quotationsData = response.data; 
    yield put(fetchCustomerQuotationsSuccess(quotationsData));
  } catch (e: any) {
    yield put(fetchCustomerQuotationsFailure(e));
  }
}

function* createCustomerQuotationSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createCustomerQuotation, data);
    const quotationData = response.data;
    yield put(createCustomerQuotationSuccess(quotationData));
    yield put({ type: CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATIONS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createCustomerQuotationFailure(e));
  }
}

function* fetchCustomerQuotationByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchCustomerQuotationById, action.payload);
    const quotationData = response.data;
    yield put(fetchCustomerQuotationByIdSuccess(quotationData));
  } catch (e: any) {
    yield put(fetchCustomerQuotationByIdFailure(e));
  }
}

function* editCustomerQuotationSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editCustomerQuotation, id, data);
    const quotationData = response.data;
    yield put(editCustomerQuotationSuccess(quotationData));
    yield put({ type: CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATION_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editCustomerQuotationFailure(e));
  }
}

function* watchCustomerQuotations() {
  yield takeEvery(CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATIONS_REQUEST, fetchCustomerQuotationsSaga);
  yield takeEvery(CustomerQuotationActionTypes.CREATE_CUSTOMER_QUOTATION_REQUEST, createCustomerQuotationSaga);
  yield takeEvery(CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATION_BY_ID_REQUEST, fetchCustomerQuotationByIdSaga);
  yield takeEvery(CustomerQuotationActionTypes.EDIT_CUSTOMER_QUOTATION_REQUEST, editCustomerQuotationSaga);
}

export default watchCustomerQuotations;
