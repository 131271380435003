import { ActionTypes } from "./constants";

export type RawMaterialType = {
    type: 
    | ActionTypes.FETCH_RAW_MATERIAL_LIST
    | ActionTypes.FETCH_RAW_MATERIAL_LIST_SUCCESS
    | ActionTypes.FETCH_RAW_MATERIAL_LIST_FAILURE
    | ActionTypes.RESET_RAW_MATERIAL_LIST
    | ActionTypes.CREATE_RAW_MATERIAL
    | ActionTypes.CREATE_RAW_MATERIAL_SUCCESS
    | ActionTypes.CREATE_RAW_MATERIAL_FAILURE
    | ActionTypes.RESET_CREATE_RAW_MATERIAL
    | ActionTypes.UPDATE_RAW_MATERIAL
    | ActionTypes.UPDATE_RAW_MATERIAL_SUCCESS
    | ActionTypes.UPDATE_RAW_MATERIAL_FAILURE
    | ActionTypes.RESET_UPDATE_RAW_MATERIAL
    | ActionTypes.DELETE_RAW_MATERIAL
    | ActionTypes.DELETE_RAW_MATERIAL_SUCCESS
    | ActionTypes.DELETE_RAW_MATERIAL_FAILURE
    | ActionTypes.RESET_DELETE_RAW_MATERIAL
    | ActionTypes.FETCH_SINGLE_RAW_MATERIAL
    | ActionTypes.FETCH_SINGLE_RAW_MATERIAL_SUCCESS
    | ActionTypes.FETCH_SINGLE_RAW_MATERIAL_FAILURE
    | ActionTypes.RESET_FETCH_SINGLE_RAW_MATERIAL
    | ActionTypes.FETCH_RAW_MATERIAL_UNITS
    | ActionTypes.FETCH_RAW_MATERIAL_UNITS_SUCCESS
    | ActionTypes.FETCH_RAW_MATERIAL_UNITS_FAILURE
    | ActionTypes.RESET_RAW_MATERIAL_UNITS
    | ActionTypes.FETCH_RAW_MATERIAL_CHART
    | ActionTypes.FETCH_RAW_MATERIAL_CHART_SUCCESS
    | ActionTypes.FETCH_RAW_MATERIAL_CHART_FAILURE
    | ActionTypes.RESET_RAW_MATERIAL_CHART
    | ActionTypes.SET_RAW_MATERIAL_ERROR
    payload: {} | string
}

export const getRawMaterials = (data:any):RawMaterialType=>({
    type: ActionTypes.FETCH_RAW_MATERIAL_LIST,
    payload: {data}
})

export const resetRawMaterials = () : RawMaterialType =>({
    type: ActionTypes.RESET_RAW_MATERIAL_LIST,
    payload: {}
})

export const fetchRawMaterialResponse = (actionType:string, data:any):RawMaterialType=>{
    switch(actionType){
        case ActionTypes.FETCH_RAW_MATERIAL_LIST_SUCCESS:
            return {
                type: ActionTypes.FETCH_RAW_MATERIAL_LIST_SUCCESS,
                payload: {data}
            }
        case ActionTypes.FETCH_RAW_MATERIAL_LIST_FAILURE:
            return {
                type: ActionTypes.FETCH_RAW_MATERIAL_LIST_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_RAW_MATERIAL_ERROR,
                    payload: { actionType, error: "Error While fetching data!" },
                };
    }
}


export const getRawMaterialChart = (data:any):RawMaterialType=>({
    type: ActionTypes.FETCH_RAW_MATERIAL_CHART,
    payload: {data}
})

export const resetRawMaterialChart = () : RawMaterialType =>({
    type: ActionTypes.RESET_RAW_MATERIAL_CHART,
    payload: {}
})

export const fetchRawMaterialChartResponse = (actionType:string, data:any):RawMaterialType=>{
    switch(actionType){
        case ActionTypes.FETCH_RAW_MATERIAL_CHART_SUCCESS:
            return {
                type: ActionTypes.FETCH_RAW_MATERIAL_CHART_SUCCESS,
                payload: {data}
            }
        case ActionTypes.FETCH_RAW_MATERIAL_CHART_FAILURE:
            return {
                type: ActionTypes.FETCH_RAW_MATERIAL_CHART_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_RAW_MATERIAL_ERROR,
                    payload: { actionType, error: "Error While fetching data!" },
                };
    }
}


export const getRawMaterialUnits = ():RawMaterialType=>({
    type: ActionTypes.FETCH_RAW_MATERIAL_UNITS,
    payload: {}
})

export const resetRawMaterialUnit = () : RawMaterialType =>({
    type: ActionTypes.RESET_RAW_MATERIAL_UNITS,
    payload: {}
})

export const fetchRawMaterialUnitResponse = (actionType:string, data:any):RawMaterialType=>{
    switch(actionType){
        case ActionTypes.FETCH_RAW_MATERIAL_UNITS_SUCCESS:
            return {
                type: ActionTypes.FETCH_RAW_MATERIAL_UNITS_SUCCESS,
                payload: {data}
            }
        case ActionTypes.FETCH_RAW_MATERIAL_UNITS_FAILURE:
            return {
                type: ActionTypes.FETCH_RAW_MATERIAL_UNITS_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_RAW_MATERIAL_ERROR,
                    payload: { actionType, error: "Error While fetching data!" },
                };
    }
}


export const createRawMaterial = (data:any):RawMaterialType=>({
    type: ActionTypes.CREATE_RAW_MATERIAL,
    payload: {data}
})

export const resetCreateRawMaterial = ():RawMaterialType =>({
    type: ActionTypes.RESET_CREATE_RAW_MATERIAL,
    payload: {}
})

export const createRawMaterialResponse = (actionType:string, data:any):RawMaterialType=>{
    switch(actionType){
        case ActionTypes.CREATE_RAW_MATERIAL_SUCCESS:
            return {
                type: ActionTypes.CREATE_RAW_MATERIAL_SUCCESS,
                payload: {data}
            }
        case ActionTypes.CREATE_RAW_MATERIAL_FAILURE:
            return {
                type: ActionTypes.CREATE_RAW_MATERIAL_FAILURE,
                payload: {error:data}
            }
        default:
            return {
                type: ActionTypes.SET_RAW_MATERIAL_ERROR,
                payload: { actionType, error: "Error While creating!" },
            };
    }
}


export const updateRawMaterial = (data:any):RawMaterialType=>({
    type: ActionTypes.UPDATE_RAW_MATERIAL,
    payload: {data}
})

export const resetUpdateRawMaterial = ():RawMaterialType =>({
    type: ActionTypes.RESET_UPDATE_RAW_MATERIAL,
    payload: {}
})


export const updateRawMaterialResponse = (actionType:string, data:any):RawMaterialType=>{
    switch(actionType){
        case ActionTypes.UPDATE_RAW_MATERIAL_SUCCESS:
            return {
                type: ActionTypes.UPDATE_RAW_MATERIAL_SUCCESS,
                payload: {data}
            }
        case ActionTypes.UPDATE_RAW_MATERIAL_FAILURE:
            return {
                type: ActionTypes.UPDATE_RAW_MATERIAL_FAILURE,
                payload: {error:data}
            }
            default:
                return {
                    type: ActionTypes.SET_RAW_MATERIAL_ERROR,
                    payload: { actionType, error: "Error While updating!" },
                };
    }
}

export const deleteRawMaterial = (data:any):RawMaterialType=>({
    type: ActionTypes.DELETE_RAW_MATERIAL,
    payload: {data}
})

export const resetDeleteRawMaterial = ():RawMaterialType =>({
    type: ActionTypes.RESET_DELETE_RAW_MATERIAL,
    payload: {}
})

export const deleteRawMaterialResponse = (actionType:string, data:any):RawMaterialType=>{
    switch(actionType){
        case ActionTypes.DELETE_RAW_MATERIAL_SUCCESS:
            return {
                type: ActionTypes.DELETE_RAW_MATERIAL_SUCCESS,
                payload: {data}
            }
        case ActionTypes.DELETE_RAW_MATERIAL_FAILURE:
            return {
                type: ActionTypes.DELETE_RAW_MATERIAL_FAILURE,
                payload: {error:data}
            }
        default:
            return {
                type: ActionTypes.SET_RAW_MATERIAL_ERROR,
                payload: { actionType, error: "Error While deleting!" },
            };
    }
}

export const fetchSingleRawMaterial = (data:any):RawMaterialType=>({
    type: ActionTypes.FETCH_SINGLE_RAW_MATERIAL,
    payload: {data}
})

export const resetFetchSingleRawMaterial = ():RawMaterialType =>({
    type: ActionTypes.RESET_FETCH_SINGLE_RAW_MATERIAL,
    payload: {}
})


export const fetchSingleRawMaterialResponse = (actionType:string, data:any):RawMaterialType=>{
    switch(actionType){
        case ActionTypes.FETCH_SINGLE_RAW_MATERIAL_SUCCESS:
            return {
                type: ActionTypes.FETCH_SINGLE_RAW_MATERIAL_SUCCESS,
                payload: {data}
            }
        case ActionTypes.FETCH_SINGLE_RAW_MATERIAL_FAILURE:
            return {
                type: ActionTypes.FETCH_SINGLE_RAW_MATERIAL_FAILURE,
                payload: {error:data}
            }
        default:
            return {
                type: ActionTypes.SET_RAW_MATERIAL_ERROR,
                payload: { actionType, error: "Error While fetching data!" },
            };
    }
}