import { ItemActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  items: [],
  itemBomInputs: [],
  singleItem: {},
  error: {},
  success: false,
};

const ItemReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ItemActionTypes.CREATE_ITEM_REQUEST:
    case ItemActionTypes.EDIT_ITEM_REQUEST:
    case ItemActionTypes.FETCH_ITEMS_REQUEST:
    case ItemActionTypes.FETCH_ITEM_BOM_INPUTS_REQUEST:
    case ItemActionTypes.FETCH_ITEM_BY_ID_REQUEST:
    case ItemActionTypes.RESET_ITEM_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case ItemActionTypes.SET_SINGLE_ITEM:
    return { ...state , singleItem: action.payload.data, loading:false }  
    case ItemActionTypes.CREATE_ITEM_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case ItemActionTypes.EDIT_ITEM_SUCCESS:
      const updatedItems = state.items.map((item: any) =>
        item.id === action.payload.data.id ? action.payload.data : item
      );
      return {
        ...state,
        items: updatedItems,
        loading: false,
        error: {},
        success: true,
      };

    case ItemActionTypes.FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };
    
    case ItemActionTypes.FETCH_ITEM_BOM_INPUTS_SUCCESS:
      console.log(action.payload,"yoooo")
      return {
        ...state,
        itemBomInputs: action.payload.data,
        loading: false,
        error: {},
        success: true
      };

    case ItemActionTypes.FETCH_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        singleItem: action.payload.data,
        items: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case ItemActionTypes.RESET_SINGLE_ITEM:
      return {
          ...state,
          singleItem: null,
      };

    case ItemActionTypes.FETCH_ITEM_BOM_INPUTS_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false, itemBomInputs:[] };
    case ItemActionTypes.CREATE_ITEM_FAILURE:
    case ItemActionTypes.EDIT_ITEM_FAILURE:
    case ItemActionTypes.FETCH_ITEMS_FAILURE:
    case ItemActionTypes.FETCH_ITEM_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default ItemReducer;
