import { ActionTypes } from "./constants";
import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  createMachineAPIResponse,
  deleteMachineAPIResponse,
  fetchMachineAPIResponse,
  fetchMachineDetailAPIResponse,
  updateMachineAPIResponse,
} from "./actions";
import {
  createMachine,
  deleteMachine,
  fetchMachineDetail,
  fetchMachines,
  updateMachine,
} from "helpers";

function* _fetchMachines(params: any): SagaIterator {
  try {
    const response = yield call(fetchMachines, {
      page: params.payload.data.page,
      limit: params.payload.data.limit,
      sortBy: params.payload.data.sortBy,
      sort: params.payload.data.sort,
      searchBy: params.payload.data?.searchBy,
    });
    const data = response.data;
    yield put(
      fetchMachineAPIResponse(ActionTypes.FETCH_MACHINE_LIST_SUCCESS, data)
    );
  } catch (error: any) {
    yield put(
      fetchMachineAPIResponse(ActionTypes.FETCH_MACHINE_LIST_FAIL, error)
    );
  }
}

function* _createMachine({ payload }: any): SagaIterator {
  try {
    const response = yield call(createMachine, {
      machine: payload.data.machine,
    });
    const data = response.data;
    yield put(
      createMachineAPIResponse(ActionTypes.CREATE_MACHINE_SUCCESS, data)
    );
  } catch (error: any) {
    yield put(createMachineAPIResponse(ActionTypes.CREATE_MACHINE_FAIL, error));
  }
}

function* _getMachineDetail({ payload }: any): SagaIterator {
  try {
    const response = yield call(fetchMachineDetail, {id: payload.data});
    const data = response.data;
    yield put(
      fetchMachineDetailAPIResponse(
        ActionTypes.FETCH_MACHINE_DETAIL_SUCCESS,
        data
      )
    );
  } catch (error: any) {
    yield put(
      fetchMachineDetailAPIResponse(
        ActionTypes.FETCH_MACHINE_DETAIL_FAIL,
        error
      )
    );
  }
}

function* _updateMachine({ payload }: any): SagaIterator {
  try {
    const response = yield call(updateMachine, {
      id: payload.data.id,
      machine: payload.data.machine,
    });
    const data = response.data;
    yield put(
      updateMachineAPIResponse(ActionTypes.UPDATE_MACHINE_SUCCESS, data)
    );
  } catch (error: any) {
    yield put(updateMachineAPIResponse(ActionTypes.UPDATE_MACHINE_FAIL, error));
  }
}

function* _deleteMachine({ payload }: any): SagaIterator {
  try {
    const response = yield call(deleteMachine, {
      id: payload.data.id,
    });
    const data = response.data;
    yield put(
      deleteMachineAPIResponse(ActionTypes.DELETE_MACHINE_SUCCESS, data)
    );
  } catch (error: any) {
    yield put(deleteMachineAPIResponse(ActionTypes.DELETE_MACHINE_FAIL, error));
  }
}

function* watchMachine() {
  yield takeEvery(ActionTypes.FETCH_MACHINE_LIST, _fetchMachines);
  yield takeEvery(ActionTypes.CREATE_MACHINE, _createMachine);
  yield takeEvery(ActionTypes.UPDATE_MACHINE, _updateMachine);
  yield takeEvery(ActionTypes.FETCH_MACHINE_DETAIL, _getMachineDetail);
  yield takeEvery(ActionTypes.DELETE_MACHINE, _deleteMachine);
}

export default watchMachine;
