import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    count: 0,
    loading: true,
    leads: [],
    error: {},
    success: false,
    uploadStatus: null,
};


type LeadActionType = {
    type:
    AuthActionTypes.UPLOAD_LEAD_LOCATOR
    | AuthActionTypes.UPLOAD_LEAD_LOCATOR_SUCCESS
    | AuthActionTypes.UPLOAD_LEAD_LOCATOR_FAILURE
    | AuthActionTypes.FETCH_ALL_LEADS
    | AuthActionTypes.FETCH_ALL_LEADS_SUCCESS
    | AuthActionTypes.FETCH_ALL_LEADS_FAILURE;

    payload: {
        actionType?: string;
        data?: any;
        successMsg?:any;
        error?: any;
    }
};

const LeadsReducer = (state = INIT_STATE, action: LeadActionType) => {
    switch (action.type) {
        case AuthActionTypes.UPLOAD_LEAD_LOCATOR:
        case AuthActionTypes.FETCH_ALL_LEADS:
            return { ...state, loading: true, error: {}, uploadStatus: null };

        case AuthActionTypes.UPLOAD_LEAD_LOCATOR_SUCCESS:
            return { ...state, leads: [...state.leads, action.payload.data], loading: false, error: {}, success: true, uploadStatus: { success: true, message: action.payload.successMsg } };

        case AuthActionTypes.FETCH_ALL_LEADS_SUCCESS:
            return { ...state, leads: action.payload.data?.response, loading: false, error: {}, success: true, count: action.payload.data.count };

        case AuthActionTypes.UPLOAD_LEAD_LOCATOR_FAILURE:
        case AuthActionTypes.FETCH_ALL_LEADS_FAILURE:
            return { ...state, error: action.payload.error, loading: false, success: false,uploadStatus: { success: false, message: action.payload.error.message || 'Could not upload the file. Please try again.' }, 
        };

        default:
            return state;
    }
};


export default LeadsReducer;
