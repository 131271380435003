export enum GateEntryActionTypes {
    CREATE_GATEENTRY_REQUEST = 'CREATE_GATEENTRY_REQUEST',
    CREATE_GATEENTRY_SUCCESS = 'CREATE_GATEENTRY_SUCCESS',
    CREATE_GATEENTRY_FAILURE = 'CREATE_GATEENTRY_FAILURE',
    FETCH_GATEENTRYS_REQUEST = 'FETCH_GATEENTRYS_REQUEST',
    FETCH_GATEENTRYS_SUCCESS = 'FETCH_GATEENTRYS_SUCCESS',
    FETCH_GATEENTRYS_FAILURE = 'FETCH_GATEENTRYS_FAILURE',
    FETCH_GATEENTRY_BY_ID_REQUEST = 'FETCH_GATEENTRY_BY_ID_REQUEST',
    FETCH_GATEENTRY_BY_ID_SUCCESS = 'FETCH_GATEENTRY_BY_ID_SUCCESS',
    FETCH_GATEENTRY_BY_ID_FAILURE = 'FETCH_GATEENTRY_BY_ID_FAILURE',
    EDIT_GATEENTRY_REQUEST = 'EDIT_GATEENTRY_REQUEST',
    EDIT_GATEENTRY_SUCCESS = 'EDIT_GATEENTRY_SUCCESS',
    EDIT_GATEENTRY_FAILURE = 'EDIT_GATEENTRY_FAILURE',
    RESET_GATEENTRY_SUCCESS_STATE = 'RESET_GATEENTRY_SUCCESS_STATE',
    FETCH_SINGLE_GATEENTRY = 'FETCH_SINGLE_GATEENTRY',
    SET_SINGLE_GATEENTRY = 'SET_SINGLE_GATEENTRY',
    RESET_SINGLE_GATEENTRY = 'RESET_SINGLE_GATEENTRY'
}
