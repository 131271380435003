import { GADrawingActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  gaDrawing: [],
  singleGADrawing: {},
  error: {},
  success: false,
};

const GADrawingReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GADrawingActionTypes.CREATE_GADrawing_REQUEST:
    case GADrawingActionTypes.EDIT_GADrawing_REQUEST:
    case GADrawingActionTypes.FETCH_GADrawingS_REQUEST:
    case GADrawingActionTypes.FETCH_GADrawing_BY_ID_REQUEST:
    case GADrawingActionTypes.RESET_GADrawing_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case GADrawingActionTypes.SET_SINGLE_GADrawing:
    return { ...state , singleGADrawing: action.payload.data, loading:false }  
    case GADrawingActionTypes.CREATE_GADrawing_SUCCESS:
      return {
        ...state,
        gaDrawing: [...state.gaDrawing, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case GADrawingActionTypes.EDIT_GADrawing_SUCCESS:
      const updatedGADrawings = state.gaDrawing.map((gaDrawing: any) =>
        gaDrawing.id === action.payload.data.id ? action.payload.data : gaDrawing
      );
      return {
        ...state,
        gaDrawing: updatedGADrawings,
        loading: false,
        error: {},
        success: true,
      };

    case GADrawingActionTypes.FETCH_GADrawingS_SUCCESS:
      return {
        ...state,
        gaDrawing: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case GADrawingActionTypes.FETCH_GADrawing_BY_ID_SUCCESS:
      return {
        ...state,
        singleGADrawing: action.payload.data,
        gaDrawing: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case GADrawingActionTypes.RESET_SINGLE_GADrawing:
      return {
          ...state,
          singleGADrawing: null,
      };

    case GADrawingActionTypes.CREATE_GADrawing_FAILURE:
    case GADrawingActionTypes.EDIT_GADrawing_FAILURE:
    case GADrawingActionTypes.FETCH_GADrawingS_FAILURE:
    case GADrawingActionTypes.FETCH_GADrawing_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default GADrawingReducer;
