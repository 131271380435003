import { CommissioningActionTypes as ActionTypes } from './constants';

export const createCommissioningRequest = (data: any) => ({
  type: ActionTypes.CREATE_COMMISSIONING_REQUEST,
  payload: { data },
});

export const resetCommissioningSuccessState = () => ({
  type: ActionTypes.RESET_COMMISSIONING_SUCCESS_STATE,
});

export const createCommissioningSuccess = (response: any) => ({
  type: ActionTypes.CREATE_COMMISSIONING_SUCCESS,
  payload: response,
});

export const createCommissioningFailure = (error: any) => ({
  type: ActionTypes.CREATE_COMMISSIONING_FAILURE,
  payload: error,
});

export const resetSingleCommissioning = () => ({
  type: ActionTypes.RESET_SINGLE_COMMISSIONING,
});

export const fetchCommissioningsRequest = (page: number, limit: number, sortBy: string, sort: string, searchBy: string) => ({
  type: ActionTypes.FETCH_COMMISSIONINGS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchCommissioningsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_COMMISSIONINGS_SUCCESS,
  payload: response,
});

export const fetchCommissioningsFailure = (error: any) => ({
  type: ActionTypes.FETCH_COMMISSIONINGS_FAILURE,
  payload: error,
});
export const fetchCommissioningsUsingProjectIdRequest = (projectId :any,page: number, limit: number, sortBy: string, sort: string, searchBy: string) => ({
  type: ActionTypes.FETCH_COMMISSIONINGS_USING_PROJECTID_REQUEST,
  payload: { projectId,page, limit, sortBy, sort, searchBy },
});

export const fetchCommissioningsUsingProjectIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_COMMISSIONINGS_USING_PROJECTID_SUCCESS,
  payload: response,
});

export const fetchCommissioningsUsingProjectIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_COMMISSIONINGS_USING_PROJECTID_FAILURE,
  payload: error,
});

export const fetchCommissioningByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_COMMISSIONING_BY_ID_REQUEST,
  payload: id,
});

export const fetchCommissioningByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_COMMISSIONING_BY_ID_SUCCESS,
  payload: response,
});

export const fetchCommissioningByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_COMMISSIONING_BY_ID_FAILURE,
  payload: error,
});

export const editCommissioningRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_COMMISSIONING_REQUEST,
  payload: { id, data },
});

export const editCommissioningSuccess = (response: any) => ({
  type: ActionTypes.EDIT_COMMISSIONING_SUCCESS,
  payload: response,
});

export const editCommissioningFailure = (error: any) => ({
  type: ActionTypes.EDIT_COMMISSIONING_FAILURE,
  payload: error,
});
