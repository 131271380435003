import { ActionTypes } from './constants';

export type OrganizationActionType = {
    type:
    | ActionTypes.CREATE_ORGANIZATION
    | ActionTypes.FETCH_ORGANIZATION
    | ActionTypes.SET_ORGANIZATION
    | ActionTypes.SET_ERROR
    | ActionTypes.UPDATE_ORGANIZATION
    | ActionTypes.FETCH_SINGLE_ORGANIZATION
    | ActionTypes.SET_SINGLE_ORGANIZATION
    | ActionTypes.UPDATE_ORGANIZATION_SUCCESS
    | ActionTypes.UPDATE_ORGANIZATION_FAIL
    | ActionTypes.ADD_ORGANIZATION_SUCCESS
    | ActionTypes.ADD_ORGANIZATION_FAIL
    | ActionTypes.DASHBOARD_ORGANIZATION_DATA
    | ActionTypes.SET_ORGANIZATION_DASHBOARD_DATA
    | ActionTypes.DELETE_ORGANIZATION_FAIL
    | ActionTypes.DELETE_ORGANIZATION
    | ActionTypes.SET_ORGANIZATION_LOCATION
    | ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER
    | ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS
    | ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_FAIL
    | ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS
    | ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_FAIL
    | ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER



    payload: {} | string;
}



export const getOrganization = (data: any): OrganizationActionType => ({
    type: ActionTypes.FETCH_ORGANIZATION,
    payload: { data }
})




//dispatch action to get single organization data
export const getSingleOrganization = (id: any): OrganizationActionType => ({
    type: ActionTypes.FETCH_SINGLE_ORGANIZATION,
    payload: { id }
})


//dispatch action to create new organization
export const createOrganization = (data: any): OrganizationActionType => ({
    type: ActionTypes.CREATE_ORGANIZATION,
    payload: { data }
})


//dispatch action to update organization
export const updateOrganization = (data: any): OrganizationActionType => ({
    type: ActionTypes.UPDATE_ORGANIZATION,
    payload: { data }
})

export const getDashboardOrganizationData = (data: any): OrganizationActionType => ({
    type: ActionTypes.DASHBOARD_ORGANIZATION_DATA,
    payload: { data }
})

export const getOrganizationMap = () => ({
    type: ActionTypes.FETCH_ORGANIZATION_LOCATION,
    payload: {}
})

export const resetOrg = () => ({
    type: ActionTypes.RESET_ORG_STATE,
    payload: {}
})



//this will trigger in the saga while receiving response of fetching organization data
export const organizationApiResponse = (actionType: string, data: any): OrganizationActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_ORGANIZATION:
            return {
                type: ActionTypes.SET_ORGANIZATION,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_ORGANIZATION:
            return {
                type: ActionTypes.SET_SINGLE_ORGANIZATION,
                payload: { data }
            }
        case ActionTypes.DASHBOARD_ORGANIZATION_DATA:
            return {
                type: ActionTypes.SET_ORGANIZATION_DASHBOARD_DATA,
                payload: { data }
            }
        case ActionTypes.FETCH_ORGANIZATION_LOCATION:
            return {
                type: ActionTypes.SET_ORGANIZATION_LOCATION,
                payload: { actionType, data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

//this action will be executed when organization is updated successfully
export const organizationUpdateResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_ORGANIZATION_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while updating
export const organizationUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_ORGANIZATION_FAIL,
        payload: { actionType, error }
    }
}

//this action will be executed when organization is added successfully
export const organizationAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_ORGANIZATION_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while adding
export const organizationAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_ORGANIZATION_FAIL,
        payload: { actionType, error }
    }
}

// this action will be executed when there is error while fetching organization info
export const organizationApiError = (actionType: string, error: any): OrganizationActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})

export const deleteOrganization = (data: any): OrganizationActionType => ({
    type: ActionTypes.DELETE_ORGANIZATION,
    payload: { data }
})

export const organizationDeleteResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.DELETE_ORGANIZATION_SUCCESS,
        payload: { data }
    }
}
export const organizationDeleteError = (actionType: string, error: any): OrganizationActionType => ({
    type: ActionTypes.DELETE_ORGANIZATION_FAIL,
    payload: { actionType, error }
})

export const createOrganizationWithCustomer = (data: any): OrganizationActionType => ({
    type: ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER,
    payload: { data }
})

export const organizationWithCustomerResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS,
        payload: { actionType, data }
    }
}

export const organizationWithCustomerError = (actionType: string, error: any): OrganizationActionType => ({
    type: ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_FAIL,
    payload: { actionType, error }
})

export const updateOrganizationWithCustomer = (data: any): OrganizationActionType => ({
    type: ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER,
    payload: { data }
})

export const updateOrganizationWithCustomerResponse = (actionType: string, data: any): OrganizationActionType => ({
    type: ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS,
    payload: { actionType, data }
})

export const updateOrganizationWithCustomerError = (actionType: string, error: any): OrganizationActionType => ({
    type: ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_FAIL,
    payload: { actionType, error }
})