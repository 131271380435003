import { ActionTypes as AuthActionTypes } from "./constants";
// import { Notification } from "../types/DataTypes";

const INIT_STATE = {
    loading: true,
    notification: [],
    allNotification: [],
    updateNotification: false,
    updateAllNotification: false,
    error: {},
    success: false
}

type AuthActionType = {
    type:
    | AuthActionTypes.SET_NOTIFICATIONS
    | AuthActionTypes.SET_NOTIF_ERROR
    | AuthActionTypes.SET_ALL_NOTIFICATIONS
    | AuthActionTypes.UPDATE_NOTIFICATION_SUCCESS
    | AuthActionTypes.UPDATE_NOTIFICATION_FAIL
    | AuthActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    }
}

const NotificationReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_NOTIFICATIONS:
            return { ...state, notification: action.payload.data.response.notification, allNotification: action.payload.data.response.allNotification, loading: false, success: true, updateNotification: false, updateAllNotification: false };
        case AuthActionTypes.SET_ALL_NOTIFICATIONS:
            return { ...state, allNotification: action.payload.data.response.allNotification, loading: false, success: true, updateNotification: false, updateAllNotification: false }
        case AuthActionTypes.UPDATE_NOTIFICATION_SUCCESS:
            return { ...state, updateNotification: true }
        case AuthActionTypes.UPDATE_NOTIFICATION_FAIL:
            return { ...state, updateNotification: false }
        case AuthActionTypes.SET_NOTIF_ERROR:
            return { ...state, notification: {}, error: action.payload.error }
        case AuthActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS:
            return { ...state, updateAllNotification: true }
        default:
            return { ...state }
    }
}

export default NotificationReducer;