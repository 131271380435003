import { GinActionTypes as ActionTypes } from './constants';

export const createGinRequest = (data: any) => ({
  type: ActionTypes.CREATE_GIN_REQUEST,
  payload: {data},
});

export const resetGinSuccessState = () => ({
  type: ActionTypes.RESET_GIN_SUCCESS_STATE,
});

export const createGinSuccess = (response: any) => ({
  type: ActionTypes.CREATE_GIN_SUCCESS,
  payload: response,
});

export const createGinFailure = (error: any) => ({
  type: ActionTypes.CREATE_GIN_FAILURE,
  payload: error,
});

export const resetSingleGin = () => ({
  type: ActionTypes.RESET_SINGLE_GIN,
});

export const fetchGinsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_GINS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchGinsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GINS_SUCCESS,
  payload: response,
});

export const fetchGinsFailure = (error: any) => ({
  type: ActionTypes.FETCH_GINS_FAILURE,
  payload: error,
});

export const fetchGinByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_GIN_BY_ID_REQUEST,
  payload: id,
});

export const fetchGinByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_GIN_BY_ID_SUCCESS,
  payload: response,
});

export const fetchGinByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_GIN_BY_ID_FAILURE,
  payload: error,
});

export const editGinRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_GIN_REQUEST,
  payload: { id, data },
});

export const editGinSuccess = (response: any) => ({
  type: ActionTypes.EDIT_GIN_SUCCESS,
  payload: response,
});

export const editGinFailure = (error: any) => ({
  type: ActionTypes.EDIT_GIN_FAILURE,
  payload: error,
});
