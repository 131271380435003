import { APICore } from "./apiCore";

const api = new APICore();

function fetchQuery(params: { page: string, limit: string, sortBy: string, sort: string,userId:string,searchBy?: string ,}) {
    const baseUrl = `/query?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&searchBy=${params.searchBy ? params.searchBy : ''}&userId=${params.userId}`;
    return api.get(`${baseUrl}`, "");
}

function createQuery(body: any) {
    const baseUrl = "/query";
    return api.create(`${baseUrl}`, body);
}


function fetchSingleQuery(id: any) {
    const baseUrl = `/query/${id}`;
    return api.get(`${baseUrl}`, "");
}

function updateQuery(body: any) {
    const baseUrl = `/query/${body.id}`;
    return api.update(`${baseUrl}`, body);
}

function deleteQuery(params: any) {
    const baseUrl = `/query/${params.id}`;
    return api.delete(`${baseUrl}`);
}


function fetchLeadHistory(id: any, type: string) {
    const baseUrl = `/query/history?id=${id}&type=${type}`;
    return api.create(`${baseUrl}`, "");
}


export { fetchQuery, fetchSingleQuery, updateQuery, createQuery, deleteQuery,fetchLeadHistory };