import { APICore } from "./apiCore";
const api = new APICore();


function fetchLeads(params: { page: string, limit: string, sortBy: string, sort: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    const baseUrl = `/lead?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}`
    return api.get(`${baseUrl}`, "");
}

function fetchLeadStatus() {
    const baseUrl = `/lead/all/status`
    return api.get(`${baseUrl}`, "")
}

function createLead(data: any) {
    const baseUrl = `/lead`
    return api.create(`${baseUrl}`, { ...data.lead })
}

function updateLead(data: any) {
    const baseUrl = `/lead/${data.id}`
    return api.update(`${baseUrl}`, { ...data.lead })
}

function getSingleLead(id: any) {
    const baseUrl = `/lead/${id}`
    return api.get(`${baseUrl}`, "")
}

export {
    getSingleLead, updateLead, createLead, fetchLeadStatus, fetchLeads
}