import { APICore } from "./apiCore";
const api = new APICore();

function fetchSupplier(params: { page: string, limit: string, sortBy: string, sort: string, filterBy?: string, searchBy?: string, orgId?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    console.log(params, "params1")
    const baseUrl = `/supplier?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&filterBy=${params.filterBy}&searchBy=${params?.searchBy ? params?.searchBy : ''}&orgId=${params?.orgId ? params?.orgId : ''} `;
    return api.get(`${baseUrl}`, "");
}

function fetchSupplierForDasbord(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/supplier/dashboard/data?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "");
}


function fetchSingleSupplier(params: any) {
    const baseUrl = `/supplier/${params}`;
    return api.get(`${baseUrl}`, {});
}

function createSupplier(body: any) {
    console.log(body, "datasupp")
    const baseUrl = "/supplier";
    return api.create(`${baseUrl}`, body);
}

function updateSupplier(data: any) {
    const baseUrl = `/supplier/${data.id}`;
    return api.update(`${baseUrl}`, { updateBody: data.updateBody });
}
function deleteSupplier(data: any) {
    const baseUrl = `/supplier/${data.id}`;
    return api.delete(`${baseUrl}`);
}






export { fetchSupplier, fetchSingleSupplier, createSupplier, updateSupplier, fetchSupplierForDasbord, deleteSupplier };