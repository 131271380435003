import { APICore } from "./apiCore";
const api = new APICore();


function fetchRawMaterials(params: {
    page: string;
    limit: string;
    sortBy: string;
    sort: string;
    searchBy?: string;
}) {
    const baseUrl = `/raw-material?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy
        }&sort=${params.sort}&searchBy=${params.searchBy ? params.searchBy : ""}`;
    return api.get(`${baseUrl}`, "");
}


function fetchRawMaterialChart(params: {
    material: string;
    type: string;
}) {
    const baseUrl = `/raw-material/chart/data?material=${params.material}&type=${params.type}`;
    return api.get(`${baseUrl}`, "");
}



function fetchRawMaterialDetails(params: { id: string }) {
    const baseUrl = `/raw-material/${params.id}`;
    return api.get(`${baseUrl}`, "");
}

function createRawMaterial(data: any) {
    const baseUrl = `/raw-material`;
    return api.create(`${baseUrl}`, { rawMaterial: data.rawMaterial });
}

function updateRawMaterial(data: any) {
    const baseUrl = `/raw-material/${data.id}`;
    return api.update(`${baseUrl}`, { rawMaterial: data.rawMaterial });
}

function deleteRawMaterial(data: any) {
    const baseUrl = `/raw-material/${data.id}`;
    return api.delete(`${baseUrl}`);
}

function fetchRawMaterialUnit(){
    const baseUrl = `/raw-material/units`
    return api.create(`${baseUrl}`, "")
}

export {
    fetchRawMaterials,
    fetchRawMaterialDetails,
    createRawMaterial,
    updateRawMaterial,
    deleteRawMaterial,
    fetchRawMaterialUnit,
    fetchRawMaterialChart
}
