export enum ExpenseActionTypes {
    CREATE_INDIRECT_EXPENSE_REQUEST = 'CREATE_INDIRECT_EXPENSE_REQUEST',
    CREATE_INDIRECT_EXPENSE_SUCCESS = 'CREATE_INDIRECT_EXPENSE_SUCCESS',
    CREATE_INDIRECT_EXPENSE_FAILURE = 'CREATE_INDIRECT_EXPENSE_FAILURE',
    FETCH_INDIRECT_EXPENSES_REQUEST = 'FETCH_INDIRECT_EXPENSES_REQUEST',
    FETCH_INDIRECT_EXPENSES_SUCCESS = 'FETCH_INDIRECT_EXPENSES_SUCCESS',
    FETCH_INDIRECT_EXPENSES_FAILURE = 'FETCH_INDIRECT_EXPENSES_FAILURE',
    FETCH_INDIRECT_EXPENSE_BY_ID_REQUEST = 'FETCH_INDIRECT_EXPENSE_BY_ID_REQUEST',
    FETCH_INDIRECT_EXPENSE_BY_ID_SUCCESS = 'FETCH_INDIRECT_EXPENSE_BY_ID_SUCCESS',
    FETCH_INDIRECT_EXPENSE_BY_ID_FAILURE = 'FETCH_INDIRECT_EXPENSE_BY_ID_FAILURE',
    EDIT_INDIRECT_EXPENSE_REQUEST = 'EDIT_INDIRECT_EXPENSE_REQUEST',
    EDIT_INDIRECT_EXPENSE_SUCCESS = 'EDIT_INDIRECT_EXPENSE_SUCCESS',
    EDIT_INDIRECT_EXPENSE_FAILURE = 'EDIT_INDIRECT_EXPENSE_FAILURE',
    RESET_INDIRECT_EXPENSE_SUCCESS_STATE = 'RESET_INDIRECT_EXPENSE_SUCCESS_STATE',
    FETCH_SINGLE_INDIRECT_EXPENSE = 'FETCH_SINGLE_INDIRECT_EXPENSE',
    SET_SINGLE_INDIRECT_EXPENSE = 'SET_SINGLE_INDIRECT_EXPENSE',
    RESET_SINGLE_INDIRECT_EXPENSE = 'RESET_SINGLE_INDIRECT_EXPENSE'
}
