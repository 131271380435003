import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ExpenseActionTypes } from './constants';
import {
  createIndirectExpense,
  fetchIndirectExpenses,
  fetchIndirectExpenseById,
  editIndirectExpense
} from 'helpers/api/expense';
import {
  createIndirectExpenseSuccess,
  createIndirectExpenseFailure,
  fetchIndirectExpensesSuccess,
  fetchIndirectExpensesFailure,
  fetchIndirectExpenseByIdSuccess,
  fetchIndirectExpenseByIdFailure,
  editIndirectExpenseSuccess,
  editIndirectExpenseFailure,
} from './actions';

function* fetchIndirectExpensesSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy, status, origin  } = action.payload || {};
    const response = yield call(fetchIndirectExpenses, page, limit , sortBy, sort, searchBy, status, origin);
    const indirectExpensesData = response.data; 
    yield put(fetchIndirectExpensesSuccess(indirectExpensesData));
  } catch (e: any) {
    yield put(fetchIndirectExpensesFailure(e));
  }
}

function* createIndirectExpenseSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createIndirectExpense, data);
    const indirectExpenseData = response.data;
    yield put(createIndirectExpenseSuccess(indirectExpenseData));
    yield put({ type: ExpenseActionTypes.FETCH_INDIRECT_EXPENSES_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createIndirectExpenseFailure(e));
  }
}

function* fetchIndirectExpenseByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchIndirectExpenseById, action.payload);
    const indirectExpenseData = response.data;
    yield put(fetchIndirectExpenseByIdSuccess(indirectExpenseData));
  } catch (e: any) {
    yield put(fetchIndirectExpenseByIdFailure(e));
  }
}

function* editIndirectExpenseSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editIndirectExpense, id, data);
    const indirectExpenseData = response.data;
    yield put(editIndirectExpenseSuccess(indirectExpenseData));
    yield put({ type: ExpenseActionTypes.FETCH_INDIRECT_EXPENSE_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editIndirectExpenseFailure(e));
  }
}

function* watchExpenses() {
  yield takeEvery(ExpenseActionTypes.FETCH_INDIRECT_EXPENSES_REQUEST, fetchIndirectExpensesSaga);
  yield takeEvery(ExpenseActionTypes.CREATE_INDIRECT_EXPENSE_REQUEST, createIndirectExpenseSaga);
  yield takeEvery(ExpenseActionTypes.FETCH_INDIRECT_EXPENSE_BY_ID_REQUEST, fetchIndirectExpenseByIdSaga);
  yield takeEvery(ExpenseActionTypes.EDIT_INDIRECT_EXPENSE_REQUEST, editIndirectExpenseSaga);
}

export default watchExpenses;
