import { APICore } from "./apiCore";

const api = new APICore();

function login(params: { username: string; password: string }) {
  const baseUrl = "/login/";
  return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
  const baseUrl = "/password/reset/confirm/";
  return api.create(`${baseUrl}`, params);
}

function deleteUserSession(params: {id:any}) {
  const baseUrl = `/login/deleteSession/${params}`;
  return api.delete(`${baseUrl}`);
}

function resendOtp(params:{email:string, key: string}){
  const baseUrl = "/login/resendOtp/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm, deleteUserSession, resendOtp }
