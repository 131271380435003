import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { IQCParameterActionTypes } from './constant';
import {
  createIQCParameter,
  fetchIQCParameters,
  fetchIQCParameterById,
  editIQCParameter,
} from 'helpers/api/iqcParameter';
import {
  createIQCParameterSuccess,
  createIQCParameterFailure,
  fetchIQCParametersSuccess,
  fetchIQCParametersFailure,
  fetchIQCParameterByIdSuccess,
  fetchIQCParameterByIdFailure,
  editIQCParameterSuccess,
  editIQCParameterFailure,
} from './actions';

function* fetchIQCParametersSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchIQCParameters, page, limit, sortBy, sort, searchBy);
    const iqcParameterData = response.data; 
    yield put(fetchIQCParametersSuccess(iqcParameterData));
  } catch (e: any) {
    yield put(fetchIQCParametersFailure(e));
  }
}

function* createIQCParameterSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;

    const response = yield call(createIQCParameter, data);
    const iqcParameterData = response.data;
    yield put(createIQCParameterSuccess(iqcParameterData));
    yield put({ type: IQCParameterActionTypes.FETCH_IQCPARAMETERS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createIQCParameterFailure(e));
  }
}

function* fetchIQCParameterByIdSaga(action: any): SagaIterator {
  try {
    console.log(action.payload, "shubhi")
    console.log("Saga: Fetching iqcParameter by ID:", action.payload);  // Debug log
    const response = yield call(fetchIQCParameterById, action.payload);
    const iqcParameterData = response.data;
    console.log("Saga: API response for iqcParameter by ID:", iqcParameterData);  // Debug log

    yield put(fetchIQCParameterByIdSuccess(iqcParameterData));
  } catch (e: any) {
    yield put(fetchIQCParameterByIdFailure(e));
  }
}

function* editIQCParameterSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;

    const response = yield call(editIQCParameter, id, data);
    const iqcParameterData = response.data;
    yield put(editIQCParameterSuccess(iqcParameterData));
    yield put({ type: IQCParameterActionTypes.FETCH_IQCPARAMETER_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editIQCParameterFailure(e));
  }
}

function* watchIQCParameter() {
  yield takeEvery(IQCParameterActionTypes.FETCH_IQCPARAMETERS_REQUEST, fetchIQCParametersSaga);
  yield takeEvery(IQCParameterActionTypes.CREATE_IQCPARAMETER_REQUEST, createIQCParameterSaga);
  yield takeEvery(IQCParameterActionTypes.FETCH_IQCPARAMETER_BY_ID_REQUEST, fetchIQCParameterByIdSaga);
  yield takeEvery(IQCParameterActionTypes.EDIT_IQCPARAMETER_REQUEST, editIQCParameterSaga);
}

export default watchIQCParameter;
