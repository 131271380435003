import { CustomerQuotationActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  quotations: [],
  singleQuotation: {},
  error: {},
  success: false,
};

const CustomerQuotationReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CustomerQuotationActionTypes.CREATE_CUSTOMER_QUOTATION_REQUEST:
    case CustomerQuotationActionTypes.EDIT_CUSTOMER_QUOTATION_REQUEST:
    case CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATIONS_REQUEST:
    case CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATION_BY_ID_REQUEST:
    case CustomerQuotationActionTypes.RESET_CUSTOMER_QUOTATION_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case CustomerQuotationActionTypes.SET_SINGLE_CUSTOMER_QUOTATION:
    return { ...state , singleQuotation: action.payload.data, loading:false }  
    case CustomerQuotationActionTypes.CREATE_CUSTOMER_QUOTATION_SUCCESS:
      return {
        ...state,
        quotations: [...state.quotations, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case CustomerQuotationActionTypes.EDIT_CUSTOMER_QUOTATION_SUCCESS:
      const updatedQuotations = state.quotations.map((quotation: any) =>
        quotation.id === action.payload.data.id ? action.payload.data : quotation
      );
      return {
        ...state,
        quotations: updatedQuotations,
        loading: false,
        error: {},
        success: true,
      };

    case CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATIONS_SUCCESS:
      return {
        ...state,
        quotations: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATION_BY_ID_SUCCESS:
      return {
        ...state,
        singleQuotation: action.payload.data,
        quotations: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case CustomerQuotationActionTypes.RESET_SINGLE_CUSTOMER_QUOTATION:
      return {
          ...state,
          singleQuotation: null,
      };

    case CustomerQuotationActionTypes.CREATE_CUSTOMER_QUOTATION_FAILURE:
    case CustomerQuotationActionTypes.EDIT_CUSTOMER_QUOTATION_FAILURE:
    case CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATIONS_FAILURE:
    case CustomerQuotationActionTypes.FETCH_CUSTOMER_QUOTATION_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default CustomerQuotationReducer;
