import { ExpenseActionTypes as ActionTypes } from './constants';

export const createIndirectExpenseRequest = (data: any) => ({
  type: ActionTypes.CREATE_INDIRECT_EXPENSE_REQUEST,
  payload: {data},
});

export const resetIndirectExpenseSuccessState = () => ({
  type: ActionTypes.RESET_INDIRECT_EXPENSE_SUCCESS_STATE,
});

export const createIndirectExpenseSuccess = (response: any) => ({
  type: ActionTypes.CREATE_INDIRECT_EXPENSE_SUCCESS,
  payload: response,
});

export const createIndirectExpenseFailure = (error: any) => ({
  type: ActionTypes.CREATE_INDIRECT_EXPENSE_FAILURE,
  payload: error,
});

export const resetSingleIndirectExpense = () => ({
  type: ActionTypes.RESET_SINGLE_INDIRECT_EXPENSE,
});

export const fetchIndirectExpensesRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string,
  status?: string,
  origin?: string) => ({
  type: ActionTypes.FETCH_INDIRECT_EXPENSES_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy, status, origin },
});

export const fetchIndirectExpensesSuccess = (response: any) => ({
  type: ActionTypes.FETCH_INDIRECT_EXPENSES_SUCCESS,
  payload: response,
});

export const fetchIndirectExpensesFailure = (error: any) => ({
  type: ActionTypes.FETCH_INDIRECT_EXPENSES_FAILURE,
  payload: error,
});

export const fetchIndirectExpenseByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_INDIRECT_EXPENSE_BY_ID_REQUEST,
  payload: id,
});

export const fetchIndirectExpenseByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_INDIRECT_EXPENSE_BY_ID_SUCCESS,
  payload: response,
});

export const fetchIndirectExpenseByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_INDIRECT_EXPENSE_BY_ID_FAILURE,
  payload: error,
});

export const editIndirectExpenseRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_INDIRECT_EXPENSE_REQUEST,
  payload: { id, data },
});

export const editIndirectExpenseSuccess = (response: any) => ({
  type: ActionTypes.EDIT_INDIRECT_EXPENSE_SUCCESS,
  payload: response,
});

export const editIndirectExpenseFailure = (error: any) => ({
  type: ActionTypes.EDIT_INDIRECT_EXPENSE_FAILURE,
  payload: error,
});
