import { APICore } from "./apiCore";

const api = new APICore();

function fetchTransporter(params: { page: string, limit: string, sortBy: string, sort: string, searchBy?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    const baseUrl = `/transporter?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&searchBy=${params?.searchBy ? params?.searchBy : ''}`
    return api.get(`${baseUrl}`, "");
}

function fetchSingleTransporter(params: any) {
    const baseUrl = `/transporter/${params}`
    return api.get(`${baseUrl}`, {});
}

function createTransporter(params: any) {
    const baseUrl = '/transporter';
    console.log("params1", params)
    return api.create(`${baseUrl}`, params)
}


function updateTransporter(params: any) {
    const baseUrl = `/transporter/${params.id}`;
    return api.update(`${baseUrl}`, { transporter: params.updateBody })
}

// function updloadFiles(params: { formData: any }) {
//     for (var data of params.formData) {
//         console.log(data);
//     }
//     const baseUrl = `file/upload`
//     return api.create(`${baseUrl}`, params.formData)
// }

async function createTransporterWithFiles(params: { transporterData: any, agreementData?: any, chequeData?: any }) {
    const baseUrl = '/transporter';
    const fileUrl = `file/upload`
    console.log("paramsT", params)
    const createdTransporter = await api.create(`${baseUrl}`, { transporter: { ...params?.transporterData } })
    const transporterResponse = createdTransporter.data
    const newTransporter = { ...transporterResponse?.response?.transporter?.dataValues, name: transporterResponse?.response?.transporter?.name, email: transporterResponse?.response?.transporter?.email, mobile: transporterResponse?.response?.transporter?.mobile }
    console.log(createdTransporter, "createdTransporter")

    if (!params.agreementData && !params.chequeData) {
        return createdTransporter

    }

    let cheque_data_response = null
    let agreement_data_response = null
    if (params?.chequeData) {
        params?.chequeData?.append('uploadedBy', newTransporter?.user_id)
        params?.chequeData?.append('type', 'Transporter')
        params?.chequeData?.append('typeId', newTransporter?.id)
        params?.chequeData?.append('tagId', "8")

        const newFile = await api.create(`${fileUrl}`, params?.chequeData)
        const newFileResponse = newFile.data
        console.log(newFileResponse, "newFileResponse")
        cheque_data_response = newFileResponse?.data?.response?.id

    }

    if (params?.agreementData) {
        params?.agreementData?.append('uploadedBy', newTransporter?.user_id)
        params?.agreementData?.append('type', 'Transporter')
        params?.agreementData?.append('typeId', newTransporter?.id)
        params?.agreementData?.append('tagId', "8")

        const newFile = await api.create(`${fileUrl}`, params?.agreementData)
        const newFileResponse = newFile.data
        console.log(newFileResponse, "newFileResponse2")
        agreement_data_response = newFileResponse?.data?.response?.id

    }

    console.log("BeforeUpdate")
    const updateBody = {
        id: newTransporter?.user_id,
        body: {
            ...newTransporter,
            cheque_file_id: cheque_data_response,
            agreement_file_id: agreement_data_response
        }
    }
    console.log("afterUpdate", updateBody)

    const baseTransporterUrl = `/transporter/${newTransporter?.user_id}`;
    return api.update(`${baseTransporterUrl}`, { transporter: { ...updateBody.body } })

}



async function updateTransporterWithFiles(params: any) {
    console.log("paramsss", params)
    const fileUrl = `file/upload`
    const newChequeData = params.chequeData
    const newAgreementData = params.agreementData
    let newChequeDataResponse = null
    let newAgreementDataResponse = null

    if (newChequeData) {
        if (params.updateBody.cheque_file_id) {
            const baseUrl = `file/${params.updateBody.cheque_file_id}`
            await api.delete(`${baseUrl}`)
        }

        newChequeData?.append('uploadedBy', params?.id)
        newChequeData?.append('type', 'Transporter')
        newChequeData?.append('typeId', params?.updateBody?.id)
        newChequeData?.append('tagId', "8")

        const newFile = await api.create(`${fileUrl}`, newChequeData)
        const newFileResponse = newFile.data
        console.log(newFileResponse, "newFileResponse")
        newChequeDataResponse = newFileResponse?.data?.response?.id

    }
    if (newAgreementData) {
        if (params.updateBody.agreement_file_id) {
            const baseUrl = `file/${params.updateBody.agreement_file_id}`
            await api.delete(`${baseUrl}`)
        }

        newAgreementData?.append('uploadedBy', params?.id)
        newAgreementData?.append('type', 'Transporter')
        newAgreementData?.append('typeId', params?.updateBody?.id)
        newAgreementData?.append('tagId', "8")

        const newFile = await api.create(`${fileUrl}`, newAgreementData)
        const newFileResponse = newFile.data
        console.log(newFileResponse, "newFileResponse1")
        newAgreementDataResponse = newFileResponse?.data?.response?.id
    }

    const baseUrl = `/transporter/${params.id}`;
    return api.update(`${baseUrl}`, { transporter: { ...params.updateBody, cheque_file_id: newChequeDataResponse ? newChequeDataResponse : params?.updateBody?.cheque_file_id, agreement_file_id: newAgreementDataResponse ? newAgreementDataResponse : params?.updateBody?.agreement_file_id } })
}


function deleteTransporter(params: any) {
    const baseUrl = `/transporter/${params.id}`;
    return api.delete(`${baseUrl}`)
}

export { fetchTransporter, fetchSingleTransporter, createTransporter, updateTransporter, createTransporterWithFiles, updateTransporterWithFiles, deleteTransporter };