// import { APICore } from 'helpers/api/apiCore';
import { ActionTypes as AuthActionTypes } from "./constants";
import { Enquiry } from "../types/DataTypes";

// const api = new APICore();

const INIT_STATE = {
  loading: true,
  enquiry: {},
  singleEnquiry: {},
  error: {},
  success: false,
  updateSuccess: false,
  updateError:null,
  formSuccess: false,
  imagePath: "",
  enquiryStatusesList: [],
  enquiryform: {},
  enquiryData: {},
  createdForm: {},
  chartData: {},
  enquiryCount: {},
  location: {},
  skuList: {},
  updateForm: false,
  deleteEnquirySuccess: false
};

type AuthActionType = {
  type: AuthActionTypes.SET_ENQUIRY
  | AuthActionTypes.SET_SINGLE_ENQUIRY
  | AuthActionTypes.SET_ERROR
  | AuthActionTypes.UPDATE_ENQUIRY_SUCCESS
  | AuthActionTypes.CREATE_ENQUIRY_SUCCESS
  | AuthActionTypes.CREATE_ENQUIRY_SUCCESS_FORM
  | AuthActionTypes.SET_ENQUIRY_STATUSES
  | AuthActionTypes.SET_ENQUIRY_FORM
  | AuthActionTypes.RESET
  | AuthActionTypes.SET_REVENUE_CHART_DATA
  | AuthActionTypes.HARD_RESET
  | AuthActionTypes.RESET_ENQUIRY_FORM
  | AuthActionTypes.SET_ENQUIRY_DASHBOARD_DATA
  | AuthActionTypes.DELETE_ENQUIRY
  | AuthActionTypes.DELETE_ENQUIRY_SUCCESS
  | AuthActionTypes.DELETE_ENQUIRY_FAIL
  | AuthActionTypes.SET_ENQUIRY_LOCATION
  | AuthActionTypes.FETCH_SKU
  | AuthActionTypes.SET_SKU
  | AuthActionTypes.UPDATE_ENQUIRY_FORM_SUCCESS
  | AuthActionTypes.UPDATE_ENQUIRY_FORM_FAIL
  | AuthActionTypes.RESET_ENQUIRY_FORM_UPDATE
  | AuthActionTypes.RESET_ENQUIRY_CUSTOM_FORM
  | AuthActionTypes.UPDATE_ENQUIRY_FAIL
  | AuthActionTypes.UPDATE_ENQUIRY_RESET
  payload: {
    actionType?: string;
    data?: Enquiry | {};
    error?: string;
  };
};

const EnquiryReducer = (state: any = INIT_STATE, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionTypes.CREATE_ENQUIRY_SUCCESS:
      return { ...state, success: true, loading: false, updateSuccess: false, enquiryData: action.payload.data, deleteEnquirySuccess: false };
    case AuthActionTypes.CREATE_ENQUIRY_SUCCESS_FORM:
      return { ...state, formSuccess: true, loading: false, createdForm: action.payload.data };
    case AuthActionTypes.SET_ENQUIRY:
      return { ...state, enquiry: action.payload, loading: false, updateSuccess: false, success: false, enquiryData: {} };
    case AuthActionTypes.SET_SINGLE_ENQUIRY:
      return { ...state, singleEnquiry: action.payload, loading: false, updateSuccess: false, success: false, enquiryForm: {}, createdForm: {} };
    case AuthActionTypes.SET_ENQUIRY_DASHBOARD_DATA:
      return { ...state, enquiryCount: action.payload.data, loading: false, updateSuccess: false, success: false }
    case AuthActionTypes.SET_ENQUIRY_FORM:
      return { ...state, enquiryform: action.payload, loading: false, updateSuccess: false, success: false, createdForm: {} };
    case AuthActionTypes.UPDATE_ENQUIRY_SUCCESS:
      return { ...state, loading: false, updateSuccess: true, success: false };
      case AuthActionTypes.UPDATE_ENQUIRY_FAIL:
        return { ...state, loading: false, updateSuccess: false, success: false,updateError:action.payload.error };
    case AuthActionTypes.UPDATE_ENQUIRY_RESET:
      return {...state,updateSuccess:false,updateError:null,success:false}
    case AuthActionTypes.SET_ENQUIRY_STATUSES:
      return { ...state, enquiryStatusesList: action.payload.data, loading: false, updateSuccess: false, success: false };
    case AuthActionTypes.SET_REVENUE_CHART_DATA:
      return { ...state, chartData: action.payload.data, loading: false, updateSuccess: false, success: false }
    case AuthActionTypes.RESET:
      return { ...INIT_STATE, singleEnquiry: state.singleEnquiry, enquiryStatusesList: state.enquiryStatusesList, enquiryForm: {} }
    case AuthActionTypes.RESET_ENQUIRY_FORM:
      return { ...INIT_STATE, singleEnquiry: state.singleEnquiry, enquiryStatusesList: state.enquiryStatusesList, enquiryForm: {}, error: {} }
    case AuthActionTypes.SET_SKU:
      return { ...state, skuList: action.payload.data, error: {} }
    case AuthActionTypes.HARD_RESET:
      return { ...INIT_STATE }
    case AuthActionTypes.SET_ERROR:
      return { ...state, enquiry: {}, error: action.payload, loading: false };
    case AuthActionTypes.DELETE_ENQUIRY_SUCCESS:
      return { ...state, deleteEnquirySuccess: true, error: false };
    case AuthActionTypes.DELETE_ENQUIRY_FAIL:
      return { ...state, deleteEnquirySuccess: false, error: action.payload.error };
    case AuthActionTypes.SET_ENQUIRY_LOCATION:
      return { ...state, loading: false, updateSuccess: false, success: false, location: action.payload.data };
    case AuthActionTypes.UPDATE_ENQUIRY_FORM_SUCCESS:
      return { ...state, updateForm: true }
    case AuthActionTypes.UPDATE_ENQUIRY_FORM_FAIL:
      return { ...state, updateForm: false, error: action.payload.error }
    case AuthActionTypes.RESET_ENQUIRY_FORM_UPDATE:
      return { ...state, updateForm: false, error: {} }
    case AuthActionTypes.RESET_ENQUIRY_CUSTOM_FORM:
      return { ...state, formSuccess: false }
    default:
      return { ...state };
  }
};

export default EnquiryReducer;
