import { ActionTypes } from './constants';

export type ContactActionType = {
    type: ActionTypes.SET_CONTACT | ActionTypes.SET_ERROR | ActionTypes.FETCH_CONTACT | ActionTypes.SYNC_CONTACTS;

    payload: {} | string;
}

export const getContact = (): ContactActionType => ({
    type: ActionTypes.FETCH_CONTACT,
    payload: {}
})

export const syncContacts = (): ContactActionType => ({ 
    type: ActionTypes.SYNC_CONTACTS,
    payload: {}
});


export const contactApiResponse = (actionType: string, data: any): ContactActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_CONTACT:
            return {
                type: ActionTypes.SET_CONTACT,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}


export const contactApiError = (actionType: string, error: any): ContactActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})