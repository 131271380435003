export enum ActionTypes {
  SET_TASK = "SET_TASK",
  SET_TASK_COUNT = "SET_TASK_COUNT",
  SET_ERROR = "SET_TASK",
  CREATE_TASK = "CREATE_TASK",
  FETCH_TASK = "FETCH_TASK",
  FETCH_TASK_COUNT = "FETCH_TASK_COUNT",
  UPDATE_TASK = "UPDATE_TASK",
  FETCH_SINGLE_TASKS = "FETCH_SINGLE_TASKS",
  SET_SINGLE_TASK = "SET_SINGLE_TASK",
  UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS",
  UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL",
  ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS",
  ADD_TASK_FAIL = "ADD_TASK_FAIL",
  FETCH_TASK_STATUS = "FETCH_TASK_STATUS",
  FETCH_TASK_PRIORITY = "FETCH_TASK_PRIORITY",
  SET_TASK_STATUS = "SET_TASK_STATUS",
  SET_TASK_PRIORITY = "SET_TASK_PRIORITY",
  RESET_TASK_STATE = "RESET_TASK_STATE",

  DELETE_TASK = "DELETE_TASK",
  DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAIL = "DELETE_TASK_FAIL"
}
