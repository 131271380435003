import { ActionTypes } from './constants';

export type CustomerActionType = {
    type:
    | ActionTypes.CREATE_CUSTOMER
    | ActionTypes.FETCH_CUSTOMER
    | ActionTypes.SET_CUSTOMER
    | ActionTypes.SET_ERROR
    | ActionTypes.UPDATE_CUSTOMER
    | ActionTypes.FETCH_SINGLE_CUSTOMER
    | ActionTypes.SET_SINGLE_CUSTOMER
    | ActionTypes.UPDATE_CUSTOMER_SUCCESS
    | ActionTypes.UPDATE_CUSTOMER_FAIL
    | ActionTypes.ADD_CUSTOMER_SUCCESS
    | ActionTypes.ADD_CUSTOMER_FAIL
    | ActionTypes.DASHBOARD_CUSTOMER_DATA
    | ActionTypes.SET_CUSTOMER_DASHBOARD_DATA
    | ActionTypes.DELETE_CUSTOMER_FAIL
    | ActionTypes.DELETE_CUSTOMER
    | ActionTypes.SET_CUSTOMER_LOCATION
    | ActionTypes.DELETE_CUSTOMER_BY_PHONE
    | ActionTypes.DELETE_CUSTOMER_FAIL_BY_PHONE
    | ActionTypes.DELETE_CUSTOMER_SUCCESS_BY_PHONE


    payload: {} | string;
}



export const getCustomer = (data: any): CustomerActionType => ({
    type: ActionTypes.FETCH_CUSTOMER,
    payload: { data }
})




//dispatch action to get single customer data
export const getSingleCustomer = (id: any): CustomerActionType => ({
    type: ActionTypes.FETCH_SINGLE_CUSTOMER,
    payload: { id }
})


//dispatch action to create new customer
export const createCustomer = (data: any): CustomerActionType => ({
    type: ActionTypes.CREATE_CUSTOMER,
    payload: { data }
})


//dispatch action to update customer
export const updateCustomer = (data: any): CustomerActionType => ({
    type: ActionTypes.UPDATE_CUSTOMER,
    payload: { data }
})

export const getDashboardCustomerData = (data: any): CustomerActionType => ({
    type: ActionTypes.DASHBOARD_CUSTOMER_DATA,
    payload: { data }
})

export const getCustomerMap = () => ({
    type: ActionTypes.FETCH_CUSTOMER_LOCATION,
    payload: {}
})

export const resetCustomer = () => ({
    type: ActionTypes.RESET_CUSTOMER,
    payload: {}
})



//this will trigger in the saga while receiving response of fetching customer data
export const customerApiResponse = (actionType: string, data: any): CustomerActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_CUSTOMER:
            return {
                type: ActionTypes.SET_CUSTOMER,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_CUSTOMER:
            return {
                type: ActionTypes.SET_SINGLE_CUSTOMER,
                payload: { data }
            }
        case ActionTypes.DASHBOARD_CUSTOMER_DATA:
            return {
                type: ActionTypes.SET_CUSTOMER_DASHBOARD_DATA,
                payload: { data }
            }
        case ActionTypes.FETCH_CUSTOMER_LOCATION:
            return {
                type: ActionTypes.SET_CUSTOMER_LOCATION,
                payload: { actionType, data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

//this action will be executed when customer is updated successfully
export const customerUpdateResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_CUSTOMER_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while updating
export const customerUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_CUSTOMER_FAIL,
        payload: { actionType, error }
    }
}

//this action will be executed when customer is added successfully
export const customerAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_CUSTOMER_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while adding
export const customerAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_CUSTOMER_FAIL,
        payload: { actionType, error }
    }
}

// this action will be executed when there is error while fetching customer info
export const customerApiError = (actionType: string, error: any): CustomerActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})

export const deleteCustomer = (data: any): CustomerActionType => ({
    type: ActionTypes.DELETE_CUSTOMER,
    payload: { data }
})

export const customerDeleteResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.DELETE_CUSTOMER_SUCCESS,
        payload: { data }
    }
}
export const customerDeleteError = (actionType: string, error: any): CustomerActionType => ({
    type: ActionTypes.DELETE_CUSTOMER_FAIL,
    payload: { actionType, error }
})

export const deleteCustomerByPhoneNo = (data: any): CustomerActionType => ({
    type: ActionTypes.DELETE_CUSTOMER_BY_PHONE,
    payload: { data }
})

export const customerDeleteByPhoneResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.DELETE_CUSTOMER_SUCCESS_BY_PHONE,
        payload: { data }
    }
}
export const customerDeleteByPhoneError = (actionType: string, error: any): CustomerActionType => ({
    type: ActionTypes.DELETE_CUSTOMER_FAIL_BY_PHONE,
    payload: { actionType, error }
})