export type Enquiry = {
  payload: {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
  };
};
export type Task = {
  payload: {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
  };
};
export type User = {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
};

export type LoginState = {
  email: string;
  key: string;
  otp: string;
  isValidSession: boolean;
  error: {};
  loading: boolean;
};

export type Response = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    key?: string;
    session?: UserSession;
    enquiryId?: any;
    isValidSession?: boolean;
    userType?: UserType;
    userId: string,
    userName: string,
    email?: string,
    privileges: string[]
  };
};

export type VendorResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    vendor?: any[]
  }
}


export type TransporterResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    transporter?: any[]
  }
}

export type TeamResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    team?: any[]
  }
}

export type ReportResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    report?: any[]
  }
}

export type EmployeeResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    employee?: any[]
    singleEmployee?: any[]
  }
}

export type Form = {
  payload: {
    id: number;
    name: string;
    status: string;
    type: string;
    tag: string;
    description: string;
    customForm: string;
  };
};

export type FormState = {
  loading?: boolean;
  form: Form | {};
}


export type State = {
  loading?: boolean;
  enquiry: Enquiry | {};
  error: {};
};


export type ProductState = {
  loading?: boolean;
  product: Enquiry | {};
}
export type TaskState = {
  loading?: boolean;
  task: Task | {};
  error: {};
};

export type Params = {
  id?: string;
};

export type Approval = {
  payload: {
    id: number;
    enquiry_id: number;
    title: string;
    description: string;
    type: string;
    type_id: number;
    assigned_by: number;
    assigned_to: number;
    status: number;
    comment: string;
  };
};

export type ApprovalState = {
  loading?: boolean;
  approval: Approval | {};
  error: {};
};

export type ApprovalResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    approval?: any[]
  }
}

export enum UserType {
  EMPLOYEE = 'Employee',
  CUSTOMER = 'Customer',
  VENDOR = 'Vendor',
  TRANSPORTER = 'Transporter',
  SUPPLIER = "Supplier"
}

export type UserSession = {
  sessionId: string;
  userType: UserType;
  userRole: string;
  privileges: string[];
  userId: string;
  userName: string;
}

export type Chat = {
  payload: {
    id: number;
    enquiry_id: number;
    title: string;
    description: string;
    type: string;
    type_id: number;
    assigned_by: number;
    assigned_to: number;
    status: number;
    comment: string;
  };
};

export type ChatState = {
  loading?: boolean;
  chat: Chat | {};
  error: {};
};

export type ChatResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    chat?: any[]
  }
}


export type Notification = {
  success: boolean;
  loading?: boolean;
  errors?: string[];
  response: {
    notification?: any[]
  }
}

export const APP_PRIVILEGES = {
  "Customer_Listing": {
    description: "Customer Listing",
    key: "Customer_Listing",
    name: "May Access Customer List",
  },
  "May_Update_Customer": {
    description: "May Update Customer Data",
    key: "May_Update_Customer",
    name: "May Update Customer"
  },
  "May_Add_Customer": {
    description: "May Add Customer",
    key: "May_Add_Customer",
    name: "May Add Customer"
  },
  "May_Delete_Customer": {
    description: "May Delete Customer",
    key: "May_Delete_Customer",
    name: "May Delete Customer"
  },
  "Organization_Listing": {
    description: "Organization Listing",
    key: "Organization_Listing",
    name: "May Access Organization List",
  },
  "May_Update_Organization": {
    description: "May Update Organization Data",
    key: "May_Update_Organization",
    name: "May Update Organization"
  },
  "May_Add_Organization": {
    description: "May Add Organization",
    key: "May_Add_Organization",
    name: "May Add Organization"
  },
  "May_Delete_Organization": {
    description: "May Delete Organization",
    key: "May_Delete_Organization",
    name: "May Delete Organization"
  },
  "May_Access_Vendor_List": {
    description: "Vendor Listing",
    key: "May_Access_Vendor_List",
    name: "May Access Vendor List"
  },
  "May_Update_Vendor": {
    description: "May Update Vendor Data",
    key: "May_Update_Vendor",
    name: "May Update Vendor"
  },
  "May_Add_Vendor": {
    description: "May Add Vendor",
    key: "May_Add_Vendor",
    name: "May Add Vendor"
  },
  "May_Delete_Vendor": {
    description: "May Delete Vendor",
    key: "May_Delete_Vendor",
    name: "May Delete Vendor"
  },
  "May_Access_Transporter_List": {
    description: "Transporter Listing",
    key: "May_Access_Transporter_List",
    name: "May Access Transporter List"
  },
  "May_Update_Transporter": {
    description: "May Update Transporter Data",
    key: "May_Update_Transporter",
    name: "May Update Transporter"
  },
  "May_Add_Transporter": {
    description: "May Add Transporter",
    key: "May_Add_Transporter",
    name: "May Add Transporter"
  },
  "May_Delete_Transporter": {
    description: "May Delete Transporter",
    key: "May_Delete_Transporter",
    name: "May Delete Transporter"
  },
  "May_Access_Query": {
    description: "Complete access to query",
    key: "May_Access_Query",
    name: "May Access Query"
  },
  "May_Add_Query": {
    description: "May Add Query",
    key: "May_Add_Query",
    name: "May Add Query"
  },
  "May_Update_Query": {
    description: "May Update Query",
    key: "May_Update_Query",
    name: "May Update Query"
  },
  "May_Delete_Query": {
    description: "May Delete Query",
    key: "May_Delete_Query",
    name: "May Delete Query"
  },
  "May_Access_Enquiry_List": {
    description: "Enquiry Listing",
    key: "May_Access_Enquiry_List",
    name: "May Access Enquiry List"
  },
  "May_Update_Enquiry": {
    description: "May Update Enquiry Data",
    key: "May_Update_Enquiry",
    name: "May Update Transporter"
  },
  "May_Add_Enquiry": {
    description: "May Add Enquiry",
    key: "May_Add_Enquiry",
    name: "May Add Enquiry"
  },
  "May_Delete_Enquiry": {
    description: "May Delete Enquiry",
    key: "May_Delete_Enquiry",
    name: "May Delete Enquiry"
  },
  "May_Access_Project_List": {
    description: "Project Listing",
    key: "May_Access_Project_List",
    name: "May Access Project List"
  },
  "May_Update_Project": {
    description: "May Update Project Data",
    key: "May_Update_Project",
    name: "May Update Project"
  },
  "May_Delete_Project": {
    description: "May Delete Project Data",
    key: "May_Delete_Project",
    name: "May Delete Project"
  },
  "May_Access_Job_List": {
    description: "Job Listing",
    key: "May_Access_Job_List",
    name: "May Access Job List"
  },
  "May_Update_Job": {
    description: "May Update Job Data",
    key: "May_Update_Job",
    name: "May Update Job"
  },
  "May_Delete_Job": {
    description: "May Delete Job Data",
    key: "May_Delete_Job",
    name: "May Delete Job"
  },
  "May_Access_Approval_List": {
    description: "Approval Listing",
    key: "May_Access_Approval_List",
    name: "May Access Approval List"
  },
  "May_Update_Approval": {
    description: "May Update Approval Data",
    key: "May_Update_Approval",
    name: "May Update Approval"
  },
  "May_Add_Approval": {
    description: "May Add Approval",
    key: "May_Add_Approval",
    name: "May Add Approval"
  },
  "May_Delete_Approval": {
    description: "May Delete Approval",
    key: "May_Delete_Approval",
    name: "May Delete Approval"
  },
  "May_Access_Task_List": {
    description: "Task Listing",
    key: "May_Access_Task_List",
    name: "May Access Task List"
  },
  "May_Update_Task": {
    description: "May Update Task Data",
    key: "May_Update_Task",
    name: "May Update Task"
  },
  "May_Add_Task": {
    description: "May Add Task",
    key: "May_Add_Task",
    name: "May Add Task"
  },
  "May_Delete_Task": {
    description: "May Delete Task",
    key: "May_Delete_Task",
    name: "May Delete Task"
  },
  "May_Access_Team_List": {
    description: "Team Listing",
    key: "May_Access_Team_List",
    name: "May Access Team List"
  },
  "May_Update_Team": {
    description: "May Update Team Data",
    key: "May_Update_Team",
    name: "May Update Team"
  },
  "May_Add_Team": {
    description: "May Add Team",
    key: "May_Add_Team",
    name: "May Add Team"
  },
  "May_Delete_Team": {
    description: "May Delete Team",
    key: "May_Delete_Team",
    name: "May Delete Team"
  },
  "May_Access_Employee_List": {
    description: "Employee Listing",
    key: "May_Access_Employee_List",
    name: "May Access Employee List"
  },
  "May_Update_Employee": {
    description: "May Update Employee Data",
    key: "May_Update_Employee",
    name: "May Update Employee"
  },
  "May_Add_Employee": {
    description: "May Add Employee",
    key: "May_Add_Employee",
    name: "May Add Employee"
  },
  "May_Delete_Employee": {
    description: "May Delete Employee",
    key: "May_Delete_Employee",
    name: "May Delete Employee"
  },
  "May_Access_Product_List": {
    description: "Product Listing",
    key: "May_Access_Product_List",
    name: "May Access Product List"
  },
  "May_Update_Product": {
    description: "May Update Product Data",
    key: "May_Update_Product",
    name: "May Update Product"
  },
  "May_Add_Product": {
    description: "May Add Product",
    key: "May_Add_Product",
    name: "May Add Product"
  },
  "May_Delete_Product": {
    description: "May Delete Product",
    key: "May_Delete_Product",
    name: "May Delete Product"
  },
  "May_Access_Roles_List": {
    description: "Roles Listing",
    key: "May_Access_Roles_List",
    name: "May Access Roles List"
  },
  "May_Update_Roles": {
    description: "May Update Roles Data",
    key: "May_Update_Roles",
    name: "May Update Roles"
  },
  "May_Add_Roles": {
    description: "May Add Roles",
    key: "May_Add_Roles",
    name: "May Add Roles"
  },
  "May_Delete_Roles": {
    description: "May Delete Roles",
    key: "May_Delete_Roles",
    name: "May Delete Roles"
  },
  "May_Access_Form_List": {
    description: "Form Listing",
    key: "May_Access_Form_List",
    name: "May Access Form List"
  },
  "May_Update_Form": {
    description: "May Update Form Data",
    key: "May_Update_Form",
    name: "May Update Form"
  },
  "May_Add_Form": {
    description: "May Add Form",
    key: "May_Add_Form",
    name: "May Add Form"
  },
  "May_Delete_Form": {
    description: "May Delete Form",
    key: "May_Delete_Form",
    name: "May Delete Form"
  },
  "May_Access_Tag_List": {
    description: "Tag Listing",
    key: "May_Access_Tag_List",
    name: "May Access Tag List"
  },
  "May_Update_Tag": {
    description: "May Update Tag Data",
    key: "May_Update_Tag",
    name: "May Update Tag"
  },
  "May_Add_Tag": {
    description: "May Add Tag",
    key: "May_Add_Tag",
    name: "May Add Tag"
  },
  "May_Delete_Tag": {
    description: "May Delete Tag",
    key: "May_Delete_Tag",
    name: "May Delete Tag"
  },
  "May_Access_Machine_List": {
    description: "Machine Listing",
    key: "May_Access_Machine_List",
    name: "May Access Machine List"
  },
  "May_Update_Machine": {
    description: "May Update Machine Data",
    key: "May_Update_Machine",
    name: "May Update Machine"
  },
  "May_Add_Machine": {
    description: "May Add Machine",
    key: "May_Add_Machine",
    name: "May Add Machine"
  },
  "May_Delete_Machine": {
    description: "May Delete Machine",
    key: "May_Delete_Machine",
    name: "May Delete Machine"
  },
  "May_Access_Raw_Material_List":
  {
    description: "Raw Material Listing",
    key: "May_Access_Raw_Material_List",
    name: "May Access Raw Material List"
  },
  "May_Update_Raw_Material":
  {
    description: "May Update Raw Material",
    key: "May_Update_Raw_Material",
    name: "May Update Raw Material"
  },
  "May_Add_Raw_Material":
  {
    description: "May Add Raw Material",
    key: "May_Add_Raw_Material",
    name: "May Add Raw Material"
  },
  "May_Delete_Raw_Material":
  {
    description: "May Delete Raw Material",
    key: "May_Delete_Raw_Material",
    name: "May Delete Raw Material"
  },
  "May_Access_Warehouse_List":
  {
    description: "Warehouse Listing",
    key: "May_Access_Warehouse_List",
    name: "May Access Warehouse List"
  },
  "May_Update_Warehouse":
  {
    description: "May Update Warehouse",
    key: "May_Update_Warehouse",
    name: "May Update Warehouse"
  },
  "May_Add_Warehouse":
  {
    description: "May Add Warehouse",
    key: "May_Add_Warehouse",
    name: "May Add Warehouse"
  },
  "May_Delete_Warehouse":
  {
    description: "May Delete Warehouse",
    key: "May_Delete_Warehouse",
    name: "May Delete Warehouse"
  },
  "May_Access_Supplier_List":
  {
    description: "Supplier Listing",
    key: "May_Access_Supplier_List",
    name: "May Access Supplier List"
  },
  "May_Update_Supplier":
  {
    description: "May Update Supplier",
    key: "May_Update_Supplier",
    name: "May Update Supplier"
  },
  "May_Add_Supplier":
  {
    description: "May Add Supplier",
    key: "May_Add_Supplier",
    name: "May Add Supplier"
  },
  "May_Delete_Supplier":
  {
    description: "May Delete Supplier",
    key: "May_Delete_Supplier",
    name: "May Delete Supplier"
  },
}
