export enum ActionTypes {
    SET_ROLE = "SET_ROLE",
    SET_ERROR = "SET_ERROR",
    CREATE_ROLE = "CREATE_ROLE",
    FETCH_ROLE = "FETCH_ROLE",
    UPDATE_ROLE = "UPDATE_ROLE",
    FETCH_SINGLE_ROLE = "FETCH_SINGLE_ROLE",
    SET_SINGLE_ROLE = "SET_SINGLE_ROLE",
    UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS",
    UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL",
    ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS",
    ADD_ROLE_FAIL = "ADD_ROLE_FAIL",
    FETCH_PRIVILEGES = "FETCH_PRIVILEGES",
    SET_PRIVILEGES = "SET_PRIVILEGES",

    DELETE_ROLE = "DELETE_ROLE",
    DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS",
    DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL"
}