import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { ActionTypes } from './constants';
import { fetchNotification, updateAllNotification, updateNotification } from "helpers"
import { notificationApiError, notificationApiResponse, readAllNotificationResponse } from "./actions";

function* notification(params: any): SagaIterator {
    try {
        const response = yield call(fetchNotification, { userId: params.payload.data.userId, userType: params.payload.data.userType, typeId: params.payload.data.typeId, limit: params.payload.data.limit });
        const notificationData = response.data;
        // console.log("notificationData", notificationData)
        yield put(notificationApiResponse(params.payload.data.typeId ? ActionTypes.FETCH_NOTIFICATIONS : ActionTypes.FETCH_ALL_NOTIFICATIONS, notificationData));

    } catch (error: any) {
        yield put(notificationApiError(ActionTypes.FETCH_NOTIFICATIONS, error));
    }
}

function* _updateNotification(params: any): SagaIterator {
    try {
        const response = yield call(updateNotification, { userId: params.payload.data.userId, id: params.payload.data.id });
        const notificationData = response.data;
        yield put(notificationApiResponse(ActionTypes.UPDATE_NOTIFICATION_SUCCESS, notificationData));

        //sending new notification after updating
        const res = yield call(fetchNotification, { userId: params.payload.data.userId, userType: params.payload.data.userType, typeId: params.payload.data.typeId });
        const notifications = res.data;
        yield put(notificationApiResponse(ActionTypes.FETCH_ALL_NOTIFICATIONS, notifications));

    } catch (error: any) {
        yield put(notificationApiError(ActionTypes.UPDATE_NOTIFICATION_FAIL, error));
    }
}
function* _updateAllNotification(params: any): SagaIterator {
    try {
        const response = yield call(updateAllNotification, { userId: params.payload.data.userId });
        const notificationData: any = response.data;
        console.log(notificationData, "notificationData")
        yield put(readAllNotificationResponse(ActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS, notificationData));
        const res = yield call(fetchNotification, { userId: params.payload.data.userId, userType: params.payload.data.userType, typeId: params.payload.data.typeId });
        const notifications = res.data;
        yield put(notificationApiResponse(ActionTypes.FETCH_ALL_NOTIFICATIONS, notifications));
    } catch (error: any) {
        yield put(readAllNotificationResponse(ActionTypes.READ_ALL_NOTIFICATIONS_FAIL, error));
    }
}




export function* watchNotification() {
    yield takeEvery(ActionTypes.FETCH_NOTIFICATIONS, notification);
    yield takeEvery(ActionTypes.UPDATE_NOTIFICATION_STATUS, _updateNotification)
    yield takeEvery(ActionTypes.READ_ALL_NOTIFICATIONS, _updateAllNotification)

}

export default watchNotification;
