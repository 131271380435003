import { TeamResponse } from 'redux/types/DataTypes';
import { ActionTypes } from './constants';

export type TeamActionType = {
    type:
    | ActionTypes.CREATE_TEAM
    | ActionTypes.FETCH_TEAM
    | ActionTypes.SET_ERROR
    | ActionTypes.SET_TEAM
    | ActionTypes.UPDATE_TEAM
    | ActionTypes.FETCH_SINGLE_TEAM
    | ActionTypes.SET_SINGLE_TEAM
    | ActionTypes.ADD_EMPLOY_SUCCESS
    | ActionTypes.ADD_EMPLOY_FAIL
    | ActionTypes.UPDATE_TEAM_SUCCESS
    | ActionTypes.UPDATE_TEAM_FAIL
    | ActionTypes.DELETE_TEAM
    | ActionTypes.DELETE_TEAM_SUCCESS
    | ActionTypes.DELETE_TEAM_FAIL
    payload: {} | string;
}

//disptach action to get all teams
export const getTeam = (data: any): TeamActionType => ({
    type: ActionTypes.FETCH_TEAM,
    payload: { data }
})

//disptach action to get team details
export const getSingleTeam = (id: any): TeamActionType => ({
    type: ActionTypes.FETCH_SINGLE_TEAM,
    payload: { id }
})

//disptach action to update team
export const updateTeam = (data: any): TeamActionType => ({
    type: ActionTypes.UPDATE_TEAM,
    payload: { data }
})

//disptach action to create team
export const createTeam = (data: any): TeamActionType => ({
    type: ActionTypes.CREATE_TEAM,
    payload: { data }
})

//this action will be executed to handle api response
export const teamApiResponse = (
    actionType: string,
    data: TeamResponse
) => {
    switch (actionType) {
        case ActionTypes.FETCH_TEAM:
            return {
                type: ActionTypes.SET_TEAM,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_TEAM:
            return {
                type: ActionTypes.SET_SINGLE_TEAM,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

// this action will be executed when team updated successfully
export const teamUpdateResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_TEAM_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while updating team
export const teamUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_TEAM_FAIL,
        payload: { actionType, error }
    }
}

// this action will be executed when team is added successfully
export const teamAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_EMPLOY_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while adding team
export const teamAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_EMPLOY_FAIL,
        payload: { actionType, error }
    }
}

// this action will be executed when there is error while adding team
export const teamApiError = (actionType: string, error: any): TeamActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})



//! DELETING Team
export const deleteTeam = (data: any): TeamActionType => ({
    type: ActionTypes.DELETE_TEAM,
    payload: { data }
})

export const deleteTeamResponse = (actionType: any, data: any): TeamActionType => ({
    type: ActionTypes.DELETE_TEAM_SUCCESS,
    payload: { actionType, data }
})
export const deleteTeamError = (actionType: any, error: any): TeamActionType => ({
    type: ActionTypes.DELETE_TEAM_FAIL,
    payload: { actionType, error }
})