import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchChats, fetchCountChats } from "helpers";
import { chatApiResponse, chatApiError } from "./actions";
import { ActionTypes } from './constants';

function* chat(params: any): SagaIterator {
    try {
        const response = yield call(fetchChats, { page: params.payload.data.page, limit: params.payload.data.limit,sortBy: params.payload.data.sortBy, sort: params.payload.data.sort,userId: params.payload.data?.userId,userType: params.payload.data?.userType});
        const chatData = yield response.data;
        yield put(chatApiResponse(ActionTypes.FETCH_CHAT, chatData));
    } catch (error: any) {
        yield put(chatApiError(ActionTypes.FETCH_CHAT, error));
    }
}

function* countChats(params: any): SagaIterator {
    try {
        const response = yield call(fetchCountChats, { user_id: params.payload.data.user_id });
        const countChatData = yield response.data;
        yield put(chatApiResponse(ActionTypes.FETCH_CHAT_COUNT, countChatData));
    } catch (error: any) {
        yield put(chatApiError(ActionTypes.FETCH_CHAT_COUNT, error));
    }
}

export function* watchChat() {
    yield takeEvery(ActionTypes.FETCH_CHAT, chat);
    yield takeEvery(ActionTypes.FETCH_CHAT_COUNT, countChats);
}

export default watchChat;
