import { ActionTypes } from "./constants";

export type TriggerActionType = {
    type:
    | ActionTypes.FETCH_ALL_TRIGGER
    | ActionTypes.SET_ALL_TRIGGER
    | ActionTypes.FETCH_TRIGGER_TYPE_BY
    | ActionTypes.SET_TRIGGER_TYPE_BY
    | ActionTypes.FETCH_TRIGGER_TYPE_FOR
    | ActionTypes.SET_TRIGGER_TYPE_FOR
    | ActionTypes.FETCH_TRIGGER_ENTITY
    | ActionTypes.SET_TRIGGER_ENTITY
    | ActionTypes.FETCH_TRIGGER_ENTITY_TYPE
    | ActionTypes.SET_TRIGGER_ENTITY_TYPE
    | ActionTypes.FETCH_TRIGGER_ENTITY_STATE
    | ActionTypes.SET_TRIGGER_ENTITY_STATE
    | ActionTypes.FETCH_SINGLE_TRIGGER
    | ActionTypes.SET_SINGLE_TRIGGER
    | ActionTypes.RESET_ENTITY_DEPENDENT
    | ActionTypes.RESET_ENTITY_TYPE_DEPENDENT
    | ActionTypes.RESET_ENTITY_STATE_DEPENDENT
    | ActionTypes.ADD_TRIGGER
    | ActionTypes.ADD_TRIGGER_SUCCESS
    | ActionTypes.ADD_TRIGGER_FAIL
    | ActionTypes.UPDATE_TRIGGER
    | ActionTypes.UPDATE_TRIGGER_SUCCESS
    | ActionTypes.UPDATE_TRIGGER_FAIL
    | ActionTypes.SET_TRIGGER_ERROR;
    payload: {} | string;
}



export const getTriggers = (data: any): TriggerActionType => ({
    type: ActionTypes.FETCH_ALL_TRIGGER,
    payload: { data }
})


export const getTriggersBy = (): TriggerActionType => ({
    type: ActionTypes.FETCH_TRIGGER_TYPE_BY,
    payload: {}
})

export const getTriggerEntity = (): TriggerActionType => ({
    type: ActionTypes.FETCH_TRIGGER_ENTITY,
    payload: {}
})

export const getTriggerEntityType = (data: any): TriggerActionType => ({
    type: ActionTypes.FETCH_TRIGGER_ENTITY_TYPE,
    payload: { data }
})


export const getTriggerEntityTypeState = (data: any): TriggerActionType => ({
    type: ActionTypes.FETCH_TRIGGER_ENTITY_STATE,
    payload: { data }
})

export const getTriggersFor = (data: any): TriggerActionType => ({
    type: ActionTypes.FETCH_TRIGGER_TYPE_FOR,
    payload: { data }
})


export const resetEntityDependent = (): TriggerActionType => ({
    type: ActionTypes.RESET_ENTITY_DEPENDENT,
    payload: {}
})


export const resetEntityTypeDependent = (): TriggerActionType => ({
    type: ActionTypes.RESET_ENTITY_TYPE_DEPENDENT,
    payload: {}
})

export const resetEntityStateDependent = (): TriggerActionType => ({
    type: ActionTypes.RESET_ENTITY_STATE_DEPENDENT,
    payload: {}
})

export const getSingleTrigger = (id: any): TriggerActionType => ({
    type: ActionTypes.FETCH_SINGLE_TRIGGER,
    payload: { id }
})



export const createTrigger = (data: any): TriggerActionType => ({
    type: ActionTypes.ADD_TRIGGER,
    payload: { data }
})


export const updateTrigger = (data: any): TriggerActionType => ({
    type: ActionTypes.UPDATE_TRIGGER,
    payload: { data }
})

export const triggerApiResponse = (actionType: string, data: any): TriggerActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_ALL_TRIGGER:
            return {
                type: ActionTypes.SET_ALL_TRIGGER,
                payload: { data }
            }
        case ActionTypes.FETCH_TRIGGER_ENTITY:
            return {
                type: ActionTypes.SET_TRIGGER_ENTITY,
                payload: { data }
            }
        case ActionTypes.FETCH_TRIGGER_ENTITY_TYPE:
            return {
                type: ActionTypes.SET_TRIGGER_ENTITY_TYPE,
                payload: { data }
            }
        case ActionTypes.FETCH_TRIGGER_ENTITY_STATE:
            return {
                type: ActionTypes.SET_TRIGGER_ENTITY_STATE,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_TRIGGER:
            return {
                type: ActionTypes.SET_SINGLE_TRIGGER,
                payload: { data }
            }
        case ActionTypes.FETCH_TRIGGER_TYPE_FOR:
            return {
                type: ActionTypes.SET_TRIGGER_TYPE_FOR,
                payload: { data }
            }
        case ActionTypes.FETCH_TRIGGER_TYPE_BY:
            return {
                type: ActionTypes.SET_TRIGGER_TYPE_BY,
                payload: { data }
            }
        case ActionTypes.ADD_TRIGGER_SUCCESS:
            return {
                type: ActionTypes.ADD_TRIGGER_SUCCESS,
                payload: { data }
            }
        case ActionTypes.ADD_TRIGGER_FAIL:
            return {
                type: ActionTypes.ADD_TRIGGER_FAIL,
                payload: { error: data }
            }
        case ActionTypes.UPDATE_TRIGGER_SUCCESS:
            return {
                type: ActionTypes.UPDATE_TRIGGER_SUCCESS,
                payload: { data }
            }
        case ActionTypes.UPDATE_TRIGGER_FAIL:
            return {
                type: ActionTypes.UPDATE_TRIGGER_FAIL,
                payload: { error: data }
            }
        default:
            return {
                type: ActionTypes.SET_TRIGGER_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

export const triggerApiError = (actionType: string, error: any): TriggerActionType => ({
    type: ActionTypes.SET_TRIGGER_ERROR,
    payload: { actionType, error }
})