import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchSupplier, fetchSingleSupplier, createSupplier, updateSupplier, fetchSupplierForDasbord, deleteSupplier } from "../../helpers/api/supplier";
import { ActionTypes } from './constants'
import { supplierAddError, supplierAddResponse, supplierApiError, supplierApiResponse, supplierUpdateError, supplierUpdateResponse, supplierDeleteError, supplierDeleteResponse } from "./actions";


function* supplier(params: any): SagaIterator {
    try {
        console.log(params, "params2")

        const response = yield call(fetchSupplier, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, filterBy: params.payload.data.filterBy, searchBy: params.payload?.data?.searchBy, orgId: params.payload?.data?.orgId });
        const supplierData = yield response.data;

        yield put(supplierApiResponse(ActionTypes.FETCH_SUPPLIER, supplierData));
    } catch (e: any) {
        yield put(supplierApiError(ActionTypes.FETCH_SUPPLIER, e))
    }
}



function* _dashboardData(params: any): SagaIterator {
    try {
        const response = yield call(fetchSupplierForDasbord, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter });
        const supplierData = yield response.data;
        yield put(supplierApiResponse(ActionTypes.DASHBOARD_SUPPLIER_DATA, supplierData));
    } catch (e: any) {
        yield put(supplierApiError(ActionTypes.DASHBOARD_SUPPLIER_DATA, e))
    }
}


function* singleSupplier(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleSupplier, params.payload.id);
        const singleSupplier = response.data;
        yield put(supplierApiResponse(ActionTypes.FETCH_SINGLE_SUPPLIER, singleSupplier))
    } catch (e: any) {
        yield put(supplierApiError(ActionTypes.FETCH_SINGLE_SUPPLIER, e));
    }
}


function* _createSupplier({ payload }: any): SagaIterator {
    try {
        const response = yield call(createSupplier, { supplier: payload.data })
        console.log("response123", payload.data)
        const supplierData = response.data;
        yield put(supplierAddResponse(ActionTypes.CREATE_SUPPLIER, supplierData))
    } catch (e: any) {
        yield put(supplierAddError(ActionTypes.CREATE_SUPPLIER, e));
    }
}

function* _updateSupplier({ payload }: any): SagaIterator {
    try {
        console.log("payload", payload)
        const response = yield call(updateSupplier, { id: payload.data.id, updateBody: payload.data.updateBody })
        const supplierData = response.data;
        yield put(supplierUpdateResponse(ActionTypes.UPDATE_SUPPLIER, supplierData));
    } catch (e: any) {
        yield put(supplierUpdateError(ActionTypes.UPDATE_SUPPLIER, e));
    }
}

function* _deleteSupplier({ payload }: any): SagaIterator {
    try {
        const response = yield call(deleteSupplier, { id: payload.data.id })
        const supplierDelResponse = response.data;
        yield put(supplierDeleteResponse(ActionTypes.DELETE_SUPPLIER_SUCCESS, supplierDelResponse))
    } catch (e: any) {
        yield put(supplierDeleteError(ActionTypes.DELETE_SUPPLIER_FAIL, e))
    }
}




function* watchSupplier() {
    yield takeEvery(ActionTypes.FETCH_SUPPLIER, supplier);
    yield takeEvery(ActionTypes.CREATE_SUPPLIER, _createSupplier);
    yield takeEvery(ActionTypes.UPDATE_SUPPLIER, _updateSupplier);
    yield takeEvery(ActionTypes.FETCH_SINGLE_SUPPLIER, singleSupplier);
    yield takeEvery(ActionTypes.DASHBOARD_SUPPLIER_DATA, _dashboardData)
    yield takeEvery(ActionTypes.DELETE_SUPPLIER, _deleteSupplier)



}

export default watchSupplier;