import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    supplier: {},
    singleSupplier: {},
    error: {},
    success: false,
    newSupplier: {},
    supplierCount: {},
    location: {},
    supplierDeleteSuccess: false,
}

type SupplierActionType = {
    type:
    | AuthActionTypes.SET_SUPPLIER
    | AuthActionTypes.CREATE_SUPPLIER
    | AuthActionTypes.FETCH_SUPPLIER
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_SUPPLIER
    | AuthActionTypes.SET_SINGLE_SUPPLIER
    | AuthActionTypes.ADD_SUPPLIER_SUCCESS
    | AuthActionTypes.ADD_SUPPLIER_FAIL
    | AuthActionTypes.UPDATE_SUPPLIER_SUCCESS
    | AuthActionTypes.UPDATE_SUPPLIER_FAIL
    | AuthActionTypes.SET_SUPPLIER_DASHBOARD_DATA
    | AuthActionTypes.DELETE_SUPPLIER_SUCCESS
    | AuthActionTypes.DELETE_SUPPLIER_FAIL
    | AuthActionTypes.SET_SUPPLIER_LOCATION
    | AuthActionTypes.RESET_SUPPLIER
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}


const SupplierReducer = (state: any = INIT_STATE, action: SupplierActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_SUPPLIER:
            console.log(action.payload.data);
            return { ...state, supplier: action.payload.data, singleSupplier: {}, loading: false, success: false, error: {}, supplierDeleteSuccess: false }
        case AuthActionTypes.SET_SINGLE_SUPPLIER:
            console.log(action.payload.data);
            return { ...state, singleSupplier: action.payload.data, loading: false, success: false, error: {} }
        case AuthActionTypes.SET_SUPPLIER_DASHBOARD_DATA:
            return { ...state, supplierCount: action.payload.data, loading: false, success: false, error: {} }
        case AuthActionTypes.ADD_SUPPLIER_SUCCESS:
            return { ...state, success: true, error: {}, newSupplier: action.payload.data }
        case AuthActionTypes.ADD_SUPPLIER_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_SUPPLIER_SUCCESS:
            return { ...state, success: true, error: {} }
        case AuthActionTypes.UPDATE_SUPPLIER_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.SET_ERROR:
            return { ...state, supplier: {}, singleSupplier: {}, error: action.payload.error }
        case AuthActionTypes.DELETE_SUPPLIER_SUCCESS:
            return { ...state, supplierDeleteSuccess: true, error: {} }
        case AuthActionTypes.DELETE_SUPPLIER_FAIL:
            return { ...state, supplierDeleteSuccess: false, error: action.payload.error, }
        case AuthActionTypes.SET_SUPPLIER_LOCATION:
            return { ...state, loading: false, success: false, location: action.payload.data };
        case AuthActionTypes.RESET_SUPPLIER:
            return { ...INIT_STATE }
        default:
            return { ...state }
    }
}

export default SupplierReducer;