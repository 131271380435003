import { ActionTypes } from './constants';

export type ProjectManagementType = {
    type:
    | ActionTypes.CREATE_PROJECT
    | ActionTypes.FETCH_PROJECT_LIST
    | ActionTypes.FETCH_PROJECT_LIST_SUCCESS
    | ActionTypes.FETCH_PROJECT_LIST_FAIL
    | ActionTypes.FETCH_PROJECT_DETAIL
    | ActionTypes.FETCH_PROJECT_DETAIL_SUCCESS
    | ActionTypes.FETCH_PROJECT_DETAIL_FAIL
    | ActionTypes.SET_ERROR
    | ActionTypes.UPDATE_PROJECT
    | ActionTypes.UPDATE_PROJECT_SUCCESS
    | ActionTypes.UPDATE_PROJECT_FAIL
    | ActionTypes.CREATE_PROJECT_SUCCESS
    | ActionTypes.CREATE_PROJECT_FAIL
    | ActionTypes.CREATE_PROJECT_TASK
    | ActionTypes.CREATE_PROJECT_TASK_SUCCESS
    | ActionTypes.CREATE_PROJECT_TASK_FAIL
    | ActionTypes.CREATE_PROJECT_TASK_RESET_STATE
    | ActionTypes.UPDATE_PROJECT_TASK
    | ActionTypes.UPDATE_PROJECT_TASK_SUCCESS
    | ActionTypes.UPDATE_PROJECT_TASK_FAIL
    | ActionTypes.UPDATE_PROJECT_TASK_RESET_STATE
    | ActionTypes.FETCH_SINGLE_PROJECT_TASK
    | ActionTypes.FETCH_SINGLE_PROJECT_TASK_SUCCESS
    | ActionTypes.FETCH_SINGLE_PROJECT_TASK_FAIL
    | ActionTypes.FETCH_SINGLE_PROJECT_TASK_RESET_STATE
    | ActionTypes.FETCH_SINGLE_TASK
    | ActionTypes.FETCH_SINGLE_TASK_SUCCESS
    | ActionTypes.FETCH_SINGLE_TASK_FAIL
    | ActionTypes.FETCH_SINGLE_TASK_RESET_STATE
    | ActionTypes.FETCH_ASSIGNED_TASK
    | ActionTypes.SET_ASSIGNED_TASK
    | ActionTypes.FETCH_ASSIGNED_TASK_COUNT
    | ActionTypes.SET_ASSIGNED_TASK_COUNT
    | ActionTypes.FETCH_CUSTOMER_PROJECT
    | ActionTypes.SET_CUSTOMER_PROJECT
    | ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER
    | ActionTypes.SET_TASK_COUNT_BASED_ON_USER
    | ActionTypes.FETCH_PROJECT_STATUS
    | ActionTypes.SET_PROJECT_STATUS
    | ActionTypes.FETCH_PROJECT_MONTH_DATA
    | ActionTypes.SET_PROJECT_MONTH_DATA
    | ActionTypes.FETCH_COMPLETE_PROJECT
    | ActionTypes.SET_COMPLETE_PROJECT
    | ActionTypes.GET_PROJECTS_WITH_VALUE
    | ActionTypes.SET_PROJECTS_WITH_VALUE
    | ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE
    | ActionTypes.SET_ONGOING_PROJECT_WITH_VALUE
    | ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE
    | ActionTypes.SET_COMPLETE_PROJECT_WITH_VALUE
    | ActionTypes.GET_FUNNEL_DATA
    | ActionTypes.SET_FUNNEL_DATA
    | ActionTypes.FETCH_PROJECT_BY_LOCATION
    | ActionTypes.SET_PROJECT_BY_LOCATION
    | ActionTypes.UPDATE_PROJECT_RESET_STATE
    | ActionTypes.DELETE_PROJECT
    | ActionTypes.DELETE_PROJECT_SUCCESS
    | ActionTypes.DELETE_PROJECT_FAIL
    | ActionTypes.SET_PROJECT_LOCATION
    | ActionTypes.SET_PROJECT_TASK_STATUS
    | ActionTypes.FETCH_JOB_TYPE
    | ActionTypes.FETCH_JOB_TYPE_SUCCESS
    | ActionTypes.FETCH_JOB_TYPE_FAIL
    | ActionTypes.BULK_UPDATE_PROJECT_TASK
    | ActionTypes.BULK_UPDATE_PROJECT_TASK_SUCCESS
    | ActionTypes.BULK_UPDATE_PROJECT_TASK_FAIL
    | ActionTypes.FETCH_TASK
    | ActionTypes.FETCH_TASK_SUCCESS
    | ActionTypes.FETCH_TASK_FAIL
    | ActionTypes.FETCH_TASK_RESET_STATE
    payload: {} | string;
}

//dispatch to get all projects
export const getProjects = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_PROJECT_LIST,
    payload: { data }
})

export const getProjectWithValue = (data: any): ProjectManagementType => ({
    type: ActionTypes.GET_PROJECTS_WITH_VALUE,
    payload: { data }
})

export const getProjectWithOngoing = (data: any): ProjectManagementType => ({
    type: ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE,
    payload: { data }
})

export const getProjectWithCompleted = (data: any): ProjectManagementType => ({
    type: ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE,
    payload: { data }
})

export const getFunnelData = (data: any): ProjectManagementType => ({
    type: ActionTypes.GET_FUNNEL_DATA,
    payload: { data }
})

export const resetUpdateProjectState = (): ProjectManagementType => ({
    type: ActionTypes.UPDATE_PROJECT_RESET_STATE,
    payload: {}
})

export const resetCreateProjectState = () => ({
    type: ActionTypes.CREATE_PROJECT_RESET_STATE,
    payload: {}
})

export const resetCreateTaskState = () => ({
    type: ActionTypes.CREATE_PROJECT_TASK_RESET_STATE,
    payload: {}
})

export const resetUpdateTaskState = () => {
    console.log('sdsdsd'); return ({
        type: ActionTypes.UPDATE_PROJECT_TASK_RESET_STATE,
        payload: {}
    })
}

export const resetSingleProjectState = () => ({
    type: ActionTypes.FETCH_PROJECT_DETAIL_RESET_STATE,
    payload: {}
})

export const getProjectTask = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_SINGLE_PROJECT_TASK,
    payload: { data }
})

export const getAllTasks = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_TASK,
    payload: { data }
})

export const createProject = (data: any): ProjectManagementType => ({
    type: ActionTypes.CREATE_PROJECT,
    payload: { data }
})

export const getAssignedTasks = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_ASSIGNED_TASK,
    payload: { data }
})

export const countAssignedTasks = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_ASSIGNED_TASK_COUNT,
    payload: { data }
})

export const createProjectTask = (data: any): ProjectManagementType => ({
    type: ActionTypes.CREATE_PROJECT_TASK,
    payload: { data }
})

export const updateProjectTask = (data: any): ProjectManagementType => ({
    type: ActionTypes.UPDATE_PROJECT_TASK,
    payload: { data }
})

export const updateProject = (data: any): ProjectManagementType => ({
    type: ActionTypes.UPDATE_PROJECT,
    payload: { data }
})

export const getProjectByID = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_PROJECT_DETAIL,
    payload: { data }
})

export const getTaskByID = (data: any): ProjectManagementType => {
    console.log('xcxcxc', data);
    return ({
        type: ActionTypes.FETCH_SINGLE_TASK,
        payload: { data }
    })
}


export const clearTaskDetailState = (): ProjectManagementType => ({
    type: ActionTypes.FETCH_SINGLE_TASK_RESET_STATE,
    payload: {}
})

export const getMyProjects = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_CUSTOMER_PROJECT,
    payload: { data }
})

export const getTaskCountBasedOnUser = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER,
    payload: { data }
})

export const getProjectStatus = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_PROJECT_STATUS,
    payload: { data }
})

export const getProjectMonthData = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_PROJECT_MONTH_DATA,
    payload: { data }
})

export const getProjectMapLocation = () => ({
    type: ActionTypes.FETCH_PROJECT_LOCATION,
    payload: {}
})

export const getProjectTaskStatus = () => ({
    type: ActionTypes.FETCH_PROJECT_TASK_STATUS,
    payload: {}
})

export const getProjectByMapLocation = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_PROJECT_BY_LOCATION,
    payload: { data }
})

export const getJobType = (): ProjectManagementType => ({
    type: ActionTypes.FETCH_JOB_TYPE,
    payload: {},
});





export const projectApiResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_ASSIGNED_TASK:
            return {
                type: ActionTypes.SET_ASSIGNED_TASK,
                payload: { data }
            }
        case ActionTypes.FETCH_ASSIGNED_TASK_COUNT:
            return {
                type: ActionTypes.SET_ASSIGNED_TASK_COUNT,
                payload: { data }
            }
        case ActionTypes.FETCH_CUSTOMER_PROJECT:
            return {
                type: ActionTypes.SET_CUSTOMER_PROJECT,
                payload: { data }
            }
        case ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER:
            return {
                type: ActionTypes.SET_TASK_COUNT_BASED_ON_USER,
                payload: { data }
            }
        case ActionTypes.FETCH_COMPLETE_PROJECT:
            return {
                type: ActionTypes.SET_COMPLETE_PROJECT,
                payload: { data }
            }
        case ActionTypes.FETCH_PROJECT_STATUS:
            return {
                type: ActionTypes.SET_PROJECT_STATUS,
                payload: { data }
            }
        case ActionTypes.FETCH_PROJECT_MONTH_DATA:
            return {
                type: ActionTypes.SET_PROJECT_MONTH_DATA,
                payload: { data }
            }
        case ActionTypes.GET_PROJECTS_WITH_VALUE:
            return {
                type: ActionTypes.SET_PROJECTS_WITH_VALUE,
                payload: { data }
            }
        case ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE:
            return {
                type: ActionTypes.SET_ONGOING_PROJECT_WITH_VALUE,
                payload: { data }
            }
        case ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE:
            return {
                type: ActionTypes.SET_COMPLETE_PROJECT_WITH_VALUE,
                payload: { data }
            }
        case ActionTypes.GET_FUNNEL_DATA:
            return {
                type: ActionTypes.SET_FUNNEL_DATA,
                payload: { data }
            }
        case ActionTypes.FETCH_PROJECT_LOCATION:
            return {
                type: ActionTypes.SET_PROJECT_LOCATION,
                payload: { data }
            }
        case ActionTypes.FETCH_PROJECT_BY_LOCATION:
            return {
                type: ActionTypes.SET_PROJECT_BY_LOCATION,
                payload: { data }
            }
        case ActionTypes.FETCH_PROJECT_TASK_STATUS:
            return {
                type: ActionTypes.SET_PROJECT_TASK_STATUS,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

export const fetchProjectAPIResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_PROJECT_LIST_SUCCESS:
            return {
                type: ActionTypes.FETCH_PROJECT_LIST_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.FETCH_PROJECT_LIST_FAIL:
            return {
                type: ActionTypes.FETCH_PROJECT_LIST_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const createProjectAPIResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.CREATE_PROJECT_SUCCESS:
            return {
                type: ActionTypes.CREATE_PROJECT_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.CREATE_PROJECT_FAIL:
            return {
                type: ActionTypes.CREATE_PROJECT_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const fetchProjectDetailAPIResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_PROJECT_DETAIL_SUCCESS:
            return {
                type: ActionTypes.FETCH_PROJECT_DETAIL_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.FETCH_PROJECT_DETAIL_FAIL:
            return {
                type: ActionTypes.FETCH_PROJECT_DETAIL_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const updateProjectAPIResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.UPDATE_PROJECT_SUCCESS:
            return {
                type: ActionTypes.UPDATE_PROJECT_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.UPDATE_PROJECT_FAIL:
            return {
                type: ActionTypes.UPDATE_PROJECT_FAIL,
                payload: { actionType, data: null, error: data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const fetchProjectTaskAPIResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_SINGLE_PROJECT_TASK_SUCCESS:
            return {
                type: ActionTypes.FETCH_SINGLE_PROJECT_TASK_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.FETCH_SINGLE_PROJECT_TASK_FAIL:
            return {
                type: ActionTypes.FETCH_SINGLE_PROJECT_TASK_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const fetchAllTaskAPIResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_TASK_SUCCESS:
            return {
                type: ActionTypes.FETCH_TASK_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.FETCH_TASK_FAIL:
            return {
                type: ActionTypes.FETCH_TASK_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}


export const createProjectTaskAPIResponse = (actionType: string, data: any) => {
    switch (actionType) {
        case ActionTypes.CREATE_PROJECT_TASK_SUCCESS:
            return {
                type: ActionTypes.CREATE_PROJECT_TASK_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.CREATE_PROJECT_TASK_FAIL:
            return {
                type: ActionTypes.CREATE_PROJECT_TASK_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const fetchTaskDetailAPIResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_SINGLE_TASK_SUCCESS:
            return {
                type: ActionTypes.FETCH_SINGLE_TASK_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.FETCH_SINGLE_TASK_FAIL:
            return {
                type: ActionTypes.FETCH_SINGLE_TASK_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}

export const fetchJobTypeAPIResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_JOB_TYPE_SUCCESS:
            return {
                type: ActionTypes.FETCH_JOB_TYPE_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.FETCH_JOB_TYPE_FAIL:
            return {
                type: ActionTypes.FETCH_JOB_TYPE_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}


export const updateProjectTaskAPIResponse = (actionType: string, data: any) => {
    switch (actionType) {
        case ActionTypes.UPDATE_PROJECT_TASK_SUCCESS:
            return {
                type: ActionTypes.UPDATE_PROJECT_TASK_SUCCESS,
                payload: { actionType, data, error: null }
            }
        case ActionTypes.UPDATE_PROJECT_TASK_FAIL:
            return {
                type: ActionTypes.UPDATE_PROJECT_TASK_FAIL,
                payload: { actionType, error: data, data: null }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}


export const projectApiError = (actionType: string, error: any): ProjectManagementType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})

//! DELETING Project
export const deleteProject = (data: any): ProjectManagementType => ({
    type: ActionTypes.DELETE_PROJECT,
    payload: { data }
})

export const deleteProjectResponse = (actionType: any, data: any): ProjectManagementType => ({
    type: ActionTypes.DELETE_PROJECT_SUCCESS,
    payload: { actionType, data }
})
export const deleteProjectError = (actionType: any, error: any): ProjectManagementType => ({
    type: ActionTypes.DELETE_PROJECT_FAIL,
    payload: { actionType, error }
})

//! bulk update project task status
export const bulkUpdateProjectTaskStatus = (data: any): ProjectManagementType => ({
    type: ActionTypes.BULK_UPDATE_PROJECT_TASK,
    payload: { data }
})

export const bulkUpdateProjectTaskStatusResponse = (actionType: any, data: any): ProjectManagementType => ({
    type: ActionTypes.BULK_UPDATE_PROJECT_TASK_SUCCESS,
    payload: { actionType, data }
})

export const bulkUpdateProjectTaskStatusError = (actionType: any, error: any): ProjectManagementType => ({
    type: ActionTypes.BULK_UPDATE_PROJECT_TASK_FAIL,
    payload: { actionType, error }
})


