import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
// import { APICore } from 'helpers/api/apiCore';
import { fetchProduct, fetchSingleProduct, createProduct, updateProduct, getProductSkuWise, deleteProduct, fetchStatus, fetchProductSkuList } from "helpers";
import { deleteProductError, deleteProductResponse, getSkuFieldDataError, getSkuFieldDataResponse, productApiResponse, productResponseError } from "./actions";
import { ActionTypes } from "./constants";
import { getSkuFiledsDetails } from "helpers/api/product";

// const api = new APICore();

function* product(params: any): SagaIterator {
  try {

    const response = yield call(fetchProduct, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, searchBy: params.payload.data.searchBy });
    const productData = response.data;
    yield put(productApiResponse(ActionTypes.FETCH_PRODUCT, productData));
  } catch (error: any) {
    yield put(productResponseError(ActionTypes.FETCH_PRODUCT, error));
  }
}

function* singleProduct(params: any): SagaIterator {
  try {
    const response = yield call(fetchSingleProduct, params.payload.id);
    const productData = response.data
    console.log("Single query data->", productData);
    yield put(productApiResponse(ActionTypes.FETCH_SINGLE_PRODUCT, productData))
  } catch (error: any) {
    yield put(productResponseError(ActionTypes.FETCH_SINGLE_PRODUCT, error));
  }

}
function* _createProduct({ payload }: any): SagaIterator {

  try {
    const response = yield call(createProduct, { product: payload.data });
    const productData = response.data
    console.log(productData);

    yield put(productApiResponse(ActionTypes.CREATE_PRODUCT_SUCCESS, productData))
  } catch (error: any) {
    yield put(productResponseError(ActionTypes.CREATE_PRODUCT_SUCCESS, error));
  }

}

function* _updateProduct({ payload }: any): SagaIterator {
  try {
    const response = yield call(updateProduct, { id: payload.data.id, updateBody: payload.data.body });
    const productData = response.data
    yield put(productApiResponse(ActionTypes.UPDATE_PRODUCT, productData))
  } catch (error: any) {
    yield put(productResponseError(ActionTypes.UPDATE_PRODUCT, error));
  }
}



function* _resetProduct({ payload }: any): SagaIterator {
  yield put(productApiResponse(ActionTypes.RESET_PRODUCT, payload))

}

function* skuWise(params: any): SagaIterator {
  try {
    const response = yield call(getProductSkuWise, { limit: params.payload.data.limit, filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter, type: params.payload.data?.type })
    const data = response.data;
    yield put(productApiResponse(ActionTypes.FETCH_SKU_WISE, data))
  } catch (err: any) {
    yield put(productResponseError(ActionTypes.FETCH_SKU_WISE, err))
  }
}

function* _deleteProduct(params: any): SagaIterator {
  console.log(params, "params")
  try {
    const response = yield call(deleteProduct, { id: params.payload.data.id });
    const ProductData = yield response.data;
    yield put(deleteProductResponse(ActionTypes.DELETE_PRODUCT_SUCCESS, ProductData));
  } catch (e: any) {
    yield put(deleteProductError(ActionTypes.DELETE_PRODUCT_FAIL, e))
  }
}

function* getProductStatus(): SagaIterator {
  try {
    const response = yield call(fetchStatus, "FORM_PRODUCT_STATUS");
    const ProductData = yield response.data.data.values;
    yield put(productApiResponse(ActionTypes.FETCH_PRODUCT_STATUS, ProductData));
  } catch (e: any) {
    yield put(productApiResponse(ActionTypes.FETCH_PRODUCT_STATUS, e))
  }
}

function* _getSkuList(params: any): SagaIterator {
  try {
    const response = yield call(fetchProductSkuList, params.payload.data);
    const skuData = response.data?.response
    yield put(productApiResponse(ActionTypes.FETCH_SKU, skuData));
  } catch (e: any) {
    yield put(productApiResponse(ActionTypes.FETCH_SKU, e))
  }
}
function* _getSkuFieldValue({ payload }: any): SagaIterator {
  try {
    console.log("{ payload }: any", payload)
    const response:any = yield call(getSkuFiledsDetails, payload.data);
    const skuData = response.data?.response
    yield put(getSkuFieldDataResponse(ActionTypes.FETCH_SKU_FIELD_SUCCESS, skuData));
  } catch (e: any) {
    yield put(getSkuFieldDataError(ActionTypes.FETCH_SKU_FIELD_FAIL, e))
  }
}


export function* watchProduct() {
  yield takeEvery(ActionTypes.FETCH_PRODUCT, product);
  yield takeEvery(ActionTypes.FETCH_SINGLE_PRODUCT, singleProduct);
  yield takeEvery(ActionTypes.CREATE_PRODUCT, _createProduct);
  yield takeEvery(ActionTypes.UPDATE_PRODUCT, _updateProduct);
  yield takeEvery(ActionTypes.RESET_PRODUCT, _resetProduct);
  yield takeEvery(ActionTypes.FETCH_SKU_WISE, skuWise);
  yield takeEvery(ActionTypes.DELETE_PRODUCT, _deleteProduct);
  yield takeEvery(ActionTypes.FETCH_PRODUCT_STATUS, getProductStatus)
  yield takeEvery(ActionTypes.FETCH_SKU, _getSkuList)
  yield takeEvery(ActionTypes.FETCH_SKU_FIELD, _getSkuFieldValue)

}

export default watchProduct;
