import { useState } from "react";

    
    
export default function usePagination(defaultPage?: number, defaultLimit?: number, defaultSortBy?: string, defaultSort?: string) {

    if(!defaultPage || defaultPage <= 0) defaultPage = 1;
    if(!defaultLimit || defaultLimit <= 0) defaultLimit = 15;

    const [page, setPage] = useState(defaultPage);
    const [limit, setLimit] = useState(defaultLimit);
    const [sort, setSort] = useState({ order: defaultSort ?? 'DESC', orderBy: defaultSortBy ?? 'id' });

    return {
        page, setPage, limit, setLimit, sort, setSort
    }
}