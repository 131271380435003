import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { GRNActionTypes } from './constants';
import {
  createGRN,
  fetchGRNs,
  fetchGRNById,
  editGRN,
  fetchGrnByProjectId,
} from 'helpers/api/grn';
import {
  createGRNSuccess,
  createGRNFailure,
  fetchGRNsSuccess,
  fetchGRNsFailure,
  fetchGRNByIdSuccess,
  fetchGRNByIdFailure,
  editGRNSuccess,
  editGRNFailure,
  fetchGRNByProjectIdSuccess,
  fetchGRNByProjectIdFailure,
} from './actions';

function* fetchGRNsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchGRNs, page, limit , sortBy, sort, searchBy);
    const grnsData = response.data; 
    yield put(fetchGRNsSuccess(grnsData));
  } catch (e: any) {
    yield put(fetchGRNsFailure(e));
  }
}

function* createGRNSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createGRN, data);
    const grnData = response.data;
    yield put(createGRNSuccess(grnData));
    yield put({ type: GRNActionTypes.FETCH_GRNS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createGRNFailure(e));
  }
}

function* fetchGRNByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchGRNById, action.payload);
    const grnData = response.data;
    yield put(fetchGRNByIdSuccess(grnData));
  } catch (e: any) {
    yield put(fetchGRNByIdFailure(e));
  }
}

function* editGRNSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editGRN, id, data);
    const grnData = response.data;
    yield put(editGRNSuccess(grnData));
    yield put({ type: GRNActionTypes.FETCH_GRN_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editGRNFailure(e));
  }
}

function* fetchGRNByProjectIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchGrnByProjectId, action.payload);
    const indentData = response.data;
    yield put(fetchGRNByProjectIdSuccess(indentData));
  } catch (e: any) {
    yield put(fetchGRNByProjectIdFailure(e));
  }
}


function* watchGRNs() {
  yield takeEvery(GRNActionTypes.FETCH_GRNS_REQUEST, fetchGRNsSaga);
  yield takeEvery(GRNActionTypes.CREATE_GRN_REQUEST, createGRNSaga);
  yield takeEvery(GRNActionTypes.FETCH_GRN_BY_ID_REQUEST, fetchGRNByIdSaga);
  yield takeEvery(GRNActionTypes.EDIT_GRN_REQUEST, editGRNSaga);
  yield takeEvery(GRNActionTypes.FETCH_GRN_BY_PROJECT_ID_REQUEST, fetchGRNByProjectIdSaga);

}

export default watchGRNs;
