export enum ActionTypes {
    SET_TEAM = "SET_TEAM",
    SET_ERROR = "SET_ERROR",
    CREATE_TEAM = "CREATE_TEAM",
    FETCH_TEAM = "FETCH_TEAM",
    UPDATE_TEAM = "UPDATE_TEAM",
    FETCH_SINGLE_TEAM = "FETCH_SINGLE_TEAM",
    SET_SINGLE_TEAM = "SET_SINGLE_TEAM",
    UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS",
    UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL",
    ADD_EMPLOY_SUCCESS = "ADD_EMPLOY_SUCCESS",
    ADD_EMPLOY_FAIL = "ADD_EMPLOY_FAIL",

    DELETE_TEAM = "DELETE_TEAM",
    DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS",
    DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL"
}