import { RfqActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  rfqs: [],
  singleRfq: {},
  projectRfq:[],
  error: {},
  success: false,
};

const RfqReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case RfqActionTypes.CREATE_RFQ_REQUEST:
    case RfqActionTypes.EDIT_RFQ_REQUEST:
    case RfqActionTypes.FETCH_RFQS_REQUEST:
    case RfqActionTypes.FETCH_RFQ_BY_ID_REQUEST:
    case RfqActionTypes.FETCH_RFQ_BY_PROJECT_ID_REQUEST:
    case RfqActionTypes.RESET_RFQ_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case RfqActionTypes.SET_SINGLE_RFQ:
    return { ...state , singleRfq: action.payload.data, loading:false }  
    case RfqActionTypes.CREATE_RFQ_SUCCESS:
      return {
        ...state,
        rfqs: [...state.rfqs, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
      case RfqActionTypes.FETCH_RFQ_BY_PROJECT_ID_SUCCESS:
        return {
          ...state,
          rfqs: [action.payload.data],
          projectRfq: [action.payload.data],
          loading: false,
          error: {},
          success: true,
        };

    case RfqActionTypes.EDIT_RFQ_SUCCESS:
      const updatedRfqs = state.rfqs.map((rfq: any) =>
        rfq.id === action.payload.data.id ? action.payload.data : rfq
      );
      return {
        ...state,
        rfqs: updatedRfqs,
        loading: false,
        error: {},
        success: true,
      };

    case RfqActionTypes.FETCH_RFQS_SUCCESS:
      return {
        ...state,
        rfqs: action.payload.data,
        singleRfq: {},
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case RfqActionTypes.FETCH_RFQ_BY_ID_SUCCESS:
      return {
        ...state,
        singleRfq: action.payload.data,
        rfqs: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case RfqActionTypes.RESET_SINGLE_RFQ:
      return {
          ...state,
          singleRfq: null,
      };
    case RfqActionTypes.RESET_SINGLE_PROJECT_RFQ:
      return {
          ...state,
          projectRfq: [],
      };

    case RfqActionTypes.CREATE_RFQ_FAILURE:
    case RfqActionTypes.EDIT_RFQ_FAILURE:
    case RfqActionTypes.FETCH_RFQS_FAILURE:
    case RfqActionTypes.FETCH_RFQ_BY_ID_FAILURE:
    case RfqActionTypes.FETCH_RFQ_BY_PROJECT_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default RfqReducer;
