import { ActionTypes as AuthActionTypes } from "./constants";
import { VendorResponse } from "redux/types/DataTypes";

const INIT_STATE = {
    loading: true,
    vendor: {},
    singleVendor: {},
    error: {},
    success: false,
    newVendor: {},
    updateSuccess: false,
    vendorCount: {},
    vendorDeleteSuccess: false,
    updateVendorFileSuccess: false,
    vendorAddSucessWithFiles: false,
    vendorSearch: {
        loading: false,
        error: null,
        success: false,
        data: null,
        jobId: null,
    }
}

type AuthActionType = {
    type:
    | AuthActionTypes.SET_VENDOR
    | AuthActionTypes.CREATE_VENDOR
    | AuthActionTypes.FETCH_VENDOR
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_VENDOR
    | AuthActionTypes.SET_SINGLE_VENDOR
    | AuthActionTypes.ADD_VENDOR_SUCCESS
    | AuthActionTypes.ADD_VENDOR_FAIL
    | AuthActionTypes.UPDATE_VENDOR_SUCCESS
    | AuthActionTypes.UPDATE_VENDOR_FAIL
    | AuthActionTypes.SET_DASHBOARD_VENDOR_DATA
    | AuthActionTypes.RESET_VENDOR
    | AuthActionTypes.DELETE_VENDOR_SUCCESS
    | AuthActionTypes.DELETE_VENDOR_FAIL
    | AuthActionTypes.UPDATE_VENDOR_SUCCESS_WITH_FILES
    | AuthActionTypes.UPDATE_VENDOR_FAIL_WITH_FILES
    | AuthActionTypes.SEARCH_VENDOR
    | AuthActionTypes.SEARCH_VENDOR_SUCCESS
    | AuthActionTypes.SEARCH_VENDOR_RESET_STATE
    | AuthActionTypes.SEARCH_VENDOR_FAIL
    | AuthActionTypes.CREATE_VENDOR_WITH_FILES_SUCCESS
    | AuthActionTypes.CREATE_VENDOR_WITH_FILES_FAIL

    payload: {
        actionType?: string;
        data?: VendorResponse | {};
        error?: string;
    }
}

const VendorReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_VENDOR:
            console.log(action.payload.data);
            return { ...state, vendor: action.payload.data, singleVendor: {}, loading: false, success: false, error: {}, updateSuccess: false, newVendor: {}, vendorDeleteSuccess: false, updateVendorFileSuccess: false, vendorAddSucessWithFiles: false }
        case AuthActionTypes.SET_SINGLE_VENDOR:
            console.log(action.payload.data);
            return { ...state, singleVendor: action.payload.data, loading: false, success: false, error: {}, updateSuccess: false }
        case AuthActionTypes.SET_DASHBOARD_VENDOR_DATA:
            return { ...state, vendorCount: action.payload.data, loading: false, success: false, error: {}, updateSuccess: false }
        case AuthActionTypes.ADD_VENDOR_SUCCESS:
            return { ...state, success: true, error: {}, newVendor: action.payload.data, updateVendorFileSuccess: true }
        case AuthActionTypes.ADD_VENDOR_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_VENDOR_SUCCESS:
            return { ...state, success: true, error: {}, updateSuccess: true }
        case AuthActionTypes.UPDATE_VENDOR_FAIL:
            return { ...state, success: false, error: action.payload.error, updateSuccess: false }
        case AuthActionTypes.SET_ERROR:
            return { ...state, vendor: {}, singleVendor: {}, error: action.payload.error }
        case AuthActionTypes.RESET_VENDOR:
            return { ...INIT_STATE }
        case AuthActionTypes.CREATE_VENDOR_WITH_FILES_SUCCESS:
            return { ...state, vendorAddSucessWithFiles: true, error: {} }
        case AuthActionTypes.CREATE_VENDOR_WITH_FILES_FAIL:
            return { ...state, vendorAddSucessWithFiles: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_VENDOR_SUCCESS_WITH_FILES:
            return { ...state, error: {}, updateVendorFileSuccess: true }
        case AuthActionTypes.UPDATE_VENDOR_FAIL_WITH_FILES:
            return { ...state, error: {message:"Failed to update Vendor"}, updateVendorFileSuccess: false }
        case AuthActionTypes.DELETE_VENDOR_SUCCESS:
            return { ...state, vendorDeleteSuccess: true, error: {} }
        case AuthActionTypes.DELETE_VENDOR_FAIL:
            return { ...state, vendorDeleteSuccess: false, error: action.payload.error }

        case AuthActionTypes.SEARCH_VENDOR_RESET_STATE:
            return { ...state, vendorSearch: { loading: false, error: null, success: false } }
        case AuthActionTypes.SEARCH_VENDOR:
            return { ...state, vendorSearch: { loading: true, error: null, success: false, data: null } }
        case AuthActionTypes.SEARCH_VENDOR_SUCCESS:
            return { ...state, vendorSearch: { loading: false, error: null, success: true, data: action.payload.data } }
        case AuthActionTypes.SEARCH_VENDOR_FAIL:
            return { ...state, vendorSearch: { loading: false, error: action.payload.error, success: false, data: null } }
        default:
            return { ...state }
    }
}

export default VendorReducer;