import { ActionTypes as AuthActionTypes } from "./constants";
import { EmployeeResponse } from "redux/types/DataTypes";

const INIT_STATE = {
    loading: true,
    employee: {},
    singleEmployee: {},
    error: {},
    success: false,
    groupList: {},
    newEmployee: null,
    employeeStatus:{},
    updatedAfterFileUpload: false,
    employeeDeleteSuccess: false,
    employeeProfileDetails: {}
}

type AuthActionType = {
    type:
    | AuthActionTypes.SET_EMPLOYEE
    | AuthActionTypes.CREATE_EMPLOYEE
    | AuthActionTypes.FETCH_EMPLOYEE
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_EMPLOYE
    | AuthActionTypes.SET_SINGLE_EMPLOYEE
    | AuthActionTypes.SET_GROUPLIST
    | AuthActionTypes.ADD_EMPLOY_SUCCESS
    | AuthActionTypes.ADD_EMPLOY_FAIL
    | AuthActionTypes.UPDATE_EMPLOYEE_SUCCESS
    | AuthActionTypes.UPDATE_EMPLOYEE_FAIL
    | AuthActionTypes.DELETE_EMPLOYEE_FAIL
    | AuthActionTypes.DELETE_EMPLOYEE_SUCCESS
    | AuthActionTypes.FETCH_PROFILE_IMAGE_SUCCESS
    | AuthActionTypes.FETCH_PROFILE_IMAGE_FAIL
    | AuthActionTypes.SET_EMPLOYEE_STATUS
    payload: {
        actionType?: string;
        data?: EmployeeResponse | {};
        error?: string;
    }
}

const EmployeeReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_EMPLOYEE:
            // console.log(action.payload.data);
            return { ...state, employee: action.payload.data, singleEmployee: {}, loading: false, success: false, error: {}, employeeDeleteSuccess: false }
        case AuthActionTypes.SET_SINGLE_EMPLOYEE:
            // console.log(action.payload.data);
            return { ...state, singleEmployee: action.payload.data, loading: false, success: false, error: {} }
        case AuthActionTypes.SET_GROUPLIST:
            return { ...state, groupList: action.payload.data, loading: false, success: false, error: {} }
        case AuthActionTypes.ADD_EMPLOY_SUCCESS:
            return { ...state, success: true, error: {}, newEmployee: action.payload.data }
        case AuthActionTypes.ADD_EMPLOY_FAIL:
            // console.log("employe add fail", action.payload.error);
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_EMPLOYEE_SUCCESS:
            return { ...state, success: true, error: {}, updatedAfterFileUpload: true, newEmployee: null }
        case AuthActionTypes.UPDATE_EMPLOYEE_FAIL:
            return { ...state, success: false, error: action.payload.error, newEmployee: null }
        case AuthActionTypes.SET_ERROR:
            return { ...state, employee: {}, singleEmployee: {}, error: action.payload.error }
        case AuthActionTypes.DELETE_EMPLOYEE_SUCCESS:
            return { ...state, employeeDeleteSuccess: true, error: {} }
        case AuthActionTypes.DELETE_EMPLOYEE_FAIL:
            return { ...state, employeeDeleteSuccess: false, error: action.payload.error }
        case AuthActionTypes.SET_EMPLOYEE_STATUS:
            return { ...state, employeeStatus:action.payload.data }
        case AuthActionTypes.FETCH_PROFILE_IMAGE_SUCCESS:
            return { ...state, employeeProfileDetails: action.payload.data, error: {} }
        case AuthActionTypes.FETCH_PROFILE_IMAGE_FAIL:
            return { ...state, employeeProfileDetails: {}, error: action.payload.error }

        default:
            return { ...state }
    }
}

export default EmployeeReducer;