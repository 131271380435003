import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { RfqActionTypes } from './constants';
import {
  createRfq,
  fetchRfqs,
  fetchRfqById,
  editRfq,
  fetchRfqByProjectId,
} from 'helpers/api/rfq';
import {
  createRfqSuccess,
  createRfqFailure,
  fetchRfqsSuccess,
  fetchRfqsFailure,
  fetchRfqByIdSuccess,
  fetchRfqByIdFailure,
  editRfqSuccess,
  editRfqFailure,
  fetchRfqByProjectIdFailure,
  fetchRfqByProjectIdSuccess,
} from './actions';

function* fetchRfqsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy, origin  } = action.payload || {};
    const response = yield call(fetchRfqs, page, limit , sortBy, sort, searchBy, origin);
    const rfqsData = response.data; 
    yield put(fetchRfqsSuccess(rfqsData));
  } catch (e: any) {
    yield put(fetchRfqsFailure(e));
  }
}

function* createRfqSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createRfq, data);
    const rfqData = response.data;
    yield put(createRfqSuccess(rfqData));
    yield put({ type: RfqActionTypes.FETCH_RFQS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createRfqFailure(e));
  }
}

function* fetchRfqByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchRfqById, action.payload);
    const rfqData = response.data;
    yield put(fetchRfqByIdSuccess(rfqData));
  } catch (e: any) {
    yield put(fetchRfqByIdFailure(e));
  }
}

function* fetchRfqByProjectIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchRfqByProjectId, action.payload);
    const indentData = response.data;
    yield put(fetchRfqByProjectIdSuccess(indentData));
  } catch (e: any) {
    yield put(fetchRfqByProjectIdFailure(e));
  }
}

function* editRfqSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editRfq, id, data);
    const rfqData = response.data;
    yield put(editRfqSuccess(rfqData));
    yield put({ type: RfqActionTypes.FETCH_RFQ_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editRfqFailure(e));
  }
}

function* watchRfqs() {
  yield takeEvery(RfqActionTypes.FETCH_RFQS_REQUEST, fetchRfqsSaga);
  yield takeEvery(RfqActionTypes.CREATE_RFQ_REQUEST, createRfqSaga);
  yield takeEvery(RfqActionTypes.FETCH_RFQ_BY_PROJECT_ID_REQUEST, fetchRfqByProjectIdSaga);
  yield takeEvery(RfqActionTypes.FETCH_RFQ_BY_ID_REQUEST, fetchRfqByIdSaga);
  yield takeEvery(RfqActionTypes.EDIT_RFQ_REQUEST, editRfqSaga);
}

export default watchRfqs;
