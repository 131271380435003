import { ActionTypes } from "./constants";

export type ProductActionType = {
  type:
  | ActionTypes.FETCH_PRODUCT
  | ActionTypes.SET_PRODUCT
  | ActionTypes.SET_ERROR
  | ActionTypes.SET_SINGLE_PRODUCT
  | ActionTypes.FETCH_SINGLE_PRODUCT
  | ActionTypes.CREATE_PRODUCT
  | ActionTypes.UPDATE_PRODUCT
  | ActionTypes.UPDATE_PRODUCT_SUCCESS
  | ActionTypes.CREATE_PRODUCT_SUCCESS
  | ActionTypes.UPDATE_PRODUCT_FAILURE
  | ActionTypes.RESET_PRODUCT
  | ActionTypes.FETCH_SKU_WISE
  | ActionTypes.SET_SKU_WISE
  | ActionTypes.DELETE_PRODUCT
  | ActionTypes.DELETE_PRODUCT_SUCCESS
  | ActionTypes.DELETE_PRODUCT_FAIL
  | ActionTypes.FETCH_PRODUCT_STATUS
  | ActionTypes.SET_PRODUCT_STATUS
  | ActionTypes.FETCH_SKU
  | ActionTypes.SET_SKU
  | ActionTypes.FETCH_SKU_FIELD
  | ActionTypes.FETCH_SKU_FIELD_SUCCESS
  | ActionTypes.FETCH_SKU_FIELD_FAIL


  payload: {} | string;
};



export const getProduct = (
  data: any
): ProductActionType => ({
  type: ActionTypes.FETCH_PRODUCT,
  payload: { data },
});

//dispatch action to get productDetails
export const getProductDetail = (id: any): ProductActionType => ({
  type: ActionTypes.FETCH_SINGLE_PRODUCT,
  payload: { id },
});


export const fetchSkuWise = (data: any): ProductActionType => ({
  type: ActionTypes.FETCH_SKU_WISE,
  payload: { data }
})


//create product
export const saveProduct = (data: any): ProductActionType => ({
  type: ActionTypes.CREATE_PRODUCT,
  payload: { data },
});

//update product
export const updateProduct = (data: any): ProductActionType => ({
  type: ActionTypes.UPDATE_PRODUCT,
  payload: { data },
});

//reset product
export const resetProduct = (data: any): ProductActionType => ({
  type: ActionTypes.RESET_PRODUCT,
  payload: { data },
});

//reset product
export const getProductStatus = (): ProductActionType => ({
  type: ActionTypes.FETCH_PRODUCT_STATUS,
  payload: {  },
});


//enquiry action to  dispatch
export const productApiResponse = (
  actionType: string,
  data: any
): ProductActionType => {
  switch (actionType) {
    case ActionTypes.FETCH_PRODUCT:
      return {
        type: ActionTypes.SET_PRODUCT,
        payload: { actionType, data },
      };
    case ActionTypes.FETCH_SINGLE_PRODUCT:
      return {
        type: ActionTypes.SET_SINGLE_PRODUCT,
        payload: { actionType, data },
      };
    case ActionTypes.CREATE_PRODUCT_SUCCESS:
      return {
        type: ActionTypes.CREATE_PRODUCT_SUCCESS,
        payload: { actionType, data },
      };
    case ActionTypes.UPDATE_PRODUCT:
      return {
        type: ActionTypes.UPDATE_PRODUCT_SUCCESS,
        payload: { actionType, data },
      };
    case ActionTypes.RESET_PRODUCT:
      return {
        type: ActionTypes.RESET_PRODUCT,
        payload: { actionType, data },
      };
      case ActionTypes.FETCH_SKU:
        return {
          type: ActionTypes.SET_SKU,
          payload: { actionType, data },
        }
    case ActionTypes.FETCH_SKU_WISE:
      return {
        type: ActionTypes.SET_SKU_WISE,
        payload: { actionType, data },
      }
    case ActionTypes.FETCH_PRODUCT_STATUS:
      return{
        type:ActionTypes.SET_PRODUCT_STATUS,
        payload: { actionType, data },

      }
    default:
      return {
        type: ActionTypes.SET_ERROR,
        payload: { actionType, error: "Error While setting data to store ! " },
      };
  }
};

//api response error handling
export const productResponseError = (
  actionType: string,
  error: any
): ProductActionType => ({
  type: ActionTypes.SET_ERROR,
  payload: { actionType, error },
});


//! DELETING Product
export const deleteProduct = (data: any): ProductActionType => ({
  type: ActionTypes.DELETE_PRODUCT,
  payload: { data }
})

export const deleteProductResponse = (actionType: any, data: any): ProductActionType => ({
  type: ActionTypes.DELETE_PRODUCT_SUCCESS,
  payload: { actionType, data }
})
export const deleteProductError = (actionType: any, error: any): ProductActionType => ({
  type: ActionTypes.DELETE_PRODUCT_FAIL,
  payload: { actionType, error }
})

export const getSkuVariantList=(data:any)=>{
  return (
    {  
    type: ActionTypes.FETCH_SKU,
    payload: { data }
  })
}

export const getSkuFieldData = (data: any): ProductActionType =>({
  type: ActionTypes.FETCH_SKU_FIELD,
  payload: { data }
})

export const getSkuFieldDataResponse = (actionType: any, data: any): ProductActionType => ({
  type: ActionTypes.FETCH_SKU_FIELD_SUCCESS,
  payload: { actionType, data }
})
export const getSkuFieldDataError = (actionType: any, error: any): ProductActionType => ({
  type: ActionTypes.FETCH_SKU_FIELD_FAIL,
  payload: { actionType, error }
})