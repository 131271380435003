import { APICore } from "./apiCore";
const api = new APICore();

function createGRN(data: any) {
    const baseUrl = `/grn/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchGRNs(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/grn/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchGRNById(id: number) {
    const url = `/grn/${id}`;
    return api.get(url, "");
}

function editGRN(id: number, data: any) {
    const url = `/grn/${id}`;
    return api.update(url, data);
}

function fetchGrnByProjectId(id: number) {
    const url = `/grn/project/${id}`;
    return api.get(url, "");
}

async function createGRNWithFiles(params: { grnData: any, imageData?: any }) {
    try {
        const baseUrl = '/grn';

        const createdGRN = await api.create(baseUrl, { ...params.grnData });

        const grnResponse = createdGRN.data;

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'GRN');
            params.imageData.append('typeId', String(grnResponse?.data?.newGRN?.id));
            // params.imageData.append('tagId', "42");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: grnResponse?.data?.newGRN?.id,
                body: {
                    ...grnResponse?.data?.newGRN,
                    image_id: newImageDataResponse.filter((img: any) => img !== null && img !== undefined)
                }
            };

            const baseRfqUrl = `/grn/updateImage/${grnResponse?.data?.newGRN?.id}`;
            const updateRfq = await api.update(baseRfqUrl, { ...updatedBody.body });

            return updateRfq;
        }

        return grnResponse;

    } catch (error) {
        console.error('Error creating grn with files:', error);
        throw error;
    }
}

async function updateGRNWithFiles(params: { id: number, grnData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'GRN');
            params.imageData.append('typeId', String(params.id));
            // params.imageData.append('tagId', "42");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.grnData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/grn/${params.id}`;
        return api.update(baseUrl, {
            ...params.grnData,
            image_id: combinedImageIds.filter((img: any) => img !== null && img !== undefined)
        });
    } catch (error) {
        console.error('Error updating grn with files:', error);
        throw error;
    }
}




export { createGRN, fetchGRNById, fetchGRNs, editGRN, createGRNWithFiles, updateGRNWithFiles, fetchGrnByProjectId };
