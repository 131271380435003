import { ActionTypes as AuthActionTypes } from "./constants";
import { TeamResponse } from "redux/types/DataTypes";

const INIT_STATE = {
    loading: true,
    team: {},
    singleTeam: {},
    error: {},
    success: false,
    deleteTeamSuccess: false
}

type AuthActionType = {
    type:
    | AuthActionTypes.SET_TEAM
    | AuthActionTypes.CREATE_TEAM
    | AuthActionTypes.FETCH_TEAM
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_TEAM
    | AuthActionTypes.SET_SINGLE_TEAM
    | AuthActionTypes.ADD_EMPLOY_SUCCESS
    | AuthActionTypes.ADD_EMPLOY_FAIL
    | AuthActionTypes.UPDATE_TEAM_SUCCESS
    | AuthActionTypes.UPDATE_TEAM_FAIL
    | AuthActionTypes.DELETE_TEAM
    | AuthActionTypes.DELETE_TEAM_SUCCESS
    | AuthActionTypes.DELETE_TEAM_FAIL
    payload: {
        actionType?: string;
        data?: TeamResponse | {};
        error?: string;
    }
}

const TeamReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_TEAM:
            return { ...state, team: action.payload.data, loading: false, success: false, deleteTeamSuccess: false };
        case AuthActionTypes.SET_SINGLE_TEAM:
            return { ...state, singleTeam: action.payload.data, loading: false, success: false }
        case AuthActionTypes.ADD_EMPLOY_SUCCESS:
            return { ...state, success: true, error: {} }
        case AuthActionTypes.ADD_EMPLOY_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_TEAM_SUCCESS:
            return { ...state, success: true, error: {} }
        case AuthActionTypes.UPDATE_TEAM_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.SET_ERROR:
            return { ...state, team: {}, error: action.payload.error }
        case AuthActionTypes.DELETE_TEAM_SUCCESS:
            return { ...state, deleteTeamSuccess: true, error: {} }
        case AuthActionTypes.DELETE_TEAM_FAIL:
            return { ...state, deleteTeamSuccess: false, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default TeamReducer;