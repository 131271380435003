import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    error: {},
    query: {},
    singleQuery: {},
    createdQuery: {},
    success: false,
    queryStatus: {},
    queryDeleteSuccess: false,
    statusHistory: {},
    commentHistory: {},
}


type AuthActionType = {
    type:
    | AuthActionTypes.FETCH_QUERY
    | AuthActionTypes.SET_QUERY
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_QUERY
    | AuthActionTypes.SET_SINGLE_QUERY
    | AuthActionTypes.SET_SINGLE_ERROR
    | AuthActionTypes.UPDATE_QUERY
    | AuthActionTypes.UPDATE_QUERY_ERROR
    | AuthActionTypes.UPDATE_QUERY_SUCCESS
    | AuthActionTypes.CREATE_QUERY_SUCCESS
    | AuthActionTypes.RESET
    | AuthActionTypes.DELETE_QUERY
    | AuthActionTypes.DELETE_QUERY_SUCCESS
    | AuthActionTypes.DELETE_QUERY_FAIL
    | AuthActionTypes.SET_QUERY_STATUS
    | AuthActionTypes.SET_QUERY_STATUS_HISTORY
    | AuthActionTypes.SET_QUERY_COMMENT_HISTORY
    | AuthActionTypes.RESET_UPDATE_SUCCESS
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}


const QueryReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_QUERY:
            return { ...state, query: action.payload.data, loading: false, success: false, singleQuery: {}, createdQuery: {}, queryDeleteSuccess: false }
        case AuthActionTypes.SET_SINGLE_QUERY:
            return { ...state, singleQuery: action.payload.data, loading: false, success: false }
        case AuthActionTypes.CREATE_QUERY_SUCCESS:
            return { ...state, createdQuery: action.payload.data, loading: false, success: true }
        case AuthActionTypes.UPDATE_QUERY_SUCCESS:
            return { ...state, loading: false, success: true }
        case AuthActionTypes.SET_ERROR:
            return { ...state, error: action.payload.error, loading: false, success: false }
        case AuthActionTypes.RESET:
            return {
                ...state, loading: false, success: false, singleQuery: {}, createdQuery: {}, statusHistory: {},
                commentHistory: {},
            }
        case AuthActionTypes.RESET_UPDATE_SUCCESS:
            return { ...state, success: false }
        case AuthActionTypes.DELETE_QUERY_SUCCESS:
            return { ...state, queryDeleteSuccess: true, error: {} }
        case AuthActionTypes.SET_QUERY_STATUS:
            return { ...state, queryStatus: action.payload.data, error: {} }
        case AuthActionTypes.SET_QUERY_STATUS_HISTORY:
            return { ...state, statusHistory: action.payload.data, loading: false }
        case AuthActionTypes.SET_QUERY_COMMENT_HISTORY:
            return { ...state, commentHistory: action.payload.data, loading: false }
        case AuthActionTypes.DELETE_QUERY_FAIL:
            return { ...state, queryDeleteSuccess: false, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default QueryReducer;