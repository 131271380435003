import { GateEntryActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  gateEntrys: [],
  singleGateEntry: {},
  error: {},
  success: false,
};

const GateEntryReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GateEntryActionTypes.CREATE_GATEENTRY_REQUEST:
    case GateEntryActionTypes.EDIT_GATEENTRY_REQUEST:
    case GateEntryActionTypes.FETCH_GATEENTRYS_REQUEST:
    case GateEntryActionTypes.FETCH_GATEENTRY_BY_ID_REQUEST:
    case GateEntryActionTypes.RESET_GATEENTRY_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case GateEntryActionTypes.SET_SINGLE_GATEENTRY:
    return { ...state , singleGateEntry: action.payload.data, loading:false }  
    case GateEntryActionTypes.CREATE_GATEENTRY_SUCCESS:
      return {
        ...state,
        gateEntrys: [...state.gateEntrys, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case GateEntryActionTypes.EDIT_GATEENTRY_SUCCESS:
      const updatedGateEntrys = state.gateEntrys.map((gateEntry: any) =>
        gateEntry.id === action.payload.data.id ? action.payload.data : gateEntry
      );
      return {
        ...state,
        gateEntrys: updatedGateEntrys,
        loading: false,
        error: {},
        success: true,
      };

    case GateEntryActionTypes.FETCH_GATEENTRYS_SUCCESS:
      return {
        ...state,
        gateEntrys: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case GateEntryActionTypes.FETCH_GATEENTRY_BY_ID_SUCCESS:
      return {
        ...state,
        singleGateEntry: action.payload.data,
        gateEntrys: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case GateEntryActionTypes.RESET_SINGLE_GATEENTRY:
      return {
          ...state,
          singleGateEntry: null,
      };

    case GateEntryActionTypes.CREATE_GATEENTRY_FAILURE:
    case GateEntryActionTypes.EDIT_GATEENTRY_FAILURE:
    case GateEntryActionTypes.FETCH_GATEENTRYS_FAILURE:
    case GateEntryActionTypes.FETCH_GATEENTRY_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default GateEntryReducer;
