import { ActionTypes } from './constants';


const INIT_STATE = {
    machines: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    createMachine: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    machineDetail: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    updateMachine: {
        loading: false,
        error: null,
        success: false,
        data: null
    },

    deleteMachine: {
        loading: false,
        error: null,
        success: false,
        data: null
    },
}


type MachineActionType = {
    type:
    | ActionTypes.CREATE_MACHINE
    | ActionTypes.CREATE_MACHINE_RESET_STATE
    | ActionTypes.FETCH_MACHINE_LIST
    | ActionTypes.FETCH_MACHINE_LIST_SUCCESS
    | ActionTypes.FETCH_MACHINE_LIST_FAIL
    | ActionTypes.FETCH_MACHINE_LIST_RESET_STATE
    | ActionTypes.FETCH_MACHINE_DETAIL
    | ActionTypes.FETCH_MACHINE_DETAIL_RESET_STATE
    | ActionTypes.FETCH_MACHINE_DETAIL_SUCCESS
    | ActionTypes.FETCH_MACHINE_DETAIL_FAIL
    | ActionTypes.UPDATE_MACHINE
    | ActionTypes.UPDATE_MACHINE_SUCCESS
    | ActionTypes.UPDATE_MACHINE_FAIL
    | ActionTypes.CREATE_MACHINE_SUCCESS
    | ActionTypes.CREATE_MACHINE_FAIL
    | ActionTypes.UPDATE_MACHINE_RESET_STATE
    | ActionTypes.DELETE_MACHINE
    | ActionTypes.DELETE_MACHINE_SUCCESS
    | ActionTypes.DELETE_MACHINE_FAIL
    | ActionTypes.DELETE_MACHINE_RESET_STATE
    | ActionTypes.SET_ERROR
    payload: {
        actionType?: string;
        data?: {} | any;
        error?: string;
    }
}


const MachineReducer = (state: any = INIT_STATE, action: MachineActionType) => {
    switch (action.type) {
        
        case ActionTypes.FETCH_MACHINE_LIST_RESET_STATE:
            return { ...state, machines: {loading: false, error: null, success: false} }
        case ActionTypes.FETCH_MACHINE_LIST:
            return { ...state}
        case ActionTypes.FETCH_MACHINE_LIST_SUCCESS:
            return { ...state, machines: {loading: false, error: null, success: true, data: action.payload.data} }
        case ActionTypes.FETCH_MACHINE_LIST_FAIL:
            return { ...state, machines: {loading: false, error: action.payload.error, success: false, data: null} }

        case ActionTypes.CREATE_MACHINE_RESET_STATE:
            return { ...state, createMachine: {loading: false, error: null, success: false, data: null} }
        case ActionTypes.CREATE_MACHINE:
            return { ...state, createMachine: {loading: true, error: null, success: false, data: null} }
        case ActionTypes.CREATE_MACHINE_SUCCESS:
            return { ...state, createMachine: {loading: false, error: null, success: true, data: action.payload.data} }
        case ActionTypes.CREATE_MACHINE_FAIL:
            return { ...state, createMachine: {loading: false, error: action.payload.error, success: false, data: null} }

        case ActionTypes.FETCH_MACHINE_DETAIL_RESET_STATE:
            return { ...state, machineDetail: {loading: false, error: null, success: false} }
        case ActionTypes.FETCH_MACHINE_DETAIL:
            return { ...state, machineDetail: {loading: true, error: null, success: false, data: null} }
        case ActionTypes.FETCH_MACHINE_DETAIL_SUCCESS:
            return { ...state, machineDetail: {loading: false, error: null, success: true, data: action.payload.data} }
        case ActionTypes.FETCH_MACHINE_DETAIL_FAIL:
            return { ...state, machineDetail: {loading: false, error: action.payload.error, success: false, data: null} }

        case ActionTypes.UPDATE_MACHINE_RESET_STATE:
            return { ...state, updateMachine: {loading: false, error: null, success: false, data: null} }
        case ActionTypes.UPDATE_MACHINE:
            return { ...state, updateMachine: {loading: true, error: null, success: false, data: null} }
        case ActionTypes.UPDATE_MACHINE_SUCCESS:
            return { ...state, updateMachine: {loading: false, error: null, success: true, data: action.payload.data} }
        case ActionTypes.UPDATE_MACHINE_FAIL:
            return { ...state, updateMachine: {loading: false, error: action.payload.error, success: false, data: null} }

        case ActionTypes.DELETE_MACHINE_RESET_STATE:
            return { ...state, updateMachine: {loading: false, error: null, success: false, data: null} }
        case ActionTypes.DELETE_MACHINE:
            return { ...state, updateMachine: {loading: true, error: null, success: false, data: null} }
        case ActionTypes.DELETE_MACHINE_SUCCESS:
            return { ...state, updateMachine: {loading: false, error: null, success: true, data: action.payload.data} }
        case ActionTypes.DELETE_MACHINE_FAIL:
            return { ...state, updateMachine: {loading: false, error: action.payload.error, success: false, data: null} }
              
            default:
            return { ...state }
    }
}

export default MachineReducer;