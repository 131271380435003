import { APICore } from "./apiCore";

const api = new APICore();

function fetchTeam(params: { page: string, limit: string, sortBy: string, sort: string }) {
    const baseUrl = `/team?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}`;
    console.log(baseUrl)
    return api.get(`${baseUrl}`, "");
}

function createTeam(body: any) {
    const baseUrl = '/team';
    return api.create(`${baseUrl}`, body);
}

function fetchSingleTeam(params: any) {
    const baseUrl = `/team/${params}`;
    return api.get(`${baseUrl}`, {});
}

function updateTeam(data: any) {
    const baseUrl = `/team/${data.id}`;
    return api.update(`${baseUrl}`, data);
}

function deleteTeam(params: any) {
    const baseUrl = `/team/${params.id}`;
    return api.delete(`${baseUrl}`);
}

export { fetchTeam, createTeam, fetchSingleTeam, updateTeam, deleteTeam }