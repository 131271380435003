import { APICore } from "./apiCore";
const api = new APICore();

function createFabDrawing(data: any) {
    const baseUrl = `/fabDrawing/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchFabDrawings(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/fabDrawing/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchFabDrawingById(id: number) {
    const url = `/fabDrawing/${id}`;
    return api.get(url, "");
}

function editFabDrawing(id: number, data: any) {
    const url = `/fabDrawing/${id}`;
    return api.update(url, data);
}


async function createFabDrawingWithFiles(params: { fabDrawingData: any, imageData?: any, gaImageData?:any }) {
    try {
        const baseUrl = '/fabDrawing';

        const createdFabDrawing = await api.create(baseUrl, { ...params.fabDrawingData });

        const fabDrawingResponse = createdFabDrawing.data;

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        let newGaImageDataResponse: number[] = [];

        if(params.gaImageData instanceof FormData){
            params.gaImageData.append('uploadedBy', String(105));
            params.gaImageData.append('type', 'GA Drawing');
            params.gaImageData.append('typeId', String(fabDrawingResponse?.data?.newFab?.id));
            params.gaImageData.append('tagId', "3");

            const newImageFile = await api.create(fileUrl, params.gaImageData);
            const newImageFileResponse = newImageFile.data;
            newGaImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'Fabrication Drawing');
            params.imageData.append('typeId', String(fabDrawingResponse?.data?.newFab?.id));
            params.imageData.append('tagId', "5");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if (newImageDataResponse.length > 0 || newGaImageDataResponse.length> 0) {
            const updatedBody = {
                id: fabDrawingResponse?.data?.newFab?.id,
                body: {
                    ...fabDrawingResponse?.data?.newFab,
                    image_id: newImageDataResponse,
                    ga_approved_image_id: newGaImageDataResponse
                }
            };

            const baseRfqUrl = `/fabDrawing/updateImage/${fabDrawingResponse?.data?.newFab?.id}`;
            const updateRfq = await api.update(baseRfqUrl, { ...updatedBody.body });

            return updateRfq;
        }

        return fabDrawingResponse;

    } catch (error) {
        console.error('Error creating fabDrawing with files:', error);
        throw error;
    }
}

async function updateFabDrawingWithFiles(params: { id: number, fabDrawingData: any, imageData?: any, gaImageData?:any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        let newGaImageDataResponse: number[] = [];

        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'Fabrication Drawing');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "5");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if(params.gaImageData instanceof FormData){
            params.gaImageData.append('uploadedBy', String(105));
            params.gaImageData.append('type', 'GA Drawing');
            params.gaImageData.append('typeId', String(params.id));
            params.gaImageData.append('tagId', "3");

            const newImageFile = await api.create(fileUrl, params.gaImageData);
            const newImageFileResponse = newImageFile.data;
            newGaImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedGaImageIds = [
            ...(params.fabDrawingData.ga_approved_image_id || []),
            ...newGaImageDataResponse
        ]

        const combinedImageIds = [
            ...(params.fabDrawingData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/fabDrawing/${params.id}`;
        return api.update(baseUrl, {
            ...params.fabDrawingData,
            image_id: combinedImageIds,
            ga_approved_image_id: combinedGaImageIds
        });
    } catch (error) {
        console.error('Error updating fabDrawing with files:', error);
        throw error;
    }
}




export { createFabDrawing, fetchFabDrawingById, fetchFabDrawings, editFabDrawing, createFabDrawingWithFiles, updateFabDrawingWithFiles };
