export const config = {
   API_URL: process.env.REACT_APP_ENV === 'development' ? "https://faraday-admin.arachnomesh.com/api/v1/" : process.env.REACT_APP_ENV === 'production' ? "https://admin.bengaws.com/api/v1/" : process.env.REACT_APP_ENV === 'test' ? "https://admin-dev.bengaws.com/api/v1/" : "http://localhost:5005/api/v1/",
  //API_URL: "https://faraday-admin.arachnomesh.com/api/v1/" ,

  SOCKET_URL: process.env.REACT_APP_ENV === 'development' ? "https://faraday-admin.arachnomesh.com" : process.env.REACT_APP_ENV === 'production' ? "https://admin.bengaws.com" : process.env.REACT_APP_ENV === 'test' ? "https://admin-dev.bengaws.com" : "http://localhost:7666",
  GOOGLE_MAP_KEY: process.env.REACT_APP_ENV === 'development' ? "AIzaSyD5AKpn2uqqzDuIYskh5TfJLmXsupw76SI" : process.env.REACT_APP_ENV === 'production' ? "AIzaSyD5AKpn2uqqzDuIYskh5TfJLmXsupw76SI" : process.env.REACT_APP_ENV === 'test' ? "AIzaSyD5AKpn2uqqzDuIYskh5TfJLmXsupw76SI" : "",
  IMAGE_URL: process.env.REACT_APP_ENV === 'development' ? "https://faraday-admin.arachnomesh.com" : process.env.REACT_APP_ENV === 'production' ? "https://admin.bengaws.com" : process.env.REACT_APP_ENV === 'test' ? "https://admin-dev.bengaws.com" : "http://localhost:5005",
};


/**

 * @desc ${form_id-type_id} combination mapped to a unique uuid
 * to be updated manually
 * uuid will be coming from db , created by user
 **/
const map_uuid: any = {
  stages: "stages_10"

};

/**
* @param key: The key for which uuid is required.
* @desc it will return mapped uuid 
 **/
export function mapFormTypeToUuid(key: any): string | null {

  if (key in map_uuid) {
    return map_uuid[key];
  }
  return null
}


