/* eslint-disable */
import Routes from "routes/Routes";

// For Default import Saas.scss
import "assets/scss/Saas.scss";
import "assets/global/global.css"
import "assets/global/style.css"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';
import 'react-image-lightbox/style.css';
import { onMessageListener } from "./firebase";
const App = () => {


  onMessageListener()
    .then((payload: any) => {
      console.log(payload, "foreground");
    })
    .catch((err) => console.log("failed: ", err));


  document.body.addEventListener('click', onbodyClickHandler)
  function onbodyClickHandler() {
    try {
      let toastBody: any = document.getElementsByClassName('Toastify__toast--close-on-click')
      toastBody[0].click();
    } catch (error) {
      // console.log(error)
    }

  }
  return <Routes />;
};

export default App;
