import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchOrganization, fetchSingleOrganization, createOrganization, updateOrganization, fetchOrganizationForDasbord, deleteOrganization, fetchOrganizationLocation, createOrganizationWithCust, updateOrganizationWithCust } from "helpers";
import { ActionTypes } from './constants'
import { organizationAddError, organizationAddResponse, organizationApiError, organizationApiResponse, organizationUpdateError, organizationUpdateResponse, organizationDeleteError, organizationDeleteResponse, organizationWithCustomerResponse, organizationWithCustomerError, updateOrganizationWithCustomerResponse, updateOrganizationWithCustomerError } from "./actions";


function* organization(params: any): SagaIterator {
    try {
        const response = yield call(fetchOrganization, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, filterBy: params.payload.data.filterBy, searchBy: params.payload?.data?.searchBy });
        const organizationData = yield response.data;
        yield put(organizationApiResponse(ActionTypes.FETCH_ORGANIZATION, organizationData));
    } catch (e: any) {
        yield put(organizationApiError(ActionTypes.FETCH_ORGANIZATION, e))
    }
}



function* _dashboardData(params: any): SagaIterator {
    try {
        const response = yield call(fetchOrganizationForDasbord, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter });
        const organizationData = yield response.data;
        yield put(organizationApiResponse(ActionTypes.DASHBOARD_ORGANIZATION_DATA, organizationData));
    } catch (e: any) {
        yield put(organizationApiError(ActionTypes.DASHBOARD_ORGANIZATION_DATA, e))
    }
}


function* singleOrganization(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleOrganization, params.payload.id);
        const singleOrganization = response.data;
        yield put(organizationApiResponse(ActionTypes.FETCH_SINGLE_ORGANIZATION, singleOrganization))
    } catch (e: any) {
        yield put(organizationApiError(ActionTypes.FETCH_SINGLE_ORGANIZATION, e));
    }
}


function* _createOrganization({ payload }: any): SagaIterator {
    try {
        const response = yield call(createOrganization, { organization: payload.data })
        const organizationData = response.data;
        yield put(organizationAddResponse(ActionTypes.CREATE_ORGANIZATION, organizationData))
    } catch (e: any) {
        yield put(organizationAddError(ActionTypes.CREATE_ORGANIZATION, e));
    }
}

function* _updateOrganization({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateOrganization, { id: payload.data.id, updateBody: payload.data.updateBody })
        const organizationData = response.data;
        yield put(organizationUpdateResponse(ActionTypes.UPDATE_ORGANIZATION_SUCCESS, organizationData));
    } catch (e: any) {
        yield put(organizationUpdateError(ActionTypes.UPDATE_ORGANIZATION_FAIL, e));
    }
}

function* _deleteOrganization({ payload }: any): SagaIterator {
    try {
        const response = yield call(deleteOrganization, { id: payload.data.id })
        const organizationDelResponse = response.data;
        yield put(organizationDeleteResponse(ActionTypes.DELETE_ORGANIZATION_SUCCESS, organizationDelResponse))
    } catch (e: any) {
        yield put(organizationDeleteError(ActionTypes.DELETE_ORGANIZATION_FAIL, e))
    }
}

function* _organizationLocation(): SagaIterator {
    try {
        const response = yield call(fetchOrganizationLocation);
        const organizationData = response.data?.response?.organizationLocation
        yield put(organizationApiResponse(ActionTypes.FETCH_ORGANIZATION_LOCATION, organizationData));
    } catch (e: any) {
        yield put(organizationApiResponse(ActionTypes.FETCH_ORGANIZATION_LOCATION, e))
    }
}

function* _createOrganizationWithCustomer({ payload }: any): SagaIterator {
    try {
        console.log(payload, "sagapayload")
        const response = yield call(createOrganizationWithCust, { organization: payload.data, customer_details: payload.data.customer_details })
        const organizationData = response;
        console.log(response, "organizationData")
        console.log(organizationData, "organizationData")
        if (organizationData?.error) {
            yield put(organizationWithCustomerError(ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_FAIL, organizationData?.error));
        } else {
            yield put(organizationWithCustomerResponse(ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS, organizationData))
        }

    } catch (e: any) {
        yield put(organizationWithCustomerError(ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER_FAIL, e));
    }
}

function* _updateOrganizationWithCustomer({ payload }: any): SagaIterator {
    try {
        console.log(payload, "sagapayload")
        const response = yield call(updateOrganizationWithCust, { id: payload.data.id, updateBody: payload.data.updateBody, customer_details: payload.data.updateBody.customer_details })
        const organizationData = response;
        console.log(organizationData, "organizationData")
        if (organizationData?.error) {
            yield put(updateOrganizationWithCustomerError(ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_FAIL, organizationData?.error));
        } else {
            yield put(updateOrganizationWithCustomerResponse(ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_SUCCESS, organizationData));
        }
    } catch (e: any) {
        yield put(updateOrganizationWithCustomerError(ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER_FAIL, e));
    }
}





function* watchOrganization() {
    yield takeEvery(ActionTypes.FETCH_ORGANIZATION, organization);
    yield takeEvery(ActionTypes.CREATE_ORGANIZATION, _createOrganization);
    yield takeEvery(ActionTypes.UPDATE_ORGANIZATION, _updateOrganization);
    yield takeEvery(ActionTypes.FETCH_SINGLE_ORGANIZATION, singleOrganization);
    yield takeEvery(ActionTypes.DASHBOARD_ORGANIZATION_DATA, _dashboardData)
    yield takeEvery(ActionTypes.DELETE_ORGANIZATION, _deleteOrganization)
    yield takeEvery(ActionTypes.FETCH_ORGANIZATION_LOCATION, _organizationLocation)
    yield takeEvery(ActionTypes.CREATE_ORGANIZATION_WITH_CUSTOMER, _createOrganizationWithCustomer)
    yield takeEvery(ActionTypes.UPDATE_ORGANIZATION_WITH_CUSTOMER, _updateOrganizationWithCustomer)



}

export default watchOrganization;