export enum ActionTypes {
    SET_CUSTOMER = "SET_CUSTOMER",
    SET_ERROR = "SET_ERROR",
    CREATE_CUSTOMER = "CREATE_CUSTOMER",
    FETCH_CUSTOMER = "FETCH_CUSTOMER",
    UPDATE_CUSTOMER = "UPDATE_CUSTOMER",
    FETCH_SINGLE_CUSTOMER = "FETCH_SINGLE_CUSTOMER",
    SET_SINGLE_CUSTOMER = "SET_SINGLE_CUSTOMER",
    UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS",
    UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL",
    ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS",
    ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL",
    DASHBOARD_CUSTOMER_DATA = "DASHBOARD_CUSTOMER_DATA",
    SET_CUSTOMER_DASHBOARD_DATA = "SET_CUSTOMER_DASHBOARD_DATA",
    DELETE_CUSTOMER = "DELETE_CUSTOMER",
    DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL",
    DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS",
    DELETE_CUSTOMER_BY_PHONE = "DELETE_CUSTOMER_BY_PHONE",
    DELETE_CUSTOMER_FAIL_BY_PHONE = "DELETE_CUSTOMER_FAIL_BY_PHONE",
    DELETE_CUSTOMER_SUCCESS_BY_PHONE = "DELETE_CUSTOMER_SUCCESS_BY_PHONE",
    FETCH_CUSTOMER_LOCATION="FETCH_CUSTOMER_LOCATION",
    SET_CUSTOMER_LOCATION="SET_CUSTOMER_LOCATION",
    RESET_CUSTOMER = "RESET_CUSTOMER"

}


