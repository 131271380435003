export enum IndentActionTypes {
    CREATE_INDENT_REQUEST = 'CREATE_INDENT_REQUEST',
    CREATE_INDENT_SUCCESS = 'CREATE_INDENT_SUCCESS',
    CREATE_INDENT_FAILURE = 'CREATE_INDENT_FAILURE',
    FETCH_INDENTS_REQUEST = 'FETCH_INDENTS_REQUEST',
    FETCH_INDENTS_SUCCESS = 'FETCH_INDENTS_SUCCESS',
    FETCH_INDENTS_FAILURE = 'FETCH_INDENTS_FAILURE',
    FETCH_INDENTS_ITEMS_REQUEST = 'FETCH_INDENTS_ITEMS_REQUEST',
    FETCH_INDENTS_ITEMS_SUCCESS = 'FETCH_INDENTS_ITEMS_SUCCESS',
    FETCH_INDENTS_ITEMS_FAILURE = 'FETCH_INDENTS_ITEMS_FAILURE',
    FETCH_INDENT_BY_ID_REQUEST = 'FETCH_INDENT_BY_ID_REQUEST',
    FETCH_INDENT_BY_ID_SUCCESS = 'FETCH_INDENT_BY_ID_SUCCESS',
    FETCH_INDENT_BY_ID_FAILURE = 'FETCH_INDENT_BY_ID_FAILURE',
    FETCH_INDENT_BY_PROJECT_ID_REQUEST = 'FETCH_INDENT_BY_PROJECT_ID_REQUEST',
    FETCH_INDENT_BY_PROJECT_ID_SUCCESS = 'FETCH_INDENT_BY_PROJECT_ID_SUCCESS',
    FETCH_INDENT_BY_PROJECT_ID_FAILURE = 'FETCH_INDENT_BY_PROJECT_ID_FAILURE',
    EDIT_INDENT_REQUEST = 'EDIT_INDENT_REQUEST',
    EDIT_INDENT_SUCCESS = 'EDIT_INDENT_SUCCESS',
    EDIT_INDENT_FAILURE = 'EDIT_INDENT_FAILURE',
    RESET_INDENT_SUCCESS_STATE = 'RESET_INDENT_SUCCESS_STATE',
    FETCH_SINGLE_INDENT = 'FETCH_SINGLE_INDENT',
    SET_SINGLE_INDENT = 'SET_SINGLE_INDENT',
    RESET_SINGLE_INDENT = 'RESET_SINGLE_INDENT',
    FETCH_SINGLE_PROJECT_INDENT = 'FETCH_SINGLE_PROJECT_INDENT',
    SET_SINGLE_PROJECT_INDENT = 'SET_SINGLE_PROJECT_INDENT',
    RESET_SINGLE_PROJECT_INDENT = 'RESET_SINGLE_PROJECT_INDENT'
}
