import { ActionTypes as AuthActionTypes } from "./constants";
import { ApprovalResponse } from "redux/types/DataTypes";

const INIT_STATE = {
    loading: true,
    approval: {},
    count: null,
    singleApproval: {},
    approvalTypes: {},
    approvalStatus: {},
    error: {},
    success: false,
    createdApproval: {},
    statusHistory: {},
    commentHistory: {},
    deleteApprovalSuccess: false
}

type AuthActionType = {
    type:
    | AuthActionTypes.SET_APPROVAL
    | AuthActionTypes.CREATE_APPROVAL
    | AuthActionTypes.FETCH_APPROVAL
    | AuthActionTypes.SET_APPROVAL_COUNT
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_APPROVAL
    | AuthActionTypes.SET_SINGLE_APPROVAL
    | AuthActionTypes.ADD_APPROVAL_SUCCESS
    | AuthActionTypes.ADD_APPROVAL_FAIL
    | AuthActionTypes.UPDATE_APPROVAL_SUCCESS
    | AuthActionTypes.UPDATE_APPROVAL_FAIL
    | AuthActionTypes.SET_APPROVAL_TYPES
    | AuthActionTypes.FETCH_APPROVAL_TYPES
    | AuthActionTypes.RESET_APPROVAL_STATE
    | AuthActionTypes.DELETE_APPROVAL
    | AuthActionTypes.DELETE_APPROVAL_SUCCESS
    | AuthActionTypes.DELETE_APPROVAL_FAIL
    | AuthActionTypes.SET_APPROVAL_STATUS
    | AuthActionTypes.SET_APPROVAL_STATUS_HISTORY
    | AuthActionTypes.SET_APPROVAL_COMMENT_HISTORY
    | AuthActionTypes.SET_APPROVAL_DUEDATE_HISTORY
    | AuthActionTypes.RESET_APPROVAL_DELETE_STATE
    | AuthActionTypes.RESET_ADD_APPROVAL
    payload: {
        actionType?: string;
        data?: ApprovalResponse | {};
        error?: string;
    }
}

const ApprovalReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_APPROVAL:
            return { ...state, approval: action.payload.data, loading: false, success: false, statusHistory: {}, commentHistory: {} };
        case AuthActionTypes.SET_SINGLE_APPROVAL:
            return { ...state, singleApproval: action.payload.data, loading: false, success: false }
        case AuthActionTypes.SET_APPROVAL_COUNT:
            return { ...state, count: action.payload.data, loading: false, success: false }
        case AuthActionTypes.SET_APPROVAL_TYPES:
            return { ...state, approvalTypes: action.payload.data, loading: false, success: false };
        case AuthActionTypes.SET_APPROVAL_STATUS:
            return { ...state, approvalStatus: action.payload.data, loading: false, success: false };
        case AuthActionTypes.SET_APPROVAL_STATUS_HISTORY:
            return { ...state, statusHistory: action.payload.data, loading: false }
        case AuthActionTypes.SET_APPROVAL_COMMENT_HISTORY:
            return { ...state, commentHistory: action.payload.data, loading: false }
        case AuthActionTypes.SET_APPROVAL_DUEDATE_HISTORY:
            return { ...state, dueDateHistory: action.payload.data, loading: false }
        case AuthActionTypes.ADD_APPROVAL_SUCCESS:
            return { ...state, success: true, error: {}, createdApproval: action.payload.data }
        case AuthActionTypes.ADD_APPROVAL_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_APPROVAL_SUCCESS:
            return { ...state, success: true, error: {} }
        case AuthActionTypes.UPDATE_APPROVAL_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.SET_ERROR:
            return { ...state, approval: {}, error: action.payload.error }
        case AuthActionTypes.RESET_APPROVAL_STATE:
            return { ...state, singleApproval: {} }
        case AuthActionTypes.RESET_APPROVAL_DELETE_STATE:
            return { ...state, deleteApprovalSuccess: false, error: {} }
        case AuthActionTypes.DELETE_APPROVAL_SUCCESS:
            return { ...state, deleteApprovalSuccess: true, error: {} }
        case AuthActionTypes.DELETE_APPROVAL_FAIL:
            return { ...state, deleteApprovalSuccess: false, error: action.payload.error }
        case AuthActionTypes.RESET_ADD_APPROVAL:
            return {...state, success: false, error: {}, createdApproval:{}}
        default:
            return { ...state }
    }
}

export default ApprovalReducer;