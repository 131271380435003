import { APICore } from "./apiCore";
const api = new APICore();

function createProcess(data: any) {
    const baseUrl = `/processes/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchProcesses(page?: any, limit?: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/processes/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchProcessById(id: number) {
    const url = `/processes/${id}`;
    return api.get(url, "");
}

function editProcess(id: number, data: any) {
    const url = `/processes/${id}`;
    return api.update(url, data);
}

export { createProcess, fetchProcessById, fetchProcesses, editProcess};
