import { WarehouseActionTypes as ActionTypes } from './constant';

export const createWarehouseRequest = (data: any) => ({
  type: ActionTypes.CREATE_WAREHOUSE_REQUEST,
  payload: {data},
});

export const resetWarehouseSuccessState = () => ({
  type: ActionTypes.RESET_WAREHOUSE_SUCCESS_STATE,
});

export const createWarehouseSuccess = (response: any) => ({
  type: ActionTypes.CREATE_WAREHOUSE_SUCCESS,
  payload: response,
});

export const createWarehouseFailure = (error: any) => ({
  type: ActionTypes.CREATE_WAREHOUSE_FAILURE,
  payload: error,
});

export const fetchWarehousesRequest = (page?: number, limit?: number, sortBy?: string,
  sort?: string,
  searchBy?: string) => ({
  
  type: ActionTypes.FETCH_WAREHOUSES_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchWarehousesSuccess = (response: any) => ({
  type: ActionTypes.FETCH_WAREHOUSES_SUCCESS,
  payload: response,
});

export const fetchWarehousesFailure = (error: any) => ({
  type: ActionTypes.FETCH_WAREHOUSES_FAILURE,
  payload: error,
});

export const fetchWarehouseByIdRequest = (id: number) => {
  console.log("Action Creator: fetchWarehouseByIdRequest called with id:", id); // Debug log
  return{
  type: ActionTypes.FETCH_WAREHOUSE_BY_ID_REQUEST,
  payload: id,
  }};

export const fetchWarehouseByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_WAREHOUSE_BY_ID_SUCCESS,
  payload: response,
});

export const fetchWarehouseByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_WAREHOUSE_BY_ID_FAILURE,
  payload: error,
});

export const editWarehouseRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_WAREHOUSE_REQUEST,
  payload: { id, data },
});

export const editWarehouseSuccess = (response: any) => ({
  type: ActionTypes.EDIT_WAREHOUSE_SUCCESS,
  payload: response,
});

export const editWarehouseFailure = (error: any) => ({
  type: ActionTypes.EDIT_WAREHOUSE_FAILURE,
  payload: error,
});

export const resetSingleWarehouse = () => ({
  type: ActionTypes.RESET_SINGLE_WAREHOUSE,
});

export const fetchWarehouseItemsRequest = (warehouseId: number) => ({
  type: ActionTypes.FETCH_WAREHOUSE_ITEMS_REQUEST,
  payload: { warehouseId },
});

export const fetchWarehouseItemsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_WAREHOUSE_ITEMS_SUCCESS,
  payload: response,
});

export const fetchWarehouseItemsFailure = (error: any) => ({
  type: ActionTypes.FETCH_WAREHOUSE_ITEMS_FAILURE,
  payload: error,
});

export const warehouseApiResponse = (actionType: string, data: any) => {
  console.log(actionType, "herre")

  switch (actionType) {
    case ActionTypes.FETCH_WAREHOUSES_SUCCESS:
    case ActionTypes.CREATE_WAREHOUSE_SUCCESS:
    case ActionTypes.EDIT_WAREHOUSE_SUCCESS:
      return {
        type: actionType,
        payload: data,
      };
    case ActionTypes.FETCH_WAREHOUSE_BY_ID_SUCCESS:
      console.log("shubhi hii")
      return {
        type: ActionTypes.SET_SINGLE_WAREHOUSE,
        payload: data
      }

    case ActionTypes.FETCH_WAREHOUSES_FAILURE:
    case ActionTypes.FETCH_WAREHOUSE_BY_ID_FAILURE:
    case ActionTypes.CREATE_WAREHOUSE_FAILURE:
    case ActionTypes.EDIT_WAREHOUSE_FAILURE:
      return {
        type: actionType,
        payload: { error: "Error while setting data to store!" },
      };
    default:
      return {
        type: ActionTypes.FETCH_WAREHOUSES_FAILURE,
        payload: { actionType, error: "Unknown action type" },
      };
  }
};

export const warehouseApiError = (actionType: string, error: any) => {
  console.log(actionType, "herre")
  switch (actionType) {
    case ActionTypes.CREATE_WAREHOUSE_REQUEST:
    case ActionTypes.EDIT_WAREHOUSE_REQUEST:
    case ActionTypes.FETCH_WAREHOUSES_REQUEST:
    case ActionTypes.FETCH_WAREHOUSE_BY_ID_REQUEST:
      return {
        type: ActionTypes.CREATE_WAREHOUSE_FAILURE,
        payload: { error }
      };
    default:
      return {
        type: ActionTypes.CREATE_WAREHOUSE_FAILURE,
        payload: { error: "Error while setting data to store!" }
      };
  }
};
