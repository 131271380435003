export enum ActionTypes {
    CREATE_PROCESS_REQUEST = 'CREATE_PROCESS_REQUEST',
    CREATE_PROCESS_SUCCESS = 'CREATE_PROCESS_SUCCESS',
    CREATE_PROCESS_FAILURE = 'CREATE_PROCESS_FAILURE',
    FETCH_PROCESSES_REQUEST = 'FETCH_PROCESSES_REQUEST',
    FETCH_PROCESSES_SUCCESS = 'FETCH_PROCESSES_SUCCESS',
    FETCH_PROCESSES_FAILURE = 'FETCH_PROCESSES_FAILURE',
    FETCH_PROCESS_BY_ID_REQUEST = 'FETCH_PROCESS_BY_ID_REQUEST',
    FETCH_PROCESS_BY_ID_SUCCESS = 'FETCH_PROCESS_BY_ID_SUCCESS',
    FETCH_PROCESS_BY_ID_FAILURE = 'FETCH_PROCESS_BY_ID_FAILURE',
    EDIT_PROCESS_REQUEST = 'EDIT_PROCESS_REQUEST',
    EDIT_PROCESS_SUCCESS = 'EDIT_PROCESS_SUCCESS',
    EDIT_PROCESS_FAILURE = 'EDIT_PROCESS_FAILURE',
    RESET_SUCCESS_STATE = 'RESET_SUCCESS_STATE',
    FETCH_SINGLE_PROCESSES = 'FETCH_SINGLE_PROCESSES',
    SET_SINGLE_PROCESSES = 'SET_SINGLE_PROCESSES',
    RESET_SINGLE_PROCESSES = 'RESET_SINGLE_PROCESSES'

}
