import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    role: { data: [] },
    singleRole: {},
    privileges: {},
    error: {},
    success: false,
    deleteRoleSuccess: false
}


type AuthActionType = {
    type:
    | AuthActionTypes.SET_ROLE
    | AuthActionTypes.CREATE_ROLE
    | AuthActionTypes.FETCH_ROLE
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_ROLE
    | AuthActionTypes.SET_SINGLE_ROLE
    | AuthActionTypes.ADD_ROLE_SUCCESS
    | AuthActionTypes.ADD_ROLE_FAIL
    | AuthActionTypes.UPDATE_ROLE_SUCCESS
    | AuthActionTypes.UPDATE_ROLE_FAIL
    | AuthActionTypes.SET_PRIVILEGES
    | AuthActionTypes.DELETE_ROLE_SUCCESS
    | AuthActionTypes.DELETE_ROLE_FAIL

    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}


const RoleReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_ROLE:
            return { ...state, role: action.payload.data, singleRole: {}, loading: false, success: false, deleteRoleSuccess: false }
        case AuthActionTypes.SET_SINGLE_ROLE:
            return { ...state, singleRole: action.payload.data, loading: false, success: false }
        case AuthActionTypes.SET_PRIVILEGES:
            return { ...state, privileges: action.payload.data, loading: false, success: false }
        case AuthActionTypes.ADD_ROLE_SUCCESS:
            return { ...state, success: true, error: {} }
        case AuthActionTypes.ADD_ROLE_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_ROLE_SUCCESS:
            return { ...state, success: true, error: {} }
        case AuthActionTypes.UPDATE_ROLE_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.SET_ERROR:
            return { ...state, role: { data: [] }, singleRole: {}, error: action.payload.error }
        case AuthActionTypes.DELETE_ROLE_SUCCESS:
            return { ...state, deleteRoleSuccess: true, error: {} }
        case AuthActionTypes.DELETE_ROLE_FAIL:
            return { ...state, deleteRoleSuccess: false, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default RoleReducer;