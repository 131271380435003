import { editDealsTypes as ActionTypes } from './constants';

export const fetchDealsRequest = (page: number, limit: number, sortBy: string,
    sort: string,
    searchBy: string) => ({
    type: ActionTypes.FETCH_DEALS_REQUEST,
    payload: { page, limit, sortBy, sort, searchBy },
  });
  
  export const fetchDealsSuccess = (response: any) => ({
    type: ActionTypes.FETCH_DEALS_SUCCESS,
    payload: response,
  });
  
  export const fetchDealsFailure = (error: any) => ({
    type: ActionTypes.FETCH_DEALS_FAILURE,
    payload: error,
  });
  
  export const fetchDealByIdRequest = (id: number) => ({
    type: ActionTypes.FETCH_DEAL_BY_ID_REQUEST,
    payload: id,
  });
  
  export const fetchDealByIdSuccess = (response: any) => ({
    type: ActionTypes.FETCH_DEAL_BY_ID_SUCCESS,
    payload: response,
  });
  
  export const fetchDealByIdFailure = (error: any) => ({
    type: ActionTypes.FETCH_DEAL_BY_ID_FAILURE,
    payload: error,
  });

export const editDealRequest = (id: number, data: any) => ({
    type: ActionTypes.EDIT_DEAL_REQUEST,
    payload: { id, data },
  });
  
  export const editDealSuccess = (response: any) => ({
    type: ActionTypes.EDIT_DEAL_SUCCESS,
    payload: response,
  });
  
  export const editDealFailure = (error: any) => ({
    type: ActionTypes.EDIT_DEAL_FAILURE,
    payload: error,
  });