// import { LoginState } from "redux/types/DataTypes";
import { ActionTypes } from "./constants";
import { Response } from "../../redux/types/DataTypes";

export type ActionType = {
  type:
  | ActionTypes.SEND_OTP_REQUEST
  | ActionTypes.OTP_REQUEST_SUCCESS
  | ActionTypes.OTP_REQUEST_FAIL
  | ActionTypes.VERIFY_OTP_REQUEST
  | ActionTypes.VERIFY_OTP_SUCCESS
  | ActionTypes.VERIFY_OTP_FAIL
  | ActionTypes.EDIT_MAIL
  | ActionTypes.LOGOUT
  | ActionTypes.ERROR_CUSTOMER_SESSION
  | ActionTypes.DELETE_USER_SESSION
  | ActionTypes.DELETE_USER_SESSION_SUCCESS
  | ActionTypes.DELETE_USER_SESSION_FAIL
  | ActionTypes.RESEND_OTP_REQUEST
  | ActionTypes.RESEND_OTP_SUCCESS
  | ActionTypes.RESEND_OTP_FAIL;
  payload: {} | string;
};

//dispatch to send otp request
export const getOtpRequest = (email: string): ActionType => ({
  type: ActionTypes.SEND_OTP_REQUEST,
  payload: { email },
});

//disptach to logout 
export const logout = (): ActionType =>({
  type: ActionTypes.LOGOUT,
  payload: { }
})


//action to set key after successfully get the response
//need to change the datatype of data
export const otpRequestResponse = (actionType: string, data: Response) => ({
  type: ActionTypes.OTP_REQUEST_SUCCESS,
  payload: { actionType, data },
});

//action to set error
export const otpErrorResponse = (actionType: string, error: any) => ({
  type: ActionTypes.OTP_REQUEST_FAIL,
  payload: { actionType, error },
});

//dispatch to verify otp
export const verifyOtpRequest = (email: string, key: string, otp: string,fcmToken:string) => ({
  type: ActionTypes.VERIFY_OTP_REQUEST,
  payload: { email, key, otp,fcmToken },
});

//dispatch to verify otp
export const verifyCustomerSession = (sessionId: object) => ({
  type: ActionTypes.VERIFY_CUSTOMER_SESSION,
  payload: {sessionId},
});


//dispatch to verify otp
export const verifyCustomerSessionRespose = (actionType: string, data: Response) => ({
  type: ActionTypes.SET_CUSTOMER_SESSION,
  payload: { actionType, data },
});


//action will be executed when otp is verified
export const verifyOtpResponse = (actionType: string, data: Response) => ({
  type: ActionTypes.VERIFY_OTP_SUCCESS,
  payload: { actionType, data },
});

//action will be executed when otp is not verified
export const verifyOtpError = (actionType: string, error: any) => ({
  type: ActionTypes.VERIFY_OTP_FAIL,
  payload: { actionType, error },
});

//customer session error
//dispatch to verify otp
export const verifyCustomerSessionError = (actionType: string, error: any) => ({
  type: ActionTypes.ERROR_CUSTOMER_SESSION,
  payload: { actionType, error },
});


//action to edit mail
export const editMailAction = () => ({
  type: ActionTypes.EDIT_MAIL,
  payload: {},
});

export const deleteUsersession = (userId: any) => ({
  type: ActionTypes.DELETE_USER_SESSION,
  payload: {userId},
});

export const deleteUserSessionSuccess = (actionType: string, data: any) => ({
  type: ActionTypes.DELETE_USER_SESSION_SUCCESS,
  payload: { actionType, data },
});

export const deleteUserSessionError = (actionType: string, error: any) => ({
  type: ActionTypes.DELETE_USER_SESSION_FAIL,
  payload: { actionType, error },
});

export const resendOtpRequest = (email: string, key: string) => ({
  type: ActionTypes.RESEND_OTP_REQUEST,
  payload: { email, key }
});

export const resendOtpResponse = (actionType: string, data: any) =>({
  type: ActionTypes.RESEND_OTP_SUCCESS,
  payload: {actionType, data}
})

export const resendOtpError = (actionType:string, data:any) =>({
  type: ActionTypes.RESEND_OTP_FAIL,
  data: {actionType, data}
})
