import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    tag: {},
    tagType: {},
    singleTag: {},
    error: {},
    success: false,
    updateSuccess: false,
    deleteTagSuccess: false
}

type AuthActionType = {
    type:
    | AuthActionTypes.SAVE_TAG
    | AuthActionTypes.SET_TAG
    | AuthActionTypes.SET_SINGLE_TAG
    | AuthActionTypes.SET_TAG_TYPE
    | AuthActionTypes.DELETE_TAG_SUCCESS
    | AuthActionTypes.DELETE_TAG_FAIL



    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}


const TagReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SAVE_TAG:
            return { ...state, singleTag: action.payload.data, loading: false, success: true, deleteTagSuccess: false }
        case AuthActionTypes.SET_SINGLE_TAG:
            return { ...state, singleTag: action.payload.data, loading: false, success: false, updateSuccess: true }
        case AuthActionTypes.SET_TAG:
            return { ...state, tag: action.payload.data, loading: false, success: false }
        case AuthActionTypes.SET_TAG_TYPE:
            return { ...state, tagType: action.payload.data, loading: false, success: false }
        case AuthActionTypes.DELETE_TAG_SUCCESS:
            return { ...state, deleteTagSuccess: true, error: {} }
        case AuthActionTypes.DELETE_TAG_FAIL:
            return { ...state, deleteTagSuccess: false, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default TagReducer