export enum ActionTypes {

    FETCH_MACHINE_LIST = "FETCH_MACHINE_LIST",
    FETCH_MACHINE_LIST_SUCCESS = "FETCH_MACHINE_LIST_SUCCESS",
    FETCH_MACHINE_LIST_FAIL = "FETCH_MACHINE_LIST_FAIL",
    FETCH_MACHINE_LIST_RESET_STATE = "FETCH_MACHINE_LIST_RESET_STATE",

    CREATE_MACHINE = "CREATE_MACHINE",
    CREATE_MACHINE_SUCCESS = "CREATE_MACHINE_SUCCESS",
    CREATE_MACHINE_FAIL = "CREATE_MACHINE_FAIL",
    CREATE_MACHINE_RESET_STATE = "CREATE_MACHINE_RESET_STATE",

    FETCH_MACHINE_DETAIL = "FETCH_MACHINE_DETAIL",
    FETCH_MACHINE_DETAIL_SUCCESS = "FETCH_MACHINE_DETAIL_SUCCESS",
    FETCH_MACHINE_DETAIL_FAIL = "FETCH_MACHINE_DETAIL_FAIL",
    FETCH_MACHINE_DETAIL_RESET_STATE = "FETCH_MACHINE_DETAIL_RESET_STATE",

    UPDATE_MACHINE = "UPDATE_MACHINE",
    UPDATE_MACHINE_SUCCESS = "UPDATE_MACHINE_SUCCESS",
    UPDATE_MACHINE_FAIL = "UPDATE_MACHINE_FAIL",
    UPDATE_MACHINE_RESET_STATE = "UPDATE_MACHINE_RESET_STATE",


    DELETE_MACHINE = "DELETE_MACHINE",
    DELETE_MACHINE_SUCCESS = "DELETE_MACHINE_SUCCESS",
    DELETE_MACHINE_FAIL = "DELETE_MACHINE_FAIL",
    DELETE_MACHINE_RESET_STATE = "DELETE_MACHINE_RESET_STATE",

    SET_ERROR = "SET_ERROR"
}


