import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { createQuery, fetchQuery, fetchSingleQuery, updateQuery, deleteQuery, fetchStatus, fetchLeadHistory } from "helpers";
import { ActionTypes } from "./constants";
import { queryApiError, queryApiResponse, deleteQueryResponse, deleteQueryError } from "./actions";

function* query(params: any): SagaIterator {
    try {
        const response = yield call(fetchQuery, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, searchBy: params.payload.data.searchBy,userId:params.payload.data.userId });
        const queryData = yield response.data;
        yield put(queryApiResponse(ActionTypes.FETCH_QUERY, queryData));
    } catch (e: any) {
        yield put(queryApiError(ActionTypes.FETCH_QUERY, e))
    }
}

function* singleQuery(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleQuery, params.payload.data);
        const queryData = yield response.data;
        yield put(queryApiResponse(ActionTypes.FETCH_SINGLE_QUERY, queryData));
    } catch (e: any) {
        yield put(queryApiError(ActionTypes.FETCH_SINGLE_QUERY, e))
    }
}

function* updateSingleQuery(params: any): SagaIterator {
    try {
        const response = yield call(updateQuery, { id: params.payload.data.id, query: params.payload.data.query });
        const queryData = yield response.data;
        yield put(queryApiResponse(ActionTypes.UPDATE_QUERY_SUCCESS, queryData));
    } catch (e: any) {
        yield put(queryApiError(ActionTypes.UPDATE_QUERY_ERROR, e))
    }
}


function* leadStatusHistory(params: any): SagaIterator {
    try {
        const response = yield call(fetchLeadHistory, params.payload.id, params.payload.type);
        const history = response.data;
        yield put(queryApiResponse(ActionTypes.FETCH_QUERY_STATUS_HISTORY, history))
    } catch (error: any) {
        yield put(queryApiError(ActionTypes.FETCH_QUERY_STATUS_HISTORY, error));
    }
}



function* leadCommentHistory(params: any): SagaIterator {
    try {
        const response = yield call(fetchLeadHistory, params.payload.id, params.payload.type);
        const history = response.data;
        yield put(queryApiResponse(ActionTypes.FETCH_QUERY_COMMENT_HISTORY, history))
    } catch (error: any) {
        yield put(queryApiError(ActionTypes.FETCH_QUERY_COMMENT_HISTORY, error));
    }
}

function* _createQuery({ payload }: any): SagaIterator {
    try {
        const response = yield call(createQuery, { query: payload.data })
        const queryData = response.data;
        yield put(queryApiResponse(ActionTypes.CREATE_QUERY_SUCCESS, queryData))
    } catch (e: any) {
        yield put(queryApiError(ActionTypes.CREATE_QUERY_ERROR, e));
    }
}

function* fetchQueryStatus(): SagaIterator {
    try {
        const response = yield call(fetchStatus, "QUERY_TASK");
        const queryData = yield response.data.data.values;
        yield put(queryApiResponse(ActionTypes.FETCH_QUERY_STATUS, queryData));
    } catch (e: any) {
        yield put(queryApiError(ActionTypes.FETCH_QUERY_STATUS, e))
    }
}

function* _deleteQuery(params: any): SagaIterator {
    console.log(params, "params")
    try {

        const response = yield call(deleteQuery, { id: params.payload.data.id });
        const queryData = yield response.data;
        yield put(deleteQueryResponse(ActionTypes.DELETE_QUERY_SUCCESS, queryData));
    } catch (e: any) {
        yield put(deleteQueryError(ActionTypes.DELETE_QUERY_FAIL, e))
    }
}

function* watchQuery() {
    yield takeEvery(ActionTypes.FETCH_QUERY, query);
    yield takeEvery(ActionTypes.FETCH_SINGLE_QUERY, singleQuery);
    yield takeEvery(ActionTypes.UPDATE_QUERY, updateSingleQuery);
    yield takeEvery(ActionTypes.CREATE_QUERY, _createQuery);
    yield takeEvery(ActionTypes.DELETE_QUERY, _deleteQuery);
    yield takeEvery(ActionTypes.FETCH_QUERY_STATUS, fetchQueryStatus);
    yield takeEvery(ActionTypes.FETCH_QUERY_COMMENT_HISTORY, leadCommentHistory)
    yield takeEvery(ActionTypes.FETCH_QUERY_STATUS_HISTORY, leadStatusHistory)
}

export default watchQuery;