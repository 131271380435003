import { IQCParameterActionTypes } from './constant';

const INIT_STATE = {
  count: 0,
  loading: true,
  iqcParameter: [],
  error: {},
  success: false,
  singleIQCParameter: {},
};

const IQCParameterReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case IQCParameterActionTypes.CREATE_IQCPARAMETER_REQUEST:
    case IQCParameterActionTypes.EDIT_IQCPARAMETER_REQUEST:
    case IQCParameterActionTypes.FETCH_IQCPARAMETERS_REQUEST:
    case IQCParameterActionTypes.FETCH_IQCPARAMETER_BY_ID_REQUEST:
    case IQCParameterActionTypes.RESET_IQCPARAMETER_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case IQCParameterActionTypes.SET_SINGLE_IQCPARAMETER:
      console.log(action.payload,"shubhi")
      return { ...state , singleIQCParameter: action.payload.data, loading:false }
    
    case IQCParameterActionTypes.CREATE_IQCPARAMETER_SUCCESS:
      return {
        ...state,
        iqcParameter: [...state.iqcParameter, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case IQCParameterActionTypes.EDIT_IQCPARAMETER_SUCCESS:
      const updatedIQCParameters = state.iqcParameter.map((iqcParameter: any) =>
        iqcParameter.id === action.payload.data.id ? action.payload.data : iqcParameter
      );
      return {
        ...state,
        iqcParameter: updatedIQCParameters,
        loading: false,
        error: {},
        success: true,
      };

    case IQCParameterActionTypes.FETCH_IQCPARAMETERS_SUCCESS:
      console.log(action.payload,"yoooo")
      return {
        ...state,
        iqcParameter: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case IQCParameterActionTypes.FETCH_IQCPARAMETER_BY_ID_SUCCESS:
      return {
        ...state,
        singleIQCParameter: action.payload.data,
        iqcParameter: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case IQCParameterActionTypes.RESET_SINGLE_IQCPARAMETER:
        return {
            ...state,
            singleIQCParameter: null,
        };

    case IQCParameterActionTypes.CREATE_IQCPARAMETER_FAILURE:
    case IQCParameterActionTypes.EDIT_IQCPARAMETER_FAILURE:
    case IQCParameterActionTypes.FETCH_IQCPARAMETERS_FAILURE:
    case IQCParameterActionTypes.FETCH_IQCPARAMETER_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default IQCParameterReducer;
