import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchCustomer, fetchSingleCustomer, createCustomer, updateCustomer, fetchCustomerForDasbord, deleteCustomer,fetchCustomerLocation, deleteCustomerByPhone } from "helpers";
import { ActionTypes } from './constants'
import { customerAddError, customerAddResponse, customerApiError, customerApiResponse, customerUpdateError, customerUpdateResponse, customerDeleteError, customerDeleteResponse, customerDeleteByPhoneResponse, customerDeleteByPhoneError } from "./actions";


function* customer(params: any): SagaIterator {
    try {
    console.log(params, "params2")

        const response = yield call(fetchCustomer, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, filterBy: params.payload.data.filterBy, searchBy: params.payload?.data?.searchBy , orgId: params.payload?.data?.orgId});
        const customerData = yield response.data;

        yield put(customerApiResponse(ActionTypes.FETCH_CUSTOMER, customerData));
    } catch (e: any) {
        yield put(customerApiError(ActionTypes.FETCH_CUSTOMER, e))
    }
}



function* _dashboardData(params: any): SagaIterator {
    try {
        const response = yield call(fetchCustomerForDasbord, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter });
        const customerData = yield response.data;
        yield put(customerApiResponse(ActionTypes.DASHBOARD_CUSTOMER_DATA, customerData));
    } catch (e: any) {
        yield put(customerApiError(ActionTypes.DASHBOARD_CUSTOMER_DATA, e))
    }
}


function* singleCustomer(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleCustomer, params.payload.id);
        const singleCustomer = response.data;
        yield put(customerApiResponse(ActionTypes.FETCH_SINGLE_CUSTOMER, singleCustomer))
    } catch (e: any) {
        yield put(customerApiError(ActionTypes.FETCH_SINGLE_CUSTOMER, e));
    }
}


function* _createCustomer({ payload }: any): SagaIterator {
    try {
        const response = yield call(createCustomer, { customer: payload.data })
        const customerData = response.data;
        yield put(customerAddResponse(ActionTypes.CREATE_CUSTOMER, customerData))
    } catch (e: any) {
        yield put(customerAddError(ActionTypes.CREATE_CUSTOMER, e));
    }
}

function* _updateCustomer({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateCustomer, { id: payload.data.id, updateBody: payload.data.body })
        const customerData = response.data;
        yield put(customerUpdateResponse(ActionTypes.UPDATE_CUSTOMER, customerData));
    } catch (e: any) {
        yield put(customerUpdateError(ActionTypes.UPDATE_CUSTOMER, e));
    }
}

function* _deleteCustomer({ payload }: any): SagaIterator {
    try {
        const response = yield call(deleteCustomer, { id: payload.data.id })
        const customerDelResponse = response.data;
        yield put(customerDeleteResponse(ActionTypes.DELETE_CUSTOMER_SUCCESS, customerDelResponse))
    } catch (e: any) {
        yield put(customerDeleteError(ActionTypes.DELETE_CUSTOMER_FAIL, e))
    }
}

function* _deleteCustomerByPhone({ payload }: any): SagaIterator {
    try {
        console.log("payload--->", payload)
        const response = yield call(deleteCustomerByPhone, { phone: payload.data.phone })
        const customerDelResponse = response.data;
        yield put(customerDeleteByPhoneResponse(ActionTypes.DELETE_CUSTOMER_SUCCESS_BY_PHONE, customerDelResponse))
    } catch (e: any) {
        yield put(customerDeleteByPhoneError(ActionTypes.DELETE_CUSTOMER_FAIL_BY_PHONE, e))
    }
}

function* _customerLocation(): SagaIterator {
    try {
      const response = yield call(fetchCustomerLocation);
      const customerData = response.data?.response?.customerLocation    
      yield put(customerApiResponse(ActionTypes.FETCH_CUSTOMER_LOCATION, customerData));
    } catch (e: any) {
      yield put(customerApiResponse(ActionTypes.FETCH_CUSTOMER_LOCATION, e))
    }
  }



function* watchCustomer() {
    yield takeEvery(ActionTypes.FETCH_CUSTOMER, customer);
    yield takeEvery(ActionTypes.CREATE_CUSTOMER, _createCustomer);
    yield takeEvery(ActionTypes.UPDATE_CUSTOMER, _updateCustomer);
    yield takeEvery(ActionTypes.FETCH_SINGLE_CUSTOMER, singleCustomer);
    yield takeEvery(ActionTypes.DASHBOARD_CUSTOMER_DATA, _dashboardData)
    yield takeEvery(ActionTypes.DELETE_CUSTOMER, _deleteCustomer)
    yield takeEvery(ActionTypes.DELETE_CUSTOMER_BY_PHONE, _deleteCustomerByPhone)
    yield takeEvery(ActionTypes.FETCH_CUSTOMER_LOCATION, _customerLocation)
    



}

export default watchCustomer;