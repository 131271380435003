export enum ActionTypes {
    SET_APPROVAL = "SET_APPROVAL",
    SET_APPROVAL_COUNT = "SET_APPROVAL_COUNT",
    SET_ERROR = "SET_ERROR",
    CREATE_APPROVAL = "CREATE_APPROVAL",
    FETCH_APPROVAL = "FETCH_APPROVAL",
    FETCH_APPROVAL_COUNT = "FETCH_APPROVAL_COUNT",
    UPDATE_APPROVAL = "UPDATE_APPROVAL",
    FETCH_SINGLE_APPROVAL = "FETCH_SINGLE_APPROVAL",
    SET_SINGLE_APPROVAL = "SET_SINGLE_APPROVAL",
    UPDATE_APPROVAL_SUCCESS = "UPDATE_APPROVAL_SUCCESS",
    UPDATE_APPROVAL_FAIL = "UPDATE_APPROVAL_FAIL",
    ADD_APPROVAL_SUCCESS = "ADD_APPROVAL_SUCCESS",
    ADD_APPROVAL_FAIL = "ADD_APPROVAL_FAIL",
    FETCH_APPROVAL_TYPES = "FETCH_APPROVAL_TYPES",
    SET_APPROVAL_TYPES = "SET_APPROVAL_TYPES",
    RESET_APPROVAL_STATE = "RESET_APPROVAL_STATE",
    DELETE_APPROVAL = "DELETE_APPROVAL",
    DELETE_APPROVAL_SUCCESS = "DELETE_APPROVAL_SUCCESS",
    DELETE_APPROVAL_FAIL = "DELETE_APPROVAL_FAIL",
    FETCH_APPROVAL_STATUS = "FETCH_APPROVAL_STATUS",
    SET_APPROVAL_STATUS = "SET_APPROVAL_STATUS",
    FETCH_APPROVAL_STATUS_HISTORY = "FETCH_APPROVAL_STATUS_HISTORY",
    SET_APPROVAL_STATUS_HISTORY = "SET_APPROVAL_STATUS_HISTORY",
    FETCH_APPROVAL_COMMENT_HISTORY = "FETCH_APPROVAL_COMMENT_HISTORY",
    SET_APPROVAL_COMMENT_HISTORY = "SET_APPROVAL_COMMENT_HISTORY",
    RESET_APPROVAL_DELETE_STATE = "RESET_APPROVAL_DELETE_STATE",
    RESET_ADD_APPROVAL = "RESET_ADD_APPROVAL",
    FETCH_APPROVAL_DUEDATE_HISTORY = "FETCH_APPROVAL_DUEDATE_HISTORY",
    SET_APPROVAL_DUEDATE_HISTORY = "SET_APPROVAL_DUEDATE_HISTORY",
}