import { ActionTypes as AuthActionTypes } from "./constants";
import { ReportResponse } from "redux/types/DataTypes";

const INIT_STATE = {
    loading: true,
    report: {},
    error: {},
    success: false
}

type AuthActionType = {
    type:
    | AuthActionTypes.SET_PROJECT_REPORT
    | AuthActionTypes.FETCH_PROJECT_REPORT
    | AuthActionTypes.SET_PROJECT_ERROR
    | AuthActionTypes.SET_INDENT_REPORT
    | AuthActionTypes.FETCH_INDENT_REPORT
    | AuthActionTypes.SET_INDENT_ERROR
    payload: {
        actionType?: string;
        data?: ReportResponse | {};
        error?: string;
    }
}

const ReportReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_PROJECT_REPORT:
            return { ...state, report: action.payload.data, loading: false, success: false };
        case AuthActionTypes.SET_PROJECT_ERROR:
            return { ...state, report: {}, error: action.payload.error }
        case AuthActionTypes.SET_INDENT_REPORT:
            return { ...state, report: action.payload.data, loading: false, success: false };
        case AuthActionTypes.SET_INDENT_ERROR:
            return { ...state, report: {}, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default ReportReducer;