import { APICore } from './apiCore';
const api = new APICore();

function uploadLeadLocater(file: File, source: any) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('source', source);
  const baseUrl = `/leads-locater/upload`;
  return api.upload(baseUrl, formData);
}

function fetchAllLeads(page?: number, limit?: number, sortBy?: string, sort?: string, searchBy?: string) {
  const baseURL = `/leads-locater/`;
  const params = new URLSearchParams();

  if (page !== undefined) params.append('page', page.toString());
  if (limit !== undefined) params.append('limit', limit.toString());
  if (sortBy) params.append('sortBy', sortBy);
  if (sort) params.append('sort', sort);
  if (searchBy) params.append('searchBy', searchBy);

  const url = `${baseURL}?${params.toString()}`;

  return api.get(url, "");
}


function markAsDuplicate(id: number, isDuplicate: number) {
  const url = `/leads-locater/leads/mark-as-duplicate/${id}`;
  return api.update(url, { isDuplicate }); 
}

function fetchLeadById(id: number) {
  const url = `/leads-locater/leads/${id}`;
  return api.get(url, "");
}

function searchContacts(id: string, linkedin_url: string) {
  const url = `/leads-locater/searchContacts`;
  return api.create(url, { id, linkedin_url  }); 
}

export {
  uploadLeadLocater,
  fetchAllLeads,
  markAsDuplicate,
  fetchLeadById,
  searchContacts
};
