import { ApprovalActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  typesCount: 0,
  loading: true,
  approvals: [],
  approvalTypes: [],
  singleApproval: {},
  error: {},
  success: false,
};

const ApprovalReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ApprovalActionTypes.CREATE_APPROVALV2_REQUEST:
    case ApprovalActionTypes.EDIT_APPROVALV2_REQUEST:
    case ApprovalActionTypes.FETCH_APPROVALSV2_REQUEST:
    case ApprovalActionTypes.FETCH_APPROVALSV2_TYPES_REQUEST:
    case ApprovalActionTypes.FETCH_APPROVALV2_BY_ID_REQUEST:
    case ApprovalActionTypes.RESET_APPROVALV2_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case ApprovalActionTypes.SET_SINGLE_APPROVALV2:
    return { ...state , singleApproval: action.payload.data, loading:false }  
    case ApprovalActionTypes.CREATE_APPROVALV2_SUCCESS:
      return {
        ...state,
        approvals: [...state.approvals, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case ApprovalActionTypes.EDIT_APPROVALV2_SUCCESS:
      const updatedApprovals = state.approvals.map((approval: any) =>
        approval.id === action.payload.data.id ? action.payload.data : approval
      );
      return {
        ...state,
        approvals: updatedApprovals,
        loading: false,
        error: {},
        success: true,
      };

    case ApprovalActionTypes.FETCH_APPROVALSV2_SUCCESS:
      return {
        ...state,
        approvals: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case ApprovalActionTypes.FETCH_APPROVALSV2_TYPES_SUCCESS:
      return {
        ...state,
        approvalTypes: action.payload.data,
        loading: false,
        error: {},
        success: true,
        typesCount: action.payload.count,
      };

    case ApprovalActionTypes.FETCH_APPROVALV2_BY_ID_SUCCESS:
      return {
        ...state,
        singleApproval: action.payload.data,
        approvals: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case ApprovalActionTypes.RESET_SINGLE_APPROVALV2:
      return {
          ...state,
          singleApproval: null,
      };

    case ApprovalActionTypes.CREATE_APPROVALV2_FAILURE:
    case ApprovalActionTypes.EDIT_APPROVALV2_FAILURE:
    case ApprovalActionTypes.FETCH_APPROVALSV2_FAILURE:
    case ApprovalActionTypes.FETCH_APPROVALSV2_TYPES_FAILURE:
    case ApprovalActionTypes.FETCH_APPROVALV2_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default ApprovalReducer;
