import { PaymentActionTypes as ActionTypes } from './constants';

export const createPaymentRequest = (data: any) => ({
  type: ActionTypes.CREATE_PAYMENT_REQUEST,
  payload: {data},
});

export const resetPaymentSuccessState = () => ({
  type: ActionTypes.RESET_PAYMENT_SUCCESS_STATE,
});

export const createPaymentSuccess = (response: any) => ({
  type: ActionTypes.CREATE_PAYMENT_SUCCESS,
  payload: response,
});

export const createPaymentFailure = (error: any) => ({
  type: ActionTypes.CREATE_PAYMENT_FAILURE,
  payload: error,
});

export const createPaymentInvoiceRequest = (data: any) => ({
  type: ActionTypes.CREATE_PAYMENT_INVOICE_REQUEST,
  payload: {data},
});

export const createPaymentInvoiceSuccess = (response: any) => ({
  type: ActionTypes.CREATE_PAYMENT_INVOICE_SUCCESS,
  payload: response,
});

export const createPaymentInvoiceFailure = (error: any) => ({
  type: ActionTypes.CREATE_PAYMENT_INVOICE_FAILURE,
  payload: error,
});

export const resetSinglePayment = () => ({
  type: ActionTypes.RESET_SINGLE_PAYMENT,
});

export const fetchPaymentsRequest = (id: string) => ({
  type: ActionTypes.FETCH_PAYMENTS_REQUEST,
  payload: id,
});

export const fetchPaymentsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_PAYMENTS_SUCCESS,
  payload: response,
});

export const fetchPaymentsFailure = (error: any) => ({
  type: ActionTypes.FETCH_PAYMENTS_FAILURE,
  payload: error,
});

export const fetchMilestonesRequest = () => ({
  type: ActionTypes.FETCH_MILESTONES_REQUEST
});

export const fetchMilestonesSuccess = (response: any) => ({
  type: ActionTypes.FETCH_MILESTONES_SUCCESS,
  payload: response,
});

export const fetchMilestonesFailure = (error: any) => ({
  type: ActionTypes.FETCH_MILESTONES_FAILURE,
  payload: error,
});

export const fetchOutgoingPaymentsRequest = (id: string) => ({
  type: ActionTypes.FETCH_OUTGOING_PAYMENTS_REQUEST,
  payload: id,
});

export const fetchOutgoingPaymentsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_OUTGOING_PAYMENTS_SUCCESS,
  payload: response,
});

export const fetchOutgoingPaymentsFailure = (error: any) => ({
  type: ActionTypes.FETCH_OUTGOING_PAYMENTS_FAILURE,
  payload: error,
});

export const fetchPoPaymentsRequest = () => ({
  type: ActionTypes.FETCH_PO_PAYMENTS_REQUEST
});

export const fetchPoPaymentsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_PO_PAYMENTS_SUCCESS,
  payload: response,
});

export const fetchPoPaymentsFailure = (error: any) => ({
  type: ActionTypes.FETCH_PO_PAYMENTS_FAILURE,
  payload: error,
});

export const fetchPaymentByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_PAYMENT_BY_ID_REQUEST,
  payload: id,
});

export const fetchPaymentByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_PAYMENT_BY_ID_SUCCESS,
  payload: response,
});

export const fetchPaymentByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_PAYMENT_BY_ID_FAILURE,
  payload: error,
});

export const editPaymentRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_PAYMENT_REQUEST,
  payload: { id, data },
});

export const editPaymentSuccess = (response: any) => ({
  type: ActionTypes.EDIT_PAYMENT_SUCCESS,
  payload: response,
});

export const editPaymentFailure = (error: any) => ({
  type: ActionTypes.EDIT_PAYMENT_FAILURE,
  payload: error,
});
