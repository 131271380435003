import { APICore } from "./apiCore";
const api = new APICore();

function createApprovalV2(data: any) {
    const baseUrl = `/approvalV2/`
    return api.create(`${baseUrl}`, { ...data })
}

function fetchApprovalsV2(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string, status?:string) {
    const baseURL = `/approvalV2/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    if(status) params.append('status',status)
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchApprovalsV2Types(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/approvalV2/types`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchApprovalV2ById(id: number) {
    const url = `/approvalV2/${id}`;
    return api.get(url, "");
}

function editApprovalV2(id: number, data: any) {
    const url = `/approvalV2/${id}`;
    return api.update(url, data);
}

function updateApprovalV2Status(id: number, data: any) {
    const url = `/approvalV2/update/${id}`;
    return api.update(url, data);
}

function fetchTimeline(id:number) {
    const url = `/approvalV2/timeline/${id}`;
    return api.get(url, "")
}

export { createApprovalV2, fetchApprovalV2ById, fetchApprovalsV2, editApprovalV2, fetchApprovalsV2Types, updateApprovalV2Status, fetchTimeline };
