import { APICore } from "./apiCore";
const api = new APICore();

function createCommissioning(data: any) {
    const baseUrl = `/commissioning/`;
    return api.create(`${baseUrl}`, { ...data });
}

function fetchCommissionings(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/commissioning/`;
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}
function fetchCommissioningsUsingProjectId(projectId:number,page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/commissioning/project`;
    const params = new URLSearchParams();
    if(projectId !== undefined) params.append('projectId',projectId.toString());
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchCommissioningById(id: number) {
    const url = `/commissioning/${id}`;
    return api.get(url, "");
}

function editCommissioning(id: number, data: any) {
    const url = `/commissioning/${id}`;
    return api.update(url, data);
}


export {
    createCommissioning,
    fetchCommissioningById,
    fetchCommissionings,
    editCommissioning,
    fetchCommissioningsUsingProjectId
};
