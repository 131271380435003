import { APICore } from "./apiCore";

const api = new APICore();

function fetchProjectReport(params: { startDate: string, endDate: string }) {
    const baseUrl = `/report/project?startDate=${params.startDate}&endDate=${params.endDate}`;
    console.log(baseUrl)
    return api.get(`${baseUrl}`, "");
}

function fetchIndentReport(params: { startDate: string, endDate: string }) {
    const baseUrl = `/report/indent?startDate=${params.startDate}&endDate=${params.endDate}`;
    console.log(baseUrl)
    return api.get(`${baseUrl}`, "");
}

export { fetchProjectReport, fetchIndentReport }