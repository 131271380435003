import { ActionTypes } from './constants';

export type LeadActionType = {
    type: string;
    payload: any;
};

export const fetchAllLeads = (
    page: number,
    limit: number,
    sortBy: string,
    sort: string,
    searchBy: string
): LeadActionType => ({
    type: ActionTypes.FETCH_ALL_LEADS,
    payload: { page, limit, sortBy, sort, searchBy }
});

export const uploadLeadLocator = (file: File, source: string): LeadActionType => ({
    type: ActionTypes.UPLOAD_LEAD_LOCATOR,
    payload: { file, source }
});

export const leadApiResponse = (actionType: string, data: any): LeadActionType => {
    switch (actionType) {
        case ActionTypes.UPLOAD_LEAD_LOCATOR:
            return {
                type: ActionTypes.UPLOAD_LEAD_LOCATOR_SUCCESS,
                payload: data
            };
        case ActionTypes.FETCH_ALL_LEADS:
            return {
                type: ActionTypes.FETCH_ALL_LEADS_SUCCESS,
                payload: data
            };
        default:
            return {
                type: ActionTypes.FETCH_ALL_LEADS_FAILURE,
                payload: { actionType, error: "Error while setting data to store!" },
            };
    }
};

export const leadApiError = (actionType: string, error: any): LeadActionType => ({
    type: actionType === ActionTypes.UPLOAD_LEAD_LOCATOR 
        ? ActionTypes.UPLOAD_LEAD_LOCATOR_FAILURE 
        : ActionTypes.FETCH_ALL_LEADS_FAILURE,
    payload: { actionType, error }
});
