import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { IQCEquipmentActionTypes } from './constant';
import {
  createIQCEquipment,
  fetchIQCEquipments,
  fetchIQCEquipmentById,
  editIQCEquipment,
} from 'helpers/api/iqcEquipment';
import {
  createIQCEquipmentSuccess,
  createIQCEquipmentFailure,
  fetchIQCEquipmentsSuccess,
  fetchIQCEquipmentsFailure,
  fetchIQCEquipmentByIdSuccess,
  fetchIQCEquipmentByIdFailure,
  editIQCEquipmentSuccess,
  editIQCEquipmentFailure,
} from './actions';

function* fetchIQCEquipmentsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchIQCEquipments, page, limit, sortBy, sort, searchBy);
    const iqcEquipmentData = response.data; 
    yield put(fetchIQCEquipmentsSuccess(iqcEquipmentData));
  } catch (e: any) {
    yield put(fetchIQCEquipmentsFailure(e));
  }
}

function* createIQCEquipmentSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;

    const response = yield call(createIQCEquipment, data);
    const iqcEquipmentData = response.data;
    yield put(createIQCEquipmentSuccess(iqcEquipmentData));
    yield put({ type: IQCEquipmentActionTypes.FETCH_IQCEQUIPMENTS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createIQCEquipmentFailure(e));
  }
}

function* fetchIQCEquipmentByIdSaga(action: any): SagaIterator {
  try {
    console.log(action.payload, "shubhi")
    console.log("Saga: Fetching iqcEquipment by ID:", action.payload);  // Debug log
    const response = yield call(fetchIQCEquipmentById, action.payload);
    const iqcEquipmentData = response.data;
    console.log("Saga: API response for iqcEquipment by ID:", iqcEquipmentData);  // Debug log

    yield put(fetchIQCEquipmentByIdSuccess(iqcEquipmentData));
  } catch (e: any) {
    yield put(fetchIQCEquipmentByIdFailure(e));
  }
}

function* editIQCEquipmentSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;

    const response = yield call(editIQCEquipment, id, data);
    const iqcEquipmentData = response.data;
    yield put(editIQCEquipmentSuccess(iqcEquipmentData));
    yield put({ type: IQCEquipmentActionTypes.FETCH_IQCEQUIPMENT_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editIQCEquipmentFailure(e));
  }
}

function* watchIQCEquipment() {
  yield takeEvery(IQCEquipmentActionTypes.FETCH_IQCEQUIPMENTS_REQUEST, fetchIQCEquipmentsSaga);
  yield takeEvery(IQCEquipmentActionTypes.CREATE_IQCEQUIPMENT_REQUEST, createIQCEquipmentSaga);
  yield takeEvery(IQCEquipmentActionTypes.FETCH_IQCEQUIPMENT_BY_ID_REQUEST, fetchIQCEquipmentByIdSaga);
  yield takeEvery(IQCEquipmentActionTypes.EDIT_IQCEQUIPMENT_REQUEST, editIQCEquipmentSaga);
}

export default watchIQCEquipment;
