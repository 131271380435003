import { IQCParameterActionTypes as ActionTypes } from './constant';

export const createIQCParameterRequest = (data: any) => ({
  type: ActionTypes.CREATE_IQCPARAMETER_REQUEST,
  payload: {data},
});

export const resetIQCParameterSuccessState = () => ({
  type: ActionTypes.RESET_IQCPARAMETER_SUCCESS_STATE,
});

export const createIQCParameterSuccess = (response: any) => ({
  type: ActionTypes.CREATE_IQCPARAMETER_SUCCESS,
  payload: response,
});

export const createIQCParameterFailure = (error: any) => ({
  type: ActionTypes.CREATE_IQCPARAMETER_FAILURE,
  payload: error,
});

export const fetchIQCParametersRequest = (page?: number, limit?: number, sortBy?: string,
  sort?: string,
  searchBy?: string) => ({
  
  type: ActionTypes.FETCH_IQCPARAMETERS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchIQCParametersSuccess = (response: any) => ({
  type: ActionTypes.FETCH_IQCPARAMETERS_SUCCESS,
  payload: response,
});

export const fetchIQCParametersFailure = (error: any) => ({
  type: ActionTypes.FETCH_IQCPARAMETERS_FAILURE,
  payload: error,
});

export const fetchIQCParameterByIdRequest = (id: number) => {
  console.log("Action Creator: fetchIQCParameterByIdRequest called with id:", id); // Debug log
  return{
  type: ActionTypes.FETCH_IQCPARAMETER_BY_ID_REQUEST,
  payload: id,
  }};

export const fetchIQCParameterByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_IQCPARAMETER_BY_ID_SUCCESS,
  payload: response,
});

export const fetchIQCParameterByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_IQCPARAMETER_BY_ID_FAILURE,
  payload: error,
});

export const editIQCParameterRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_IQCPARAMETER_REQUEST,
  payload: { id, data },
});

export const editIQCParameterSuccess = (response: any) => ({
  type: ActionTypes.EDIT_IQCPARAMETER_SUCCESS,
  payload: response,
});

export const editIQCParameterFailure = (error: any) => ({
  type: ActionTypes.EDIT_IQCPARAMETER_FAILURE,
  payload: error,
});

export const resetSingleIQCParameter = () => ({
  type: ActionTypes.RESET_SINGLE_IQCPARAMETER,
});

export const iqcParameterApiResponse = (actionType: string, data: any) => {
  console.log(actionType, "herre")

  switch (actionType) {
    case ActionTypes.FETCH_IQCPARAMETERS_SUCCESS:
    case ActionTypes.CREATE_IQCPARAMETER_SUCCESS:
    case ActionTypes.EDIT_IQCPARAMETER_SUCCESS:
      return {
        type: actionType,
        payload: data,
      };
    case ActionTypes.FETCH_IQCPARAMETER_BY_ID_SUCCESS:
      console.log("shubhi hii")
      return {
        type: ActionTypes.SET_SINGLE_IQCPARAMETER,
        payload: data
      }

    case ActionTypes.FETCH_IQCPARAMETERS_FAILURE:
    case ActionTypes.FETCH_IQCPARAMETER_BY_ID_FAILURE:
    case ActionTypes.CREATE_IQCPARAMETER_FAILURE:
    case ActionTypes.EDIT_IQCPARAMETER_FAILURE:
      return {
        type: actionType,
        payload: { error: "Error while setting data to store!" },
      };
    default:
      return {
        type: ActionTypes.FETCH_IQCPARAMETERS_FAILURE,
        payload: { actionType, error: "Unknown action type" },
      };
  }
};

export const iqcParameterApiError = (actionType: string, error: any) => {
  console.log(actionType, "herre")
  switch (actionType) {
    case ActionTypes.CREATE_IQCPARAMETER_REQUEST:
    case ActionTypes.EDIT_IQCPARAMETER_REQUEST:
    case ActionTypes.FETCH_IQCPARAMETERS_REQUEST:
    case ActionTypes.FETCH_IQCPARAMETER_BY_ID_REQUEST:
      return {
        type: ActionTypes.CREATE_IQCPARAMETER_FAILURE,
        payload: { error }
      };
    default:
      return {
        type: ActionTypes.CREATE_IQCPARAMETER_FAILURE,
        payload: { error: "Error while setting data to store!" }
      };
  }
};
