import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchViewCount } from "helpers";
import { fetchCountResponse, fetchCountError } from "./actions";
import { ActionTypes } from "./constants";

function* count(): SagaIterator {
    try {
        const response = yield call(fetchViewCount);
        const data = yield response.data;
        yield put(fetchCountResponse(ActionTypes.FETCH_COUNT, data));
    } catch (e: any) {
        yield put(fetchCountError(ActionTypes.FETCH_COUNT, e))
    }
}


function* watchVisitCount() {
    yield takeEvery(ActionTypes.FETCH_COUNT, count);
   
}

export default watchVisitCount;