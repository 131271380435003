import { useRedux } from "hooks";
import { UserSession } from "redux/types/DataTypes";

export default function useUser(): {session: UserSession} {
  
  const { appSelector } = useRedux();

  const { session } = appSelector((state) => {
      return {
          session: state.Auth.session
      }
  });

  return {
    session
  }
}
