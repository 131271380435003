import { SagaIterator } from "@redux-saga/core";
// import { APICore } from "helpers/api/apiCore";
import { call, put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "./constants";
import { sendOtp as sendOtpApi, verifyOtp as verifyOtpApi, verifyCustomerSession, deleteUserSession, resendOtp } from "helpers";
import {
  otpErrorResponse,
  otpRequestResponse,
  verifyOtpError,
  verifyOtpResponse,
  verifyCustomerSessionRespose,
  verifyCustomerSessionError,
  deleteUserSessionError,
  deleteUserSessionSuccess,
  resendOtpResponse,
  resendOtpError

} from "./actions";

type LoginData = {
  payload: {
    email: string;
    key: string;
    otp: string;
    fcmToken:string
  };
  type: string;
};

// const api = new APICore();

function* sendOtpRequest({
  payload: { email },
  type,
}: LoginData): SagaIterator {
  try {
    const response = yield call(sendOtpApi, { email });
    const res = yield response.data;
    yield put(otpRequestResponse(ActionTypes.SEND_OTP_REQUEST, res));
  } catch (e: any) {
    yield put(otpErrorResponse(ActionTypes.SEND_OTP_REQUEST, e));
  }
}

//function to verify otp
function* verifyOtpRequest({
  payload: { email, key, otp,fcmToken },
  type,
}: LoginData): SagaIterator {
  try {
    const response = yield call(verifyOtpApi, { email, key, otp,fcmToken });
    const res = yield response.data;
    // console.log("response in the verifyOtpRequest", res);
    yield put(verifyOtpResponse(ActionTypes.VERIFY_OTP_REQUEST, res));
  } catch (e: any) {
    yield put(verifyOtpError(ActionTypes.VERIFY_OTP_REQUEST, e));
  }
}
//function to verify otp
function* _verifyCustomerSession({
  payload: { sessionId }
}: any): SagaIterator {
  try {
    const response = yield call(verifyCustomerSession, sessionId);
    const res = yield response.data;
    yield put(verifyCustomerSessionRespose(ActionTypes.SET_CUSTOMER_SESSION, res));
  } catch (e: any) {
    yield put(verifyCustomerSessionError(ActionTypes.SET_CUSTOMER_SESSION, e));
  }
}

function* _deleteUserSession({
  payload: { userId }
}: any): SagaIterator {
  try {
    console.log(userId, "payload123")
    const response = yield call(deleteUserSession, userId);
    const res = yield response.data;
    yield put(deleteUserSessionSuccess(ActionTypes.DELETE_USER_SESSION_SUCCESS, res));
  } catch (e: any) {
    yield put(deleteUserSessionError(ActionTypes.DELETE_USER_SESSION_FAIL, e));
  }
}

function* _resendOtpRequest({
  payload: { email, key }} :any) : SagaIterator {
  try {
    const response = yield call(resendOtp, { email, key });
    const res = yield response.data
    yield put(resendOtpResponse(ActionTypes.RESEND_OTP_SUCCESS, res))
  } catch (e:any){
    yield put(resendOtpError(ActionTypes.RESEND_OTP_FAIL, e))
  }
}



export function* watchSendOtpRequest() {
  yield takeEvery(ActionTypes.SEND_OTP_REQUEST, sendOtpRequest);
  yield takeEvery(ActionTypes.RESEND_OTP_REQUEST, _resendOtpRequest)
}

export function* watchVerifyOtpRequest() {
  yield takeEvery(ActionTypes.VERIFY_OTP_REQUEST, verifyOtpRequest);
}


export function* watchVerifyCustomerSession() {
  yield takeEvery(ActionTypes.VERIFY_CUSTOMER_SESSION, _verifyCustomerSession);
}

export function* watchDeleteUserSession() {
  yield takeEvery(ActionTypes.DELETE_USER_SESSION, _deleteUserSession);
}