import { ActionTypes } from './constants';

export type CountActionType = {
    type:
    | ActionTypes.FETCH_COUNT
    | ActionTypes.FETCH_COUNT_SUCCESS
    | ActionTypes.FETCH_COUNT_FAIL

    payload: {} | string;

}


export const fetchCount = (): CountActionType => ({
    type: ActionTypes.FETCH_COUNT,
    payload: {}
})

export const fetchCountResponse = (actionType: string, data: any): CountActionType => ({
    type: ActionTypes.FETCH_COUNT_SUCCESS,
    payload: { actionType, data }
})

export const fetchCountError = (actionType: string, data: any): CountActionType => ({
    type: ActionTypes.FETCH_COUNT_FAIL,
    payload: { actionType, data }
})