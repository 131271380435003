import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { QuotationActionTypes } from './constants';
import {
  createQuotation,
  fetchQuotations,
  fetchQuotationById,
  editQuotation,
  fetchQuotationByProjectId,
} from 'helpers/api/quotation';
import {
  createQuotationSuccess,
  createQuotationFailure,
  fetchQuotationsSuccess,
  fetchQuotationsFailure,
  fetchQuotationByIdSuccess,
  fetchQuotationByIdFailure,
  editQuotationSuccess,
  editQuotationFailure,
  fetchQuotationByProjectIdSuccess,
  fetchQuotationByProjectIdFailure,
} from './actions';

function* fetchQuotationsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchQuotations, page, limit , sortBy, sort, searchBy);
    const quotationsData = response.data; 
    yield put(fetchQuotationsSuccess(quotationsData));
  } catch (e: any) {
    yield put(fetchQuotationsFailure(e));
  }
}

function* createQuotationSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createQuotation, data);
    const quotationData = response.data;
    yield put(createQuotationSuccess(quotationData));
    yield put({ type: QuotationActionTypes.FETCH_QUOTATIONS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createQuotationFailure(e));
  }
}

function* fetchQuotationByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchQuotationById, action.payload);
    const quotationData = response.data;
    yield put(fetchQuotationByIdSuccess(quotationData));
  } catch (e: any) {
    yield put(fetchQuotationByIdFailure(e));
  }
}

function* fetchQuotationByProjectIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchQuotationByProjectId, action.payload);
    const indentData = response.data;
    yield put(fetchQuotationByProjectIdSuccess(indentData));
  } catch (e: any) {
    yield put(fetchQuotationByProjectIdFailure(e));
  }
}

function* editQuotationSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editQuotation, id, data);
    const quotationData = response.data;
    yield put(editQuotationSuccess(quotationData));
    yield put({ type: QuotationActionTypes.FETCH_QUOTATION_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editQuotationFailure(e));
  }
}

function* watchQuotations() {
  yield takeEvery(QuotationActionTypes.FETCH_QUOTATIONS_REQUEST, fetchQuotationsSaga);
  yield takeEvery(QuotationActionTypes.CREATE_QUOTATION_REQUEST, createQuotationSaga);
  yield takeEvery(QuotationActionTypes.FETCH_QUOTATION_BY_ID_REQUEST, fetchQuotationByIdSaga);
  yield takeEvery(QuotationActionTypes.EDIT_QUOTATION_REQUEST, editQuotationSaga);
  yield takeEvery(QuotationActionTypes.FETCH_QUOTATION_BY_PROJECT_ID_REQUEST, fetchQuotationByProjectIdSaga);

}

export default watchQuotations;
