export enum ActionTypes{

FETCH_RAW_MATERIAL_LIST = "FETCH_RAW_MATERIAL_LIST",
FETCH_RAW_MATERIAL_LIST_SUCCESS = "FETCH_RAW_MATERIAL_LIST_SUCCESS",
FETCH_RAW_MATERIAL_LIST_FAILURE = "FETCH_RAW_MATERIAL_LIST_FAILURE",
RESET_RAW_MATERIAL_LIST = "RESET_RAW_MATERIAL_LIST",


CREATE_RAW_MATERIAL = "CREATE_RAW_MATERIAL",
CREATE_RAW_MATERIAL_SUCCESS = "CREATE_RAW_MATERIAL_SUCCESS",
CREATE_RAW_MATERIAL_FAILURE = "CREATE_RAW_MATERIAL_FAILURE",
RESET_CREATE_RAW_MATERIAL = "RESET_CREATE_RAW_MATERIAL",


UPDATE_RAW_MATERIAL = "UPDATE_RAW_MATERIAL",
UPDATE_RAW_MATERIAL_SUCCESS = "UPDATE_RAW_MATERIAL_SUCCESS",
UPDATE_RAW_MATERIAL_FAILURE = "UPDATE_RAW_MATERIAL_FAILURE",
RESET_UPDATE_RAW_MATERIAL = "RESET_UPDATE_RAW_MATERIAL",


DELETE_RAW_MATERIAL = "DELETE_RAW_MATERIAL",
DELETE_RAW_MATERIAL_SUCCESS = "DELETE_RAW_MATERIAL_SUCCESS",
DELETE_RAW_MATERIAL_FAILURE = "DELETE_RAW_MATERIAL_FAILURE",
RESET_DELETE_RAW_MATERIAL = "RESET_DELETE_RAW_MATERIAL",

FETCH_SINGLE_RAW_MATERIAL = "FETCH_SINGLE_RAW_MATERIAL",
FETCH_SINGLE_RAW_MATERIAL_SUCCESS = "FETCH_SINGLE_RAW_MATERIAL_SUCCESS",
FETCH_SINGLE_RAW_MATERIAL_FAILURE = "FETCH_SINGLE_RAW_MATERIAL_FAILURE",
RESET_FETCH_SINGLE_RAW_MATERIAL = "RESET_FETCH_SINGLE_RAW_MATERIAL",

FETCH_RAW_MATERIAL_UNITS = "FETCH_RAW_MATERIAL_UNITS",
FETCH_RAW_MATERIAL_UNITS_SUCCESS = "FETCH_RAW_MATERIAL_UNIT_SUCCESS",
FETCH_RAW_MATERIAL_UNITS_FAILURE = "FETCH_RAW_MATERIAL_UNIT_FAIL",
RESET_RAW_MATERIAL_UNITS = "RESET_RAW_MATERIAL_UNIT",


FETCH_RAW_MATERIAL_CHART = "FETCH_RAW_MATERIAL_CHART",
FETCH_RAW_MATERIAL_CHART_SUCCESS = "FETCH_RAW_MATERIAL_CHART_SUCCESS",
FETCH_RAW_MATERIAL_CHART_FAILURE = "FETCH_RAW_MATERIAL_CHART_FAILURE",
RESET_RAW_MATERIAL_CHART = "RESET_RAW_MATERIAL_CHART",

SET_RAW_MATERIAL_ERROR = "SET_RAW_MATERIAL_ERROR",

}