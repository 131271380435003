import { APICore } from "./apiCore";

const api = new APICore();

function fetchForm(params: { page: string, limit: string, sortBy: string, sort: string, type: any, searchBy?: string }) {
  let baseUrl = "";
  let userType = localStorage.getItem('userType');
  if (params.type) {
    baseUrl = `/form?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&userType=${userType}&type=${params.type}&searchBy=${params.searchBy ? params.searchBy : ''}`;
  } else {
    baseUrl = `/form?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&searchBy=${params.searchBy}`;
  }
  return api.get(`${baseUrl}`, "");
}

function fetchSingleForm(id: any) {
  const baseUrl = `/form/${id}`;
  return api.get(`${baseUrl}`, id);
}

function fetchSingleFormByType(type: string, subType: number) {
  const baseUrl = `/form/${type}/${subType}`;
  return api.get(`${baseUrl}`, "");
}

function fetchFormAnswers(params: { id: string, type: string, typeId: string, sortBy: string, sort: string, page: string, limit: string }) {
  const baseUrl = `/form/${params.id}/answer?type=${params.type}&typeId=${params.typeId}&sortBy=${params.sortBy}&sort=${params.sort}&page=${params.page}&limit=${params.limit}`;
  return api.update(`${baseUrl}`, { type: params.type, typeId: params.typeId });
}


function fetchAllForm(data: any) {
  let userType = localStorage.getItem('userType');
  data.userType = userType;
  const baseUrl = `/form`;
  return api.get(`${baseUrl}`, data);
}

function createForm(body: any) {
  const baseUrl = "/form";
  return api.create(`${baseUrl}`, body);
}

function updateForm(body: any) {
  const baseUrl = `/form/${body.id}`;
  return api.update(`${baseUrl}`, body);
}

function fetchEnquiryForm(params: {}) {
  const baseUrl = `/form/getForm`;
  return api.get(`${baseUrl}`, params);
}

function deleteForm(params: any) {
  const baseUrl = `/form/${params.id}`;
  return api.delete(`${baseUrl}`);
}

function updateEnquiryForm(params: any) {
  const baseUrl = `/enquiry/enquiryForm/${params}`;
  return api.update(`${baseUrl}`, params)
}
function getFormByUUID(body: any) {
  const baseUrl = `/form/getFormByUUID/`;
  return api.create(`${baseUrl}`, body);
}


export { fetchForm, fetchSingleForm, createForm, updateForm, fetchEnquiryForm, fetchAllForm, fetchFormAnswers, deleteForm, fetchSingleFormByType, updateEnquiryForm, getFormByUUID };
