export enum ActionTypes {
    SET_VENDOR = "SET_VENDOR",
    SET_ERROR = "SET_ERROR",
    CREATE_VENDOR = "CREATE_VENDOR",
    FETCH_VENDOR = "FETCH_VENDOR",
    UPDATE_VENDOR = "UPDATE_VENDOR",
    FETCH_SINGLE_VENDOR = "FETCH_SINGLE_VENDOR",
    SET_SINGLE_VENDOR = "SET_SINGLE_VENDOR",
    UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS",
    UPDATE_VENDOR_FAIL = "UPDATE_VENDOR_FAIL",
    ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS",
    ADD_VENDOR_FAIL = "ADD_VENDOR_FAIL",
    DASHBOARD_VENDOR_DATA = "DASHBOARD_VENDOR_DATA",
    SET_DASHBOARD_VENDOR_DATA = "SET_DASHBOARD_VENDOR_DATA",
    RESET_VENDOR = "RESET_VENDOR",
    DELETE_VENDOR = "DELETE_VENDOR",
    DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS",
    DELETE_VENDOR_FAIL = "DELETE_VENDOR_FAIL",

    //! File upload for transporter
    CREATE_VENDOR_WITH_FILES = "CREATE_VENDOR_WITH_FILES",
    CREATE_VENDOR_WITH_FILES_SUCCESS = "CREATE_VENDOR_WITH_FILES_SUCCESS",
    CREATE_VENDOR_WITH_FILES_FAIL = "CREATE_VENDOR_WITH_FILES_FAIL",
    // GET_SINGLE_VENDOR_WITH_FILES = "GET_SINGLE_VENDOR_WITH_FILES",
    UPDATE_VENDOR_WITH_FILES = "UPDATE_VENDOR_WITH_FILES",
    UPDATE_VENDOR_SUCCESS_WITH_FILES = "UPDATE_VENDOR_SUCCESS_WITH_FILES",
    UPDATE_VENDOR_FAIL_WITH_FILES = "UPDATE_VENDOR_FAIL_WITH_FILES",

    SEARCH_VENDOR = "SEARCH_VENDOR",
    SEARCH_VENDOR_SUCCESS = "SEARCH_VENDOR_SUCCESS",
    SEARCH_VENDOR_FAIL = "SEARCH_VENDOR_FAIL",
    SEARCH_VENDOR_RESET_STATE = "SEARCH_VENDOR_RESET_STATE"

}


