import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchAllFiles, updloadFiles, downFileById, downloadFileByPath, fetchTags, deleteFileById, fetchFilesById, getSingleFileById } from "helpers"
import { fileApiError, fileApiResponse, fileDeleteResponse, fileDownloadResponse, fileUploadResponse,resetFileUpdate } from "./actions";
import { ActionTypes } from "./constants";
import { updateFileById } from "helpers/api/files";

function* files(params: any): SagaIterator {
    try {
        const response = yield call(fetchAllFiles, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort });
        const filesData = response.data;
        yield put(fileApiResponse(ActionTypes.FETCH_FILES, filesData));
    } catch (e: any) {
        yield put(fileApiError(ActionTypes.FETCH_FILES, e))
    }
}

function* filesById(params: any): SagaIterator {
    try {
        const response = yield call(fetchFilesById, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, id: params.payload.data.id, type: params.payload.data.type });
        const filesData = response.data;
        yield put(fileApiResponse(ActionTypes.FILES_BY_ID, filesData));
    } catch (e: any) {
        yield put(fileApiError(ActionTypes.FILES_BY_ID, e))
    }
}


function* _uploadFile(params: any): SagaIterator {
    try {
        const response = yield call(updloadFiles, { formData: params.payload.data.formData })
        const filesData = response.data;
        console.log(filesData);

        yield put(fileUploadResponse(ActionTypes.UPLOAD_FILE_SUCCESS, filesData))
    } catch (err) {
        yield put(fileUploadResponse(ActionTypes.UPLOAD_FILE_FAIL, err))
    }
}

function* _downloadById(params: any): SagaIterator {
    try {
        const response = yield call(downFileById, { id: params.payload.data.id })
        const filesData = response.data;
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const fileName = response.headers['content-disposition']?.split('filename=')[1] || 'downloaded-file';
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log("sdchbj", filesData)

        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_SUCCESS, filesData))
    } catch (err) {
        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_FAIL, err))
    }
}
function* _viewFileById(params: any): SagaIterator {
    try {
        const response = yield call(downFileById, { id: params.payload.data.id });
        const filesData = response.data;
        const blob = new Blob([filesData], { type: response.headers['content-type'] });
        const blobUrl = window.URL.createObjectURL(blob);
        const newTab = window.open(blobUrl, '_blank');
        if (newTab) {
            newTab.onload = () => {
                window.URL.revokeObjectURL(blobUrl);
            };
        }
        console.log("sdchbj", filesData);
        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_SUCCESS, filesData));
    } catch (err) {
        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_FAIL, err));
    }
}

function* _downloadByPath(params: any): SagaIterator {
    try {
        const response = yield call(downloadFileByPath, { path: params.payload.data.path })
        const filesData = response.data;
        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_SUCCESS, filesData))
    } catch (err) {
        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_FAIL, err))
    }
}

function* _deleteById(params: any): SagaIterator {
    try {
        const response = yield call(deleteFileById, { id: params.payload.data.id });
        const filesData = response.data;
        yield put(fileDeleteResponse(ActionTypes.DELETE_SUCCESS, filesData))
    } catch (e: any) {
        yield put(fileDeleteResponse(ActionTypes.DELETE_FAIL, e))
    }
}


function* _singleFileById(params: any): SagaIterator {
    try {
        const response = yield call(getSingleFileById, { id: params.payload.data.id });
        const filesData = response.data;
        yield put(fileApiResponse(ActionTypes.FETCH_SINGLE_FILE_BY_ID, filesData))
    } catch (e: any) {
        yield put(fileApiError(ActionTypes.FETCH_SINGLE_FILE_BY_ID, e))
    }
}

function* _tags(): SagaIterator {
    try {
        const response = yield call(fetchTags);
        const tagData = response.data;
        yield put(fileApiResponse(ActionTypes.FETCH_TAG, tagData))
    } catch (err) {
        yield put(fileApiError(ActionTypes.SET_TAG, err))
    }
}


function* _updateFile(params:any):SagaIterator{
    try {
        const response = yield call(updateFileById, { id: params.payload.data.id,updateBody: params.payload.data.updateBody});
        const filesData = response.data;
        yield put(fileApiResponse(ActionTypes.UPDATE_FILE_SUCCESS, filesData))
        yield put(resetFileUpdate())
    } catch (e: any) {
        yield put(fileApiResponse(ActionTypes.UPDATE_FILE_FAIL, e))
    } 
}

function* watchFiles() {
    yield takeEvery(ActionTypes.FETCH_FILES, files);
    yield takeEvery(ActionTypes.DOWNLOAD_FILE_BY_ID, _downloadById);
    yield takeEvery(ActionTypes.VIEW_FILE_BY_ID, _viewFileById);
    yield takeEvery(ActionTypes.DOWNLOAD_FILE_BY_PATH, _downloadByPath)
    yield takeEvery(ActionTypes.UPLOAD_FILE, _uploadFile)
    yield takeEvery(ActionTypes.FETCH_TAG, _tags)
    yield takeEvery(ActionTypes.DELETE_FILE_BY_ID, _deleteById)
    yield takeEvery(ActionTypes.FILES_BY_ID, filesById)
    yield takeEvery(ActionTypes.FETCH_SINGLE_FILE_BY_ID, _singleFileById)
    yield takeEvery(ActionTypes.UPDATE_FILE_BY_ID, _updateFile)
}


export default watchFiles;