import { IQCEquipmentActionTypes } from './constant';

const INIT_STATE = {
  count: 0,
  loading: true,
  iqcEquipment: [],
  error: {},
  success: false,
  singleIQCEquipment: {},
};

const IQCEquipmentReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case IQCEquipmentActionTypes.CREATE_IQCEQUIPMENT_REQUEST:
    case IQCEquipmentActionTypes.EDIT_IQCEQUIPMENT_REQUEST:
    case IQCEquipmentActionTypes.FETCH_IQCEQUIPMENTS_REQUEST:
    case IQCEquipmentActionTypes.FETCH_IQCEQUIPMENT_BY_ID_REQUEST:
    case IQCEquipmentActionTypes.RESET_IQCEQUIPMENT_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case IQCEquipmentActionTypes.SET_SINGLE_IQCEQUIPMENT:
      console.log(action.payload,"shubhi")
      return { ...state , singleIQCEquipment: action.payload.data, loading:false }
    
    case IQCEquipmentActionTypes.CREATE_IQCEQUIPMENT_SUCCESS:
      return {
        ...state,
        iqcEquipment: [...state.iqcEquipment, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case IQCEquipmentActionTypes.EDIT_IQCEQUIPMENT_SUCCESS:
      const updatedIQCEquipments = state.iqcEquipment.map((iqcEquipment: any) =>
        iqcEquipment.id === action.payload.data.id ? action.payload.data : iqcEquipment
      );
      return {
        ...state,
        iqcEquipment: updatedIQCEquipments,
        loading: false,
        error: {},
        success: true,
      };

    case IQCEquipmentActionTypes.FETCH_IQCEQUIPMENTS_SUCCESS:
      console.log(action.payload,"yoooo")
      return {
        ...state,
        iqcEquipment: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case IQCEquipmentActionTypes.FETCH_IQCEQUIPMENT_BY_ID_SUCCESS:
      return {
        ...state,
        singleIQCEquipment: action.payload.data,
        iqcEquipment: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case IQCEquipmentActionTypes.RESET_SINGLE_IQCEQUIPMENT:
        return {
            ...state,
            singleIQCEquipment: null,
        };

    case IQCEquipmentActionTypes.CREATE_IQCEQUIPMENT_FAILURE:
    case IQCEquipmentActionTypes.EDIT_IQCEQUIPMENT_FAILURE:
    case IQCEquipmentActionTypes.FETCH_IQCEQUIPMENTS_FAILURE:
    case IQCEquipmentActionTypes.FETCH_IQCEQUIPMENT_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default IQCEquipmentReducer;
