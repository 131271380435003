import { InventoryActionTypes as ActionTypes } from './constant';

export const createInventoryRequest = (data: any) => ({
  type: ActionTypes.CREATE_INVENTORY_REQUEST,
  payload: {data},
});

export const resetInventorySuccessState = () => ({
  type: ActionTypes.RESET_INVENTORY_SUCCESS_STATE,
});

export const createInventorySuccess = (response: any) => ({
  type: ActionTypes.CREATE_INVENTORY_SUCCESS,
  payload: response,
});

export const createInventoryFailure = (error: any) => ({
  type: ActionTypes.CREATE_INVENTORY_FAILURE,
  payload: error,
});

export const fetchInventorysRequest = (page?: number, limit?: number, sortBy?: string,
  sort?: string,
  searchBy?: string) => ({
  
  type: ActionTypes.FETCH_INVENTORYS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchInventorysSuccess = (response: any) => ({
  type: ActionTypes.FETCH_INVENTORYS_SUCCESS,
  payload: response,
});

export const fetchInventorysFailure = (error: any) => ({
  type: ActionTypes.FETCH_INVENTORYS_FAILURE,
  payload: error,
});

export const fetchInventorysHistoryRequest = (page?: number, limit?: number, sortBy?: string,
  sort?: string,
  searchBy?: string) => ({
  
  type: ActionTypes.FETCH_INVENTORYSHISTORY_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchInventorysHistorySuccess = (response: any) => ({
  type: ActionTypes.FETCH_INVENTORYSHISTORY_SUCCESS,
  payload: response,
});

export const fetchInventorysHistoryFailure = (error: any) => ({
  type: ActionTypes.FETCH_INVENTORYSHISTORY_FAILURE,
  payload: error,
});

export const fetchInventoryByIdRequest = (id: number) => {
  console.log("Action Creator: fetchInventoryByIdRequest called with id:", id); // Debug log
  return{
  type: ActionTypes.FETCH_INVENTORY_BY_ID_REQUEST,
  payload: id,
  }};

export const fetchInventoryByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_INVENTORY_BY_ID_SUCCESS,
  payload: response,
});

export const fetchInventoryByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_INVENTORY_BY_ID_FAILURE,
  payload: error,
});

export const editInventoryRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_INVENTORY_REQUEST,
  payload: { id, data },
});

export const editInventorySuccess = (response: any) => ({
  type: ActionTypes.EDIT_INVENTORY_SUCCESS,
  payload: response,
});

export const editInventoryFailure = (error: any) => ({
  type: ActionTypes.EDIT_INVENTORY_FAILURE,
  payload: error,
});

export const resetSingleInventory = () => ({
  type: ActionTypes.RESET_SINGLE_INVENTORY,
});

export const inventoryApiResponse = (actionType: string, data: any) => {
  console.log(actionType, "herre")

  switch (actionType) {
    case ActionTypes.FETCH_INVENTORYS_SUCCESS:
    case ActionTypes.FETCH_INVENTORYSHISTORY_SUCCESS:
    case ActionTypes.CREATE_INVENTORY_SUCCESS:
    case ActionTypes.EDIT_INVENTORY_SUCCESS:
      return {
        type: actionType,
        payload: data,
      };
    case ActionTypes.FETCH_INVENTORY_BY_ID_SUCCESS:
      console.log("shubhi hii")
      return {
        type: ActionTypes.SET_SINGLE_INVENTORY,
        payload: data
      }

    case ActionTypes.FETCH_INVENTORYS_FAILURE:
    case ActionTypes.FETCH_INVENTORYSHISTORY_FAILURE:
    case ActionTypes.FETCH_INVENTORY_BY_ID_FAILURE:
    case ActionTypes.CREATE_INVENTORY_FAILURE:
    case ActionTypes.EDIT_INVENTORY_FAILURE:
      return {
        type: actionType,
        payload: { error: "Error while setting data to store!" },
      };
    default:
      return {
        type: ActionTypes.FETCH_INVENTORYS_FAILURE,
        payload: { actionType, error: "Unknown action type" },
      };
  }
};

export const inventoryApiError = (actionType: string, error: any) => {
  console.log(actionType, "herre")
  switch (actionType) {
    case ActionTypes.CREATE_INVENTORY_REQUEST:
    case ActionTypes.EDIT_INVENTORY_REQUEST:
    case ActionTypes.FETCH_INVENTORYS_REQUEST:
    case ActionTypes.FETCH_INVENTORYSHISTORY_REQUEST:
    case ActionTypes.FETCH_INVENTORY_BY_ID_REQUEST:
      return {
        type: ActionTypes.CREATE_INVENTORY_FAILURE,
        payload: { error }
      };
    default:
      return {
        type: ActionTypes.CREATE_INVENTORY_FAILURE,
        payload: { error: "Error while setting data to store!" }
      };
  }
};
