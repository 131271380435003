import { APICore } from "./apiCore";

const api = new APICore();

function fetchViewCount() {
    const baseUrl = `/getview/`;
    return api.get(`${baseUrl}`, '');
}


export { fetchViewCount }