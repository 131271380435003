import { APICore } from "./apiCore";

const api = new APICore();

function fetchAllZohoVendors(page: any, limit: any) {
    const baseUrl = `/zoho-vendor/list/`
    const params = new URLSearchParams({
        page: page,
        limit: limit
    }).toString();
    const url = `${baseUrl}?${params}`;

    return api.get(url, "");
}

function syncZohoVendors() {
    const baseURL = `/zoho-vendor/list/sync`
    return api.create(`${baseURL}`, "");
}


export { fetchAllZohoVendors, syncZohoVendors };
