import { APICore } from "./apiCore";
const api = new APICore();

function createCustomerQuotation(data: any) {
    const baseUrl = `/customer-quotation/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchCustomerQuotations(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/customer-quotation/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchCustomerQuotationById(id: number) {
    const url = `/customer-quotation/${id}`;
    return api.get(url, "");
}

function editCustomerQuotation(id: number, data: any) {
    const url = `/customer-quotation/${id}`;
    return api.update(url, data);
}


async function createCustomerQuotationWithFiles(params: { quotationData: any, imageData?: any }) {
    try {
        const baseUrl = '/customer-quotation';

        const createdQuotation = await api.create(baseUrl, { ...params.quotationData });

        const quotationResponse = createdQuotation.data;

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'Customer Quotation');
            params.imageData.append('typeId', String(quotationResponse?.data?.newQuotation?.id));
            params.imageData.append('tagId', "52");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: quotationResponse?.data?.newQuotation?.id,
                body: {
                    ...quotationResponse?.data?.newQuotation,
                    image_id: newImageDataResponse,
                }
            };

            const baseRfqUrl = `/customer-quotation/updateImage/${quotationResponse?.data?.newQuotation?.id}`;
            const updateRfq = await api.update(baseRfqUrl, { ...updatedBody.body });

            return updateRfq;
        }

        return quotationResponse;

    } catch (error) {
        console.error('Error creating quotation with files:', error);
        throw error;
    }
}

async function updateCustomerQuotationWithFiles(params: { id: number, quotationData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'Customer Quotation');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "52");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.quotationData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/customer-quotation/${params.id}`;
        return api.update(baseUrl, {
            ...params.quotationData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating quotation with files:', error);
        throw error;
    }
}




export { createCustomerQuotation, fetchCustomerQuotationById, fetchCustomerQuotations, editCustomerQuotation, createCustomerQuotationWithFiles, updateCustomerQuotationWithFiles };
