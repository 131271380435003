export enum IQCEquipmentActionTypes {
    CREATE_IQCEQUIPMENT_REQUEST = 'CREATE_IQCEQUIPMENT_REQUEST',
    CREATE_IQCEQUIPMENT_SUCCESS = 'CREATE_IQCEQUIPMENT_SUCCESS',
    CREATE_IQCEQUIPMENT_FAILURE = 'CREATE_IQCEQUIPMENT_FAILURE',
    FETCH_IQCEQUIPMENTS_REQUEST = 'FETCH_IQCEQUIPMENTS_REQUEST',
    FETCH_IQCEQUIPMENTS_SUCCESS = 'FETCH_IQCEQUIPMENTS_SUCCESS',
    FETCH_IQCEQUIPMENTS_FAILURE = 'FETCH_IQCEQUIPMENTS_FAILURE',
    FETCH_IQCEQUIPMENT_BY_ID_REQUEST = 'FETCH_IQCEQUIPMENT_BY_ID_REQUEST',
    FETCH_IQCEQUIPMENT_BY_ID_SUCCESS = 'FETCH_IQCEQUIPMENT_BY_ID_SUCCESS',
    FETCH_IQCEQUIPMENT_BY_ID_FAILURE = 'FETCH_IQCEQUIPMENT_BY_ID_FAILURE',
    EDIT_IQCEQUIPMENT_REQUEST = 'EDIT_IQCEQUIPMENT_REQUEST',
    EDIT_IQCEQUIPMENT_SUCCESS = 'EDIT_IQCEQUIPMENT_SUCCESS',
    EDIT_IQCEQUIPMENT_FAILURE = 'EDIT_IQCEQUIPMENT_FAILURE',
    RESET_IQCEQUIPMENT_SUCCESS_STATE = 'RESET_IQCEQUIPMENT_SUCCESS_STATE',
    FETCH_SINGLE_IQCEQUIPMENT = 'FETCH_SINGLE_IQCEQUIPMENT',
    SET_SINGLE_IQCEQUIPMENT = 'SET_SINGLE_IQCEQUIPMENT',
    RESET_SINGLE_IQCEQUIPMENT = 'RESET_SINGLE_IQCEQUIPMENT'
  }
  